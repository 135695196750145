body {
  background: #000;
  max-width: none;
}

body.cisco-live-override {
  background: #14284d;
}

body.cui {
  max-width: none;
}

.cui .App {
  .btn.btn--primary.disabled, .btn.btn--primary[disabled] {
    opacity: .5;
  }

  .dropdown__menu {
    width: auto;
    min-width: 150px;
    max-width: 600px;
  }

  .dropdown--tail {
    .dropdown__menu::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      margin: 0;
      position: absolute;
      transform: rotate(45deg);
      left: 10px;
      right: auto;
      top: -5px;
      box-shadow: -1px -1px 0 0 #dfdfdf;
      background: #fff;
    }
  }
  
  .dropdown--center {
    .dropdown__menu {
      left: 50%;

      &::after {
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  .dropdown.active.dropdown--center {
    .dropdown__menu {
      transform: translate(-50%, 0);
    }
  }
}