@import '../cisco-ui/src/scss/utilities/variables';

$base-font: 10;

$header-height-regular: 15vh;
$footer-height-regular: 10vh;

$full-body-height: 100vh;
$full-body-height-regular: calc(
  #{($full-body-height - ($header-height-regular + $footer-height-regular))} -
    40px
);

.animate {
  transition: 0.2s all ease-in-out;
}

// colours:
$black: #000;
$disabled: #58585b;
$white: #fff;
$sky: #00bceb;
$gray-100: #f8f8f8;
$blue: #017cad;
$charcoal: #4a4a4a;
$ash: #d8d8d8;
$red: #ff0000;
$light-yellow: #fcf5d4;

// tabs:
$cui-tab-border-active: var($sky);
$cui-active-color: #212529;
$cui-inactive-color: #495057;
$cui-tab-background-active: var($white);
$cui-tab-background-inactive: var($gray-100);
$cui-tab-background-hover: var($gray-100);
$cui-shadow-outset-color: rgba(0, 0, 0, 0.15);

// dropdown buttons:
$cui-background-hover: var($gray-100);
$cui-background-active: var($white);
$cui-dropdown-icon-selected: var($sky);

// generic:
$cui-spacing: 16px;
$cui-spacing-half: calc(var($cui-spacing) * 0.5);
$cui-border: 1px solid #dee2e6;
$cui-border-radius: 2px;
$cui-vertical-tab-padding: var($cui-spacing-half) var($cui-spacing);
