/*! 
 * cisco-ui - Cisco UI Kit
 * @version 1.3.1-official
 * @home https://www.cisco.com
 * @license 2018 Cisco Systems, Inc. All rights reserved. Terms and Conditions: http://cisco.com/en/US/swassets/sw293/sitewide_important_notices.html
 */
@charset "UTF-8";
.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1440px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.full-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.divider--vertical > :after {
  color: #9e9ea2;
  content: '|';
  margin: 0 5px;
  padding: 0;
}

.cui .btn,
.cui .btn.btn--small,
.cui .btn.btn--icon,
.cui .btn.btn--icon.btn--small,
.cui .btn.btn--icon.btn--large,
.cui.cui--compressed .btn,
.cui.cui--compressed .btn.btn--icon,
.cui a:not(.btn),
.cui .link {
  display: inline-block;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-width: 0;
  border-radius: 30px;
  font-weight: inherit;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
}

@font-face {
  font-family: 'CiscoSans';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/CiscoSans/CiscoSansTTThin.woff2') format('woff2'),
    url('../fonts/CiscoSans/CiscoSansTTThin.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'CiscoSans';
  font-style: oblique;
  font-weight: 100;
  src: url('../fonts/CiscoSans/CiscoSansTTThinOblique.woff2') format('woff2'),
    url('../fonts/CiscoSans/CiscoSansTTThinOblique.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'CiscoSans';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/CiscoSans/CiscoSansTTExtraLight.woff2') format('woff2'),
    url('../fonts/CiscoSans/CiscoSansTTExtraLight.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'CiscoSans';
  font-style: oblique;
  font-weight: 200;
  src: url('../fonts/CiscoSans/CiscoSansTTExtraLightOblique.woff2')
      format('woff2'),
    url('../fonts/CiscoSans/CiscoSansTTExtraLightOblique.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'CiscoSans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/CiscoSans/CiscoSansTTLight.woff2') format('woff2'),
    url('../fonts/CiscoSans/CiscoSansTTLight.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'CiscoSans';
  font-style: oblique;
  font-weight: 300;
  src: url('../fonts/CiscoSans/CiscoSansTTLightOblique.woff2') format('woff2'),
    url('../fonts/CiscoSans/CiscoSansTTLightOblique.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'CiscoSans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/CiscoSans/CiscoSansTTRegular.woff2') format('woff2'),
    url('../fonts/CiscoSans/CiscoSansTTRegular.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'CiscoSans';
  font-style: oblique;
  font-weight: 400;
  src: url('../fonts/CiscoSans/CiscoSansTTRegularOblique.woff2') format('woff2'),
    url('../fonts/CiscoSans/CiscoSansTTRegularOblique.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'CiscoSans';
  font-style: bold;
  font-weight: 700;
  src: url('../fonts/CiscoSans/CiscoSansTTBold.woff2') format('woff2'),
    url('../fonts/CiscoSans/CiscoSansTTBold.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'CiscoSans';
  font-style: oblique;
  font-weight: 700;
  src: url('../fonts/CiscoSans/CiscoSansTTBoldOblique.woff2') format('woff2'),
    url('../fonts/CiscoSans/CiscoSansTTBoldOblique.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'CiscoSans';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/CiscoSans/CiscoSansTTHeavy.woff2') format('woff2'),
    url('../fonts/CiscoSans/CiscoSansTTHeavy.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'CiscoSans';
  font-style: oblique;
  font-weight: 900;
  src: url('../fonts/CiscoSans/CiscoSansTTHeavyOblique.woff2') format('woff2'),
    url('../fonts/CiscoSans/CiscoSansTTHeavyOblique.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

html {
  height: 100%;
  font-size: 62.5%;
}

.cui body,
body.cui {
  color: #58585b;
  font-family: 'CiscoSans', 'Arial', sans-serif;
  line-height: 2rem;
  font-weight: 400;
  font-size: 1.4rem;
  position: relative;
  min-width: 320px;
  max-width: 2560px;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  direction: ltr;
  text-align: left;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'cui-font';
  src: url('../fonts/cui-font.eot');
  src: url('../fonts/cui-font.eot?#iefix') format('eot'),
    url('../fonts/cui-font.woff2') format('woff2'),
    url('../fonts/cui-font.woff') format('woff'),
    url('../fonts/cui-font.ttf') format('truetype'),
    url('../fonts/cui-font.svg#cui-font') format('svg');
}

.cui .dropdown__menu a.selected:after,
.cui .dropdown .btn.btn--dropdown:after,
.cui .checkbox .checkbox__input:after,
.cui .panel.panel--dkgray .checkbox .checkbox__input:after,
.cui .panel.panel--indigo .checkbox .checkbox__input:after,
.cui .panel.panel--success .checkbox .checkbox__input:after,
.cui .panel.panel--blue .checkbox .checkbox__input:after,
.cui .form-dropdown__chevron:after,
.cui .form-group .form-group__text.select:after,
.cui .form-group--error .form-group__text:after,
.cui .form-group--error .form-group__text.select:after,
.cui .sidebar ul li.sidebar__drawer > a:after,
.cui .sidebar ul li.sidebar-drawer > a:after,
*[class*='icon-'] {
  position: relative;
  display: inline-block;
  font-family: 'cui-font';
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  vertical-align: middle;
  text-indent: 0.5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon--medium {
  font-size: 3.2rem;
}

.icon--large {
  font-size: 4.8rem;
}

.icon-4-way-nav:before {
  content: '';
}

.icon-ac-power:before {
  content: '';
}

.icon-account:before {
  content: '';
}

.icon-acessibility:before {
  content: '';
}

.icon-active-speaker-cross:before {
  content: '';
}

.icon-active-speaker:before {
  content: '';
}

.icon-activities:before {
  content: '';
}

.icon-add-contact:before {
  content: '';
}

.icon-add-contain:before {
  content: '';
}

.icon-add-outline:before {
  content: '';
}

.icon-add:before {
  content: '';
}

.icon-admin:before {
  content: '';
}

.icon-alarm:before {
  content: '';
}

.icon-alert-blank:before {
  content: '';
}

.icon-alert:before {
  content: '';
}

.icon-alerting:before {
  content: '';
}

.icon-all-calls:before {
  content: '';
}

.icon-analysis:before {
  content: '';
}

.icon-android-home:before {
  content: '';
}

.icon-animation:before {
  content: '';
}

.icon-annotation:before {
  content: '';
}

.icon-answer-oldest:before {
  content: '';
}

.icon-applause:before {
  content: '';
}

.icon-application:before {
  content: '';
}

.icon-applications:before {
  content: '';
}

.icon-arrow-left-tail:before {
  content: '';
}

.icon-arrow-right-tail:before {
  content: '';
}

.icon-arrow:before {
  content: '';
}

.icon-asterisk:before {
  content: '';
}

.icon-at-contain:before {
  content: '';
}

.icon-at:before {
  content: '';
}

.icon-attachment:before {
  content: '';
}

.icon-audio-broadcast:before {
  content: '';
}

.icon-audio-min:before {
  content: '';
}

.icon-audio-plus:before {
  content: '';
}

.icon-audio-settings:before {
  content: '';
}

.icon-aux-camera:before {
  content: '';
}

.icon-back:before {
  content: '';
}

.icon-backup-data:before {
  content: '';
}

.icon-battery-0:before {
  content: '';
}

.icon-battery-25:before {
  content: '';
}

.icon-battery-50:before {
  content: '';
}

.icon-battery-75:before {
  content: '';
}

.icon-battery-full:before {
  content: '';
}

.icon-battery:before {
  content: '';
}

.icon-bell-cross:before {
  content: '';
}

.icon-bell:before {
  content: '';
}

.icon-blocked:before {
  content: '';
}

.icon-bluetooth-contain-cross:before {
  content: '';
}

.icon-bluetooth-contained:before {
  content: '';
}

.icon-bluetooth-outline:before {
  content: '';
}

.icon-bluetooth:before {
  content: '';
}

.icon-bookmark:before {
  content: '';
}

.icon-briefcase:before {
  content: '';
}

.icon-brightness:before {
  content: '';
}

.icon-broadcast-message:before {
  content: '';
}

.icon-broken-image:before {
  content: '';
}

.icon-browser:before {
  content: '';
}

.icon-bug:before {
  content: '';
}

.icon-calendar-meetings:before {
  content: '';
}

.icon-calendar-weekly:before {
  content: '';
}

.icon-calendar:before {
  content: '';
}

.icon-call-forward-divert:before {
  content: '';
}

.icon-call-handling:before {
  content: '';
}

.icon-call-log:before {
  content: '';
}

.icon-call-rate:before {
  content: '';
}

.icon-callback:before {
  content: '';
}

.icon-camera:before {
  content: '';
}

.icon-cart:before {
  content: '';
}

.icon-certified:before {
  content: '';
}

.icon-chapters:before {
  content: '';
}

.icon-charging:before {
  content: '';
}

.icon-chats:before {
  content: '';
}

.icon-check-outline:before {
  content: '';
}

.icon-check-square-o:before {
  content: '';
}

.icon-check-square:before {
  content: '';
}

.icon-check:before {
  content: '';
}

.icon-chevron-down:before {
  content: '';
}

.icon-chevron-left:before {
  content: '';
}

.icon-chevron-right-circle:before {
  content: '';
}

.icon-chevron-right:before {
  content: '';
}

.icon-chevron-up:before {
  content: '';
}

.icon-circle:before {
  content: '';
}

.icon-cisco:before {
  content: '';
}

.icon-clipboard-md:before {
  content: '';
}

.icon-clipboard-sm:before {
  content: '';
}

.icon-clipboard:before {
  content: '';
}

.icon-clock:before {
  content: '';
}

.icon-close-keyboard:before {
  content: '';
}

.icon-close:before {
  content: '';
}

.icon-cloud-upload:before {
  content: '';
}

.icon-cloud:before {
  content: '';
}

.icon-cog:before {
  content: '';
}

.icon-comment:before {
  content: '';
}

.icon-communities:before {
  content: '';
}

.icon-community:before {
  content: '';
}

.icon-compass:before {
  content: '';
}

.icon-computer:before {
  content: '';
}

.icon-conference:before {
  content: '';
}

.icon-contact-card:before {
  content: '';
}

.icon-contact:before {
  content: '';
}

.icon-contract-login-md:before {
  content: '';
}

.icon-contract-login-sm:before {
  content: '';
}

.icon-contract-login:before {
  content: '';
}

.icon-cpu-chip:before {
  content: '';
}

.icon-create-page:before {
  content: '';
}

.icon-data-usage:before {
  content: '';
}

.icon-day:before {
  content: '';
}

.icon-dc-power:before {
  content: '';
}

.icon-default-app:before {
  content: '';
}

.icon-delete:before {
  content: '';
}

.icon-desk-phone:before {
  content: '';
}

.icon-devices:before {
  content: '';
}

.icon-diagnostics:before {
  content: '';
}

.icon-dial:before {
  content: '';
}

.icon-directory:before {
  content: '';
}

.icon-disc-not-connected:before {
  content: '';
}

.icon-disc:before {
  content: '';
}

.icon-display:before {
  content: '';
}

.icon-dms:before {
  content: '';
}

.icon-document-camera-cross:before {
  content: '';
}

.icon-document-camera:before {
  content: '';
}

.icon-document:before {
  content: '';
}

.icon-download-contain:before {
  content: '';
}

.icon-download:before {
  content: '';
}

.icon-draw:before {
  content: '';
}

.icon-dropdown:before {
  content: '';
}

.icon-dual-line:before {
  content: '';
}

.icon-edit-call:before {
  content: '';
}

.icon-edit:before {
  content: '';
}

.icon-email:before {
  content: '';
}

.icon-emoticons:before {
  content: '';
}

.icon-endpoint:before {
  content: '';
}

.icon-eraser:before {
  content: '';
}

.icon-error-outline:before {
  content: '';
}

.icon-error:before {
  content: '';
}

.icon-ethernet:before {
  content: '';
}

.icon-exclamation-circle:before {
  content: '';
}

.icon-exclamation-triangle:before {
  content: '';
}

.icon-exernal-calendar:before {
  content: '';
}

.icon-exit-contain:before {
  content: '';
}

.icon-exit-fullscreen:before {
  content: '';
}

.icon-exit-outline:before {
  content: '';
}

.icon-exit:before {
  content: '';
}

.icon-export:before {
  content: '';
}

.icon-extension-mobility:before {
  content: '';
}

.icon-eye-closed:before {
  content: '';
}

.icon-eye:before {
  content: '';
}

.icon-fbw:before {
  content: '';
}

.icon-feedback-clear:before {
  content: '';
}

.icon-feedback-result:before {
  content: '';
}

.icon-feedback:before {
  content: '';
}

.icon-ffw:before {
  content: '';
}

.icon-file-archive-o:before {
  content: '';
}

.icon-file-audio-o:before {
  content: '';
}

.icon-file-code-o:before {
  content: '';
}

.icon-file-excel-o:before {
  content: '';
}

.icon-file-image-o:before {
  content: '';
}

.icon-file-o:before {
  content: '';
}

.icon-file-pdf-o:before {
  content: '';
}

.icon-file-powerpoint-o:before {
  content: '';
}

.icon-file-text-o:before {
  content: '';
}

.icon-file-text:before {
  content: '';
}

.icon-file-video-o:before {
  content: '';
}

.icon-file-word-o:before {
  content: '';
}

.icon-file:before {
  content: '';
}

.icon-filter:before {
  content: '';
}

.icon-flagged:before {
  content: '';
}

.icon-folder:before {
  content: '';
}

.icon-forced-sign-in:before {
  content: '';
}

.icon-forward-to-mobility:before {
  content: '';
}

.icon-fullscreen:before {
  content: '';
}

.icon-general-source-cross:before {
  content: '';
}

.icon-general-source:before {
  content: '';
}

.icon-google-analytics:before {
  content: '';
}

.icon-gpu-graphicscard:before {
  content: '';
}

.icon-graph:before {
  content: '';
}

.icon-grid-view:before {
  content: '';
}

.icon-ground:before {
  content: '';
}

.icon-group-call:before {
  content: '';
}

.icon-group-chat:before {
  content: '';
}

.icon-headset-cross:before {
  content: '';
}

.icon-headset:before {
  content: '';
}

.icon-help-alt:before {
  content: '';
}

.icon-help-outline:before {
  content: '';
}

.icon-help:before {
  content: '';
}

.icon-hide-editor:before {
  content: '';
}

.icon-highlight-line:before {
  content: '';
}

.icon-highlight:before {
  content: '';
}

.icon-highlighter-check:before {
  content: '';
}

.icon-highlighter:before {
  content: '';
}

.icon-home:before {
  content: '';
}

.icon-hue:before {
  content: '';
}

.icon-hunt-group:before {
  content: '';
}

.icon-idefix:before {
  content: '';
}

.icon-image-contain:before {
  content: '';
}

.icon-image:before {
  content: '';
}

.icon-import:before {
  content: '';
}

.icon-inbox:before {
  content: '';
}

.icon-incoming-call:before {
  content: '';
}

.icon-info-circle:before {
  content: '';
}

.icon-info-outline:before {
  content: '';
}

.icon-info:before {
  content: '';
}

.icon-instant-meeting:before {
  content: '';
}

.icon-intercom-duplex-connected:before {
  content: '';
}

.icon-intercom-whisper:before {
  content: '';
}

.icon-intercom:before {
  content: '';
}

.icon-invite:before {
  content: '';
}

.icon-jump-out:before {
  content: '';
}

.icon-key-expansion-module:before {
  content: '';
}

.icon-keyboard-close:before {
  content: '';
}

.icon-keyboard:before {
  content: '';
}

.icon-keywords:before {
  content: '';
}

.icon-language:before {
  content: '';
}

.icon-laser-pointer:before {
  content: '';
}

.icon-layers:before {
  content: '';
}

.icon-layout:before {
  content: '';
}

.icon-leave-meeting:before {
  content: '';
}

.icon-left-arrow:before {
  content: '';
}

.icon-lightbulb:before {
  content: '';
}

.icon-like:before {
  content: '';
}

.icon-line-out-left:before {
  content: '';
}

.icon-line-out-right:before {
  content: '';
}

.icon-link-broken:before {
  content: '';
}

.icon-link:before {
  content: '';
}

.icon-list-menu:before {
  content: '';
}

.icon-list-view:before {
  content: '';
}

.icon-location:before {
  content: '';
}

.icon-lock-contain:before {
  content: '';
}

.icon-lock:before {
  content: '';
}

.icon-locked-speaker:before {
  content: '';
}

.icon-mail-read:before {
  content: '';
}

.icon-mail:before {
  content: '';
}

.icon-manage-cable:before {
  content: '';
}

.icon-maximize:before {
  content: '';
}

.icon-media-viewer:before {
  content: '';
}

.icon-meet-me:before {
  content: '';
}

.icon-meeting-room:before {
  content: '';
}

.icon-memory-ram:before {
  content: '';
}

.icon-merge-call:before {
  content: '';
}

.icon-message:before {
  content: '';
}

.icon-mic-in:before {
  content: '';
}

.icon-micro-blog:before {
  content: '';
}

.icon-microphone:before {
  content: '';
}

.icon-minimize:before {
  content: '';
}

.icon-missed-call:before {
  content: '';
}

.icon-mlpp-1:before {
  content: '';
}

.icon-mlpp-2:before {
  content: '';
}

.icon-mlpp-3:before {
  content: '';
}

.icon-mlpp-4:before {
  content: '';
}

.icon-mlpp-5:before {
  content: '';
}

.icon-mobile-phone:before {
  content: '';
}

.icon-mobile-presenter:before {
  content: '';
}

.icon-month:before {
  content: '';
}

.icon-more:before {
  content: '';
}

.icon-move-page:before {
  content: '';
}

.icon-multi-display:before {
  content: '';
}

.icon-music:before {
  content: '';
}

.icon-mute:before {
  content: '';
}

.icon-network:before {
  content: '';
}

.icon-new-call:before {
  content: '';
}

.icon-no-signal:before {
  content: '';
}

.icon-notebook-in:before {
  content: '';
}

.icon-notes:before {
  content: '';
}

.icon-numbered-input:before {
  content: '';
}

.icon-numbered-output:before {
  content: '';
}

.icon-off-hook:before {
  content: '';
}

.icon-other-phone:before {
  content: '';
}

.icon-outbox:before {
  content: '';
}

.icon-outgoing-call:before {
  content: '';
}

.icon-paired-audio:before {
  content: '';
}

.icon-paired-call:before {
  content: '';
}

.icon-panel-shift-left:before {
  content: '';
}

.icon-panel-shift-right:before {
  content: '';
}

.icon-parked:before {
  content: '';
}

.icon-participant-list:before {
  content: '';
}

.icon-pass-mouse:before {
  content: '';
}

.icon-pause:before {
  content: '';
}

.icon-pc:before {
  content: '';
}

.icon-pencil:before {
  content: '';
}

.icon-persistent-chat:before {
  content: '';
}

.icon-phone-cross:before {
  content: '';
}

.icon-phone:before {
  content: '';
}

.icon-picture-in-picture:before {
  content: '';
}

.icon-pin:before {
  content: '';
}

.icon-pip-0:before {
  content: '';
}

.icon-pip-1:before {
  content: '';
}

.icon-pip-2:before {
  content: '';
}

.icon-pip-3:before {
  content: '';
}

.icon-pip:before {
  content: '';
}

.icon-play-contained:before {
  content: '';
}

.icon-play:before {
  content: '';
}

.icon-playlist:before {
  content: '';
}

.icon-plugin:before {
  content: '';
}

.icon-plus:before {
  content: '';
}

.icon-point:before {
  content: '';
}

.icon-poll:before {
  content: '';
}

.icon-popout:before {
  content: '';
}

.icon-popup-dialogue:before {
  content: '';
}

.icon-power-contain:before {
  content: '';
}

.icon-power:before {
  content: '';
}

.icon-presence-available:before {
  content: '';
}

.icon-presence-end:before {
  content: '';
}

.icon-presence-offline:before {
  content: '';
}

.icon-presentation:before {
  content: '';
}

.icon-prevent-download-contain:before {
  content: '';
}

.icon-prevent-download:before {
  content: '';
}

.icon-print:before {
  content: '';
}

.icon-priority:before {
  content: '';
}

.icon-privacy:before {
  content: '';
}

.icon-private:before {
  content: '';
}

.icon-profile-settings:before {
  content: '';
}

.icon-proximity-not-connected:before {
  content: '';
}

.icon-proximity:before {
  content: '';
}

.icon-quality:before {
  content: '';
}

.icon-question-circle:before {
  content: '';
}

.icon-raise-hand:before {
  content: '';
}

.icon-read-email:before {
  content: '';
}

.icon-recent-apps:before {
  content: '';
}

.icon-record:before {
  content: '';
}

.icon-redial:before {
  content: '';
}

.icon-refresh:before {
  content: '';
}

.icon-remove-contact:before {
  content: '';
}

.icon-remove-contain:before {
  content: '';
}

.icon-remove-outline:before {
  content: '';
}

.icon-remove:before {
  content: '';
}

.icon-reply-all:before {
  content: '';
}

.icon-report:before {
  content: '';
}

.icon-reset:before {
  content: '';
}

.icon-right-arrow-closed-contained:before {
  content: '';
}

.icon-right-arrow-closed-outline:before {
  content: '';
}

.icon-right-arrow-contain:before {
  content: '';
}

.icon-right-arrow-contained:before {
  content: '';
}

.icon-right-arrow-outline:before {
  content: '';
}

.icon-right-arrow:before {
  content: '';
}

.icon-ringer-settings:before {
  content: '';
}

.icon-rotate-object-ccw:before {
  content: '';
}

.icon-rotate-object-cw:before {
  content: '';
}

.icon-rtprx-rtptx-duplex:before {
  content: '';
}

.icon-rtprx:before {
  content: '';
}

.icon-rtptx:before {
  content: '';
}

.icon-running-application:before {
  content: '';
}

.icon-save:before {
  content: '';
}

.icon-schedule-add:before {
  content: '';
}

.icon-screen-capture-square:before {
  content: '';
}

.icon-screen-capture:before {
  content: '';
}

.icon-sd:before {
  content: '';
}

.icon-search:before {
  content: '';
}

.icon-self-view-alt:before {
  content: '';
}

.icon-self-view-crossed:before {
  content: '';
}

.icon-self-view:before {
  content: '';
}

.icon-send-email:before {
  content: '';
}

.icon-send:before {
  content: '';
}

.icon-sent:before {
  content: '';
}

.icon-setup-assistant:before {
  content: '';
}

.icon-share-contain:before {
  content: '';
}

.icon-share-content:before {
  content: '';
}

.icon-share:before {
  content: '';
}

.icon-shipment:before {
  content: '';
}

.icon-show-editor:before {
  content: '';
}

.icon-sign-in:before {
  content: '';
}

.icon-sign-out:before {
  content: '';
}

.icon-signal-1:before {
  content: '';
}

.icon-signal-2:before {
  content: '';
}

.icon-signal-3:before {
  content: '';
}

.icon-signal-4:before {
  content: '';
}

.icon-skip-bw:before {
  content: '';
}

.icon-skip-fw:before {
  content: '';
}

.icon-slides:before {
  content: '';
}

.icon-soft-phone:before {
  content: '';
}

.icon-software-certified-md:before {
  content: '';
}

.icon-software-certified-sm:before {
  content: '';
}

.icon-software-certified:before {
  content: '';
}

.icon-software-suggested-md:before {
  content: '';
}

.icon-software-suggested-sm:before {
  content: '';
}

.icon-software-suggested:before {
  content: '';
}

.icon-software:before {
  content: '';
}

.icon-sort-amount-asc:before {
  content: '';
}

.icon-sort-amount-desc:before {
  content: '';
}

.icon-sound:before {
  content: '';
}

.icon-space:before {
  content: '';
}

.icon-spam:before {
  content: '';
}

.icon-spark:before {
  content: '';
}

.icon-speaker-cross:before {
  content: '';
}

.icon-speaker-out-left:before {
  content: '';
}

.icon-speaker:before {
  content: '';
}

.icon-speed-dial:before {
  content: '';
}

.icon-spinner:before {
  content: '';
}

.icon-square-o:before {
  content: '';
}

.icon-star-empty:before {
  content: '';
}

.icon-star-half:before {
  content: '';
}

.icon-star:before {
  content: '';
}

.icon-step-backward:before {
  content: '';
}

.icon-step-forward:before {
  content: '';
}

.icon-step-next:before {
  content: '';
}

.icon-step-prev:before {
  content: '';
}

.icon-stop:before {
  content: '';
}

.icon-storage:before {
  content: '';
}

.icon-subscribe:before {
  content: '';
}

.icon-swap-calls:before {
  content: '';
}

.icon-swap-camera:before {
  content: '';
}

.icon-swap-video-camera:before {
  content: '';
}

.icon-tables:before {
  content: '';
}

.icon-tablet:before {
  content: '';
}

.icon-tags:before {
  content: '';
}

.icon-team-collapsed-view:before {
  content: '';
}

.icon-team-expanded-view:before {
  content: '';
}

.icon-terminalalt:before {
  content: '';
}

.icon-text-color:before {
  content: '';
}

.icon-text-format:before {
  content: '';
}

.icon-text-size:before {
  content: '';
}

.icon-text:before {
  content: '';
}

.icon-thumbnail-view:before {
  content: '';
}

.icon-time:before {
  content: '';
}

.icon-timeline:before {
  content: '';
}

.icon-too-fast:before {
  content: '';
}

.icon-too-slow:before {
  content: '';
}

.icon-tools:before {
  content: '';
}

.icon-touch-gesture:before {
  content: '';
}

.icon-touch-point:before {
  content: '';
}

.icon-touch:before {
  content: '';
}

.icon-transcript:before {
  content: '';
}

.icon-transfer-to-mobile:before {
  content: '';
}

.icon-transfer:before {
  content: '';
}

.icon-transit:before {
  content: '';
}

.icon-trash:before {
  content: '';
}

.icon-universal-inbox:before {
  content: '';
}

.icon-unlock:before {
  content: '';
}

.icon-upload-contain:before {
  content: '';
}

.icon-upload:before {
  content: '';
}

.icon-usb:before {
  content: '';
}

.icon-user:before {
  content: '';
}

.icon-vibrate:before {
  content: '';
}

.icon-video-cross:before {
  content: '';
}

.icon-video-input:before {
  content: '';
}

.icon-video-layout:before {
  content: '';
}

.icon-video-settings:before {
  content: '';
}

.icon-video-tips:before {
  content: '';
}

.icon-video:before {
  content: '';
}

.icon-view-feed-dual:before {
  content: '';
}

.icon-view-feed-multi:before {
  content: '';
}

.icon-view-feed-single:before {
  content: '';
}

.icon-view-preview-telepresence:before {
  content: '';
}

.icon-view-side-by-side:before {
  content: '';
}

.icon-view-split:before {
  content: '';
}

.icon-view-stacked:before {
  content: '';
}

.icon-virtual-machine:before {
  content: '';
}

.icon-voicemail:before {
  content: '';
}

.icon-volume-cross:before {
  content: '';
}

.icon-volume:before {
  content: '';
}

.icon-waiting-silence:before {
  content: '';
}

.icon-wallpaper:before {
  content: '';
}

.icon-warning-outline:before {
  content: '';
}

.icon-warning:before {
  content: '';
}

.icon-watchlist:before {
  content: '';
}

.icon-web-camera:before {
  content: '';
}

.icon-web-sharing:before {
  content: '';
}

.icon-webex:before {
  content: '';
}

.icon-webhook:before {
  content: '';
}

.icon-week:before {
  content: '';
}

.icon-whisper:before {
  content: '';
}

.icon-whiteboard-cross:before {
  content: '';
}

.icon-whiteboard:before {
  content: '';
}

.icon-wifi-1:before {
  content: '';
}

.icon-wifi-2:before {
  content: '';
}

.icon-wifi-3:before {
  content: '';
}

.icon-wifi:before {
  content: '';
}

.icon-work:before {
  content: '';
}

.icon-zip:before {
  content: '';
}

.icon-zoom-in:before {
  content: '';
}

.icon-zoom-out:before {
  content: '';
}

.cui {
  /* KJB - Fix this later
.form-group {
    @include clearfix;

    margin-right: 0;
    @include media-breakpoint-up(md) {
        margin-right: $base-spacing * 0.5;
    }

    position: relative;
    margin-bottom: $base-spacing * 0.5;
    box-shadow: 0 -1px 0 0 $base-form-border-color inset;
    line-height: $base-line-height;

    &--noseparator {
        box-shadow: none;
    }

    &--helper {
        box-shadow: 0 0 0 0 $base-form-border-color inset;
        .help-block {
            margin-top: 5px;
        }
    }

    label,
    select {
        line-height: $base-line-height;
    }

    select {
        padding: 6px $base-spacing 6px 0;
    }

    label {
        padding: 6px 10px 6px 0;
        margin-bottom: 0;
        float: left;
        transition: color 0.2s ease;

        &:first-of-type {
            @include text-ellipsis;
            color: $base-form-label-color;
            order: 2;

            &[for] {
                cursor: pointer;
                user-select: none;

                &:hover {
                    color: $base-font-color;
                }
            }
        }
    }

    input {
        position: relative;
        padding: 6px 0;

        &::-ms-clear {
            display: none; // Hide clear field button in IE
        }
        // allow selecting text in disabled inputs:
        &[disabled] {
            pointer-events: auto;
        }
    }

    textarea {
        padding: 6px 0;
    }

    .select:after {
        top: 6px;
    }
}

.msd-elastic {
    max-height: 150px;
    overflow-y: scroll;
}


.form-group__dropdown {
    input {
        padding-right: (($base-spacing * 0.25) * 2) + 14px;
    }
}

.form-group--noseparator {
    box-shadow: none;
}

.form-group--typeahead {
    position: relative;

    .dropdown-menu {
        position: absolute;
        z-index: 1100;
    }
}
*/
  /*
 * Note: This file contains only the IE-specific overrides for UI kit elements
 *       Once IE goes away this file will go with it
 */
}
.cui .inline-qtr-spacing > * + * {
  margin-left: 5px;
}
.cui .no-margin {
  margin: 0 !important;
}
.cui .no-margin-top {
  margin-top: 0 !important;
}
.cui .no-margin-right {
  margin-right: 0 !important;
}
.cui .no-margin-bottom {
  margin-bottom: 0 !important;
}
.cui .no-margin-left {
  margin-left: 0 !important;
}
.cui .qtr-margin {
  margin: 5px !important;
}
.cui .qtr-margin-top {
  margin-top: 5px !important;
}
.cui .qtr-margin-right {
  margin-right: 5px !important;
}
.cui .qtr-margin-bottom {
  margin-bottom: 5px !important;
}
.cui .qtr-margin-left {
  margin-left: 5px !important;
}
.cui .half-margin {
  margin: 10px !important;
}
.cui .half-margin-top {
  margin-top: 10px !important;
}
.cui .half-margin-right {
  margin-right: 10px !important;
}
.cui .half-margin-bottom {
  margin-bottom: 10px !important;
}
.cui .half-margin-left {
  margin-left: 10px !important;
}
.cui .base-margin {
  margin: 20px !important;
}
.cui .base-margin-top {
  margin-top: 20px !important;
}
.cui .base-margin-right {
  margin-right: 20px !important;
}
.cui .base-margin-bottom {
  margin-bottom: 20px !important;
}
.cui .base-margin-left {
  margin-left: 20px !important;
}
.cui .dbl-margin {
  margin: 40px !important;
}
.cui .dbl-margin-top {
  margin-top: 40px !important;
}
.cui .dbl-margin-right {
  margin-right: 40px !important;
}
.cui .dbl-margin-bottom {
  margin-bottom: 40px !important;
}
.cui .dbl-margin-left {
  margin-left: 40px !important;
}
.cui .no-padding {
  padding: 0 !important;
}
.cui .no-padding-top {
  padding-top: 0 !important;
}
.cui .no-padding-right {
  padding-right: 0 !important;
}
.cui .no-padding-bottom {
  padding-bottom: 0 !important;
}
.cui .no-padding-left {
  padding-left: 0 !important;
}
.cui .qtr-padding {
  padding: 5px !important;
}
.cui .qtr-padding-top {
  padding-top: 5px !important;
}
.cui .qtr-padding-right {
  padding-right: 5px !important;
}
.cui .qtr-padding-bottom {
  padding-bottom: 5px !important;
}
.cui .qtr-padding-left {
  padding-left: 5px !important;
}
.cui .half-padding {
  padding: 10px !important;
}
.cui .half-padding-top {
  padding-top: 10px !important;
}
.cui .half-padding-right {
  padding-right: 10px !important;
}
.cui .half-padding-bottom {
  padding-bottom: 10px !important;
}
.cui .half-padding-left {
  padding-left: 10px !important;
}
.cui .base-padding {
  padding: 20px !important;
}
.cui .base-padding-top {
  padding-top: 20px !important;
}
.cui .base-padding-right {
  padding-right: 20px !important;
}
.cui .base-padding-bottom {
  padding-bottom: 20px !important;
}
.cui .base-padding-left {
  padding-left: 20px !important;
}
.cui .dbl-padding {
  padding: 40px !important;
}
.cui .dbl-padding-top {
  padding-top: 40px !important;
}
.cui .dbl-padding-right {
  padding-right: 40px !important;
}
.cui .dbl-padding-bottom {
  padding-bottom: 40px !important;
}
.cui .dbl-padding-left {
  padding-left: 40px !important;
}
.cui *[disabled],
.cui .disabled {
  opacity: 0.75;
  cursor: not-allowed !important;
  pointer-events: none !important;
  z-index: 0 !important;
}
.cui .readonly {
  opacity: 0.5;
  cursor: not-allowed !important;
  z-index: 0 !important;
}
.cui .blurred {
  pointer-events: none !important;
  -webkit-filter: blur(5px) !important;
  filter: blur(5px) !important;
}
.cui .clearfix:after {
  clear: both !important;
  content: '' !important;
  display: table !important;
}
.cui .absolute-center {
  z-index: 10 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}
.cui .absolute-center--top {
  top: 30% !important;
}
.cui .absolute-center--right {
  left: 70% !important;
}
.cui .absolute-center--bottom {
  top: 70% !important;
}
.cui .absolute-center--left {
  left: 30% !important;
}
.cui .pull-left {
  float: left !important;
}
.cui .pull-center {
  float: center !important;
}
.cui .pull-right {
  float: right !important;
}
.cui .flex {
  display: flex !important;
}
.cui .flex-inline {
  display: inline-flex !important;
}
.cui .flex-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.cui .flex-center-vertical {
  display: flex !important;
  align-items: center !important;
}
.cui .flex-center-horizontal {
  display: flex !important;
  justify-content: center !important;
}
.cui .flex-fluid {
  flex: 1 !important;
  flex-shrink: 1 !important;
  flex-wrap: wrap !important;
}
.cui .flex-fill {
  flex: 1 0 auto !important;
}
.cui .flex-inline-center-horizontal {
  display: inline-flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
.cui .flex-inline-center-vertical {
  display: inline-flex !important;
  justify-content: center !important;
}
.cui .flex-align-end {
  align-items: flex-end !important;
}
.cui .flex-align-start {
  align-items: flex-start !important;
}
.cui .flex--compressed > * {
  flex-basis: 10px !important;
}
.cui .flex--regular > * {
  flex-basis: 20px !important;
}
.cui .flex--loose > * {
  flex-basis: 40px !important;
}
.cui .flex-right {
  justify-content: flex-end !important;
}
.cui .flex-column {
  flex-direction: column !important;
}
.cui .flex-wrap {
  display: flex !important;
  flex-wrap: wrap !important;
}
.cui .text-left {
  text-align: left !important;
}
.cui .text-right {
  text-align: right !important;
}
.cui .text-bold {
  font-weight: bold !important;
}
.cui .text-muted {
  color: #9e9ea2 !important;
}
.cui .text-normal {
  font-weight: normal !important;
}
.cui .text-light {
  font-weight: 200 !important;
}
.cui .text-center {
  text-align: center !important;
}
.cui .text-justify {
  text-align: justify !important;
}
.cui .text-nowrap {
  white-space: nowrap !important;
}
.cui .text-lowercase {
  text-transform: lowercase !important;
}
.cui .text-uppercase {
  text-transform: uppercase !important;
}
.cui .text-capitalize {
  text-transform: capitalize !important;
}
.cui .text-tiny {
  font-size: 0.8rem !important;
  line-height: 0.8rem !important;
}
.cui .text-xsmall {
  font-size: 1rem !important;
  line-height: 1rem !important;
}
.cui .text-small {
  font-size: 1.2rem !important;
}
.cui .text-medium {
  font-size: 1.4rem !important;
  line-height: 1.4rem !important;
}
.cui .text-large {
  font-size: 1.6rem !important;
  line-height: 1.6rem !important;
}
.cui .text-xlarge {
  font-size: 2.4rem !important;
  line-height: 2.4rem !important;
}
.cui .text-huge {
  font-size: 4.8rem !important;
  line-height: 4.8rem !important;
}
.cui .text-goliath {
  font-size: 6.4rem !important;
  line-height: 6.4rem !important;
}
.cui .text-italic {
  font-style: italic !important;
}
.cui .text-ghost {
  color: #ffffff !important;
}
.cui .text-transparent-qtr {
  opacity: 0.25 !important;
}
.cui .monospace,
.cui .text-monospace {
  font-family: Monaco, Inconsolata, Consolas, 'Courier New', Courier,
    'Lucida Console', monospace !important;
}
.cui .text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cui .text-noellipsis {
  white-space: normal;
}
.cui .text-noselect {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.cui .text-break {
  word-wrap: break-word !important;
  word-break: break-all !important;
}
.cui .text-marquee {
  font-size: 3.2rem !important;
}
@media (min-width: 576px) {
  .cui .text-marquee {
    font-size: 4rem !important;
  }
}
@media (min-width: 768px) {
  .cui .text-marquee {
    font-size: 4.8rem !important;
  }
}
.cui .text-billboard {
  font-size: 6rem !important;
}
@media (max-width: 991.98px) {
  .cui .text-billboard {
    font-size: 5.4rem !important;
  }
}
.cui .text-billboard-title {
  font-size: 3.6rem !important;
}
.cui .text-billboard-subtitle {
  font-size: 2rem !important;
}
.cui .text-subheading {
  text-transform: uppercase !important;
  font-weight: bold !important;
  margin-bottom: 10px !important;
}
.cui .text-primary,
.cui .text--primary {
  color: #00bceb !important;
}
.cui .text-secondary,
.cui .text--secondary {
  color: #00bceb !important;
}
.cui .text-default,
.cui .text--default {
  color: #58585b !important;
}
.cui .text-cta,
.cui .text--cta {
  color: #6ebe4a !important;
}
.cui .text-info,
.cui .text--info {
  color: #64bbe3 !important;
}
.cui .text-success,
.cui .text--success {
  color: #6ebe4a !important;
}
.cui .text-danger,
.cui .text--danger {
  color: #e2231a !important;
}
.cui .text-warning,
.cui .text--warning {
  color: #fbab18 !important;
}
.cui .text-warning-alt,
.cui .text--warning-alt {
  color: #ffcc00 !important;
}
.cui .text-turquoise,
.cui .text--turquoise {
  color: #14a792 !important;
}
.cui .text-default-color {
  color: #58585b !important;
}
.cui .text-blue {
  color: #00bceb !important;
}
.cui .text-darkgreen {
  color: #487b32 !important;
}
.cui .text-dkgray-1 {
  color: #39393b !important;
}
.cui .text-dkgray-4 {
  color: #7f7f86 !important;
}
.cui .text-indigo,
.cui .text--indigo {
  color: #005073 !important;
}
.cui .icon-action:before {
  line-height: 3.4rem !important;
  font-size: 3.4rem !important;
}
.cui .icon-huge:before {
  line-height: 8.4rem !important;
  font-size: 8.4rem !important;
}
.cui .icon-large:before {
  line-height: 5.6rem !important;
  font-size: 5.6rem !important;
}
.cui .icon-medium-large:before {
  line-height: 3.6rem !important;
  font-size: 3.6rem !important;
}
.cui .icon-medium:before {
  line-height: 2.8rem !important;
  font-size: 2.8rem !important;
}
.cui .icon-medium-small:before {
  line-height: 2.4rem !important;
  font-size: 2.4rem !important;
}
.cui .icon-small:before {
  line-height: 1.8rem !important;
  font-size: 1.8rem !important;
}
.cui .icon-tiny:before {
  line-height: 1rem !important;
  font-size: 1rem !important;
}
.cui .is-ios-device .hide-on-ios {
  display: none !important;
}
.cui .toggle {
  cursor: pointer !important;
}
.cui .v-separator {
  border-right: 1px solid #dfdfdf;
  margin: 5px 10px 5px 0;
  padding-left: 10px;
  display: inline-flex;
  vertical-align: middle;
  height: 15px;
}
.cui .v-separator--small {
  height: 10px;
}
.cui .v-separator--large {
  height: 20px;
}
.cui .v-separator--huge {
  height: 40px;
}
.cui .rotate-180 {
  -webkit-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
}
.cui .hide {
  display: none !important;
}
.cui .show {
  display: block !important;
}
.cui .visible {
  visibility: visible !important;
}
.cui .invisible {
  visibility: hidden !important;
}
.cui .user-entered-data {
  white-space: pre-line !important;
}
.cui .small {
  font-size: 1.2rem !important;
}
@media (max-width: 767px) {
  .cui .center-xs {
    text-align: center !important;
  }
  .cui .pull-right-xs {
    float: right !important;
  }
  .cui .pull-left-xs {
    float: left !important;
  }
  .cui .visible-inline-xs {
    display: inline-block !important;
  }
}
.cui .row.no-gutters {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.cui .row.no-gutters > [class^='col-'],
.cui .row.no-gutters > [class*=' col-'] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.cui .disable-pointer {
  pointer-events: none !important;
}
@media (max-width: 575px) {
  .cui .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .cui .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cui .hidden-md {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .cui .hidden-lg {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .cui .hidden-xl {
    display: none !important;
  }
}
.cui .visible-xs,
.cui .visible-sm,
.cui .visible-md,
.cui .visible-lg .visible-xl {
  display: none !important;
}
@media (max-width: 575px) {
  .cui .visible-xs {
    display: block !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .cui .visible-sm {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cui .visible-md {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .cui .visible-lg {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .cui .visible-xl {
    display: block !important;
  }
}
@media (max-width: 767.98px) {
  .cui .hidden-sm-down {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .cui .visible-sm-down {
    display: block !important;
  }
}
@media (max-width: 991.98px) {
  .cui .hidden-md-down {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .cui .visible-md-down {
    display: block !important;
  }
}
@media (max-width: 1199.98px) {
  .cui .hidden-lg-down {
    display: none !important;
  }
}
@media (max-width: 1199.98px) {
  .cui .visible-lg-down {
    display: block !important;
  }
}
.cui .hidden-xl-down {
  display: none !important;
}
.cui .visible-xl-down {
  display: block !important;
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@-webkit-keyframes blowup {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes blowup {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounce {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes pop {
  0% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  33% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  66% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pop {
  0% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  33% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  66% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes pulse {
  0%,
  80%,
  100% {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
  }
  40% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  0%,
  80%,
  100% {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
  }
  40% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes pulseShadow {
  0% {
    box-shadow: 0 0 0 0 rgba(88, 88, 91, 0.5);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(88, 88, 91, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(88, 88, 91, 0);
  }
}

@keyframes pulseShadow {
  0% {
    box-shadow: 0 0 0 0 rgba(88, 88, 91, 0.5);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(88, 88, 91, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(88, 88, 91, 0);
  }
}

@-webkit-keyframes pulseShadowInfo {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 188, 235, 0.5);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 188, 235, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 188, 235, 0);
  }
}

@keyframes pulseShadowInfo {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 188, 235, 0.5);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 188, 235, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 188, 235, 0);
  }
}

@-webkit-keyframes pulseShadowSuccess {
  0% {
    box-shadow: 0 0 0 0 rgba(110, 190, 74, 0.5);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(110, 190, 74, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(110, 190, 74, 0);
  }
}

@keyframes pulseShadowSuccess {
  0% {
    box-shadow: 0 0 0 0 rgba(110, 190, 74, 0.5);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(110, 190, 74, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(110, 190, 74, 0);
  }
}

@-webkit-keyframes pulseShadowWarning {
  0% {
    box-shadow: 0 0 0 0 rgba(251, 171, 24, 0.5);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(251, 171, 24, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(251, 171, 24, 0);
  }
}

@keyframes pulseShadowWarning {
  0% {
    box-shadow: 0 0 0 0 rgba(251, 171, 24, 0.5);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(251, 171, 24, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(251, 171, 24, 0);
  }
}

@-webkit-keyframes pulseShadowDanger {
  0% {
    box-shadow: 0 0 0 0 rgba(226, 35, 26, 0.5);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(226, 35, 26, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(226, 35, 26, 0);
  }
}

@keyframes pulseShadowDanger {
  0% {
    box-shadow: 0 0 0 0 rgba(226, 35, 26, 0.5);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(226, 35, 26, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(226, 35, 26, 0);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slideInLeftSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeftSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideInRightSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRightSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.cui .slideInLeft {
  -webkit-animation: slideInLeft 1s;
  animation: slideInLeft 1s;
}
.cui .slideInLeftSmall {
  -webkit-animation: slideInLeftSmall 1s;
  animation: slideInLeftSmall 1s;
}
.cui .slideInRight {
  -webkit-animation: slideInRight 1s;
  animation: slideInRight 1s;
}
.cui .slideInRightSmall {
  -webkit-animation: slideInRightSmall 1s;
  animation: slideInRightSmall 1s;
}
.cui .spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
.cui .fadeIn {
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
.cui .fade-in-pop {
  -webkit-animation: pop 1.5s 1 linear, fade-in 1.5s 1 linear;
  animation: pop 1.5s 1 linear, fade-in 1.5s 1 linear;
}
.cui .fade-in-and-out {
  transition: opacity 0.15s ease-in-out;
}
.cui .rotate-right {
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.cui .rotate-left {
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.cui .animation-delay-1 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.cui .animation-delay-2 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.cui .animation-delay-3 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.cui .animation-delay-100 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.cui .animation-delay-200 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.cui .animation-delay-250 {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.cui .animation-delay-500 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.cui .animation-delay-750 {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
.cui blockquote {
  margin: 20px 0;
  padding: 10px;
  border-width: 0;
  border-style: none;
  border-left-width: 5px;
  border-left-style: solid;
  border-color: #dfdfdf;
}
.cui blockquote p {
  -webkit-animation-name: slideInLeftSmall;
  animation-name: slideInLeftSmall;
}
.cui blockquote cite {
  border-color: #dfdfdf;
}
.cui blockquote.blockquote--blue {
  border-color: #00bceb;
}
.cui blockquote.blockquote--blue cite {
  border-color: #00bceb;
}
.cui blockquote.blockquote--indigo {
  border-color: #005073;
}
.cui blockquote.blockquote--indigo cite {
  border-color: #005073;
}
.cui blockquote.blockquote--success {
  border-color: #6ebe4a;
}
.cui blockquote.blockquote--success cite {
  border-color: #6ebe4a;
}
.cui blockquote.blockquote--right {
  border-width: 0;
  border-style: none;
  text-align: right;
  border-right-width: 5px;
  border-right-style: solid;
}
.cui blockquote.blockquote--right p {
  -webkit-animation-name: slideInRightSmall;
  animation-name: slideInRightSmall;
}
.cui blockquote.blockquote--center {
  border-width: 0;
  border-style: none;
  text-align: center;
}
.cui blockquote.blockquote--center cite {
  width: 50%;
  padding-top: 10px;
  margin-top: 10px;
  border-top-width: 5px;
  border-top-style: solid;
}
.cui blockquote.blockquote--center p {
  -webkit-animation-name: slideInUpSmall;
  animation-name: slideInUpSmall;
}
.cui cite {
  display: inline-block;
  color: #98989b;
  font-style: italic;
}
.cui cite:before {
  content: '\2014 \00A0';
}
.cui.cui--animated blockquote p {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.cui a.btn,
.cui a.btn.btn--icon,
.cui a.btn.btn--icon.btn--small {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.cui .btn + .btn {
  margin-left: 10px;
}
.cui .btn.small + .btn.small {
  margin-left: 5px;
}
.cui .btn {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-width: 1px;
  text-align: center;
  vertical-align: middle;
  padding: 0 35px;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 4rem;
  height: 40px;
  min-width: 0;
  max-width: 300px;
  overflow: visible;
  border: 1px solid transparent;
  background: #7f7f86;
  color: #ffffff;
  text-decoration: none;
  border: 1px solid transparent;
  background: #58585b;
  color: #ffffff;
  text-decoration: none;
}
.cui .btn > span[class^='icon-'] {
  font-size: 1.6rem;
}
.cui .btn .btn__subtext {
  top: 45px;
}
.cui .btn:hover,
.cui .btn:focus,
.cui .btn:active {
  text-decoration: none;
}
.cui .btn:hover {
  box-shadow: 0 0 0 0 transparent;
  background-color: #626469;
  color: #ffffff;
}
.cui .btn.active,
.cui .btn:active {
  box-shadow: 0 0 0 0 transparent;
  color: #ffffff;
  background-color: #626469;
}
.cui .btn.disabled,
.cui .btn[disabled] {
  opacity: 1;
  color: #626469;
  outline: 0;
  border: 1px solid transparent;
  box-shadow: none;
  background-color: #dfdfdf;
}
.cui .btn.selected {
  background-color: #ffffff;
}
.cui .btn.btn--small {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-width: 1px;
  text-align: center;
  vertical-align: middle;
  padding: 0 20px;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 2.4rem;
  height: 30px;
  min-width: 0;
  max-width: 200px;
  overflow: visible;
}
.cui .btn.btn--small > span[class^='icon-'] {
  font-size: 1.4rem;
}
.cui .btn.btn--small .btn__subtext {
  top: 35px;
}
.cui .btn.btn--wide {
  padding-left: 60px;
  padding-right: 60px;
  max-width: 300px;
}
.cui .btn:hover,
.cui .btn:focus,
.cui .btn:active {
  text-decoration: none;
}
.cui .btn:hover {
  box-shadow: 0 0 0 0 transparent;
  background-color: #4b4b4e;
  color: #ffffff;
}
.cui .btn.focus,
.cui .btn:focus {
  outline: none;
  box-shadow: 0 0 1px 1px #58585b;
  border: 1px solid #ffffff;
}
.cui .btn.active,
.cui .btn:active {
  box-shadow: 0 0 0 0 transparent;
  color: #ffffff;
  background-color: #3f3f41;
}
.cui .btn.disabled,
.cui .btn[disabled] {
  opacity: 1;
  color: #ffffff;
  outline: 0;
  border: 1px solid transparent;
  box-shadow: none;
  background-color: #bebec1;
}
.cui .btn.selected {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #58585b;
  color: #58585b;
}
.cui .btn.btn--primary {
  border: 1px solid transparent;
  background: #017cad;
  color: #ffffff;
  text-decoration: none;
}
.cui .btn.btn--primary:hover,
.cui .btn.btn--primary:focus,
.cui .btn.btn--primary:active {
  text-decoration: none;
}
.cui .btn.btn--primary:hover {
  box-shadow: 0 0 0 0 transparent;
  background-color: #016a94;
  color: #ffffff;
}
.cui .btn.btn--primary.focus,
.cui .btn.btn--primary:focus {
  outline: none;
  box-shadow: 0 0 1px 1px #017cad;
  border: 1px solid #ffffff;
}
.cui .btn.btn--primary.active,
.cui .btn.btn--primary:active {
  box-shadow: 0 0 0 0 transparent;
  color: #ffffff;
  background-color: #01587a;
}
.cui .btn.btn--primary.disabled,
.cui .btn.btn--primary[disabled] {
  opacity: 1;
  color: #ffffff;
  outline: 0;
  border: 1px solid transparent;
  box-shadow: none;
  background-color: #aee8ff;
}
.cui .btn.btn--primary.selected {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #017cad;
  color: #017cad;
}
.cui .btn.btn--primary-ghost {
  border: 1px solid transparent;
  background: transparent;
  color: #017cad;
  text-decoration: none;
  box-shadow: inset 0 0 0 1px #017cad;
}
.cui .btn.btn--primary-ghost:hover,
.cui .btn.btn--primary-ghost:focus,
.cui .btn.btn--primary-ghost:active {
  text-decoration: none;
}
.cui .btn.btn--primary-ghost:hover {
  box-shadow: 0 0 0 0 transparent;
  background-color: #017cad;
  color: #ffffff;
}
.cui .btn.btn--primary-ghost.focus,
.cui .btn.btn--primary-ghost:focus {
  outline: none;
  box-shadow: 0 0 1px 1px #017cad;
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: #017cad;
}
.cui .btn.btn--primary-ghost.active,
.cui .btn.btn--primary-ghost:active {
  box-shadow: 0 0 0 0 transparent;
  color: #ffffff;
  background-color: #016a94;
}
.cui .btn.btn--primary-ghost.disabled,
.cui .btn.btn--primary-ghost[disabled] {
  opacity: 1;
  color: #017cad;
  outline: 0;
  border: 1px solid transparent;
  box-shadow: none;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #aee8ff;
}
.cui .btn.btn--primary-ghost.selected {
  background-color: #017cad;
  box-shadow: inset 0 0 0 1px #017cad;
  color: #ffffff;
}
.cui .btn.btn--secondary {
  border: 1px solid transparent;
  background: transparent;
  color: #017cad;
  text-decoration: none;
  box-shadow: inset 0 0 0 1px #017cad;
}
.cui .btn.btn--secondary:hover,
.cui .btn.btn--secondary:focus,
.cui .btn.btn--secondary:active {
  text-decoration: none;
}
.cui .btn.btn--secondary:hover {
  box-shadow: 0 0 0 0 transparent;
  background-color: #017cad;
  color: #ffffff;
}
.cui .btn.btn--secondary.focus,
.cui .btn.btn--secondary:focus {
  outline: none;
  box-shadow: 0 0 1px 1px #017cad;
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: #017cad;
}
.cui .btn.btn--secondary.active,
.cui .btn.btn--secondary:active {
  box-shadow: 0 0 0 0 transparent;
  color: #ffffff;
  background-color: #016a94;
}
.cui .btn.btn--secondary.disabled,
.cui .btn.btn--secondary[disabled] {
  opacity: 1;
  color: #017cad;
  outline: 0;
  border: 1px solid transparent;
  box-shadow: none;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #aee8ff;
}
.cui .btn.btn--secondary.selected {
  background-color: #017cad;
  box-shadow: inset 0 0 0 1px #017cad;
  color: #ffffff;
}
.cui .btn.btn--negative {
  border: 1px solid transparent;
  background: #e2231a;
  color: #ffffff;
  text-decoration: none;
}
.cui .btn.btn--negative:hover,
.cui .btn.btn--negative:focus,
.cui .btn.btn--negative:active {
  text-decoration: none;
}
.cui .btn.btn--negative:hover {
  box-shadow: 0 0 0 0 transparent;
  background-color: #cb1f17;
  color: #ffffff;
}
.cui .btn.btn--negative.focus,
.cui .btn.btn--negative:focus {
  outline: none;
  box-shadow: 0 0 1px 1px #e2231a;
  border: 1px solid #ffffff;
}
.cui .btn.btn--negative.active,
.cui .btn.btn--negative:active {
  box-shadow: 0 0 0 0 transparent;
  color: #ffffff;
  background-color: #b41c15;
}
.cui .btn.btn--negative.disabled,
.cui .btn.btn--negative[disabled] {
  opacity: 1;
  color: #ffffff;
  outline: 0;
  border: 1px solid transparent;
  box-shadow: none;
  background-color: #f28f8a;
}
.cui .btn.btn--negative.selected {
  background-color: #ffffff;
}
.cui .btn.btn--success {
  border: 1px solid transparent;
  background: #6ebe4a;
  color: #ffffff;
  text-decoration: none;
  box-shadow: inset 0 0 0 1px #6ebe4a;
}
.cui .btn.btn--success:hover,
.cui .btn.btn--success:focus,
.cui .btn.btn--success:active {
  text-decoration: none;
}
.cui .btn.btn--success:hover {
  box-shadow: 0 0 0 0 transparent;
  background-color: #62af3f;
  color: #ffffff;
}
.cui .btn.btn--success.focus,
.cui .btn.btn--success:focus {
  outline: none;
  box-shadow: 0 0 1px 1px #6ebe4a;
  border: 1px solid #ffffff;
  background-color: #62af3f;
}
.cui .btn.btn--success.active,
.cui .btn.btn--success:active {
  box-shadow: 0 0 0 0 transparent;
  color: #ffffff;
  background-color: #579d38;
}
.cui .btn.btn--success.disabled,
.cui .btn.btn--success[disabled] {
  opacity: 1;
  color: #ffffff;
  outline: 0;
  border: 1px solid transparent;
  box-shadow: none;
  background-color: #b9e0a8;
}
.cui .btn.btn--success.selected {
  background-color: #6ebe4a;
  box-shadow: inset 0 0 0 1px #6ebe4a;
  color: #ffffff;
}
.cui .btn.btn--white {
  border: 1px solid transparent;
  background: #ffffff;
  color: #58585b;
  text-decoration: none;
  box-shadow: inset 0 0 0 1px transparent;
}
.cui .btn.btn--white:hover,
.cui .btn.btn--white:focus,
.cui .btn.btn--white:active {
  text-decoration: none;
}
.cui .btn.btn--white:hover {
  box-shadow: 0 0 0 0 transparent;
  background-color: #f2f2f2;
  color: #58585b;
}
.cui .btn.btn--white.focus,
.cui .btn.btn--white:focus {
  background-color: #f2f2f2;
}
.cui .btn.btn--white.active,
.cui .btn.btn--white:active {
  box-shadow: 0 0 0 0 transparent;
  color: #58585b;
  background-color: #e6e6e6;
}
.cui .btn.btn--white.disabled,
.cui .btn.btn--white[disabled] {
  opacity: 1;
  color: #58585b;
  outline: 0;
  border: 1px solid transparent;
  box-shadow: none;
  background-color: #dfdfdf;
}
.cui .btn.btn--white.selected {
  background-color: #e6e6e6;
  box-shadow: inset 0 0 0 1px #e6e6e6;
  color: #58585b;
}
.cui .btn.btn--white-ghost {
  border: 1px solid transparent;
  background: transparent;
  color: #ffffff;
  text-decoration: none;
  box-shadow: inset 0 0 0 1px #ffffff;
}
.cui .btn.btn--white-ghost:hover,
.cui .btn.btn--white-ghost:focus,
.cui .btn.btn--white-ghost:active {
  text-decoration: none;
}
.cui .btn.btn--white-ghost:hover {
  box-shadow: 0 0 0 0 transparent;
  background-color: #ffffff;
  color: #58585b;
}
.cui .btn.btn--white-ghost.focus,
.cui .btn.btn--white-ghost:focus {
  color: #58585b;
  background-color: #ffffff;
}
.cui .btn.btn--white-ghost.active,
.cui .btn.btn--white-ghost:active {
  box-shadow: 0 0 0 0 transparent;
  color: #58585b;
  background-color: #f2f2f2;
}
.cui .btn.btn--white-ghost.disabled,
.cui .btn.btn--white-ghost[disabled] {
  opacity: 1;
  color: #9e9ea2;
  outline: 0;
  border: 1px solid transparent;
  box-shadow: none;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui .btn.btn--white-ghost.selected {
  background-color: #ffffff;
  color: #58585b;
}
.cui .btn.btn--gray-ghost {
  border: 1px solid transparent;
  background: transparent;
  color: #626469;
  text-decoration: none;
  box-shadow: inset 0 0 0 1px #626469;
}
.cui .btn.btn--gray-ghost:hover,
.cui .btn.btn--gray-ghost:focus,
.cui .btn.btn--gray-ghost:active {
  text-decoration: none;
}
.cui .btn.btn--gray-ghost:hover {
  box-shadow: 0 0 0 0 transparent;
  background-color: #626469;
  color: #ffffff;
}
.cui .btn.btn--gray-ghost.focus,
.cui .btn.btn--gray-ghost:focus {
  outline: none;
  box-shadow: 0 0 1px 1px #626469;
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: #626469;
}
.cui .btn.btn--gray-ghost.active,
.cui .btn.btn--gray-ghost:active {
  box-shadow: 0 0 0 0 transparent;
  color: #ffffff;
  background-color: #56575c;
}
.cui .btn.btn--gray-ghost.disabled,
.cui .btn.btn--gray-ghost[disabled] {
  opacity: 1;
  color: #9e9ea2;
  outline: 0;
  border: 1px solid transparent;
  box-shadow: none;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui .btn.btn--gray-ghost.selected {
  background-color: #626469;
  box-shadow: inset 0 0 0 1px #626469;
  color: #ffffff;
}
.cui .btn.btn--square {
  border-radius: 0;
}
.cui .btn.btn--icon {
  border-width: 1px;
  overflow: visible;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-width: 1px;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.8rem;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  overflow: visible;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.cui .btn.btn--icon > span[class^='icon-'] {
  font-size: 1.8rem;
}
.cui .btn.btn--icon .btn__subtext {
  top: 45px;
}
.cui .btn.btn--icon > span[class^='icon-'] {
  font-size: 1.8rem;
}
.cui .btn.btn--icon.btn--small {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-width: 1px;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 1.3rem;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
  overflow: visible;
}
.cui .btn.btn--icon.btn--small > span[class^='icon-'] {
  font-size: 1.3rem;
}
.cui .btn.btn--icon.btn--small .btn__subtext {
  top: 35px;
}
.cui .btn.btn--icon.btn--large {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-width: 1px;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  font-size: 2.4rem;
  font-weight: normal;
  line-height: 2.4rem;
  height: 50px;
  min-width: 50px;
  max-width: 50px;
  overflow: visible;
}
.cui .btn.btn--icon.btn--large > span[class^='icon-'] {
  font-size: 2.4rem;
}
.cui .btn.btn--icon.btn--large .btn__subtext {
  top: 55px;
}
.cui .btn.btn--icon .btn__subtext {
  color: #9e9ea2;
  font-size: 1rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 45px;
  margin: 0;
}
.cui .dropdown {
  display: inline-block;
  position: relative;
}
.cui .dropdown__menu {
  list-style: none;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  list-style: none;
  position: absolute;
  pointer-events: auto;
  top: calc(100% - 1px);
  width: 100%;
  -webkit-transform: translateY(-2em);
  transform: translateY(-2em);
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.1) 0 5px 10px;
  font-weight: normal;
}
.cui .dropdown__menu ul:not(:first-child) {
  padding-left: 20px;
}
.cui .dropdown__menu__divider {
  height: 1px;
  overflow: hidden;
  background-color: #dfdfdf;
}
.cui .dropdown__menu a,
.cui .dropdown__menu .dropdown__group-header {
  height: 35px;
  line-height: 3.5rem;
  display: inline-flex;
  color: #58585b !important;
  padding: 0 40px 0 10px;
  width: 100%;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cui .dropdown__menu .dropdown__group-header {
  pointer-events: none;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 1px solid #dfdfdf;
}
.cui .dropdown__menu a .checkbox {
  margin-right: 10px;
}
.cui .dropdown__menu a.selected {
  color: #ffffff !important;
  background-color: #005073;
}
.cui .dropdown__menu a.selected:after {
  content: '';
  position: absolute;
  right: 10px;
  font-weight: bold;
}
.cui .dropdown__menu a.selected:hover {
  color: #ffffff;
  background-color: #005073;
}
.cui .dropdown__menu a:hover {
  color: #58585b;
  background-color: rgba(0, 0, 0, 0.05);
  text-decoration: none !important;
}
.cui .dropdown.active .dropdown__menu {
  visibility: visible;
  opacity: 1;
  z-index: 1032;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.cui .dropdown.active .btn.btn--dropdown:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cui .dropdown .btn ~ .dropdown__menu {
  left: 0;
  margin-top: 5px;
  min-width: 200px;
  max-width: 300px;
}
.cui .dropdown .btn ~ .dropdown__menu.dropdown__menu--openleft {
  left: unset;
  right: 0;
}
.cui .dropdown .btn.btn--dropdown:after {
  content: '';
  -webkit-transform: rotate(0);
  transform: rotate(0);
  font-size: 80%;
  display: inline-flex;
  margin-left: 5px;
  color: inherit;
  text-align: center;
}
.cui .dropdown__divider {
  height: 1px;
  overflow: hidden;
  background-color: #dfdfdf;
}
.cui .btns,
.cui .btn-group {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.cui .btns .btn + .btn,
.cui .btn-group .btn + .btn {
  border-left: 1px solid transparent;
  margin: 0;
}
.cui .btns.btn-group--large .btn span[class^='icon-'],
.cui .btn-group.btn-group--large .btn span[class^='icon-'] {
  font-size: 2rem;
}
.cui .btns > .dropdown .btn,
.cui .btns > .btn,
.cui .btn-group > .dropdown .btn,
.cui .btn-group > .btn {
  display: inline-flex;
  padding: 0 10px;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  align-items: center;
  justify-content: center;
}
.cui .btns > .dropdown .btn--default,
.cui .btns > .btn--default,
.cui .btn-group > .dropdown .btn--default,
.cui .btn-group > .btn--default {
  border: 1px solid #58585b;
}
.cui .btns > .dropdown .btn--primary,
.cui .btns > .btn--primary,
.cui .btn-group > .dropdown .btn--primary,
.cui .btn-group > .btn--primary {
  border: 1px solid #017cad;
}
.cui .btns > .dropdown .btn--primary-ghost,
.cui .btns > .btn--primary-ghost,
.cui .btn-group > .dropdown .btn--primary-ghost,
.cui .btn-group > .btn--primary-ghost {
  border: 1px solid #017cad;
}
.cui .btns > .dropdown .btn--secondary,
.cui .btns > .btn--secondary,
.cui .btn-group > .dropdown .btn--secondary,
.cui .btn-group > .btn--secondary {
  border: 1px solid #017cad;
}
.cui .btns > .dropdown .btn--negative,
.cui .btns > .btn--negative,
.cui .btn-group > .dropdown .btn--negative,
.cui .btn-group > .btn--negative {
  border: 1px solid;
}
.cui .btns > .dropdown .btn--success,
.cui .btns > .btn--success,
.cui .btn-group > .dropdown .btn--success,
.cui .btn-group > .btn--success {
  border: 1px solid #6ebe4a;
}
.cui .btns > .dropdown .btn--white,
.cui .btns > .btn--white,
.cui .btn-group > .dropdown .btn--white,
.cui .btn-group > .btn--white {
  border: 1px solid transparent;
}
.cui .btns > .dropdown .btn--white-ghost,
.cui .btns > .btn--white-ghost,
.cui .btn-group > .dropdown .btn--white-ghost,
.cui .btn-group > .btn--white-ghost {
  border: 1px solid transparent;
}
.cui .btns > .dropdown .btn--gray-ghost,
.cui .btns > .btn--gray-ghost,
.cui .btn-group > .dropdown .btn--gray-ghost,
.cui .btn-group > .btn--gray-ghost {
  border: 1px solid #626469;
}
.cui .btns > .dropdown .btn.toggled,
.cui .btns > .btn.toggled,
.cui .btn-group > .dropdown .btn.toggled,
.cui .btn-group > .btn.toggled {
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.cui .btns > .dropdown .btn.toggled .cui .btns > .dropdown .btn.selected,
.cui .btns > .btn.toggled .cui .btns > .dropdown .btn.selected,
.cui .btn-group > .dropdown .btn.toggled .cui .btns > .dropdown .btn.selected,
.cui .btn-group > .btn.toggled .cui .btns > .dropdown .btn.selected,
.cui .btns > .dropdown .btn.toggled .cui .btns > .btn.selected,
.cui .btns > .btn.toggled .cui .btns > .btn.selected,
.cui .btn-group > .dropdown .btn.toggled .cui .btns > .btn.selected,
.cui .btn-group > .btn.toggled .cui .btns > .btn.selected,
.cui .btns > .dropdown .btn.toggled .cui .btn-group > .dropdown .btn.selected,
.cui .btns > .btn.toggled .cui .btn-group > .dropdown .btn.selected,
.cui
  .btn-group
  > .dropdown
  .btn.toggled
  .cui
  .btn-group
  > .dropdown
  .btn.selected,
.cui .btn-group > .btn.toggled .cui .btn-group > .dropdown .btn.selected,
.cui .btns > .dropdown .btn.toggled .cui .btn-group > .btn.selected,
.cui .btns > .btn.toggled .cui .btn-group > .btn.selected,
.cui .btn-group > .dropdown .btn.toggled .cui .btn-group > .btn.selected,
.cui .btn-group > .btn.toggled .cui .btn-group > .btn.selected {
  background: unset;
}
.cui .btns > .dropdown .btn:first-child,
.cui .btns > .btn:first-child,
.cui .btn-group > .dropdown .btn:first-child,
.cui .btn-group > .btn:first-child {
  padding-left: 20px;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}
.cui .btns > .dropdown .btn:last-child,
.cui .btns > .btn:last-child,
.cui .btn-group > .dropdown .btn:last-child,
.cui .btn-group > .btn:last-child {
  padding-right: 20px;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
.cui .btns > .dropdown .btn:focus,
.cui .btns > .btn:focus,
.cui .btn-group > .dropdown .btn:focus,
.cui .btn-group > .btn:focus {
  border: 1px solid transparent;
}
.cui .btns--square > .dropdown .btn:first-child,
.cui .btns--square > .btn:first-child,
.cui .btn-group--square > .dropdown .btn:first-child,
.cui .btn-group--square > .btn:first-child {
  padding-left: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.cui .btns--square > .dropdown .btn:last-child,
.cui .btns--square > .btn:last-child,
.cui .btn-group--square > .dropdown .btn:last-child,
.cui .btn-group--square > .btn:last-child {
  padding-right: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.cui .form-group + .divider,
.cui .btn-group + .divider {
  border-top: none;
  border-left: 1px solid #dfdfdf;
  margin: 0 0 0 10px;
  padding-right: 10px;
  height: 30px;
}
.cui.cui--compressed .btn {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-width: 1px;
  text-align: center;
  vertical-align: middle;
  padding: 0 20px;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 2.4rem;
  height: 30px;
  min-width: 0;
  max-width: 200px;
  overflow: visible;
}
.cui.cui--compressed .btn > span[class^='icon-'] {
  font-size: 1.4rem;
}
.cui.cui--compressed .btn .btn__subtext {
  top: 35px;
}
.cui.cui--compressed .btn.btn--icon {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-width: 1px;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 1.3rem;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
  overflow: visible;
}
.cui.cui--compressed .btn.btn--icon > span[class^='icon-'] {
  font-size: 1.3rem;
}
.cui.cui--compressed .btn.btn--icon .btn__subtext {
  top: 35px;
}
.cui.cui--compressed .btn-group > .dropdown .btn,
.cui.cui--compressed .btn-group > .btn {
  padding: 0 5px;
}
.cui.cui--compressed .btn-group > .dropdown .btn.selected,
.cui.cui--compressed .btn-group > .btn.selected {
  transition: all ease 0.25s;
}
.cui.cui--animated .btn {
  transition: box-shadow 0.1s linear;
}
.cui.cui--animated .dropdown.active .dropdown__menu {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.cui.cui--animated .dropdown.active .btn.btn--dropdown:after {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.cui.cui--animated .btn.btn--dropdown:after {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.cui .checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
}
.cui .checkbox.disabled,
.cui .checkbox[disabled] {
  opacity: 1;
}
.cui .checkbox.disabled input:checked ~ .checkbox__input:before,
.cui .checkbox[disabled] input:checked ~ .checkbox__input:before {
  background-color: #f2f2f2;
  box-shadow: inset 0 0 0 1px #c6c7ca;
}
.cui .checkbox.disabled input:checked ~ .checkbox__input:after,
.cui .checkbox[disabled] input:checked ~ .checkbox__input:after {
  color: #c6c7ca;
}
.cui .checkbox.disabled input ~ .checkbox__input:before,
.cui .checkbox[disabled] input ~ .checkbox__input:before {
  background-color: #f2f2f2;
  box-shadow: inset 0 0 0 1px #c6c7ca;
}
.cui .checkbox input {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0;
}
.cui .checkbox input.hover ~ .checkbox__input:before,
.cui .checkbox input:hover ~ .checkbox__input:before {
  box-shadow: inset 0 0 0 2px #00bceb;
}
.cui .checkbox input.checked ~ .checkbox__label,
.cui .checkbox input:checked ~ .checkbox__label {
  color: #58585b;
}
.cui .checkbox input.checked.hover ~ .checkbox__input:before,
.cui .checkbox input.checked:hover ~ .checkbox__input:before,
.cui .checkbox input:checked.hover ~ .checkbox__input:before,
.cui .checkbox input:checked:hover ~ .checkbox__input:before {
  background-color: #00bceb;
}
.cui .checkbox input.checked.hover ~ .checkbox__input:after,
.cui .checkbox input.checked:hover ~ .checkbox__input:after,
.cui .checkbox input:checked.hover ~ .checkbox__input:after,
.cui .checkbox input:checked:hover ~ .checkbox__input:after {
  color: #ffffff;
}
.cui .checkbox input.checked ~ .checkbox__input:before,
.cui .checkbox input:checked ~ .checkbox__input:before {
  box-shadow: inset 0 0 0 1px #00bceb;
  background-color: #00bceb;
}
.cui .checkbox input.checked ~ .checkbox__input:after,
.cui .checkbox input:checked ~ .checkbox__input:after {
  color: #ffffff;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cui .checkbox input.focus ~ .checkbox__input:before,
.cui .checkbox input:focus ~ .checkbox__input:before {
  outline-color: #00bceb;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
  box-shadow: inset 0 0 0 1px #00bceb;
}
.cui .checkbox input.active ~ .checkbox__input:before,
.cui .checkbox input:active ~ .checkbox__input:before {
  box-shadow: none;
  background-color: #00bceb;
}
.cui .checkbox .checkbox__input {
  position: relative;
  display: inline-flex;
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.cui .checkbox .checkbox__input:before,
.cui .checkbox .checkbox__input:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '';
}
.cui .checkbox .checkbox__input:before {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui .checkbox .checkbox__input:after {
  content: '';
  display: none;
  color: #ffffff;
  font-weight: bold;
  line-height: 1;
}
.cui .checkbox .checkbox__label {
  color: #58585b;
  margin-left: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cui .checkbox:last-of-type {
  margin-right: 0;
}
.cui .checkbox--nowrap .checkbox__label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cui .panel.panel--dkgray .checkbox.disabled,
.cui .panel.panel--dkgray .checkbox[disabled],
.cui .panel.panel--indigo .checkbox.disabled,
.cui .panel.panel--indigo .checkbox[disabled] {
  opacity: 1;
}
.cui
  .panel.panel--dkgray
  .checkbox.disabled
  input:checked
  ~ .checkbox__input:before,
.cui
  .panel.panel--dkgray
  .checkbox[disabled]
  input:checked
  ~ .checkbox__input:before,
.cui
  .panel.panel--indigo
  .checkbox.disabled
  input:checked
  ~ .checkbox__input:before,
.cui
  .panel.panel--indigo
  .checkbox[disabled]
  input:checked
  ~ .checkbox__input:before {
  background-color: #f2f2f2;
  box-shadow: inset 0 0 0 1px #c6c7ca;
}
.cui
  .panel.panel--dkgray
  .checkbox.disabled
  input:checked
  ~ .checkbox__input:after,
.cui
  .panel.panel--dkgray
  .checkbox[disabled]
  input:checked
  ~ .checkbox__input:after,
.cui
  .panel.panel--indigo
  .checkbox.disabled
  input:checked
  ~ .checkbox__input:after,
.cui
  .panel.panel--indigo
  .checkbox[disabled]
  input:checked
  ~ .checkbox__input:after {
  color: #c6c7ca;
}
.cui .panel.panel--dkgray .checkbox.disabled input ~ .checkbox__input:before,
.cui .panel.panel--dkgray .checkbox[disabled] input ~ .checkbox__input:before,
.cui .panel.panel--indigo .checkbox.disabled input ~ .checkbox__input:before,
.cui .panel.panel--indigo .checkbox[disabled] input ~ .checkbox__input:before {
  background-color: #f2f2f2;
  box-shadow: inset 0 0 0 1px #c6c7ca;
}
.cui .panel.panel--dkgray .checkbox input,
.cui .panel.panel--indigo .checkbox input {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0;
}
.cui .panel.panel--dkgray .checkbox input.hover ~ .checkbox__input:before,
.cui .panel.panel--dkgray .checkbox input:hover ~ .checkbox__input:before,
.cui .panel.panel--indigo .checkbox input.hover ~ .checkbox__input:before,
.cui .panel.panel--indigo .checkbox input:hover ~ .checkbox__input:before {
  box-shadow: inset 0 0 0 2px #00bceb;
}
.cui .panel.panel--dkgray .checkbox input.checked ~ .checkbox__label,
.cui .panel.panel--dkgray .checkbox input:checked ~ .checkbox__label,
.cui .panel.panel--indigo .checkbox input.checked ~ .checkbox__label,
.cui .panel.panel--indigo .checkbox input:checked ~ .checkbox__label {
  color: #ffffff;
}
.cui
  .panel.panel--dkgray
  .checkbox
  input.checked.hover
  ~ .checkbox__input:before,
.cui
  .panel.panel--dkgray
  .checkbox
  input.checked:hover
  ~ .checkbox__input:before,
.cui
  .panel.panel--dkgray
  .checkbox
  input:checked.hover
  ~ .checkbox__input:before,
.cui
  .panel.panel--dkgray
  .checkbox
  input:checked:hover
  ~ .checkbox__input:before,
.cui
  .panel.panel--indigo
  .checkbox
  input.checked.hover
  ~ .checkbox__input:before,
.cui
  .panel.panel--indigo
  .checkbox
  input.checked:hover
  ~ .checkbox__input:before,
.cui
  .panel.panel--indigo
  .checkbox
  input:checked.hover
  ~ .checkbox__input:before,
.cui
  .panel.panel--indigo
  .checkbox
  input:checked:hover
  ~ .checkbox__input:before {
  background-color: #00bceb;
}
.cui
  .panel.panel--dkgray
  .checkbox
  input.checked.hover
  ~ .checkbox__input:after,
.cui
  .panel.panel--dkgray
  .checkbox
  input.checked:hover
  ~ .checkbox__input:after,
.cui
  .panel.panel--dkgray
  .checkbox
  input:checked.hover
  ~ .checkbox__input:after,
.cui
  .panel.panel--dkgray
  .checkbox
  input:checked:hover
  ~ .checkbox__input:after,
.cui
  .panel.panel--indigo
  .checkbox
  input.checked.hover
  ~ .checkbox__input:after,
.cui
  .panel.panel--indigo
  .checkbox
  input.checked:hover
  ~ .checkbox__input:after,
.cui
  .panel.panel--indigo
  .checkbox
  input:checked.hover
  ~ .checkbox__input:after,
.cui
  .panel.panel--indigo
  .checkbox
  input:checked:hover
  ~ .checkbox__input:after {
  color: #ffffff;
}
.cui .panel.panel--dkgray .checkbox input.checked ~ .checkbox__input:before,
.cui .panel.panel--dkgray .checkbox input:checked ~ .checkbox__input:before,
.cui .panel.panel--indigo .checkbox input.checked ~ .checkbox__input:before,
.cui .panel.panel--indigo .checkbox input:checked ~ .checkbox__input:before {
  box-shadow: inset 0 0 0 1px #00bceb;
  background-color: #00bceb;
}
.cui .panel.panel--dkgray .checkbox input.checked ~ .checkbox__input:after,
.cui .panel.panel--dkgray .checkbox input:checked ~ .checkbox__input:after,
.cui .panel.panel--indigo .checkbox input.checked ~ .checkbox__input:after,
.cui .panel.panel--indigo .checkbox input:checked ~ .checkbox__input:after {
  color: #ffffff;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cui .panel.panel--dkgray .checkbox input.focus ~ .checkbox__input:before,
.cui .panel.panel--dkgray .checkbox input:focus ~ .checkbox__input:before,
.cui .panel.panel--indigo .checkbox input.focus ~ .checkbox__input:before,
.cui .panel.panel--indigo .checkbox input:focus ~ .checkbox__input:before {
  outline-color: #ffffff;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
  box-shadow: inset 0 0 0 1px #00bceb;
}
.cui .panel.panel--dkgray .checkbox input.active ~ .checkbox__input:before,
.cui .panel.panel--dkgray .checkbox input:active ~ .checkbox__input:before,
.cui .panel.panel--indigo .checkbox input.active ~ .checkbox__input:before,
.cui .panel.panel--indigo .checkbox input:active ~ .checkbox__input:before {
  box-shadow: none;
  background-color: #00bceb;
}
.cui .panel.panel--dkgray .checkbox .checkbox__input,
.cui .panel.panel--indigo .checkbox .checkbox__input {
  position: relative;
  display: inline-flex;
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.cui .panel.panel--dkgray .checkbox .checkbox__input:before,
.cui .panel.panel--dkgray .checkbox .checkbox__input:after,
.cui .panel.panel--indigo .checkbox .checkbox__input:before,
.cui .panel.panel--indigo .checkbox .checkbox__input:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '';
}
.cui .panel.panel--dkgray .checkbox .checkbox__input:before,
.cui .panel.panel--indigo .checkbox .checkbox__input:before {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui .panel.panel--dkgray .checkbox .checkbox__input:after,
.cui .panel.panel--indigo .checkbox .checkbox__input:after {
  content: '';
  display: none;
  color: #ffffff;
  font-weight: bold;
  line-height: 1;
}
.cui .panel.panel--dkgray .checkbox .checkbox__label,
.cui .panel.panel--indigo .checkbox .checkbox__label {
  color: #ffffff;
  margin-left: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cui .panel.panel--success .checkbox.disabled,
.cui .panel.panel--success .checkbox[disabled],
.cui .panel.panel--blue .checkbox.disabled,
.cui .panel.panel--blue .checkbox[disabled] {
  opacity: 1;
}
.cui
  .panel.panel--success
  .checkbox.disabled
  input:checked
  ~ .checkbox__input:before,
.cui
  .panel.panel--success
  .checkbox[disabled]
  input:checked
  ~ .checkbox__input:before,
.cui
  .panel.panel--blue
  .checkbox.disabled
  input:checked
  ~ .checkbox__input:before,
.cui
  .panel.panel--blue
  .checkbox[disabled]
  input:checked
  ~ .checkbox__input:before {
  background-color: #d7d7d8;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui
  .panel.panel--success
  .checkbox.disabled
  input:checked
  ~ .checkbox__input:after,
.cui
  .panel.panel--success
  .checkbox[disabled]
  input:checked
  ~ .checkbox__input:after,
.cui
  .panel.panel--blue
  .checkbox.disabled
  input:checked
  ~ .checkbox__input:after,
.cui
  .panel.panel--blue
  .checkbox[disabled]
  input:checked
  ~ .checkbox__input:after {
  color: #9e9ea2;
}
.cui .panel.panel--success .checkbox.disabled input ~ .checkbox__input:before,
.cui .panel.panel--success .checkbox[disabled] input ~ .checkbox__input:before,
.cui .panel.panel--blue .checkbox.disabled input ~ .checkbox__input:before,
.cui .panel.panel--blue .checkbox[disabled] input ~ .checkbox__input:before {
  background-color: #d7d7d8;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui .panel.panel--success .checkbox input,
.cui .panel.panel--blue .checkbox input {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0;
}
.cui .panel.panel--success .checkbox input.hover ~ .checkbox__input:before,
.cui .panel.panel--success .checkbox input:hover ~ .checkbox__input:before,
.cui .panel.panel--blue .checkbox input.hover ~ .checkbox__input:before,
.cui .panel.panel--blue .checkbox input:hover ~ .checkbox__input:before {
  box-shadow: inset 0 0 0 2px #017cad;
}
.cui .panel.panel--success .checkbox input.checked ~ .checkbox__label,
.cui .panel.panel--success .checkbox input:checked ~ .checkbox__label,
.cui .panel.panel--blue .checkbox input.checked ~ .checkbox__label,
.cui .panel.panel--blue .checkbox input:checked ~ .checkbox__label {
  color: #39393b;
}
.cui
  .panel.panel--success
  .checkbox
  input.checked.hover
  ~ .checkbox__input:before,
.cui
  .panel.panel--success
  .checkbox
  input.checked:hover
  ~ .checkbox__input:before,
.cui
  .panel.panel--success
  .checkbox
  input:checked.hover
  ~ .checkbox__input:before,
.cui
  .panel.panel--success
  .checkbox
  input:checked:hover
  ~ .checkbox__input:before,
.cui .panel.panel--blue .checkbox input.checked.hover ~ .checkbox__input:before,
.cui .panel.panel--blue .checkbox input.checked:hover ~ .checkbox__input:before,
.cui .panel.panel--blue .checkbox input:checked.hover ~ .checkbox__input:before,
.cui
  .panel.panel--blue
  .checkbox
  input:checked:hover
  ~ .checkbox__input:before {
  background-color: #005073;
}
.cui
  .panel.panel--success
  .checkbox
  input.checked.hover
  ~ .checkbox__input:after,
.cui
  .panel.panel--success
  .checkbox
  input.checked:hover
  ~ .checkbox__input:after,
.cui
  .panel.panel--success
  .checkbox
  input:checked.hover
  ~ .checkbox__input:after,
.cui
  .panel.panel--success
  .checkbox
  input:checked:hover
  ~ .checkbox__input:after,
.cui .panel.panel--blue .checkbox input.checked.hover ~ .checkbox__input:after,
.cui .panel.panel--blue .checkbox input.checked:hover ~ .checkbox__input:after,
.cui .panel.panel--blue .checkbox input:checked.hover ~ .checkbox__input:after,
.cui .panel.panel--blue .checkbox input:checked:hover ~ .checkbox__input:after {
  color: #ffffff;
}
.cui .panel.panel--success .checkbox input.checked ~ .checkbox__input:before,
.cui .panel.panel--success .checkbox input:checked ~ .checkbox__input:before,
.cui .panel.panel--blue .checkbox input.checked ~ .checkbox__input:before,
.cui .panel.panel--blue .checkbox input:checked ~ .checkbox__input:before {
  box-shadow: inset 0 0 0 1px #005073;
  background-color: #005073;
}
.cui .panel.panel--success .checkbox input.checked ~ .checkbox__input:after,
.cui .panel.panel--success .checkbox input:checked ~ .checkbox__input:after,
.cui .panel.panel--blue .checkbox input.checked ~ .checkbox__input:after,
.cui .panel.panel--blue .checkbox input:checked ~ .checkbox__input:after {
  color: #ffffff;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cui .panel.panel--success .checkbox input.focus ~ .checkbox__input:before,
.cui .panel.panel--success .checkbox input:focus ~ .checkbox__input:before,
.cui .panel.panel--blue .checkbox input.focus ~ .checkbox__input:before,
.cui .panel.panel--blue .checkbox input:focus ~ .checkbox__input:before {
  outline-color: #ffffff;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
  box-shadow: inset 0 0 0 1px #017cad;
}
.cui .panel.panel--success .checkbox input.active ~ .checkbox__input:before,
.cui .panel.panel--success .checkbox input:active ~ .checkbox__input:before,
.cui .panel.panel--blue .checkbox input.active ~ .checkbox__input:before,
.cui .panel.panel--blue .checkbox input:active ~ .checkbox__input:before {
  box-shadow: none;
  background-color: #005073;
}
.cui .panel.panel--success .checkbox .checkbox__input,
.cui .panel.panel--blue .checkbox .checkbox__input {
  position: relative;
  display: inline-flex;
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.cui .panel.panel--success .checkbox .checkbox__input:before,
.cui .panel.panel--success .checkbox .checkbox__input:after,
.cui .panel.panel--blue .checkbox .checkbox__input:before,
.cui .panel.panel--blue .checkbox .checkbox__input:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '';
}
.cui .panel.panel--success .checkbox .checkbox__input:before,
.cui .panel.panel--blue .checkbox .checkbox__input:before {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui .panel.panel--success .checkbox .checkbox__input:after,
.cui .panel.panel--blue .checkbox .checkbox__input:after {
  content: '';
  display: none;
  color: #ffffff;
  font-weight: bold;
  line-height: 1;
}
.cui .panel.panel--success .checkbox .checkbox__label,
.cui .panel.panel--blue .checkbox .checkbox__label {
  color: #39393b;
  margin-left: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cui.cui--animated .checkbox input ~ .checkbox__input:before {
  transition: all 0.1s ease-out;
}
.cui.cui--animated .checkbox input:hover ~ .checkbox__input:before {
  transition: all 0.1s ease-in;
}
.cui .swatch {
  font-size: 1.2rem;
  line-height: 1.6rem;
  display: inline-flex;
  flex-direction: column;
  padding: 10px;
  width: 200px;
  height: 200px;
  transition: all 0.2s;
  /*
        &:hover {
            box-shadow: $base-box-shadow;
            transform: scale3d(1.1,1.1,1.1);
            transition: all 0.2s;
            z-index: 1;
        }
    */
}
.cui .swatch.swatch-core--ciscoblue {
  position: relative;
  background: #00bceb;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-core--ciscoblue:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Cisco Blue  #00bceb';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-core--ciscoindigo {
  position: relative;
  background: #005073;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-core--ciscoindigo:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Indigo Blue  #005073';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-core--ciscogreen {
  position: relative;
  background: #6ebe4a;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-core--ciscogreen:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Green  #6ebe4a';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-core--accentorange {
  position: relative;
  background: #fbab18;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-core--accentorange:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Orange  #fbab18';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-core--accentred {
  position: relative;
  background: #e2231a;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-core--accentred:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Red  #e2231a';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-core--darkgray1 {
  position: relative;
  background: #39393b;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-core--darkgray1:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Dark Gray 1  #39393b';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-core--darkgray2 {
  position: relative;
  background: #58585b;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-core--darkgray2:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Dark Gray 2  #58585b';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-core--medgray2 {
  position: relative;
  background: #9e9ea2;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-core--medgray2:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Medium Gray 2  #9e9ea2';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-core--white {
  position: relative;
  background: #ffffff;
  color: #1a1a1a;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-core--white:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Cisco Core White  #ffffff';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-core--ltblue {
  position: relative;
  background: #f2fbfd;
  color: #1a1a1a;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-core--ltblue:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Light Blue  #f2fbfd';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-core--vibblue {
  position: relative;
  background: #017cad;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-core--vibblue:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Vibrant Blue  #017CAD';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-supp--darkgray3 {
  position: relative;
  background: #626469;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-supp--darkgray3:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Dark Gray 3  #626469';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-supp--darkgray4 {
  position: relative;
  background: #7f7f86;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-supp--darkgray4:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Dark Gray 4  #7f7f86';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-supp--medgray4 {
  position: relative;
  background: #b6b9bb;
  color: #1a1a1a;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-supp--medgray4:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Medium Gray 4  #b6b9bb';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-supp--litegray2 {
  position: relative;
  background: #c6c7ca;
  color: #1a1a1a;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-supp--litegray2:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Light Gray 2  #c6c7ca';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-supp--litegray3 {
  position: relative;
  background: #dfdfdf;
  color: #1a1a1a;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-supp--litegray3:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Light Gray 3  #dfdfdf';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-supp--litegray5 {
  position: relative;
  background: #f2f2f2;
  color: #1a1a1a;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-supp--litegray5:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Light Gray 5  #f2f2f2';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-stat--statusblue {
  position: relative;
  background: #64bbe3;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-stat--statusblue:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Status Blue  #64bbe3';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-stat--statusturq {
  position: relative;
  background: #14a792;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-stat--statusturq:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Status Turquoise  #14a792';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-stat--statusgreen {
  position: relative;
  background: #6cc04a;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-stat--statusgreen:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Status Green  #6cc04a';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-stat--statusyellow {
  position: relative;
  background: #ffcc00;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-stat--statusyellow:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Status Yellow  #ffcc00';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-stat--statusorange {
  position: relative;
  background: #fbab18;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-stat--statusorange:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Status Orange  #fbab18';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .swatch.swatch-stat--statusred {
  position: relative;
  background: #e2231a;
  color: #ffffff;
  border: 1px solid #dfdfdf;
}
.cui .swatch.swatch-stat--statusred:after {
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  content: 'Status Red  #e2231a';
  position: absolute;
  left: 10px;
  bottom: 5px;
}
.cui .divider {
  position: relative;
  text-align: center;
  border-top: 1px solid #dfdfdf;
  margin: 20px 0;
}
.cui .divider--thick {
  border-top: 3px solid #dfdfdf;
}
.cui .divider--thicker {
  border-top: 5px solid #dfdfdf;
}
.cui .divider--thickest {
  border-top: 8px solid #dfdfdf;
}
.cui .divider--accent-blue:before {
  position: absolute;
  left: 0;
  top: -1px;
  width: 5%;
  min-width: 40px;
  height: 1px;
  background-color: #00bceb;
  content: '';
}
.cui .divider--accent-indigo:before {
  position: absolute;
  left: 0;
  top: -1px;
  width: 5%;
  min-width: 40px;
  height: 1px;
  background-color: #005073;
  content: '';
}
.cui .divider--accent-success:before {
  position: absolute;
  left: 0;
  top: -1px;
  width: 5%;
  min-width: 40px;
  height: 1px;
  background-color: #6ebe4a;
  content: '';
}
.cui .divider--compressed {
  margin: 10px 0;
}
.cui .divider--regular {
  margin: 20px 0;
}
.cui .divider--loose {
  margin: 40px 0;
}
.cui .divider--thick.divider--accent-blue:before {
  top: -3px;
  height: 3px;
}
.cui .divider--thick.divider--accent-indigo:before {
  top: -3px;
  height: 3px;
}
.cui .divider--thick.divider--accent-success:before {
  top: -3px;
  height: 3px;
}
.cui .divider--thicker.divider--accent-blue:before {
  top: -5px;
  height: 5px;
}
.cui .divider--thicker.divider--accent-indigo:before {
  top: -5px;
  height: 5px;
}
.cui .divider--thicker.divider--accent-success:before {
  top: -5px;
  height: 5px;
}
.cui .divider--thickest.divider--accent-blue:before {
  top: -8px;
  height: 8px;
}
.cui .divider--thickest.divider--accent-indigo:before {
  top: -8px;
  height: 8px;
}
.cui .divider--thickest.divider--accent-success:before {
  top: -8px;
  height: 8px;
}
.cui.cui--compressed .divider {
  margin: 10px 0;
}
.cui form {
  margin-bottom: 20px;
}
.cui form:last-child,
.cui form > *:last-child {
  margin-bottom: 0;
}
.cui fieldset {
  margin: 0 0 20px 0;
  padding: 0;
  border: 0;
}
.cui fieldset > *:last-child {
  margin-bottom: 0;
}
.cui legend,
.cui .legend {
  margin-bottom: 10px;
  font-weight: bold;
  text-transform: uppercase;
}
.cui legend--toggle,
.cui .legend--toggle {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cui legend--toggle:hover,
.cui .legend--toggle:hover {
  color: #00bceb;
}
.cui .form-group {
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 0 0 10px 0;
}
.cui .form-group.form-group--compressed {
  margin: 0 0 5px 0;
}
.cui .form-group.form-group--regular {
  margin: 0 0 10px 0;
}
.cui .form-group.form-group--loose {
  margin: 0 0 20px 0;
}
.cui .form-group.form-group--nospacing {
  margin: 0;
}
.cui .form-group.form-group--inline {
  display: inline-flex;
  margin: 0 10px 0 0;
}
.cui .form-group.form-group--inline.form-group--compressed {
  margin: 0 5px 0 0;
}
.cui .form-group.form-group--inline.form-group--regular {
  margin: 0 10px 0 0;
}
.cui .form-group.form-group--inline.form-group--loose {
  margin: 0 20px 0 0;
}
.cui .form-group.form-group--inline.form-group--nospacing {
  margin: 0;
}
.cui .form-group--inline:last-of-type {
  margin: 0;
}
.cui .checkbox__label,
.cui .radio__label,
.cui .switch__label,
.cui .form-group label {
  color: #58585b;
  text-align: left;
  font-weight: normal;
}
.cui .form-dropdown__select {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 14px;
  z-index: 1;
}
.cui .form-dropdown__select:focus ~ label {
  color: #58585b;
}
.cui .form-dropdown__select:focus ~ label::after {
  bottom: -4px;
  height: 5px;
  background: #00bceb;
}
.cui .form-dropdown__select:hover ~ .form-dropdown__chevron::after,
.cui .form-dropdown__select:focus ~ .form-dropdown__chevron::after {
  color: #58585b;
}
.cui .form-dropdown__chevron:after {
  content: '';
  color: #c6c7ca;
  font-size: 1.2rem;
  position: absolute;
  margin-top: 1px;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.2s ease;
}
.cui.cui--compressed .form-group {
  margin: 0 0 5px 0;
}
.cui.cui--compressed .form-group.form-group--inline {
  margin: 0 5px 0 0;
}
.cui .label + .label {
  margin-left: 5px;
}
.cui .label {
  display: inline-flex;
  font-weight: 400;
  align-items: center;
  border: 0 none;
  border-radius: 3px;
  letter-spacing: 0.025rem;
  margin: 1px 0;
  padding: 0 10px;
  font-size: 1.2rem;
  height: 24px;
  line-height: 1.2rem;
  vertical-align: middle;
  background: #dfdfdf;
  border: 'unset';
  color: #39393b;
}
.cui .label:focus {
  outline-color: #00bceb;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
}
.cui .label .icon-close {
  font-size: 1.2rem;
}
.cui .label a,
.cui .label a:hover {
  color: #39393b;
}
.cui .label--info {
  background: #64bbe3;
  color: #39393b;
}
.cui .label--info a,
.cui .label--info a:hover {
  color: #39393b;
}
.cui .label--success {
  background: #6ebe4a;
  color: #ffffff;
}
.cui .label--success a,
.cui .label--success a:hover {
  color: #ffffff;
}
.cui .label--warning {
  background: #fbab18;
  color: #39393b;
}
.cui .label--warning a,
.cui .label--warning a:hover {
  color: #39393b;
}
.cui .label--warning-alt {
  background: #ffcc00;
  color: #39393b;
}
.cui .label--warning-alt a,
.cui .label--warning-alt a:hover {
  color: #39393b;
}
.cui .label--danger {
  background: #e2231a;
  color: #ffffff;
}
.cui .label--danger a,
.cui .label--danger a:hover {
  color: #ffffff;
}
.cui .label--blue {
  background: #00bceb;
  color: #39393b;
}
.cui .label--blue a,
.cui .label--blue a:hover {
  color: #39393b;
}
.cui .label--ltgray {
  background: #f2f2f2;
  color: #39393b;
}
.cui .label--ltgray a,
.cui .label--ltgray a:hover {
  color: #39393b;
}
.cui .label--dkgray {
  background: #58585b;
  color: #ffffff;
}
.cui .label--dkgray a,
.cui .label--dkgray a:hover {
  color: #ffffff;
}
.cui .label--vibblue {
  background: #017cad;
  color: #ffffff;
}
.cui .label--vibblue a,
.cui .label--vibblue a:hover {
  color: #ffffff;
}
.cui .label--indigo {
  background: #005073;
  color: #ffffff;
}
.cui .label--indigo a,
.cui .label--indigo a:hover {
  color: #ffffff;
}
.cui .label--white {
  background: #ffffff;
  color: #39393b;
}
.cui .label--white a,
.cui .label--white a:hover {
  color: #39393b;
}
.cui .label--ghost {
  background: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.cui .label--ghost a,
.cui .label--ghost a:hover {
  color: #ffffff;
}
.cui .label--tiny {
  margin: 1px 0;
  padding: 0 6px;
  font-size: 0.9rem;
  height: 15px;
  line-height: 0.9rem;
  vertical-align: middle;
}
.cui .label--tiny .icon-close {
  font-size: 0.9rem;
}
.cui .label--small {
  margin: 1px 0;
  padding: 0 10px;
  font-size: 1rem;
  height: 20px;
  line-height: 1rem;
  vertical-align: middle;
}
.cui .label--small .icon-close {
  font-size: 1rem;
}
.cui .label--medium {
  margin: 1px 0;
  padding: 0 10px;
  font-size: 1.2rem;
  height: 24px;
  line-height: 1.2rem;
  vertical-align: middle;
}
.cui .label--medium .icon-close {
  font-size: 1.2rem;
}
.cui .label--large {
  margin: 1px 0;
  padding: 0 15px;
  font-size: 1.4rem;
  height: 30px;
  line-height: 1.4rem;
  vertical-align: middle;
}
.cui .label--large .icon-close {
  font-size: 1.4rem;
}
.cui .label--circle {
  overflow: hidden;
  justify-content: center;
  vertical-align: middle;
  border-radius: 100%;
  margin: 1px 0;
  padding: 0;
  font-size: 1.2rem;
  height: 24px;
  line-height: 1.2rem;
  vertical-align: middle;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
}
.cui .label--circle .icon-close {
  font-size: 1.2rem;
}
.cui .label--circle.label--tiny {
  margin: 1px 0;
  padding: 0;
  font-size: 1.1rem;
  height: 18px;
  line-height: 1.1rem;
  vertical-align: middle;
  width: 18px;
  min-width: 18px;
  max-width: 18px;
}
.cui .label--circle.label--tiny .icon-close {
  font-size: 1.1rem;
}
.cui .label--circle.label--small {
  margin: 1px 0;
  padding: 0;
  font-size: 1.2rem;
  height: 24px;
  line-height: 1.2rem;
  vertical-align: middle;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
}
.cui .label--circle.label--small .icon-close {
  font-size: 1.2rem;
}
.cui .label--circle.label--medium {
  margin: 1px 0;
  padding: 0;
  font-size: 1.6rem;
  height: 36px;
  line-height: 1.6rem;
  vertical-align: middle;
  width: 36px;
  min-width: 36px;
  max-width: 36px;
}
.cui .label--circle.label--medium .icon-close {
  font-size: 1.6rem;
}
.cui .label--circle.label--large {
  margin: 1px 0;
  padding: 0;
  font-size: 2.4rem;
  height: 48px;
  line-height: 2.4rem;
  vertical-align: middle;
  width: 48px;
  min-width: 48px;
  max-width: 48px;
}
.cui .label--circle.label--large .icon-close {
  font-size: 2.4rem;
}
.cui .label--circle.label--xlarge {
  margin: 1px 0;
  padding: 0;
  font-size: 3.2rem;
  height: 60px;
  line-height: 3.2rem;
  vertical-align: middle;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
}
.cui .label--circle.label--xlarge .icon-close {
  font-size: 3.2rem;
}
.cui .label--circle.label--huge {
  margin: 1px 0;
  padding: 0;
  font-size: 3.6rem;
  height: 72px;
  line-height: 3.6rem;
  vertical-align: middle;
  width: 72px;
  min-width: 72px;
  max-width: 72px;
}
.cui .label--circle.label--huge .icon-close {
  font-size: 3.6rem;
}
.cui .label--circle.label--goliath {
  margin: 1px 0;
  padding: 0;
  font-size: 4rem;
  height: 96px;
  line-height: 4rem;
  vertical-align: middle;
  width: 96px;
  min-width: 96px;
  max-width: 96px;
}
.cui .label--circle.label--goliath .icon-close {
  font-size: 4rem;
}
.cui .label--bordered {
  border: 1px solid #dfdfdf;
}
.cui .label--bordered-white {
  border-color: #ffffff;
}
.cui .label--raised {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.15);
}
.cui .label .icon-close {
  margin-left: 5px;
  cursor: pointer;
  opacity: 0.75;
  transition: opacity 0.2s ease;
}
.cui .label .icon-close:hover {
  opacity: 1;
}
.cui .label-group .label + .label {
  margin-left: 0;
}
.cui .label-group .label {
  margin-bottom: 5px;
  margin-right: 5px;
}
.cui a:not(.btn),
.cui .link {
  color: #017cad;
  font-weight: normal;
  background: transparent;
  text-decoration: none;
  border-radius: 0;
}
.cui a:not(.btn):active,
.cui .link:active {
  color: #6f53bc;
}
.cui a:not(.btn):focus,
.cui a:not(.btn):hover,
.cui .link:focus,
.cui .link:hover {
  color: #005073;
}
.cui a:not(.btn):active,
.cui a:not(.btn):hover,
.cui a:not(.btn):focus,
.cui .link:active,
.cui .link:hover,
.cui .link:focus {
  text-decoration: underline;
}
.cui a:not(.btn):focus,
.cui .link:focus {
  outline: none;
}
.cui a:not(.btn).link--ghost,
.cui .link.link--ghost {
  color: #ffffff;
  opacity: 0.75;
}
.cui a:not(.btn).link--ghost:hover,
.cui .link.link--ghost:hover {
  opacity: 1;
}
.cui .link {
  color: #9e9ea2;
}
.cui .list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cui .list li {
  display: block;
}
.cui .list li + li {
  margin: -1px 0 0 0;
}
.cui .list.list--inline {
  display: inline-flex;
  flex-wrap: wrap;
}
.cui .list.list--inline li:last-child {
  padding-right: 0;
}
.cui .list.list--inline li + li {
  margin: 0 0 0 -1px;
}
.cui .list.list--inline.list--divider li:after {
  color: #9e9ea2;
  content: '|';
  margin: 0 5px;
  padding: 0;
}
.cui .list.list--inline.list--divider li:last-child:after {
  content: '';
  margin: 0;
}
.cui .list.list--inline.list--compressed li + li {
  margin: 0 0 0 10px;
}
.cui .list.list--inline.list--regular li + li {
  margin: 0 0 0 20px;
}
.cui .list.list--inline.list--loose li + li {
  margin: 0 0 0 40px;
}
.cui .list.list--highlight > li:hover > * {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}
.cui .list.list--aligntop li:first-child {
  align-items: flex-start;
}
.cui .list.list--fillbg {
  background-color: #ffffff;
}
.cui .list.list--compressed li + li {
  margin-top: 10px;
}
.cui .list.list--regular li + li {
  margin-top: 20px;
}
.cui .list.list--loose li + li {
  margin-top: 40px;
}
.cui.cui--compressed .list li + li {
  margin: -1px 0 0 0;
}
.cui.cui--compressed .list.list--inline li + li {
  margin: 0 0 0 10px;
}
.cui .panel-group .panel + .panel {
  margin-top: 2px;
}
.cui .panel-group .panel-group__header {
  margin: 0 10px;
}
.cui .panel {
  display: block;
  position: relative;
  margin: 0;
  border: 0 none;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #58585b;
  position: relative;
  background: #ffffff;
  box-shadow: none;
  border: 1px solid transparent;
  padding: 10px;
}
.cui .panel__header {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cui .panel__header,
.cui .panel__body,
.cui .panel__footer {
  position: relative;
  padding: 5px 0;
}
.cui .panel__header:after,
.cui .panel__body:after,
.cui .panel__footer:after {
  clear: both !important;
  content: '' !important;
  display: table !important;
}
.cui .panel__header > *:first-child,
.cui .panel__body > *:first-child,
.cui .panel__footer > *:first-child {
  margin-top: 0;
}
.cui .panel__header > *:last-child,
.cui .panel__body > *:last-child,
.cui .panel__footer > *:last-child {
  margin-bottom: 0;
}
.cui .panel--blue {
  color: #404040;
  position: relative;
  background: #00bceb;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .panel--blue a {
  color: #005073;
}
.cui .panel--blue a:hover {
  color: #005073;
}
.cui .panel--blue a:not(.btn) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
.cui .panel--blue a:not(.btn):active,
.cui .panel--blue a:not(.btn):hover,
.cui .panel--blue a:not(.btn):focus {
  text-decoration: underline;
}
.cui .panel--vibblue {
  color: #ffffff;
  position: relative;
  background: #017cad;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .panel--vibblue a {
  color: #ffffff;
}
.cui .panel--vibblue a:hover {
  color: #ffffff;
}
.cui .panel--vibblue a:not(.btn) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
.cui .panel--vibblue a:not(.btn):active,
.cui .panel--vibblue a:not(.btn):hover,
.cui .panel--vibblue a:not(.btn):focus {
  text-decoration: underline;
}
.cui .panel--ltgray {
  color: #58585b;
  position: relative;
  background: #f2f2f2;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .panel--mdgray {
  color: #58585b;
  position: relative;
  background: #dfdfdf;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .panel--dkgray {
  color: #ffffff;
  position: relative;
  background: #58585b;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .panel--dkgray a {
  color: #ffffff;
}
.cui .panel--dkgray a:hover {
  color: #ffffff;
}
.cui .panel--dkgray a:not(.btn) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
.cui .panel--dkgray a:not(.btn):active,
.cui .panel--dkgray a:not(.btn):hover,
.cui .panel--dkgray a:not(.btn):focus {
  text-decoration: underline;
}
.cui .panel--indigo {
  color: #ffffff;
  position: relative;
  background: #005073;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .panel--indigo a {
  color: #ffffff;
}
.cui .panel--indigo a:hover {
  color: #ffffff;
}
.cui .panel--indigo a:not(.btn) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
.cui .panel--indigo a:not(.btn):active,
.cui .panel--indigo a:not(.btn):hover,
.cui .panel--indigo a:not(.btn):focus {
  text-decoration: underline;
}
.cui .panel--gray-ghost {
  color: #58585b;
  position: relative;
  background: transparent;
  box-shadow: none;
  border: 1px solid #f2f2f2;
}
.cui .panel--info {
  color: #58585b;
  position: relative;
  background: #64bbe3;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .panel--info a {
  color: #ffffff;
}
.cui .panel--success {
  color: #404040;
  position: relative;
  background: #6ebe4a;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .panel--success a {
  color: #005073;
}
.cui .panel--success a:hover {
  color: #005073;
}
.cui .panel--success a:not(.btn) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
.cui .panel--success a:not(.btn):active,
.cui .panel--success a:not(.btn):hover,
.cui .panel--success a:not(.btn):focus {
  text-decoration: underline;
}
.cui .panel--warning {
  color: #ffffff;
  position: relative;
  background: #fbab18;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .panel--warning a {
  color: #ffffff;
}
.cui .panel--warning a:not(.btn) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
.cui .panel--warning a:not(.btn):active,
.cui .panel--warning a:not(.btn):hover,
.cui .panel--warning a:not(.btn):focus {
  text-decoration: underline;
}
.cui .panel--danger {
  color: #ffffff;
  position: relative;
  background: #e2231a;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .panel--danger a {
  color: #ffffff;
}
.cui .panel--danger a:hover {
  color: #ffffff;
}
.cui .panel--danger a:not(.btn) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
.cui .panel--danger a:not(.btn):active,
.cui .panel--danger a:not(.btn):hover,
.cui .panel--danger a:not(.btn):focus {
  text-decoration: underline;
}
.cui .panel--raised {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.15);
}
.cui .panel--raised-medium {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05), 0 5px 6px rgba(0, 0, 0, 0.15);
}
.cui .panel--raised-large {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 10px 12px rgba(0, 0, 0, 0.15);
}
.cui .panel--nopadding {
  padding: 0;
}
.cui .panel--content {
  box-sizing: content-box;
}
.cui .panel--fill {
  height: 100%;
}
.cui .panel--skinny,
.cui .panel--compressed {
  padding: 5px;
}
.cui .panel--regular {
  padding: 10px;
}
.cui .panel--loose,
.cui .panel--xspace {
  padding: 20px;
}
.cui .panel--toolbar {
  display: flex;
  align-items: center;
}
.cui .panel--bordered {
  border: 1px solid #dfdfdf;
}
.cui .panel--bordered-top {
  border-top: 1px solid #dfdfdf;
}
.cui .panel--bordered-right {
  border-right: 1px solid #dfdfdf;
}
.cui .panel--bordered-bottom {
  border-bottom: 1px solid #dfdfdf;
}
.cui .panel--bordered-left {
  border-left: 1px solid #dfdfdf;
}
.cui .panel--hover:hover {
  background-color: #f5f5f6;
}
.cui .panel--active {
  box-shadow: inset 0 0 0 2px #00bceb;
}
.cui .panel--transparency {
  z-index: 1;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.25);
}
.cui .panel--transparency:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.15);
}
.cui .panel--transparency.panel--blue {
  background-color: rgba(0, 188, 235, 0.75);
}
.cui .panel--transparency.panel--blue:hover {
  background-color: rgba(0, 188, 235, 0.5);
}
.cui .panel--transparency.panel--warning {
  background-color: rgba(251, 171, 24, 0.5);
}
.cui .panel--transparency.panel--warning:hover {
  background-color: rgba(251, 171, 24, 0.75);
}
.cui .panel--well {
  box-shadow: inset 0 5px 10px -5px rgba(0, 0, 0, 0.25);
}
.cui .panel--fluid {
  height: 100%;
}
.cui a.panel {
  cursor: pointer;
}
.cui a.panel--blue:hover,
.cui a.panel--blue:focus,
.cui a.panel--blue:active {
  background-color: #1fd2ff;
  color: #005073;
}
.cui a.panel--vibblue:hover,
.cui a.panel--vibblue:focus,
.cui a.panel--vibblue:active {
  background-color: #01a0e0;
  color: #ffffff;
}
.cui a.panel--ltgray:hover,
.cui a.panel--ltgray:focus,
.cui a.panel--ltgray:active {
  background-color: #d9d9d9;
}
.cui a.panel--mdgray:hover,
.cui a.panel--mdgray:focus,
.cui a.panel--mdgray:active {
  background-color: #f9f9f9;
}
.cui a.panel--dkgray:hover,
.cui a.panel--dkgray:focus,
.cui a.panel--dkgray:active {
  background-color: #717175;
  color: #ffffff;
}
.cui a.panel--indigo:hover,
.cui a.panel--indigo:focus,
.cui a.panel--indigo:active {
  background-color: #0073a6;
  color: #ffffff;
}
.cui a.panel--gray-ghost:hover,
.cui a.panel--gray-ghost:focus,
.cui a.panel--gray-ghost:active {
  background-color: rgba(0, 0, 0, 0);
}
.cui a.panel--info:hover,
.cui a.panel--info:focus,
.cui a.panel--info:active {
  background-color: #39a8db;
  color: #ffffff;
}
.cui a.panel--success:hover,
.cui a.panel--success:focus,
.cui a.panel--success:active {
  background-color: #8ccb70;
  color: #005073;
}
.cui a.panel--warning:hover,
.cui a.panel--warning:focus,
.cui a.panel--warning:active {
  background-color: #fcbd4a;
  color: #ffffff;
}
.cui a.panel--danger:hover,
.cui a.panel--danger:focus,
.cui a.panel--danger:active {
  background-color: #ea4d45;
  color: #ffffff;
}
.cui .panels .panel + .panel {
  margin: 1px 0;
}
.cui .panels--striping > .panel:nth-child(odd) {
  background: #ffffff;
}
.cui .panels--striping > .panel:nth-child(even) {
  background: #f9f9f9;
}
.cui .panels--compressed .panel + .panel {
  margin: 10px 0;
}
.cui .panels--regular .panel + .panel {
  margin: 20px 0;
}
.cui .panels--loose .panel + .panel {
  margin: 40px 0;
}
.cui .radio {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
}
.cui .radio.disabled,
.cui .radio[disabled] {
  opacity: 1;
}
.cui .radio.disabled input:checked ~ .radio__input:before,
.cui .radio[disabled] input:checked ~ .radio__input:before {
  background-color: #f2f2f2;
  box-shadow: inset 0 0 0 1px #c6c7ca;
}
.cui .radio.disabled input:checked ~ .radio__input:after,
.cui .radio[disabled] input:checked ~ .radio__input:after {
  background-color: #c6c7ca;
}
.cui .radio.disabled input:checked ~ .radio__label,
.cui .radio[disabled] input:checked ~ .radio__label {
  color: #58585b;
}
.cui .radio.disabled .radio__input:before,
.cui .radio[disabled] .radio__input:before {
  background-color: #f2f2f2;
  box-shadow: inset 0 0 0 1px #c6c7ca;
}
.cui .radio.disabled .radio__input:after,
.cui .radio[disabled] .radio__input:after {
  background-color: #c6c7ca;
}
.cui .radio.disabled .radio__label,
.cui .radio[disabled] .radio__label {
  color: #58585b;
}
.cui .radio input {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0;
}
.cui .radio input.hover ~ .radio__input:before,
.cui .radio input:hover ~ .radio__input:before {
  box-shadow: inset 0 0 0 2px #00bceb;
}
.cui .radio input.checked ~ .radio__label,
.cui .radio input:checked ~ .radio__label {
  color: #58585b;
}
.cui .radio input.checked.hover ~ .radio__input:before,
.cui .radio input.checked:hover ~ .radio__input:before,
.cui .radio input:checked.hover ~ .radio__input:before,
.cui .radio input:checked:hover ~ .radio__input:before {
  background-color: #00bceb;
}
.cui .radio input.checked.hover ~ .radio__input:after,
.cui .radio input.checked:hover ~ .radio__input:after,
.cui .radio input:checked.hover ~ .radio__input:after,
.cui .radio input:checked:hover ~ .radio__input:after {
  color: #ffffff;
  background-color: #9e9ea2;
  border: 6px solid #00bceb;
}
.cui .radio input.checked ~ .radio__input:before,
.cui .radio input:checked ~ .radio__input:before {
  box-shadow: inset 0 0 0 1px #00bceb;
  background-color: #00bceb;
}
.cui .radio input.checked ~ .radio__input:after,
.cui .radio input:checked ~ .radio__input:after {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cui .radio input.focus ~ .radio__input:before,
.cui .radio input:focus ~ .radio__input:before {
  outline-color: #00bceb;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
  box-shadow: inset 0 0 0 1px #017cad;
}
.cui .radio input.active ~ .radio__input:before,
.cui .radio input:active ~ .radio__input:before {
  box-shadow: none;
  background-color: #00bceb;
}
.cui .radio .radio__input {
  position: relative;
  display: inline-flex;
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.cui .radio .radio__input:before,
.cui .radio .radio__input:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '';
}
.cui .radio .radio__input:before {
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui .radio .radio__input:after {
  display: none;
  box-shadow: inset 0 0 0 4px #ffffff;
  border-radius: 50%;
  background-color: #00bceb;
  border: 2px solid transparent;
}
.cui .radio .radio__label {
  color: #58585b;
  margin-left: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cui .radio:last-of-type {
  margin-right: 0;
}
.cui .radio--nowrap .radio__label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cui .panel.panel--dkgray .radio.disabled,
.cui .panel.panel--dkgray .radio[disabled],
.cui .panel.panel--indigo .radio.disabled,
.cui .panel.panel--indigo .radio[disabled] {
  opacity: 1;
}
.cui .panel.panel--dkgray .radio.disabled input:checked ~ .radio__input:before,
.cui .panel.panel--dkgray .radio[disabled] input:checked ~ .radio__input:before,
.cui .panel.panel--indigo .radio.disabled input:checked ~ .radio__input:before,
.cui
  .panel.panel--indigo
  .radio[disabled]
  input:checked
  ~ .radio__input:before {
  background-color: #f2f2f2;
  box-shadow: inset 0 0 0 1px #c6c7ca;
}
.cui .panel.panel--dkgray .radio.disabled input:checked ~ .radio__input:after,
.cui .panel.panel--dkgray .radio[disabled] input:checked ~ .radio__input:after,
.cui .panel.panel--indigo .radio.disabled input:checked ~ .radio__input:after,
.cui .panel.panel--indigo .radio[disabled] input:checked ~ .radio__input:after {
  background-color: #c6c7ca;
}
.cui .panel.panel--dkgray .radio.disabled input:checked ~ .radio__label,
.cui .panel.panel--dkgray .radio[disabled] input:checked ~ .radio__label,
.cui .panel.panel--indigo .radio.disabled input:checked ~ .radio__label,
.cui .panel.panel--indigo .radio[disabled] input:checked ~ .radio__label {
  color: #ffffff;
}
.cui .panel.panel--dkgray .radio.disabled .radio__input:before,
.cui .panel.panel--dkgray .radio[disabled] .radio__input:before,
.cui .panel.panel--indigo .radio.disabled .radio__input:before,
.cui .panel.panel--indigo .radio[disabled] .radio__input:before {
  background-color: #f2f2f2;
  box-shadow: inset 0 0 0 1px #c6c7ca;
}
.cui .panel.panel--dkgray .radio.disabled .radio__input:after,
.cui .panel.panel--dkgray .radio[disabled] .radio__input:after,
.cui .panel.panel--indigo .radio.disabled .radio__input:after,
.cui .panel.panel--indigo .radio[disabled] .radio__input:after {
  background-color: #c6c7ca;
}
.cui .panel.panel--dkgray .radio.disabled .radio__label,
.cui .panel.panel--dkgray .radio[disabled] .radio__label,
.cui .panel.panel--indigo .radio.disabled .radio__label,
.cui .panel.panel--indigo .radio[disabled] .radio__label {
  color: #ffffff;
}
.cui .panel.panel--dkgray .radio input,
.cui .panel.panel--indigo .radio input {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0;
}
.cui .panel.panel--dkgray .radio input.hover ~ .radio__input:before,
.cui .panel.panel--dkgray .radio input:hover ~ .radio__input:before,
.cui .panel.panel--indigo .radio input.hover ~ .radio__input:before,
.cui .panel.panel--indigo .radio input:hover ~ .radio__input:before {
  box-shadow: inset 0 0 0 2px #00bceb;
}
.cui .panel.panel--dkgray .radio input.checked ~ .radio__label,
.cui .panel.panel--dkgray .radio input:checked ~ .radio__label,
.cui .panel.panel--indigo .radio input.checked ~ .radio__label,
.cui .panel.panel--indigo .radio input:checked ~ .radio__label {
  color: #ffffff;
}
.cui .panel.panel--dkgray .radio input.checked.hover ~ .radio__input:before,
.cui .panel.panel--dkgray .radio input.checked:hover ~ .radio__input:before,
.cui .panel.panel--dkgray .radio input:checked.hover ~ .radio__input:before,
.cui .panel.panel--dkgray .radio input:checked:hover ~ .radio__input:before,
.cui .panel.panel--indigo .radio input.checked.hover ~ .radio__input:before,
.cui .panel.panel--indigo .radio input.checked:hover ~ .radio__input:before,
.cui .panel.panel--indigo .radio input:checked.hover ~ .radio__input:before,
.cui .panel.panel--indigo .radio input:checked:hover ~ .radio__input:before {
  background-color: #00bceb;
}
.cui .panel.panel--dkgray .radio input.checked.hover ~ .radio__input:after,
.cui .panel.panel--dkgray .radio input.checked:hover ~ .radio__input:after,
.cui .panel.panel--dkgray .radio input:checked.hover ~ .radio__input:after,
.cui .panel.panel--dkgray .radio input:checked:hover ~ .radio__input:after,
.cui .panel.panel--indigo .radio input.checked.hover ~ .radio__input:after,
.cui .panel.panel--indigo .radio input.checked:hover ~ .radio__input:after,
.cui .panel.panel--indigo .radio input:checked.hover ~ .radio__input:after,
.cui .panel.panel--indigo .radio input:checked:hover ~ .radio__input:after {
  color: #ffffff;
  background-color: #9e9ea2;
  border: 6px solid #00bceb;
}
.cui .panel.panel--dkgray .radio input.checked ~ .radio__input:before,
.cui .panel.panel--dkgray .radio input:checked ~ .radio__input:before,
.cui .panel.panel--indigo .radio input.checked ~ .radio__input:before,
.cui .panel.panel--indigo .radio input:checked ~ .radio__input:before {
  box-shadow: inset 0 0 0 1px #00bceb;
  background-color: #00bceb;
}
.cui .panel.panel--dkgray .radio input.checked ~ .radio__input:after,
.cui .panel.panel--dkgray .radio input:checked ~ .radio__input:after,
.cui .panel.panel--indigo .radio input.checked ~ .radio__input:after,
.cui .panel.panel--indigo .radio input:checked ~ .radio__input:after {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cui .panel.panel--dkgray .radio input.focus ~ .radio__input:before,
.cui .panel.panel--dkgray .radio input:focus ~ .radio__input:before,
.cui .panel.panel--indigo .radio input.focus ~ .radio__input:before,
.cui .panel.panel--indigo .radio input:focus ~ .radio__input:before {
  outline-color: #ffffff;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
  box-shadow: inset 0 0 0 1px #017cad;
}
.cui .panel.panel--dkgray .radio input.active ~ .radio__input:before,
.cui .panel.panel--dkgray .radio input:active ~ .radio__input:before,
.cui .panel.panel--indigo .radio input.active ~ .radio__input:before,
.cui .panel.panel--indigo .radio input:active ~ .radio__input:before {
  box-shadow: none;
  background-color: #00bceb;
}
.cui .panel.panel--dkgray .radio .radio__input,
.cui .panel.panel--indigo .radio .radio__input {
  position: relative;
  display: inline-flex;
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.cui .panel.panel--dkgray .radio .radio__input:before,
.cui .panel.panel--dkgray .radio .radio__input:after,
.cui .panel.panel--indigo .radio .radio__input:before,
.cui .panel.panel--indigo .radio .radio__input:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '';
}
.cui .panel.panel--dkgray .radio .radio__input:before,
.cui .panel.panel--indigo .radio .radio__input:before {
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui .panel.panel--dkgray .radio .radio__input:after,
.cui .panel.panel--indigo .radio .radio__input:after {
  display: none;
  box-shadow: inset 0 0 0 4px #ffffff;
  border-radius: 50%;
  background-color: #00bceb;
  border: 2px solid transparent;
}
.cui .panel.panel--dkgray .radio .radio__label,
.cui .panel.panel--indigo .radio .radio__label {
  color: #ffffff;
  margin-left: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cui .radio.radio--dark.disabled,
.cui .radio.radio--dark[disabled] {
  opacity: 1;
}
.cui .radio.radio--dark.disabled input:checked ~ .radio__input:before,
.cui .radio.radio--dark[disabled] input:checked ~ .radio__input:before {
  background-color: #f2f2f2;
  box-shadow: inset 0 0 0 1px #c6c7ca;
}
.cui .radio.radio--dark.disabled input:checked ~ .radio__input:after,
.cui .radio.radio--dark[disabled] input:checked ~ .radio__input:after {
  background-color: #c6c7ca;
}
.cui .radio.radio--dark.disabled input:checked ~ .radio__label,
.cui .radio.radio--dark[disabled] input:checked ~ .radio__label {
  color: #ffffff;
}
.cui .radio.radio--dark.disabled .radio__input:before,
.cui .radio.radio--dark[disabled] .radio__input:before {
  background-color: #f2f2f2;
  box-shadow: inset 0 0 0 1px #c6c7ca;
}
.cui .radio.radio--dark.disabled .radio__input:after,
.cui .radio.radio--dark[disabled] .radio__input:after {
  background-color: #c6c7ca;
}
.cui .radio.radio--dark.disabled .radio__label,
.cui .radio.radio--dark[disabled] .radio__label {
  color: #ffffff;
}
.cui .radio.radio--dark input {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0;
}
.cui .radio.radio--dark input.hover ~ .radio__input:before,
.cui .radio.radio--dark input:hover ~ .radio__input:before {
  box-shadow: inset 0 0 0 2px #00bceb;
}
.cui .radio.radio--dark input.checked ~ .radio__label,
.cui .radio.radio--dark input:checked ~ .radio__label {
  color: #ffffff;
}
.cui .radio.radio--dark input.checked.hover ~ .radio__input:before,
.cui .radio.radio--dark input.checked:hover ~ .radio__input:before,
.cui .radio.radio--dark input:checked.hover ~ .radio__input:before,
.cui .radio.radio--dark input:checked:hover ~ .radio__input:before {
  background-color: #00bceb;
}
.cui .radio.radio--dark input.checked.hover ~ .radio__input:after,
.cui .radio.radio--dark input.checked:hover ~ .radio__input:after,
.cui .radio.radio--dark input:checked.hover ~ .radio__input:after,
.cui .radio.radio--dark input:checked:hover ~ .radio__input:after {
  color: #ffffff;
  background-color: #9e9ea2;
  border: 6px solid #00bceb;
}
.cui .radio.radio--dark input.checked ~ .radio__input:before,
.cui .radio.radio--dark input:checked ~ .radio__input:before {
  box-shadow: inset 0 0 0 1px #00bceb;
  background-color: #00bceb;
}
.cui .radio.radio--dark input.checked ~ .radio__input:after,
.cui .radio.radio--dark input:checked ~ .radio__input:after {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cui .radio.radio--dark input.focus ~ .radio__input:before,
.cui .radio.radio--dark input:focus ~ .radio__input:before {
  outline-color: #ffffff;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
  box-shadow: inset 0 0 0 1px #017cad;
}
.cui .radio.radio--dark input.active ~ .radio__input:before,
.cui .radio.radio--dark input:active ~ .radio__input:before {
  box-shadow: none;
  background-color: #00bceb;
}
.cui .radio.radio--dark .radio__input {
  position: relative;
  display: inline-flex;
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.cui .radio.radio--dark .radio__input:before,
.cui .radio.radio--dark .radio__input:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '';
}
.cui .radio.radio--dark .radio__input:before {
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui .radio.radio--dark .radio__input:after {
  display: none;
  box-shadow: inset 0 0 0 4px #ffffff;
  border-radius: 50%;
  background-color: #00bceb;
  border: 2px solid transparent;
}
.cui .radio.radio--dark .radio__label {
  color: #ffffff;
  margin-left: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cui .panel.panel--success .radio.disabled,
.cui .panel.panel--success .radio[disabled],
.cui .panel.panel--blue .radio.disabled,
.cui .panel.panel--blue .radio[disabled] {
  opacity: 1;
}
.cui .panel.panel--success .radio.disabled input:checked ~ .radio__input:before,
.cui
  .panel.panel--success
  .radio[disabled]
  input:checked
  ~ .radio__input:before,
.cui .panel.panel--blue .radio.disabled input:checked ~ .radio__input:before,
.cui .panel.panel--blue .radio[disabled] input:checked ~ .radio__input:before {
  background-color: #d7d7d8;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui .panel.panel--success .radio.disabled input:checked ~ .radio__input:after,
.cui .panel.panel--success .radio[disabled] input:checked ~ .radio__input:after,
.cui .panel.panel--blue .radio.disabled input:checked ~ .radio__input:after,
.cui .panel.panel--blue .radio[disabled] input:checked ~ .radio__input:after {
  background-color: #d7d7d8;
}
.cui .panel.panel--success .radio.disabled input:checked ~ .radio__label,
.cui .panel.panel--success .radio[disabled] input:checked ~ .radio__label,
.cui .panel.panel--blue .radio.disabled input:checked ~ .radio__label,
.cui .panel.panel--blue .radio[disabled] input:checked ~ .radio__label {
  color: #39393b;
}
.cui .panel.panel--success .radio.disabled .radio__input:before,
.cui .panel.panel--success .radio[disabled] .radio__input:before,
.cui .panel.panel--blue .radio.disabled .radio__input:before,
.cui .panel.panel--blue .radio[disabled] .radio__input:before {
  background-color: #d7d7d8;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui .panel.panel--success .radio.disabled .radio__input:after,
.cui .panel.panel--success .radio[disabled] .radio__input:after,
.cui .panel.panel--blue .radio.disabled .radio__input:after,
.cui .panel.panel--blue .radio[disabled] .radio__input:after {
  background-color: #d7d7d8;
}
.cui .panel.panel--success .radio.disabled .radio__label,
.cui .panel.panel--success .radio[disabled] .radio__label,
.cui .panel.panel--blue .radio.disabled .radio__label,
.cui .panel.panel--blue .radio[disabled] .radio__label {
  color: #39393b;
}
.cui .panel.panel--success .radio input,
.cui .panel.panel--blue .radio input {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0;
}
.cui .panel.panel--success .radio input.hover ~ .radio__input:before,
.cui .panel.panel--success .radio input:hover ~ .radio__input:before,
.cui .panel.panel--blue .radio input.hover ~ .radio__input:before,
.cui .panel.panel--blue .radio input:hover ~ .radio__input:before {
  box-shadow: inset 0 0 0 2px #017cad;
}
.cui .panel.panel--success .radio input.checked ~ .radio__label,
.cui .panel.panel--success .radio input:checked ~ .radio__label,
.cui .panel.panel--blue .radio input.checked ~ .radio__label,
.cui .panel.panel--blue .radio input:checked ~ .radio__label {
  color: #39393b;
}
.cui .panel.panel--success .radio input.checked.hover ~ .radio__input:before,
.cui .panel.panel--success .radio input.checked:hover ~ .radio__input:before,
.cui .panel.panel--success .radio input:checked.hover ~ .radio__input:before,
.cui .panel.panel--success .radio input:checked:hover ~ .radio__input:before,
.cui .panel.panel--blue .radio input.checked.hover ~ .radio__input:before,
.cui .panel.panel--blue .radio input.checked:hover ~ .radio__input:before,
.cui .panel.panel--blue .radio input:checked.hover ~ .radio__input:before,
.cui .panel.panel--blue .radio input:checked:hover ~ .radio__input:before {
  background-color: #005073;
}
.cui .panel.panel--success .radio input.checked.hover ~ .radio__input:after,
.cui .panel.panel--success .radio input.checked:hover ~ .radio__input:after,
.cui .panel.panel--success .radio input:checked.hover ~ .radio__input:after,
.cui .panel.panel--success .radio input:checked:hover ~ .radio__input:after,
.cui .panel.panel--blue .radio input.checked.hover ~ .radio__input:after,
.cui .panel.panel--blue .radio input.checked:hover ~ .radio__input:after,
.cui .panel.panel--blue .radio input:checked.hover ~ .radio__input:after,
.cui .panel.panel--blue .radio input:checked:hover ~ .radio__input:after {
  color: #ffffff;
  background-color: #9e9ea2;
  border: 6px solid #005073;
}
.cui .panel.panel--success .radio input.checked ~ .radio__input:before,
.cui .panel.panel--success .radio input:checked ~ .radio__input:before,
.cui .panel.panel--blue .radio input.checked ~ .radio__input:before,
.cui .panel.panel--blue .radio input:checked ~ .radio__input:before {
  box-shadow: inset 0 0 0 1px #005073;
  background-color: #005073;
}
.cui .panel.panel--success .radio input.checked ~ .radio__input:after,
.cui .panel.panel--success .radio input:checked ~ .radio__input:after,
.cui .panel.panel--blue .radio input.checked ~ .radio__input:after,
.cui .panel.panel--blue .radio input:checked ~ .radio__input:after {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cui .panel.panel--success .radio input.focus ~ .radio__input:before,
.cui .panel.panel--success .radio input:focus ~ .radio__input:before,
.cui .panel.panel--blue .radio input.focus ~ .radio__input:before,
.cui .panel.panel--blue .radio input:focus ~ .radio__input:before {
  outline-color: #ffffff;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
  box-shadow: inset 0 0 0 1px #017cad;
}
.cui .panel.panel--success .radio input.active ~ .radio__input:before,
.cui .panel.panel--success .radio input:active ~ .radio__input:before,
.cui .panel.panel--blue .radio input.active ~ .radio__input:before,
.cui .panel.panel--blue .radio input:active ~ .radio__input:before {
  box-shadow: none;
  background-color: #005073;
}
.cui .panel.panel--success .radio .radio__input,
.cui .panel.panel--blue .radio .radio__input {
  position: relative;
  display: inline-flex;
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.cui .panel.panel--success .radio .radio__input:before,
.cui .panel.panel--success .radio .radio__input:after,
.cui .panel.panel--blue .radio .radio__input:before,
.cui .panel.panel--blue .radio .radio__input:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '';
}
.cui .panel.panel--success .radio .radio__input:before,
.cui .panel.panel--blue .radio .radio__input:before {
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui .panel.panel--success .radio .radio__input:after,
.cui .panel.panel--blue .radio .radio__input:after {
  display: none;
  box-shadow: inset 0 0 0 4px #ffffff;
  border-radius: 50%;
  background-color: #005073;
  border: 2px solid transparent;
}
.cui .panel.panel--success .radio .radio__label,
.cui .panel.panel--blue .radio .radio__label {
  color: #39393b;
  margin-left: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cui .radio.radio--color.disabled,
.cui .radio.radio--color[disabled] {
  opacity: 1;
}
.cui .radio.radio--color.disabled input:checked ~ .radio__input:before,
.cui .radio.radio--color[disabled] input:checked ~ .radio__input:before {
  background-color: #d7d7d8;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui .radio.radio--color.disabled input:checked ~ .radio__input:after,
.cui .radio.radio--color[disabled] input:checked ~ .radio__input:after {
  background-color: #d7d7d8;
}
.cui .radio.radio--color.disabled input:checked ~ .radio__label,
.cui .radio.radio--color[disabled] input:checked ~ .radio__label {
  color: #39393b;
}
.cui .radio.radio--color.disabled .radio__input:before,
.cui .radio.radio--color[disabled] .radio__input:before {
  background-color: #d7d7d8;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui .radio.radio--color.disabled .radio__input:after,
.cui .radio.radio--color[disabled] .radio__input:after {
  background-color: #d7d7d8;
}
.cui .radio.radio--color.disabled .radio__label,
.cui .radio.radio--color[disabled] .radio__label {
  color: #39393b;
}
.cui .radio.radio--color input {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0;
}
.cui .radio.radio--color input.hover ~ .radio__input:before,
.cui .radio.radio--color input:hover ~ .radio__input:before {
  box-shadow: inset 0 0 0 2px #017cad;
}
.cui .radio.radio--color input.checked ~ .radio__label,
.cui .radio.radio--color input:checked ~ .radio__label {
  color: #39393b;
}
.cui .radio.radio--color input.checked.hover ~ .radio__input:before,
.cui .radio.radio--color input.checked:hover ~ .radio__input:before,
.cui .radio.radio--color input:checked.hover ~ .radio__input:before,
.cui .radio.radio--color input:checked:hover ~ .radio__input:before {
  background-color: #005073;
}
.cui .radio.radio--color input.checked.hover ~ .radio__input:after,
.cui .radio.radio--color input.checked:hover ~ .radio__input:after,
.cui .radio.radio--color input:checked.hover ~ .radio__input:after,
.cui .radio.radio--color input:checked:hover ~ .radio__input:after {
  color: #ffffff;
  background-color: #9e9ea2;
  border: 6px solid #005073;
}
.cui .radio.radio--color input.checked ~ .radio__input:before,
.cui .radio.radio--color input:checked ~ .radio__input:before {
  box-shadow: inset 0 0 0 1px #005073;
  background-color: #005073;
}
.cui .radio.radio--color input.checked ~ .radio__input:after,
.cui .radio.radio--color input:checked ~ .radio__input:after {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cui .radio.radio--color input.focus ~ .radio__input:before,
.cui .radio.radio--color input:focus ~ .radio__input:before {
  outline-color: #ffffff;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
  box-shadow: inset 0 0 0 1px #017cad;
}
.cui .radio.radio--color input.active ~ .radio__input:before,
.cui .radio.radio--color input:active ~ .radio__input:before {
  box-shadow: none;
  background-color: #005073;
}
.cui .radio.radio--color .radio__input {
  position: relative;
  display: inline-flex;
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.cui .radio.radio--color .radio__input:before,
.cui .radio.radio--color .radio__input:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '';
}
.cui .radio.radio--color .radio__input:before {
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #9e9ea2;
}
.cui .radio.radio--color .radio__input:after {
  display: none;
  box-shadow: inset 0 0 0 4px #ffffff;
  border-radius: 50%;
  background-color: #005073;
  border: 2px solid transparent;
}
.cui .radio.radio--color .radio__label {
  color: #39393b;
  margin-left: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cui.cui--animated .radio input ~ .radio__input:before {
  transition: all 0.1s ease-out;
}
.cui.cui--animated .radio input:hover ~ .radio__input:before {
  transition: all 0.1s ease-in;
}
.cui h1,
.cui h2,
.cui h3,
.cui h4,
.cui h5,
.cui h6,
.cui .h1,
.cui .h2,
.cui .h3,
.cui .h4,
.cui .h5,
.cui .h6 {
  margin: 0;
  font-family: inherit;
  line-height: 1.2;
}
.cui h1 small,
.cui h2 small,
.cui h3 small,
.cui h4 small,
.cui h5 small,
.cui h6 small,
.cui .h1 small,
.cui .h2 small,
.cui .h3 small,
.cui .h4 small,
.cui .h5 small,
.cui .h6 small {
  color: #9e9ea2;
}
.cui h1,
.cui .h1 {
  font-size: 4rem;
  font-weight: 100;
}
.cui h2,
.cui .h2 {
  font-size: 3.2rem;
  font-weight: 200;
}
.cui h3,
.cui .h3 {
  font-size: 2.8rem;
  font-weight: 200;
}
.cui h4,
.cui .h4 {
  font-size: 2.4rem;
  font-weight: 300;
}
.cui h5,
.cui .h5 {
  font-size: 2rem;
  font-weight: 300;
}
.cui h6,
.cui .h6 {
  font-size: 1.6rem;
  font-weight: 400;
}
.cui .display-1 {
  font-size: 6rem;
}
.cui .display-2 {
  font-size: 5.5rem;
}
.cui .display-3 {
  font-size: 4.5rem;
}
.cui .display-4 {
  font-size: 3.5rem;
}
.cui .subheading {
  color: #9e9ea2;
}
.cui .subheader {
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: bold;
}
@media (max-width: 575.98px) {
  .cui .subheader {
    font-size: 1rem !important;
  }
}
.cui .subtitle {
  font-size: 1.6rem;
  font-weight: 300;
}
.cui p {
  margin: 10px 0;
}
.cui ul,
.cui ol {
  margin: 0;
  padding: 0 0 0 20px;
}
.cui ul.list--unstyled,
.cui ol.list--unstyled {
  list-style: none;
}
.cui ul.list--unstyled ul:not(:first-child),
.cui ol.list--unstyled ul:not(:first-child) {
  padding-left: 20px;
}
.cui ul.list--unstyled ul,
.cui ul.list--unstyled ol,
.cui ol.list--unstyled ul,
.cui ol.list--unstyled ol {
  list-style: none;
}
.cui ul.list--unstyled ul ul:not(:first-child),
.cui ul.list--unstyled ol ul:not(:first-child),
.cui ol.list--unstyled ul ul:not(:first-child),
.cui ol.list--unstyled ol ul:not(:first-child) {
  padding-left: 20px;
}
.cui hr {
  border-bottom: 1px solid #dfdfdf;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: 10px 0;
}
.cui hr.hr--qtr,
.cui hr.divider-qtr {
  width: 25%;
  margin: auto;
}
.cui hr.divider--skinny {
  margin: auto auto 25px;
  padding-top: 25px;
}
.cui img,
.cui picture {
  margin: 0;
  border: 0;
  max-width: 100%;
}
.cui img.img--fluid {
  display: block;
  width: 100%;
  height: auto;
}
.cui * {
  box-sizing: inherit;
}
.cui *:before,
.cui *:after {
  box-sizing: inherit;
}
.cui .content-body {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background: #f5f5f6;
}
.cui .content-body--alt,
.cui .content-background--alt {
  background: #ffffff;
}
.cui .content {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #f5f5f6;
}
.cui .content.content--alt {
  background: #ffffff;
}
.cui .content__main {
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  .cui .content__main {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (min-width: 992px) {
  .cui .content__main {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.cui .content__main--alt {
  background: #ffffff;
}
.cui .content-wrapper {
  display: flex;
  flex-direction: column;
  background: #f5f5f6;
  height: 100%;
}
.cui .content-wrapper .content {
  display: flex;
  flex-direction: column;
}
.cui .content-wrapper .footer {
  flex: none;
  background-color: #005073;
}
.cui .container,
.cui .container-fluid {
  padding-right: 20px;
  padding-left: 20px;
}
.cui pre {
  margin: 0;
}
.cui input,
.cui select,
.cui textarea,
.cui button {
  font-size: 1.4rem;
  font-family: 'CiscoSans', 'Arial', sans-serif;
  font-weight: inherit;
}
.cui iframe {
  width: 100%;
  height: 100%;
  border: 1px solid #dfdfdf;
  margin: 10px 0;
  display: inline-block;
}
.cui .content-fluid,
.cui .sidebar-wrapper {
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}
.cui .content-fluid .sidebar ~ main,
.cui .sidebar-wrapper .sidebar ~ main {
  -webkit-transform: translate3d(280px, 0, 0);
  transform: translate3d(280px, 0, 0);
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}
@media (min-width: 768px) {
  .cui .content-fluid .sidebar ~ main,
  .cui .sidebar-wrapper .sidebar ~ main {
    margin-left: 280px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: margin 0.25s;
  }
}
.cui .content-fluid .sidebar.sidebar--hidden ~ main,
.cui .sidebar-wrapper .sidebar.sidebar--hidden ~ main {
  margin-left: auto;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: all 0.25s;
}
.cui .content-fluid main,
.cui .sidebar-wrapper main {
  flex: 1 100%;
  flex-direction: column;
  overflow: auto;
  position: relative;
  max-width: 2560px;
}
.cui .content-fluid main .footer,
.cui .sidebar-wrapper main .footer {
  flex: none;
  overflow: hidden;
  background-color: #005073;
}
.cui.cui--wide .container {
  max-width: 1600px;
}
.cui.cui--headermargins h1,
.cui.cui--headermargins h2,
.cui.cui--headermargins h3,
.cui.cui--headermargins h4,
.cui.cui--headermargins h5,
.cui.cui--headermargins h6,
.cui.cui--headermargins .h1,
.cui.cui--headermargins .h2,
.cui.cui--headermargins .h3,
.cui.cui--headermargins .h4,
.cui.cui--headermargins .h5,
.cui.cui--headermargins .h6 {
  margin: 0 0 10px 0;
}
.cui .sections .section + .section {
  margin: 20px 0;
}
.cui .sections--striping > .section:nth-child(odd) {
  background: #ffffff;
}
.cui .sections--striping > .section:nth-child(even) {
  background: #f9f9f9;
}
.cui .sections--compressed .section + .section {
  margin: 10px 0;
}
.cui .sections--regular .section + .section {
  margin: 20px 0;
}
.cui .sections--loose .section + .section {
  margin: 40px 0;
}
.cui .section-header {
  margin: 10px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #dfdfdf;
}
.cui .section {
  padding-left: 0;
  padding-right: 0;
  padding: 20px 0;
}
.cui .section:after {
  clear: both !important;
  content: '' !important;
  display: table !important;
}
.cui .section__title,
.cui .section__title--inline {
  vertical-align: middle;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.cui .section__title {
  margin-bottom: 20px;
}
.cui .section__title--inline {
  display: inline-block;
}
.cui .section__title--divider {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 10px;
}
.cui .section__intro {
  margin-bottom: 40px;
  font-size: 1.4rem;
  font-weight: 200;
}
@media (min-width: 768px) {
  .cui .section__intro {
    font-size: 2.1rem;
  }
}
.cui .section--skinny,
.cui .section--compressed {
  padding: 10px 0;
}
.cui .section--regular {
  padding: 20px 0;
}
.cui .section--loose {
  padding: 40px 0;
}
.cui .section.section--marquee,
.cui .section.section--marquee-both {
  margin: 20px 0;
  border-top: 20px solid #dfdfdf;
}
.cui .section.section--marquee-both {
  border-bottom: 5px solid #dfdfdf;
}
.cui .section + .section {
  padding-top: 0;
}
.cui .form-group .form-group__text.select input {
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cui .form-group .form-group__text.select:after {
  content: '';
  -webkit-transform: rotate(0);
  transform: rotate(0);
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #9e9ea2;
  text-align: center;
  pointer-events: none;
}
.cui .form-group.active .form-group__text.select {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.cui .form-group.active .form-group__text.select:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  position: absolute;
}
.cui .form-group.input--compressed .form-group__text.select:after {
  top: 28px;
}
.cui .form-group .form-group__text.select ~ .help-block {
  position: absolute;
  top: 100%;
  right: 0;
}
.cui.cui--animated .form-group .form-group__text.select:after {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.cui .switch,
.cui .switch-icon {
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
}
.cui .switch {
  height: 24px;
}
.cui .switch.disabled,
.cui .switch[disabled] {
  opacity: 1;
}
.cui .switch.disabled input:checked ~ .switch__input,
.cui .switch[disabled] input:checked ~ .switch__input {
  background: #d7d7d8;
}
.cui .switch.disabled input:checked ~ .switch__input:after,
.cui .switch[disabled] input:checked ~ .switch__input:after {
  background: #f2f2f2;
  border: 1px solid #d7d7d8;
  box-shadow: none;
}
.cui .switch.disabled input ~ .switch__input,
.cui .switch[disabled] input ~ .switch__input {
  background: #d7d7d8;
}
.cui .switch.disabled input ~ .switch__input:after,
.cui .switch[disabled] input ~ .switch__input:after {
  background: #f2f2f2;
  border: 1px solid #d7d7d8;
  box-shadow: none;
}
.cui .switch.disabled .switch__label,
.cui .switch[disabled] .switch__label {
  color: #58585b;
}
.cui .switch.hover input:checked ~ .switch__input:after,
.cui .switch:hover input:checked ~ .switch__input:after {
  border-width: 2px;
}
.cui .switch input.focus ~ .switch__input,
.cui .switch input:focus ~ .switch__input {
  outline-color: #00bceb;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
}
.cui .switch__label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #58585b;
}
.cui .switch .switch__input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dfdfdf;
  border-radius: 30px;
}
.cui .switch .switch__input:after {
  display: block;
  position: absolute;
  left: 0;
  border-radius: 50%;
  content: '';
  cursor: pointer;
}
.cui .switch .switch__label + .switch__input,
.cui .switch .switch__input + .switch__label {
  margin-left: 5px;
}
.cui .switch input {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
.cui .switch input ~ .switch__input {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
}
.cui .switch input ~ .switch__input:after {
  background: #aeaeaf;
  box-shadow: none;
}
.cui .switch input:checked ~ .switch__input {
  background: #00bceb;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
}
.cui .switch input:checked ~ .switch__input:after {
  background: #ffffff;
  border: 1px solid #00bceb;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}
.cui .switch .switch__input {
  height: 12px;
}
.cui .switch .switch__input:after {
  width: 24px;
  height: 24px;
}
.cui .switch input ~ .switch__input {
  width: 48px;
}
.cui .switch--large {
  height: 30px;
}
.cui .switch--large .switch__input {
  height: 15px;
}
.cui .switch--large .switch__input:after {
  width: 30px;
  height: 30px;
}
.cui .switch--large input ~ .switch__input {
  width: 60px;
}
.cui .switch-icon {
  height: 24px;
}
.cui .switch-icon input {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
.cui .switch-icon input ~ .switch__input span:first-child {
  color: #00bceb;
}
.cui .switch-icon input ~ .switch__input span:last-child {
  color: #9e9ea2;
}
.cui .switch-icon input:checked ~ .switch__input span:first-child {
  color: #9e9ea2;
}
.cui .switch-icon input:checked ~ .switch__input span:last-child {
  color: #00bceb;
}
.cui .switch-icon input.focus ~ .switch__input,
.cui .switch-icon input:focus ~ .switch__input {
  outline-color: #00bceb;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
}
.cui .switch-icon .switch__label {
  display: none;
}
.cui .switch-icon span[class^='icon-']:last-child {
  margin-left: 5px;
}
.cui .switch-icon span[class^='icon-'] {
  font-size: 2rem;
}
.cui .switch-icon--large {
  height: 30px;
}
.cui .switch-icon--large span[class^='icon-'] {
  font-size: 2.8rem;
}
.cui .switch-icon input:focus ~ .switch__input {
  outline: none;
}
.cui .panel.panel--dkgray .switch.disabled,
.cui .panel.panel--dkgray .switch[disabled],
.cui .panel.panel--indigo .switch.disabled,
.cui .panel.panel--indigo .switch[disabled] {
  opacity: 1;
}
.cui .panel.panel--dkgray .switch.disabled input:checked ~ .switch__input,
.cui .panel.panel--dkgray .switch[disabled] input:checked ~ .switch__input,
.cui .panel.panel--indigo .switch.disabled input:checked ~ .switch__input,
.cui .panel.panel--indigo .switch[disabled] input:checked ~ .switch__input {
  background: #d7d7d8;
}
.cui .panel.panel--dkgray .switch.disabled input:checked ~ .switch__input:after,
.cui
  .panel.panel--dkgray
  .switch[disabled]
  input:checked
  ~ .switch__input:after,
.cui .panel.panel--indigo .switch.disabled input:checked ~ .switch__input:after,
.cui
  .panel.panel--indigo
  .switch[disabled]
  input:checked
  ~ .switch__input:after {
  background: #f2f2f2;
  border: 1px solid #9e9ea2;
  box-shadow: none;
}
.cui .panel.panel--dkgray .switch.disabled input ~ .switch__input,
.cui .panel.panel--dkgray .switch[disabled] input ~ .switch__input,
.cui .panel.panel--indigo .switch.disabled input ~ .switch__input,
.cui .panel.panel--indigo .switch[disabled] input ~ .switch__input {
  background: #d7d7d8;
}
.cui .panel.panel--dkgray .switch.disabled input ~ .switch__input:after,
.cui .panel.panel--dkgray .switch[disabled] input ~ .switch__input:after,
.cui .panel.panel--indigo .switch.disabled input ~ .switch__input:after,
.cui .panel.panel--indigo .switch[disabled] input ~ .switch__input:after {
  background: #f2f2f2;
  border: 1px solid #9e9ea2;
  box-shadow: none;
}
.cui .panel.panel--dkgray .switch.disabled .switch__label,
.cui .panel.panel--dkgray .switch[disabled] .switch__label,
.cui .panel.panel--indigo .switch.disabled .switch__label,
.cui .panel.panel--indigo .switch[disabled] .switch__label {
  color: #ffffff;
}
.cui .panel.panel--dkgray .switch.hover input:checked ~ .switch__input:after,
.cui .panel.panel--dkgray .switch:hover input:checked ~ .switch__input:after,
.cui .panel.panel--indigo .switch.hover input:checked ~ .switch__input:after,
.cui .panel.panel--indigo .switch:hover input:checked ~ .switch__input:after {
  border-width: 2px;
}
.cui .panel.panel--dkgray .switch input.focus ~ .switch__input,
.cui .panel.panel--dkgray .switch input:focus ~ .switch__input,
.cui .panel.panel--indigo .switch input.focus ~ .switch__input,
.cui .panel.panel--indigo .switch input:focus ~ .switch__input {
  outline-color: #ffffff;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
}
.cui .panel.panel--dkgray .switch__label,
.cui .panel.panel--indigo .switch__label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
}
.cui .panel.panel--dkgray .switch .switch__input,
.cui .panel.panel--indigo .switch .switch__input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dfdfdf;
  border-radius: 30px;
}
.cui .panel.panel--dkgray .switch .switch__input:after,
.cui .panel.panel--indigo .switch .switch__input:after {
  display: block;
  position: absolute;
  left: 0;
  border-radius: 50%;
  content: '';
  cursor: pointer;
}
.cui .panel.panel--dkgray .switch .switch__label + .switch__input,
.cui .panel.panel--dkgray .switch .switch__input + .switch__label,
.cui .panel.panel--indigo .switch .switch__label + .switch__input,
.cui .panel.panel--indigo .switch .switch__input + .switch__label {
  margin-left: 5px;
}
.cui .panel.panel--dkgray .switch input,
.cui .panel.panel--indigo .switch input {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
.cui .panel.panel--dkgray .switch input ~ .switch__input,
.cui .panel.panel--indigo .switch input ~ .switch__input {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
}
.cui .panel.panel--dkgray .switch input ~ .switch__input:after,
.cui .panel.panel--indigo .switch input ~ .switch__input:after {
  background: #aeaeaf;
  box-shadow: none;
}
.cui .panel.panel--dkgray .switch input:checked ~ .switch__input,
.cui .panel.panel--indigo .switch input:checked ~ .switch__input {
  background: #00bceb;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
}
.cui .panel.panel--dkgray .switch input:checked ~ .switch__input:after,
.cui .panel.panel--indigo .switch input:checked ~ .switch__input:after {
  background: #ffffff;
  border: 1px solid #00bceb;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}
.cui .panel.panel--dkgray .switch-icon input,
.cui .panel.panel--indigo .switch-icon input {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
.cui .panel.panel--dkgray .switch-icon input ~ .switch__input span:first-child,
.cui .panel.panel--indigo .switch-icon input ~ .switch__input span:first-child {
  color: #00bceb;
}
.cui .panel.panel--dkgray .switch-icon input ~ .switch__input span:last-child,
.cui .panel.panel--indigo .switch-icon input ~ .switch__input span:last-child {
  color: #9e9ea2;
}
.cui
  .panel.panel--dkgray
  .switch-icon
  input:checked
  ~ .switch__input
  span:first-child,
.cui
  .panel.panel--indigo
  .switch-icon
  input:checked
  ~ .switch__input
  span:first-child {
  color: #9e9ea2;
}
.cui
  .panel.panel--dkgray
  .switch-icon
  input:checked
  ~ .switch__input
  span:last-child,
.cui
  .panel.panel--indigo
  .switch-icon
  input:checked
  ~ .switch__input
  span:last-child {
  color: #00bceb;
}
.cui .panel.panel--dkgray .switch-icon input.focus ~ .switch__input,
.cui .panel.panel--dkgray .switch-icon input:focus ~ .switch__input,
.cui .panel.panel--indigo .switch-icon input.focus ~ .switch__input,
.cui .panel.panel--indigo .switch-icon input:focus ~ .switch__input {
  outline-color: #ffffff;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
}
.cui .panel.panel--dkgray .switch-icon .switch__label,
.cui .panel.panel--indigo .switch-icon .switch__label {
  display: none;
}
.cui .panel.panel--dkgray .switch-icon span[class^='icon-']:last-child,
.cui .panel.panel--indigo .switch-icon span[class^='icon-']:last-child {
  margin-left: 5px;
}
.cui .panel.panel--success .switch.disabled,
.cui .panel.panel--success .switch[disabled],
.cui .panel.panel--blue .switch.disabled,
.cui .panel.panel--blue .switch[disabled] {
  opacity: 1;
}
.cui .panel.panel--success .switch.disabled input:checked ~ .switch__input,
.cui .panel.panel--success .switch[disabled] input:checked ~ .switch__input,
.cui .panel.panel--blue .switch.disabled input:checked ~ .switch__input,
.cui .panel.panel--blue .switch[disabled] input:checked ~ .switch__input {
  background: #d7d7d8;
}
.cui
  .panel.panel--success
  .switch.disabled
  input:checked
  ~ .switch__input:after,
.cui
  .panel.panel--success
  .switch[disabled]
  input:checked
  ~ .switch__input:after,
.cui .panel.panel--blue .switch.disabled input:checked ~ .switch__input:after,
.cui .panel.panel--blue .switch[disabled] input:checked ~ .switch__input:after {
  background: #f2f2f2;
  border: 1px solid #9e9ea2;
  box-shadow: none;
}
.cui .panel.panel--success .switch.disabled input ~ .switch__input,
.cui .panel.panel--success .switch[disabled] input ~ .switch__input,
.cui .panel.panel--blue .switch.disabled input ~ .switch__input,
.cui .panel.panel--blue .switch[disabled] input ~ .switch__input {
  background: #d7d7d8;
}
.cui .panel.panel--success .switch.disabled input ~ .switch__input:after,
.cui .panel.panel--success .switch[disabled] input ~ .switch__input:after,
.cui .panel.panel--blue .switch.disabled input ~ .switch__input:after,
.cui .panel.panel--blue .switch[disabled] input ~ .switch__input:after {
  background: #f2f2f2;
  border: 1px solid #9e9ea2;
  box-shadow: none;
}
.cui .panel.panel--success .switch.disabled .switch__label,
.cui .panel.panel--success .switch[disabled] .switch__label,
.cui .panel.panel--blue .switch.disabled .switch__label,
.cui .panel.panel--blue .switch[disabled] .switch__label {
  color: #39393b;
}
.cui .panel.panel--success .switch.hover input:checked ~ .switch__input:after,
.cui .panel.panel--success .switch:hover input:checked ~ .switch__input:after,
.cui .panel.panel--blue .switch.hover input:checked ~ .switch__input:after,
.cui .panel.panel--blue .switch:hover input:checked ~ .switch__input:after {
  border-width: 2px;
}
.cui .panel.panel--success .switch input.focus ~ .switch__input,
.cui .panel.panel--success .switch input:focus ~ .switch__input,
.cui .panel.panel--blue .switch input.focus ~ .switch__input,
.cui .panel.panel--blue .switch input:focus ~ .switch__input {
  outline-color: #ffffff;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
}
.cui .panel.panel--success .switch__label,
.cui .panel.panel--blue .switch__label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #39393b;
}
.cui .panel.panel--success .switch .switch__input,
.cui .panel.panel--blue .switch .switch__input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dfdfdf;
  border-radius: 30px;
}
.cui .panel.panel--success .switch .switch__input:after,
.cui .panel.panel--blue .switch .switch__input:after {
  display: block;
  position: absolute;
  left: 0;
  border-radius: 50%;
  content: '';
  cursor: pointer;
}
.cui .panel.panel--success .switch .switch__label + .switch__input,
.cui .panel.panel--success .switch .switch__input + .switch__label,
.cui .panel.panel--blue .switch .switch__label + .switch__input,
.cui .panel.panel--blue .switch .switch__input + .switch__label {
  margin-left: 5px;
}
.cui .panel.panel--success .switch input,
.cui .panel.panel--blue .switch input {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
.cui .panel.panel--success .switch input ~ .switch__input,
.cui .panel.panel--blue .switch input ~ .switch__input {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
}
.cui .panel.panel--success .switch input ~ .switch__input:after,
.cui .panel.panel--blue .switch input ~ .switch__input:after {
  background: #9e9ea2;
  box-shadow: none;
}
.cui .panel.panel--success .switch input:checked ~ .switch__input,
.cui .panel.panel--blue .switch input:checked ~ .switch__input {
  background: #005073;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
}
.cui .panel.panel--success .switch input:checked ~ .switch__input:after,
.cui .panel.panel--blue .switch input:checked ~ .switch__input:after {
  background: #ffffff;
  border: 1px solid #005073;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}
.cui .panel.panel--success .switch-icon input,
.cui .panel.panel--blue .switch-icon input {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
.cui .panel.panel--success .switch-icon input ~ .switch__input span:first-child,
.cui .panel.panel--blue .switch-icon input ~ .switch__input span:first-child {
  color: #005073;
}
.cui .panel.panel--success .switch-icon input ~ .switch__input span:last-child,
.cui .panel.panel--blue .switch-icon input ~ .switch__input span:last-child {
  color: #9e9ea2;
}
.cui
  .panel.panel--success
  .switch-icon
  input:checked
  ~ .switch__input
  span:first-child,
.cui
  .panel.panel--blue
  .switch-icon
  input:checked
  ~ .switch__input
  span:first-child {
  color: #9e9ea2;
}
.cui
  .panel.panel--success
  .switch-icon
  input:checked
  ~ .switch__input
  span:last-child,
.cui
  .panel.panel--blue
  .switch-icon
  input:checked
  ~ .switch__input
  span:last-child {
  color: #005073;
}
.cui .panel.panel--success .switch-icon input.focus ~ .switch__input,
.cui .panel.panel--success .switch-icon input:focus ~ .switch__input,
.cui .panel.panel--blue .switch-icon input.focus ~ .switch__input,
.cui .panel.panel--blue .switch-icon input:focus ~ .switch__input {
  outline-color: #ffffff;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
}
.cui .panel.panel--success .switch-icon .switch__label,
.cui .panel.panel--blue .switch-icon .switch__label {
  display: none;
}
.cui .panel.panel--success .switch-icon span[class^='icon-']:last-child,
.cui .panel.panel--blue .switch-icon span[class^='icon-']:last-child {
  margin-left: 5px;
}
.cui.cui--compressed .switch .switch__label + .switch__input {
  margin-left: 5px;
}
.cui.cui--compressed .switch .switch__input + .switch__label {
  margin-left: 5px;
}
.cui.cui--animated .switch input ~ .switch__input {
  transition: background 0.4s ease-out;
}
.cui.cui--animated .switch input ~ .switch__input:after {
  transition: -webkit-transform 0.2s cubic-bezier(0.2, 0.8, 0.2, 1.2);
  transition: transform 0.2s cubic-bezier(0.2, 0.8, 0.2, 1.2);
  transition: transform 0.2s cubic-bezier(0.2, 0.8, 0.2, 1.2),
    -webkit-transform 0.2s cubic-bezier(0.2, 0.8, 0.2, 1.2);
}
.cui.cui--animated .switch-icon .switch__input span[class^='icon-']:active {
  transition: background 0.25s cubic-bezier(0.2, 0.8, 0.2, 1.2);
}
.cui .responsive-table {
  display: block;
  max-width: 100%;
  overflow: auto;
}
.cui .table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  -webkit-font-feature-settings: 'kern', 'liga', 'tnum';
  font-feature-settings: 'kern', 'liga', 'tnum';
}
.cui .table > tbody > tr:nth-child(odd) {
  background-color: #f5f5f6;
}
.cui .table > tbody > tr:nth-child(even) {
  background-color: #ffffff;
}
.cui .table > tbody > tr.active td:first-child {
  border-left: 4px solid #00bceb;
}
.cui .table > tbody > tr.well td {
  padding: 0;
  border-top: 0 none;
}
.cui .table > tbody > tr.well td:first-child,
.cui .table > tbody > tr.well:hover td:first-child,
.cui .table .table--highlight > tbody > tr.well:hover td:first-child {
  border-left: 0 none;
}
.cui .table th {
  position: relative;
  text-align: left;
}
.cui .table th:last-child:after {
  display: none;
}
.cui .table tr,
.cui .table td {
  font-weight: normal;
}
.cui .table tr,
.cui .table td,
.cui .table th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px;
  vertical-align: middle;
}
.cui .table tr:first-child,
.cui .table td:first-child,
.cui .table th:first-child {
  width: 1px;
}
.cui .table tr td:first-child,
.cui .table tr th:first-child {
  border-left: 4px solid transparent;
}
.cui .table tr td:first-child label.checkbox,
.cui .table tr th:first-child label.checkbox {
  margin: 0;
  vertical-align: middle;
}
.cui .table--bordered {
  border: 1px solid #dfdfdf;
}
.cui .table--bordered th {
  border-right: 1px solid #dfdfdf;
}
.cui .table--bordered th:after {
  visibility: hidden;
}
.cui .table--bordered th:last-child {
  border-right: 0 none;
}
.cui .table--bordered td {
  border-top: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
}
.cui .table--bordered td:last-child {
  border-right: 1px solid transparent;
}
.cui .table--bordered-inner {
  border: 0 none;
}
.cui .table--wrap tr,
.cui .table--wrap td,
.cui .table--wrap th {
  white-space: normal;
  overflow: visible;
}
.cui .table--wrap td {
  vertical-align: top;
}
.cui .table--nowrap--col--1 td:nth-child(1),
.cui .table--nowrap--col--1 th:nth-child(1) {
  white-space: nowrap;
}
.cui .table--nostripes > tbody > tr:nth-child(odd),
.cui .table--nostripes > tbody > tr:nth-child(even) {
  background: transparent;
}
.cui .table--compressed th:after {
  display: none;
}
.cui .table--compressed tr,
.cui .table--compressed td,
.cui .table--compressed th {
  padding: 5px;
  font-size: 1.4rem;
}
.cui .table--fixed {
  table-layout: fixed;
}
.cui .table--fixed tr:first-child,
.cui .table--fixed td:first-child,
.cui .table--fixed th:first-child {
  width: auto;
}
.cui .table--loose th:after {
  display: none;
}
.cui .table--loose tr,
.cui .table--loose td,
.cui .table--loose th {
  padding: 15px;
  font-size: 1.4rem;
}
.cui .table--highlight > tbody > tr:hover {
  cursor: pointer;
}
.cui .table--highlight > tbody > tr:hover td:first-child {
  border-left: 4px solid #00bceb;
}
.cui .table--nohighlight > tbody > tr:hover td:first-child {
  border-left-color: transparent;
}
.cui .table--hover > tbody > tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.cui .table--selectable > tbody > tr > td {
  border-bottom: 1px solid #ffffff;
}
.cui .table--selectable > thead > th:first-child,
.cui .table--selectable > tbody > tr > td:first-child {
  border-left: 4px solid transparent;
}
.cui .table--selectable > tbody > tr:hover,
.cui .table--selectable > tbody > tr td:hover {
  cursor: pointer;
}
.cui .table--selectable > tbody > tr.active {
  background: rgba(0, 188, 235, 0.2);
}
.cui .table > tbody > tr.default,
.cui .table > tbody > tr > td.default,
.cui .table th.default {
  background: rgba(136, 136, 136, 0.15);
  border-left: 1px solid #888;
  border-right: 1px solid #888;
}
.cui .table > tbody > tr.primary,
.cui .table > tbody > tr > td.primary,
.cui .table th.primary {
  background: rgba(0, 188, 235, 0.15);
  border-left: 1px solid #00bceb;
  border-right: 1px solid #00bceb;
}
.cui .table > tbody > tr.secondary,
.cui .table > tbody > tr > td.secondary,
.cui .table th.secondary {
  background: rgba(0, 188, 235, 0.15);
  border-left: 1px solid #00bceb;
  border-right: 1px solid #00bceb;
}
.cui .table > tbody > tr.info,
.cui .table > tbody > tr > td.info,
.cui .table th.info {
  background: rgba(100, 187, 227, 0.15);
  border-left: 1px solid #64bbe3;
  border-right: 1px solid #64bbe3;
}
.cui .table > tbody > tr.success,
.cui .table > tbody > tr > td.success,
.cui .table th.success {
  background: rgba(110, 190, 74, 0.15);
  border-left: 1px solid #6ebe4a;
  border-right: 1px solid #6ebe4a;
}
.cui .table > tbody > tr.danger,
.cui .table > tbody > tr > td.danger,
.cui .table th.danger {
  background: rgba(226, 35, 26, 0.15);
  border-left: 1px solid #e2231a;
  border-right: 1px solid #e2231a;
}
.cui .table > tbody > tr.warning,
.cui .table > tbody > tr > td.warning,
.cui .table th.warning {
  background: rgba(251, 171, 24, 0.15);
  border-left: 1px solid #fbab18;
  border-right: 1px solid #fbab18;
}
.cui .table > tbody > tr.warning-alt,
.cui .table > tbody > tr > td.warning-alt,
.cui .table th.warning-alt {
  background: rgba(255, 204, 0, 0.15);
  border-left: 1px solid #ffcc00;
  border-right: 1px solid #ffcc00;
}
.cui .table--spotlight-1 > thead > tr > th:nth-child(1),
.cui .table--spotlight-1 > tbody > tr > td:nth-child(1) {
  background: rgba(0, 188, 235, 0.15);
  border-left: 1px solid #00bceb;
  border-right: 1px solid #00bceb;
}
.cui .table--spotlight-1 > thead > tr > th:nth-child(1) {
  border-top: 1px solid #00bceb;
}
.cui .table--spotlight-1 > tbody > tr:last-child > td:nth-child(1) {
  border-bottom: 1px solid #00bceb;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui .table--spotlight-1 > thead > tr:first-child > th:nth-child(1) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-1.table--spotlight-1-default
  > thead
  > tr
  > th:nth-child(1),
.cui
  .table--spotlight-1.table--spotlight-1-default
  > tbody
  > tr
  > td:nth-child(1) {
  background: rgba(136, 136, 136, 0.15);
  border-left: 1px solid #888;
  border-right: 1px solid #888;
}
.cui
  .table--spotlight-1.table--spotlight-1-default
  > thead
  > tr
  > th:nth-child(1) {
  border-top: 1px solid #888;
}
.cui
  .table--spotlight-1.table--spotlight-1-default
  > tbody
  > tr:last-child
  > td:nth-child(1) {
  border-bottom: 1px solid #888;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-1.table--spotlight-1-default
  > thead
  > tr:first-child
  > th:nth-child(1) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-1.table--spotlight-1-primary
  > thead
  > tr
  > th:nth-child(1),
.cui
  .table--spotlight-1.table--spotlight-1-primary
  > tbody
  > tr
  > td:nth-child(1) {
  background: rgba(0, 188, 235, 0.15);
  border-left: 1px solid #00bceb;
  border-right: 1px solid #00bceb;
}
.cui
  .table--spotlight-1.table--spotlight-1-primary
  > thead
  > tr
  > th:nth-child(1) {
  border-top: 1px solid #00bceb;
}
.cui
  .table--spotlight-1.table--spotlight-1-primary
  > tbody
  > tr:last-child
  > td:nth-child(1) {
  border-bottom: 1px solid #00bceb;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-1.table--spotlight-1-primary
  > thead
  > tr:first-child
  > th:nth-child(1) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-1.table--spotlight-1-secondary
  > thead
  > tr
  > th:nth-child(1),
.cui
  .table--spotlight-1.table--spotlight-1-secondary
  > tbody
  > tr
  > td:nth-child(1) {
  background: rgba(0, 188, 235, 0.15);
  border-left: 1px solid #00bceb;
  border-right: 1px solid #00bceb;
}
.cui
  .table--spotlight-1.table--spotlight-1-secondary
  > thead
  > tr
  > th:nth-child(1) {
  border-top: 1px solid #00bceb;
}
.cui
  .table--spotlight-1.table--spotlight-1-secondary
  > tbody
  > tr:last-child
  > td:nth-child(1) {
  border-bottom: 1px solid #00bceb;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-1.table--spotlight-1-secondary
  > thead
  > tr:first-child
  > th:nth-child(1) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui .table--spotlight-1.table--spotlight-1-info > thead > tr > th:nth-child(1),
.cui
  .table--spotlight-1.table--spotlight-1-info
  > tbody
  > tr
  > td:nth-child(1) {
  background: rgba(100, 187, 227, 0.15);
  border-left: 1px solid #64bbe3;
  border-right: 1px solid #64bbe3;
}
.cui
  .table--spotlight-1.table--spotlight-1-info
  > thead
  > tr
  > th:nth-child(1) {
  border-top: 1px solid #64bbe3;
}
.cui
  .table--spotlight-1.table--spotlight-1-info
  > tbody
  > tr:last-child
  > td:nth-child(1) {
  border-bottom: 1px solid #64bbe3;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-1.table--spotlight-1-info
  > thead
  > tr:first-child
  > th:nth-child(1) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-1.table--spotlight-1-success
  > thead
  > tr
  > th:nth-child(1),
.cui
  .table--spotlight-1.table--spotlight-1-success
  > tbody
  > tr
  > td:nth-child(1) {
  background: rgba(110, 190, 74, 0.15);
  border-left: 1px solid #6ebe4a;
  border-right: 1px solid #6ebe4a;
}
.cui
  .table--spotlight-1.table--spotlight-1-success
  > thead
  > tr
  > th:nth-child(1) {
  border-top: 1px solid #6ebe4a;
}
.cui
  .table--spotlight-1.table--spotlight-1-success
  > tbody
  > tr:last-child
  > td:nth-child(1) {
  border-bottom: 1px solid #6ebe4a;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-1.table--spotlight-1-success
  > thead
  > tr:first-child
  > th:nth-child(1) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-1.table--spotlight-1-danger
  > thead
  > tr
  > th:nth-child(1),
.cui
  .table--spotlight-1.table--spotlight-1-danger
  > tbody
  > tr
  > td:nth-child(1) {
  background: rgba(226, 35, 26, 0.15);
  border-left: 1px solid #e2231a;
  border-right: 1px solid #e2231a;
}
.cui
  .table--spotlight-1.table--spotlight-1-danger
  > thead
  > tr
  > th:nth-child(1) {
  border-top: 1px solid #e2231a;
}
.cui
  .table--spotlight-1.table--spotlight-1-danger
  > tbody
  > tr:last-child
  > td:nth-child(1) {
  border-bottom: 1px solid #e2231a;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-1.table--spotlight-1-danger
  > thead
  > tr:first-child
  > th:nth-child(1) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-1.table--spotlight-1-warning
  > thead
  > tr
  > th:nth-child(1),
.cui
  .table--spotlight-1.table--spotlight-1-warning
  > tbody
  > tr
  > td:nth-child(1) {
  background: rgba(251, 171, 24, 0.15);
  border-left: 1px solid #fbab18;
  border-right: 1px solid #fbab18;
}
.cui
  .table--spotlight-1.table--spotlight-1-warning
  > thead
  > tr
  > th:nth-child(1) {
  border-top: 1px solid #fbab18;
}
.cui
  .table--spotlight-1.table--spotlight-1-warning
  > tbody
  > tr:last-child
  > td:nth-child(1) {
  border-bottom: 1px solid #fbab18;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-1.table--spotlight-1-warning
  > thead
  > tr:first-child
  > th:nth-child(1) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-1.table--spotlight-1-warning-alt
  > thead
  > tr
  > th:nth-child(1),
.cui
  .table--spotlight-1.table--spotlight-1-warning-alt
  > tbody
  > tr
  > td:nth-child(1) {
  background: rgba(255, 204, 0, 0.15);
  border-left: 1px solid #ffcc00;
  border-right: 1px solid #ffcc00;
}
.cui
  .table--spotlight-1.table--spotlight-1-warning-alt
  > thead
  > tr
  > th:nth-child(1) {
  border-top: 1px solid #ffcc00;
}
.cui
  .table--spotlight-1.table--spotlight-1-warning-alt
  > tbody
  > tr:last-child
  > td:nth-child(1) {
  border-bottom: 1px solid #ffcc00;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-1.table--spotlight-1-warning-alt
  > thead
  > tr:first-child
  > th:nth-child(1) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui .table--spotlight-2 > thead > tr > th:nth-child(2),
.cui .table--spotlight-2 > tbody > tr > td:nth-child(2) {
  background: rgba(0, 188, 235, 0.15);
  border-left: 1px solid #00bceb;
  border-right: 1px solid #00bceb;
}
.cui .table--spotlight-2 > thead > tr > th:nth-child(2) {
  border-top: 1px solid #00bceb;
}
.cui .table--spotlight-2 > tbody > tr:last-child > td:nth-child(2) {
  border-bottom: 1px solid #00bceb;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui .table--spotlight-2 > thead > tr:first-child > th:nth-child(2) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-2.table--spotlight-2-default
  > thead
  > tr
  > th:nth-child(2),
.cui
  .table--spotlight-2.table--spotlight-2-default
  > tbody
  > tr
  > td:nth-child(2) {
  background: rgba(136, 136, 136, 0.15);
  border-left: 1px solid #888;
  border-right: 1px solid #888;
}
.cui
  .table--spotlight-2.table--spotlight-2-default
  > thead
  > tr
  > th:nth-child(2) {
  border-top: 1px solid #888;
}
.cui
  .table--spotlight-2.table--spotlight-2-default
  > tbody
  > tr:last-child
  > td:nth-child(2) {
  border-bottom: 1px solid #888;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-2.table--spotlight-2-default
  > thead
  > tr:first-child
  > th:nth-child(2) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-2.table--spotlight-2-primary
  > thead
  > tr
  > th:nth-child(2),
.cui
  .table--spotlight-2.table--spotlight-2-primary
  > tbody
  > tr
  > td:nth-child(2) {
  background: rgba(0, 188, 235, 0.15);
  border-left: 1px solid #00bceb;
  border-right: 1px solid #00bceb;
}
.cui
  .table--spotlight-2.table--spotlight-2-primary
  > thead
  > tr
  > th:nth-child(2) {
  border-top: 1px solid #00bceb;
}
.cui
  .table--spotlight-2.table--spotlight-2-primary
  > tbody
  > tr:last-child
  > td:nth-child(2) {
  border-bottom: 1px solid #00bceb;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-2.table--spotlight-2-primary
  > thead
  > tr:first-child
  > th:nth-child(2) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-2.table--spotlight-2-secondary
  > thead
  > tr
  > th:nth-child(2),
.cui
  .table--spotlight-2.table--spotlight-2-secondary
  > tbody
  > tr
  > td:nth-child(2) {
  background: rgba(0, 188, 235, 0.15);
  border-left: 1px solid #00bceb;
  border-right: 1px solid #00bceb;
}
.cui
  .table--spotlight-2.table--spotlight-2-secondary
  > thead
  > tr
  > th:nth-child(2) {
  border-top: 1px solid #00bceb;
}
.cui
  .table--spotlight-2.table--spotlight-2-secondary
  > tbody
  > tr:last-child
  > td:nth-child(2) {
  border-bottom: 1px solid #00bceb;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-2.table--spotlight-2-secondary
  > thead
  > tr:first-child
  > th:nth-child(2) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui .table--spotlight-2.table--spotlight-2-info > thead > tr > th:nth-child(2),
.cui
  .table--spotlight-2.table--spotlight-2-info
  > tbody
  > tr
  > td:nth-child(2) {
  background: rgba(100, 187, 227, 0.15);
  border-left: 1px solid #64bbe3;
  border-right: 1px solid #64bbe3;
}
.cui
  .table--spotlight-2.table--spotlight-2-info
  > thead
  > tr
  > th:nth-child(2) {
  border-top: 1px solid #64bbe3;
}
.cui
  .table--spotlight-2.table--spotlight-2-info
  > tbody
  > tr:last-child
  > td:nth-child(2) {
  border-bottom: 1px solid #64bbe3;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-2.table--spotlight-2-info
  > thead
  > tr:first-child
  > th:nth-child(2) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-2.table--spotlight-2-success
  > thead
  > tr
  > th:nth-child(2),
.cui
  .table--spotlight-2.table--spotlight-2-success
  > tbody
  > tr
  > td:nth-child(2) {
  background: rgba(110, 190, 74, 0.15);
  border-left: 1px solid #6ebe4a;
  border-right: 1px solid #6ebe4a;
}
.cui
  .table--spotlight-2.table--spotlight-2-success
  > thead
  > tr
  > th:nth-child(2) {
  border-top: 1px solid #6ebe4a;
}
.cui
  .table--spotlight-2.table--spotlight-2-success
  > tbody
  > tr:last-child
  > td:nth-child(2) {
  border-bottom: 1px solid #6ebe4a;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-2.table--spotlight-2-success
  > thead
  > tr:first-child
  > th:nth-child(2) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-2.table--spotlight-2-danger
  > thead
  > tr
  > th:nth-child(2),
.cui
  .table--spotlight-2.table--spotlight-2-danger
  > tbody
  > tr
  > td:nth-child(2) {
  background: rgba(226, 35, 26, 0.15);
  border-left: 1px solid #e2231a;
  border-right: 1px solid #e2231a;
}
.cui
  .table--spotlight-2.table--spotlight-2-danger
  > thead
  > tr
  > th:nth-child(2) {
  border-top: 1px solid #e2231a;
}
.cui
  .table--spotlight-2.table--spotlight-2-danger
  > tbody
  > tr:last-child
  > td:nth-child(2) {
  border-bottom: 1px solid #e2231a;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-2.table--spotlight-2-danger
  > thead
  > tr:first-child
  > th:nth-child(2) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-2.table--spotlight-2-warning
  > thead
  > tr
  > th:nth-child(2),
.cui
  .table--spotlight-2.table--spotlight-2-warning
  > tbody
  > tr
  > td:nth-child(2) {
  background: rgba(251, 171, 24, 0.15);
  border-left: 1px solid #fbab18;
  border-right: 1px solid #fbab18;
}
.cui
  .table--spotlight-2.table--spotlight-2-warning
  > thead
  > tr
  > th:nth-child(2) {
  border-top: 1px solid #fbab18;
}
.cui
  .table--spotlight-2.table--spotlight-2-warning
  > tbody
  > tr:last-child
  > td:nth-child(2) {
  border-bottom: 1px solid #fbab18;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-2.table--spotlight-2-warning
  > thead
  > tr:first-child
  > th:nth-child(2) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-2.table--spotlight-2-warning-alt
  > thead
  > tr
  > th:nth-child(2),
.cui
  .table--spotlight-2.table--spotlight-2-warning-alt
  > tbody
  > tr
  > td:nth-child(2) {
  background: rgba(255, 204, 0, 0.15);
  border-left: 1px solid #ffcc00;
  border-right: 1px solid #ffcc00;
}
.cui
  .table--spotlight-2.table--spotlight-2-warning-alt
  > thead
  > tr
  > th:nth-child(2) {
  border-top: 1px solid #ffcc00;
}
.cui
  .table--spotlight-2.table--spotlight-2-warning-alt
  > tbody
  > tr:last-child
  > td:nth-child(2) {
  border-bottom: 1px solid #ffcc00;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-2.table--spotlight-2-warning-alt
  > thead
  > tr:first-child
  > th:nth-child(2) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui .table--spotlight-3 > thead > tr > th:nth-child(3),
.cui .table--spotlight-3 > tbody > tr > td:nth-child(3) {
  background: rgba(0, 188, 235, 0.15);
  border-left: 1px solid #00bceb;
  border-right: 1px solid #00bceb;
}
.cui .table--spotlight-3 > thead > tr > th:nth-child(3) {
  border-top: 1px solid #00bceb;
}
.cui .table--spotlight-3 > tbody > tr:last-child > td:nth-child(3) {
  border-bottom: 1px solid #00bceb;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui .table--spotlight-3 > thead > tr:first-child > th:nth-child(3) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-3.table--spotlight-3-default
  > thead
  > tr
  > th:nth-child(3),
.cui
  .table--spotlight-3.table--spotlight-3-default
  > tbody
  > tr
  > td:nth-child(3) {
  background: rgba(136, 136, 136, 0.15);
  border-left: 1px solid #888;
  border-right: 1px solid #888;
}
.cui
  .table--spotlight-3.table--spotlight-3-default
  > thead
  > tr
  > th:nth-child(3) {
  border-top: 1px solid #888;
}
.cui
  .table--spotlight-3.table--spotlight-3-default
  > tbody
  > tr:last-child
  > td:nth-child(3) {
  border-bottom: 1px solid #888;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-3.table--spotlight-3-default
  > thead
  > tr:first-child
  > th:nth-child(3) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-3.table--spotlight-3-primary
  > thead
  > tr
  > th:nth-child(3),
.cui
  .table--spotlight-3.table--spotlight-3-primary
  > tbody
  > tr
  > td:nth-child(3) {
  background: rgba(0, 188, 235, 0.15);
  border-left: 1px solid #00bceb;
  border-right: 1px solid #00bceb;
}
.cui
  .table--spotlight-3.table--spotlight-3-primary
  > thead
  > tr
  > th:nth-child(3) {
  border-top: 1px solid #00bceb;
}
.cui
  .table--spotlight-3.table--spotlight-3-primary
  > tbody
  > tr:last-child
  > td:nth-child(3) {
  border-bottom: 1px solid #00bceb;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-3.table--spotlight-3-primary
  > thead
  > tr:first-child
  > th:nth-child(3) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-3.table--spotlight-3-secondary
  > thead
  > tr
  > th:nth-child(3),
.cui
  .table--spotlight-3.table--spotlight-3-secondary
  > tbody
  > tr
  > td:nth-child(3) {
  background: rgba(0, 188, 235, 0.15);
  border-left: 1px solid #00bceb;
  border-right: 1px solid #00bceb;
}
.cui
  .table--spotlight-3.table--spotlight-3-secondary
  > thead
  > tr
  > th:nth-child(3) {
  border-top: 1px solid #00bceb;
}
.cui
  .table--spotlight-3.table--spotlight-3-secondary
  > tbody
  > tr:last-child
  > td:nth-child(3) {
  border-bottom: 1px solid #00bceb;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-3.table--spotlight-3-secondary
  > thead
  > tr:first-child
  > th:nth-child(3) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui .table--spotlight-3.table--spotlight-3-info > thead > tr > th:nth-child(3),
.cui
  .table--spotlight-3.table--spotlight-3-info
  > tbody
  > tr
  > td:nth-child(3) {
  background: rgba(100, 187, 227, 0.15);
  border-left: 1px solid #64bbe3;
  border-right: 1px solid #64bbe3;
}
.cui
  .table--spotlight-3.table--spotlight-3-info
  > thead
  > tr
  > th:nth-child(3) {
  border-top: 1px solid #64bbe3;
}
.cui
  .table--spotlight-3.table--spotlight-3-info
  > tbody
  > tr:last-child
  > td:nth-child(3) {
  border-bottom: 1px solid #64bbe3;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-3.table--spotlight-3-info
  > thead
  > tr:first-child
  > th:nth-child(3) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-3.table--spotlight-3-success
  > thead
  > tr
  > th:nth-child(3),
.cui
  .table--spotlight-3.table--spotlight-3-success
  > tbody
  > tr
  > td:nth-child(3) {
  background: rgba(110, 190, 74, 0.15);
  border-left: 1px solid #6ebe4a;
  border-right: 1px solid #6ebe4a;
}
.cui
  .table--spotlight-3.table--spotlight-3-success
  > thead
  > tr
  > th:nth-child(3) {
  border-top: 1px solid #6ebe4a;
}
.cui
  .table--spotlight-3.table--spotlight-3-success
  > tbody
  > tr:last-child
  > td:nth-child(3) {
  border-bottom: 1px solid #6ebe4a;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-3.table--spotlight-3-success
  > thead
  > tr:first-child
  > th:nth-child(3) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-3.table--spotlight-3-danger
  > thead
  > tr
  > th:nth-child(3),
.cui
  .table--spotlight-3.table--spotlight-3-danger
  > tbody
  > tr
  > td:nth-child(3) {
  background: rgba(226, 35, 26, 0.15);
  border-left: 1px solid #e2231a;
  border-right: 1px solid #e2231a;
}
.cui
  .table--spotlight-3.table--spotlight-3-danger
  > thead
  > tr
  > th:nth-child(3) {
  border-top: 1px solid #e2231a;
}
.cui
  .table--spotlight-3.table--spotlight-3-danger
  > tbody
  > tr:last-child
  > td:nth-child(3) {
  border-bottom: 1px solid #e2231a;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-3.table--spotlight-3-danger
  > thead
  > tr:first-child
  > th:nth-child(3) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-3.table--spotlight-3-warning
  > thead
  > tr
  > th:nth-child(3),
.cui
  .table--spotlight-3.table--spotlight-3-warning
  > tbody
  > tr
  > td:nth-child(3) {
  background: rgba(251, 171, 24, 0.15);
  border-left: 1px solid #fbab18;
  border-right: 1px solid #fbab18;
}
.cui
  .table--spotlight-3.table--spotlight-3-warning
  > thead
  > tr
  > th:nth-child(3) {
  border-top: 1px solid #fbab18;
}
.cui
  .table--spotlight-3.table--spotlight-3-warning
  > tbody
  > tr:last-child
  > td:nth-child(3) {
  border-bottom: 1px solid #fbab18;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-3.table--spotlight-3-warning
  > thead
  > tr:first-child
  > th:nth-child(3) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-3.table--spotlight-3-warning-alt
  > thead
  > tr
  > th:nth-child(3),
.cui
  .table--spotlight-3.table--spotlight-3-warning-alt
  > tbody
  > tr
  > td:nth-child(3) {
  background: rgba(255, 204, 0, 0.15);
  border-left: 1px solid #ffcc00;
  border-right: 1px solid #ffcc00;
}
.cui
  .table--spotlight-3.table--spotlight-3-warning-alt
  > thead
  > tr
  > th:nth-child(3) {
  border-top: 1px solid #ffcc00;
}
.cui
  .table--spotlight-3.table--spotlight-3-warning-alt
  > tbody
  > tr:last-child
  > td:nth-child(3) {
  border-bottom: 1px solid #ffcc00;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-3.table--spotlight-3-warning-alt
  > thead
  > tr:first-child
  > th:nth-child(3) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui .table--spotlight-4 > thead > tr > th:nth-child(4),
.cui .table--spotlight-4 > tbody > tr > td:nth-child(4) {
  background: rgba(0, 188, 235, 0.15);
  border-left: 1px solid #00bceb;
  border-right: 1px solid #00bceb;
}
.cui .table--spotlight-4 > thead > tr > th:nth-child(4) {
  border-top: 1px solid #00bceb;
}
.cui .table--spotlight-4 > tbody > tr:last-child > td:nth-child(4) {
  border-bottom: 1px solid #00bceb;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui .table--spotlight-4 > thead > tr:first-child > th:nth-child(4) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-4.table--spotlight-4-default
  > thead
  > tr
  > th:nth-child(4),
.cui
  .table--spotlight-4.table--spotlight-4-default
  > tbody
  > tr
  > td:nth-child(4) {
  background: rgba(136, 136, 136, 0.15);
  border-left: 1px solid #888;
  border-right: 1px solid #888;
}
.cui
  .table--spotlight-4.table--spotlight-4-default
  > thead
  > tr
  > th:nth-child(4) {
  border-top: 1px solid #888;
}
.cui
  .table--spotlight-4.table--spotlight-4-default
  > tbody
  > tr:last-child
  > td:nth-child(4) {
  border-bottom: 1px solid #888;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-4.table--spotlight-4-default
  > thead
  > tr:first-child
  > th:nth-child(4) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-4.table--spotlight-4-primary
  > thead
  > tr
  > th:nth-child(4),
.cui
  .table--spotlight-4.table--spotlight-4-primary
  > tbody
  > tr
  > td:nth-child(4) {
  background: rgba(0, 188, 235, 0.15);
  border-left: 1px solid #00bceb;
  border-right: 1px solid #00bceb;
}
.cui
  .table--spotlight-4.table--spotlight-4-primary
  > thead
  > tr
  > th:nth-child(4) {
  border-top: 1px solid #00bceb;
}
.cui
  .table--spotlight-4.table--spotlight-4-primary
  > tbody
  > tr:last-child
  > td:nth-child(4) {
  border-bottom: 1px solid #00bceb;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-4.table--spotlight-4-primary
  > thead
  > tr:first-child
  > th:nth-child(4) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-4.table--spotlight-4-secondary
  > thead
  > tr
  > th:nth-child(4),
.cui
  .table--spotlight-4.table--spotlight-4-secondary
  > tbody
  > tr
  > td:nth-child(4) {
  background: rgba(0, 188, 235, 0.15);
  border-left: 1px solid #00bceb;
  border-right: 1px solid #00bceb;
}
.cui
  .table--spotlight-4.table--spotlight-4-secondary
  > thead
  > tr
  > th:nth-child(4) {
  border-top: 1px solid #00bceb;
}
.cui
  .table--spotlight-4.table--spotlight-4-secondary
  > tbody
  > tr:last-child
  > td:nth-child(4) {
  border-bottom: 1px solid #00bceb;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-4.table--spotlight-4-secondary
  > thead
  > tr:first-child
  > th:nth-child(4) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui .table--spotlight-4.table--spotlight-4-info > thead > tr > th:nth-child(4),
.cui
  .table--spotlight-4.table--spotlight-4-info
  > tbody
  > tr
  > td:nth-child(4) {
  background: rgba(100, 187, 227, 0.15);
  border-left: 1px solid #64bbe3;
  border-right: 1px solid #64bbe3;
}
.cui
  .table--spotlight-4.table--spotlight-4-info
  > thead
  > tr
  > th:nth-child(4) {
  border-top: 1px solid #64bbe3;
}
.cui
  .table--spotlight-4.table--spotlight-4-info
  > tbody
  > tr:last-child
  > td:nth-child(4) {
  border-bottom: 1px solid #64bbe3;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-4.table--spotlight-4-info
  > thead
  > tr:first-child
  > th:nth-child(4) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-4.table--spotlight-4-success
  > thead
  > tr
  > th:nth-child(4),
.cui
  .table--spotlight-4.table--spotlight-4-success
  > tbody
  > tr
  > td:nth-child(4) {
  background: rgba(110, 190, 74, 0.15);
  border-left: 1px solid #6ebe4a;
  border-right: 1px solid #6ebe4a;
}
.cui
  .table--spotlight-4.table--spotlight-4-success
  > thead
  > tr
  > th:nth-child(4) {
  border-top: 1px solid #6ebe4a;
}
.cui
  .table--spotlight-4.table--spotlight-4-success
  > tbody
  > tr:last-child
  > td:nth-child(4) {
  border-bottom: 1px solid #6ebe4a;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-4.table--spotlight-4-success
  > thead
  > tr:first-child
  > th:nth-child(4) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-4.table--spotlight-4-danger
  > thead
  > tr
  > th:nth-child(4),
.cui
  .table--spotlight-4.table--spotlight-4-danger
  > tbody
  > tr
  > td:nth-child(4) {
  background: rgba(226, 35, 26, 0.15);
  border-left: 1px solid #e2231a;
  border-right: 1px solid #e2231a;
}
.cui
  .table--spotlight-4.table--spotlight-4-danger
  > thead
  > tr
  > th:nth-child(4) {
  border-top: 1px solid #e2231a;
}
.cui
  .table--spotlight-4.table--spotlight-4-danger
  > tbody
  > tr:last-child
  > td:nth-child(4) {
  border-bottom: 1px solid #e2231a;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-4.table--spotlight-4-danger
  > thead
  > tr:first-child
  > th:nth-child(4) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-4.table--spotlight-4-warning
  > thead
  > tr
  > th:nth-child(4),
.cui
  .table--spotlight-4.table--spotlight-4-warning
  > tbody
  > tr
  > td:nth-child(4) {
  background: rgba(251, 171, 24, 0.15);
  border-left: 1px solid #fbab18;
  border-right: 1px solid #fbab18;
}
.cui
  .table--spotlight-4.table--spotlight-4-warning
  > thead
  > tr
  > th:nth-child(4) {
  border-top: 1px solid #fbab18;
}
.cui
  .table--spotlight-4.table--spotlight-4-warning
  > tbody
  > tr:last-child
  > td:nth-child(4) {
  border-bottom: 1px solid #fbab18;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-4.table--spotlight-4-warning
  > thead
  > tr:first-child
  > th:nth-child(4) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui
  .table--spotlight-4.table--spotlight-4-warning-alt
  > thead
  > tr
  > th:nth-child(4),
.cui
  .table--spotlight-4.table--spotlight-4-warning-alt
  > tbody
  > tr
  > td:nth-child(4) {
  background: rgba(255, 204, 0, 0.15);
  border-left: 1px solid #ffcc00;
  border-right: 1px solid #ffcc00;
}
.cui
  .table--spotlight-4.table--spotlight-4-warning-alt
  > thead
  > tr
  > th:nth-child(4) {
  border-top: 1px solid #ffcc00;
}
.cui
  .table--spotlight-4.table--spotlight-4-warning-alt
  > tbody
  > tr:last-child
  > td:nth-child(4) {
  border-bottom: 1px solid #ffcc00;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.cui
  .table--spotlight-4.table--spotlight-4-warning-alt
  > thead
  > tr:first-child
  > th:nth-child(4) {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.cui .sortable {
  cursor: pointer;
}
.cui .sortable:hover,
.cui .sortable.sorted {
  color: #00bceb;
}
.cui .sort-indicator {
  margin-left: 5px;
  font-size: 1.2rem;
  vertical-align: middle;
}
.cui .fixed-header {
  position: relative;
  width: 100%;
  padding: 36px 0 0;
}
.cui .fixed-header .responsive-table {
  width: 100%;
}
.cui .fixed-header .responsive-table thead tr {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  margin: 0;
  padding: 0;
}
.cui.cui--compressed .table th:after {
  display: none;
}
.cui.cui--compressed .table tr,
.cui.cui--compressed .table td,
.cui.cui--compressed .table th {
  padding: 5px;
  font-size: 1.4rem;
}
.cui.cui--animated .table.table--highlight > tbody > tr:hover td:first-child {
  transition: all 0.2s linear;
}
.cui.cui--animated .table.table--hover > tbody > tr:hover {
  transition: background 0.2s linear;
}
.cui.cui--animated .table.table--selectable > tbody > tr {
  transition: background 0.25s ease-in-out;
}
.cui.cui--animated .table.table--selectable > tbody > tr:hover,
.cui.cui--animated .table.table--selectable > tbody > tr.active {
  transition: background 0.25s ease-in-out;
}
.cui textarea.textarea,
.cui .form-group .form-group__text textarea {
  resize: none;
  width: 100%;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  box-shadow: none;
  padding: 10px;
  color: #58585b;
  overflow-x: hidden;
  min-height: 40px;
}
.cui textarea.textarea--alt,
.cui .form-group .form-group__text textarea--alt {
  background-color: #ffffff;
}
.cui textarea.textarea--well,
.cui .form-group .form-group__text textarea--well {
  box-shadow: inset 0 5px 10px -5px rgba(0, 0, 0, 0.25) !important;
}
.cui textarea.textarea:hover,
.cui textarea.textarea.hover,
.cui textarea.textarea:focus,
.cui textarea.textarea.focus,
.cui .form-group .form-group__text textarea:hover,
.cui .form-group .form-group__text textarea.hover,
.cui .form-group .form-group__text textarea:focus,
.cui .form-group .form-group__text textarea.focus {
  border-color: #00bceb;
}
.cui textarea.textarea:focus,
.cui textarea.textarea.focus,
.cui .form-group .form-group__text textarea:focus,
.cui .form-group .form-group__text textarea.focus {
  outline: none;
  box-shadow: inset 0 0 0 2px rgba(0, 188, 235, 0.25);
}
.cui textarea.textarea.disabled,
.cui textarea.textarea[disabled],
.cui textarea.textarea.readonly,
.cui textarea.textarea[readonly],
.cui .form-group .form-group__text textarea.disabled,
.cui .form-group .form-group__text textarea[disabled],
.cui .form-group .form-group__text textarea.readonly,
.cui .form-group .form-group__text textarea[readonly] {
  opacity: 0.75;
  cursor: not-allowed !important;
  pointer-events: none !important;
  z-index: 0 !important;
  background-color: #f2f2f2;
}
.cui textarea.textarea.disabled ~ label,
.cui textarea.textarea[disabled] ~ label,
.cui textarea.textarea.readonly ~ label,
.cui textarea.textarea[readonly] ~ label,
.cui .form-group .form-group__text textarea.disabled ~ label,
.cui .form-group .form-group__text textarea[disabled] ~ label,
.cui .form-group .form-group__text textarea.readonly ~ label,
.cui .form-group .form-group__text textarea[readonly] ~ label {
  opacity: 0.75;
  cursor: not-allowed !important;
  pointer-events: none !important;
  z-index: 0 !important;
}
.cui .form-group .form-group__text,
.cui .form-group .form-group__text.select {
  position: relative;
  display: flex;
  flex-direction: column;
}
.cui .form-group .form-group__text input,
.cui .form-group .form-group__text.select input {
  width: 100%;
}
.cui .form-group .form-group__text input,
.cui .form-group .form-group__text select,
.cui .form-group .form-group__text textarea,
.cui .form-group .form-group__text.select input,
.cui .form-group .form-group__text.select select,
.cui .form-group .form-group__text.select textarea {
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  flex: 1;
  flex-shrink: 0;
  order: 3;
  background: #ffffff;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.cui .form-group .form-group__text input::-webkit-input-placeholder,
.cui .form-group .form-group__text textarea::-webkit-input-placeholder,
.cui .form-group .form-group__text.select input::-webkit-input-placeholder,
.cui .form-group .form-group__text.select textarea::-webkit-input-placeholder {
  color: #58585b;
  font-style: italic;
}
.cui .form-group .form-group__text input:-ms-input-placeholder,
.cui .form-group .form-group__text textarea:-ms-input-placeholder,
.cui .form-group .form-group__text.select input:-ms-input-placeholder,
.cui .form-group .form-group__text.select textarea:-ms-input-placeholder {
  color: #58585b;
  font-style: italic;
}
.cui .form-group .form-group__text input::-ms-input-placeholder,
.cui .form-group .form-group__text textarea::-ms-input-placeholder,
.cui .form-group .form-group__text.select input::-ms-input-placeholder,
.cui .form-group .form-group__text.select textarea::-ms-input-placeholder {
  color: #58585b;
  font-style: italic;
}
.cui .form-group .form-group__text input::placeholder,
.cui .form-group .form-group__text textarea::placeholder,
.cui .form-group .form-group__text.select input::placeholder,
.cui .form-group .form-group__text.select textarea::placeholder {
  color: #58585b;
  font-style: italic;
}
.cui .form-group .form-group__text input,
.cui .form-group .form-group__text select,
.cui .form-group .form-group__text.select input,
.cui .form-group .form-group__text.select select {
  height: 40px;
  max-height: 40px;
  line-height: 2.4rem;
}
.cui .form-group .form-group__text input,
.cui .form-group .form-group__text select,
.cui .form-group .form-group__text textarea,
.cui .form-group .form-group__text.select input,
.cui .form-group .form-group__text.select select,
.cui .form-group .form-group__text.select textarea {
  min-height: 40px;
  padding: 10px;
}
.cui .form-group .form-group__text select,
.cui .form-group .form-group__text.select select {
  padding: 0 40px 0 10px;
}
.cui .form-group .form-group__text label,
.cui .form-group .form-group__text.select label {
  top: 10px;
}
.cui .form-group .form-group__text:after,
.cui .form-group .form-group__text.select:after {
  bottom: 10px;
}
.cui .form-group .form-group__text input[type='password'],
.cui .form-group .form-group__text.select input[type='password'] {
  font: caption;
  font-size: 1.8rem;
}
.cui .form-group .form-group__text input[type='search'] ~ label .icon-search,
.cui
  .form-group
  .form-group__text.select
  input[type='search']
  ~ label
  .icon-search {
  display: none;
}
.cui .form-group .form-group__text button.link,
.cui .form-group .form-group__text.select button.link {
  display: none;
}
.cui .form-group .form-group__text input:hover,
.cui .form-group .form-group__text input.hover,
.cui .form-group .form-group__text input:focus,
.cui .form-group .form-group__text input.focus,
.cui .form-group .form-group__text select:hover,
.cui .form-group .form-group__text select.hover,
.cui .form-group .form-group__text select:focus,
.cui .form-group .form-group__text select.focus,
.cui .form-group .form-group__text textarea:hover,
.cui .form-group .form-group__text textarea.hover,
.cui .form-group .form-group__text textarea:focus,
.cui .form-group .form-group__text textarea.focus,
.cui .form-group .form-group__text.select input:hover,
.cui .form-group .form-group__text.select input.hover,
.cui .form-group .form-group__text.select input:focus,
.cui .form-group .form-group__text.select input.focus,
.cui .form-group .form-group__text.select select:hover,
.cui .form-group .form-group__text.select select.hover,
.cui .form-group .form-group__text.select select:focus,
.cui .form-group .form-group__text.select select.focus,
.cui .form-group .form-group__text.select textarea:hover,
.cui .form-group .form-group__text.select textarea.hover,
.cui .form-group .form-group__text.select textarea:focus,
.cui .form-group .form-group__text.select textarea.focus {
  border-color: #00bceb;
}
.cui .form-group .form-group__text input:focus,
.cui .form-group .form-group__text input.focus,
.cui .form-group .form-group__text select:focus,
.cui .form-group .form-group__text select.focus,
.cui .form-group .form-group__text textarea:focus,
.cui .form-group .form-group__text textarea.focus,
.cui .form-group .form-group__text.select input:focus,
.cui .form-group .form-group__text.select input.focus,
.cui .form-group .form-group__text.select select:focus,
.cui .form-group .form-group__text.select select.focus,
.cui .form-group .form-group__text.select textarea:focus,
.cui .form-group .form-group__text.select textarea.focus {
  outline: none;
  box-shadow: inset 0 0 0 2px rgba(0, 188, 235, 0.25);
}
.cui .form-group .form-group__text input.disabled,
.cui .form-group .form-group__text input[disabled],
.cui .form-group .form-group__text input.readonly,
.cui .form-group .form-group__text input[readonly],
.cui .form-group .form-group__text select.disabled,
.cui .form-group .form-group__text select[disabled],
.cui .form-group .form-group__text select.readonly,
.cui .form-group .form-group__text select[readonly],
.cui .form-group .form-group__text textarea.disabled,
.cui .form-group .form-group__text textarea[disabled],
.cui .form-group .form-group__text textarea.readonly,
.cui .form-group .form-group__text textarea[readonly],
.cui .form-group .form-group__text.select input.disabled,
.cui .form-group .form-group__text.select input[disabled],
.cui .form-group .form-group__text.select input.readonly,
.cui .form-group .form-group__text.select input[readonly],
.cui .form-group .form-group__text.select select.disabled,
.cui .form-group .form-group__text.select select[disabled],
.cui .form-group .form-group__text.select select.readonly,
.cui .form-group .form-group__text.select select[readonly],
.cui .form-group .form-group__text.select textarea.disabled,
.cui .form-group .form-group__text.select textarea[disabled],
.cui .form-group .form-group__text.select textarea.readonly,
.cui .form-group .form-group__text.select textarea[readonly] {
  opacity: 0.75;
  cursor: not-allowed !important;
  pointer-events: none !important;
  z-index: 0 !important;
  background-color: #f2f2f2;
}
.cui .form-group .form-group__text input.disabled ~ label,
.cui .form-group .form-group__text input[disabled] ~ label,
.cui .form-group .form-group__text input.readonly ~ label,
.cui .form-group .form-group__text input[readonly] ~ label,
.cui .form-group .form-group__text select.disabled ~ label,
.cui .form-group .form-group__text select[disabled] ~ label,
.cui .form-group .form-group__text select.readonly ~ label,
.cui .form-group .form-group__text select[readonly] ~ label,
.cui .form-group .form-group__text textarea.disabled ~ label,
.cui .form-group .form-group__text textarea[disabled] ~ label,
.cui .form-group .form-group__text textarea.readonly ~ label,
.cui .form-group .form-group__text textarea[readonly] ~ label,
.cui .form-group .form-group__text.select input.disabled ~ label,
.cui .form-group .form-group__text.select input[disabled] ~ label,
.cui .form-group .form-group__text.select input.readonly ~ label,
.cui .form-group .form-group__text.select input[readonly] ~ label,
.cui .form-group .form-group__text.select select.disabled ~ label,
.cui .form-group .form-group__text.select select[disabled] ~ label,
.cui .form-group .form-group__text.select select.readonly ~ label,
.cui .form-group .form-group__text.select select[readonly] ~ label,
.cui .form-group .form-group__text.select textarea.disabled ~ label,
.cui .form-group .form-group__text.select textarea[disabled] ~ label,
.cui .form-group .form-group__text.select textarea.readonly ~ label,
.cui .form-group .form-group__text.select textarea[readonly] ~ label {
  opacity: 0.75;
  cursor: not-allowed !important;
  pointer-events: none !important;
  z-index: 0 !important;
}
.cui .form-group .form-group__text input.input--dirty.input--invalid-required,
.cui .form-group .form-group__text input.ng-dirty.ng-invalid-required,
.cui .form-group .form-group__text input.ng-touched.ng-invalid-required,
.cui .form-group .form-group__text input.input--dirty.input--invalid,
.cui .form-group .form-group__text input.ng-dirty.ng-invalid,
.cui .form-group .form-group__text input.ng-touched.ng-invalid,
.cui .form-group .form-group__text select.input--dirty.input--invalid-required,
.cui .form-group .form-group__text select.ng-dirty.ng-invalid-required,
.cui .form-group .form-group__text select.ng-touched.ng-invalid-required,
.cui .form-group .form-group__text select.input--dirty.input--invalid,
.cui .form-group .form-group__text select.ng-dirty.ng-invalid,
.cui .form-group .form-group__text select.ng-touched.ng-invalid,
.cui
  .form-group
  .form-group__text
  textarea.input--dirty.input--invalid-required,
.cui .form-group .form-group__text textarea.ng-dirty.ng-invalid-required,
.cui .form-group .form-group__text textarea.ng-touched.ng-invalid-required,
.cui .form-group .form-group__text textarea.input--dirty.input--invalid,
.cui .form-group .form-group__text textarea.ng-dirty.ng-invalid,
.cui .form-group .form-group__text textarea.ng-touched.ng-invalid,
.cui
  .form-group
  .form-group__text.select
  input.input--dirty.input--invalid-required,
.cui .form-group .form-group__text.select input.ng-dirty.ng-invalid-required,
.cui .form-group .form-group__text.select input.ng-touched.ng-invalid-required,
.cui .form-group .form-group__text.select input.input--dirty.input--invalid,
.cui .form-group .form-group__text.select input.ng-dirty.ng-invalid,
.cui .form-group .form-group__text.select input.ng-touched.ng-invalid,
.cui
  .form-group
  .form-group__text.select
  select.input--dirty.input--invalid-required,
.cui .form-group .form-group__text.select select.ng-dirty.ng-invalid-required,
.cui .form-group .form-group__text.select select.ng-touched.ng-invalid-required,
.cui .form-group .form-group__text.select select.input--dirty.input--invalid,
.cui .form-group .form-group__text.select select.ng-dirty.ng-invalid,
.cui .form-group .form-group__text.select select.ng-touched.ng-invalid,
.cui
  .form-group
  .form-group__text.select
  textarea.input--dirty.input--invalid-required,
.cui .form-group .form-group__text.select textarea.ng-dirty.ng-invalid-required,
.cui
  .form-group
  .form-group__text.select
  textarea.ng-touched.ng-invalid-required,
.cui .form-group .form-group__text.select textarea.input--dirty.input--invalid,
.cui .form-group .form-group__text.select textarea.ng-dirty.ng-invalid,
.cui .form-group .form-group__text.select textarea.ng-touched.ng-invalid {
  border-color: #e2231a;
}
.cui
  .form-group
  .form-group__text
  input.input--dirty.input--invalid-required:focus,
.cui
  .form-group
  .form-group__text
  input.input--dirty.input--invalid-required
  .focus,
.cui
  .form-group
  .form-group__text
  input.input--dirty.input--invalid-required:hover,
.cui
  .form-group
  .form-group__text
  input.input--dirty.input--invalid-required.hover,
.cui .form-group .form-group__text input.ng-dirty.ng-invalid-required:focus,
.cui .form-group .form-group__text input.ng-dirty.ng-invalid-required .focus,
.cui .form-group .form-group__text input.ng-dirty.ng-invalid-required:hover,
.cui .form-group .form-group__text input.ng-dirty.ng-invalid-required.hover,
.cui .form-group .form-group__text input.ng-touched.ng-invalid-required:focus,
.cui .form-group .form-group__text input.ng-touched.ng-invalid-required .focus,
.cui .form-group .form-group__text input.ng-touched.ng-invalid-required:hover,
.cui .form-group .form-group__text input.ng-touched.ng-invalid-required.hover,
.cui .form-group .form-group__text input.input--dirty.input--invalid:focus,
.cui .form-group .form-group__text input.input--dirty.input--invalid .focus,
.cui .form-group .form-group__text input.input--dirty.input--invalid:hover,
.cui .form-group .form-group__text input.input--dirty.input--invalid.hover,
.cui .form-group .form-group__text input.ng-dirty.ng-invalid:focus,
.cui .form-group .form-group__text input.ng-dirty.ng-invalid .focus,
.cui .form-group .form-group__text input.ng-dirty.ng-invalid:hover,
.cui .form-group .form-group__text input.ng-dirty.ng-invalid.hover,
.cui .form-group .form-group__text input.ng-touched.ng-invalid:focus,
.cui .form-group .form-group__text input.ng-touched.ng-invalid .focus,
.cui .form-group .form-group__text input.ng-touched.ng-invalid:hover,
.cui .form-group .form-group__text input.ng-touched.ng-invalid.hover,
.cui
  .form-group
  .form-group__text
  select.input--dirty.input--invalid-required:focus,
.cui
  .form-group
  .form-group__text
  select.input--dirty.input--invalid-required
  .focus,
.cui
  .form-group
  .form-group__text
  select.input--dirty.input--invalid-required:hover,
.cui
  .form-group
  .form-group__text
  select.input--dirty.input--invalid-required.hover,
.cui .form-group .form-group__text select.ng-dirty.ng-invalid-required:focus,
.cui .form-group .form-group__text select.ng-dirty.ng-invalid-required .focus,
.cui .form-group .form-group__text select.ng-dirty.ng-invalid-required:hover,
.cui .form-group .form-group__text select.ng-dirty.ng-invalid-required.hover,
.cui .form-group .form-group__text select.ng-touched.ng-invalid-required:focus,
.cui .form-group .form-group__text select.ng-touched.ng-invalid-required .focus,
.cui .form-group .form-group__text select.ng-touched.ng-invalid-required:hover,
.cui .form-group .form-group__text select.ng-touched.ng-invalid-required.hover,
.cui .form-group .form-group__text select.input--dirty.input--invalid:focus,
.cui .form-group .form-group__text select.input--dirty.input--invalid .focus,
.cui .form-group .form-group__text select.input--dirty.input--invalid:hover,
.cui .form-group .form-group__text select.input--dirty.input--invalid.hover,
.cui .form-group .form-group__text select.ng-dirty.ng-invalid:focus,
.cui .form-group .form-group__text select.ng-dirty.ng-invalid .focus,
.cui .form-group .form-group__text select.ng-dirty.ng-invalid:hover,
.cui .form-group .form-group__text select.ng-dirty.ng-invalid.hover,
.cui .form-group .form-group__text select.ng-touched.ng-invalid:focus,
.cui .form-group .form-group__text select.ng-touched.ng-invalid .focus,
.cui .form-group .form-group__text select.ng-touched.ng-invalid:hover,
.cui .form-group .form-group__text select.ng-touched.ng-invalid.hover,
.cui
  .form-group
  .form-group__text
  textarea.input--dirty.input--invalid-required:focus,
.cui
  .form-group
  .form-group__text
  textarea.input--dirty.input--invalid-required
  .focus,
.cui
  .form-group
  .form-group__text
  textarea.input--dirty.input--invalid-required:hover,
.cui
  .form-group
  .form-group__text
  textarea.input--dirty.input--invalid-required.hover,
.cui .form-group .form-group__text textarea.ng-dirty.ng-invalid-required:focus,
.cui .form-group .form-group__text textarea.ng-dirty.ng-invalid-required .focus,
.cui .form-group .form-group__text textarea.ng-dirty.ng-invalid-required:hover,
.cui .form-group .form-group__text textarea.ng-dirty.ng-invalid-required.hover,
.cui
  .form-group
  .form-group__text
  textarea.ng-touched.ng-invalid-required:focus,
.cui
  .form-group
  .form-group__text
  textarea.ng-touched.ng-invalid-required
  .focus,
.cui
  .form-group
  .form-group__text
  textarea.ng-touched.ng-invalid-required:hover,
.cui
  .form-group
  .form-group__text
  textarea.ng-touched.ng-invalid-required.hover,
.cui .form-group .form-group__text textarea.input--dirty.input--invalid:focus,
.cui .form-group .form-group__text textarea.input--dirty.input--invalid .focus,
.cui .form-group .form-group__text textarea.input--dirty.input--invalid:hover,
.cui .form-group .form-group__text textarea.input--dirty.input--invalid.hover,
.cui .form-group .form-group__text textarea.ng-dirty.ng-invalid:focus,
.cui .form-group .form-group__text textarea.ng-dirty.ng-invalid .focus,
.cui .form-group .form-group__text textarea.ng-dirty.ng-invalid:hover,
.cui .form-group .form-group__text textarea.ng-dirty.ng-invalid.hover,
.cui .form-group .form-group__text textarea.ng-touched.ng-invalid:focus,
.cui .form-group .form-group__text textarea.ng-touched.ng-invalid .focus,
.cui .form-group .form-group__text textarea.ng-touched.ng-invalid:hover,
.cui .form-group .form-group__text textarea.ng-touched.ng-invalid.hover,
.cui
  .form-group
  .form-group__text.select
  input.input--dirty.input--invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  input.input--dirty.input--invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  input.input--dirty.input--invalid-required:hover,
.cui
  .form-group
  .form-group__text.select
  input.input--dirty.input--invalid-required.hover,
.cui
  .form-group
  .form-group__text.select
  input.ng-dirty.ng-invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  input.ng-dirty.ng-invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  input.ng-dirty.ng-invalid-required:hover,
.cui
  .form-group
  .form-group__text.select
  input.ng-dirty.ng-invalid-required.hover,
.cui
  .form-group
  .form-group__text.select
  input.ng-touched.ng-invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  input.ng-touched.ng-invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  input.ng-touched.ng-invalid-required:hover,
.cui
  .form-group
  .form-group__text.select
  input.ng-touched.ng-invalid-required.hover,
.cui
  .form-group
  .form-group__text.select
  input.input--dirty.input--invalid:focus,
.cui
  .form-group
  .form-group__text.select
  input.input--dirty.input--invalid
  .focus,
.cui
  .form-group
  .form-group__text.select
  input.input--dirty.input--invalid:hover,
.cui
  .form-group
  .form-group__text.select
  input.input--dirty.input--invalid.hover,
.cui .form-group .form-group__text.select input.ng-dirty.ng-invalid:focus,
.cui .form-group .form-group__text.select input.ng-dirty.ng-invalid .focus,
.cui .form-group .form-group__text.select input.ng-dirty.ng-invalid:hover,
.cui .form-group .form-group__text.select input.ng-dirty.ng-invalid.hover,
.cui .form-group .form-group__text.select input.ng-touched.ng-invalid:focus,
.cui .form-group .form-group__text.select input.ng-touched.ng-invalid .focus,
.cui .form-group .form-group__text.select input.ng-touched.ng-invalid:hover,
.cui .form-group .form-group__text.select input.ng-touched.ng-invalid.hover,
.cui
  .form-group
  .form-group__text.select
  select.input--dirty.input--invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  select.input--dirty.input--invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  select.input--dirty.input--invalid-required:hover,
.cui
  .form-group
  .form-group__text.select
  select.input--dirty.input--invalid-required.hover,
.cui
  .form-group
  .form-group__text.select
  select.ng-dirty.ng-invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  select.ng-dirty.ng-invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  select.ng-dirty.ng-invalid-required:hover,
.cui
  .form-group
  .form-group__text.select
  select.ng-dirty.ng-invalid-required.hover,
.cui
  .form-group
  .form-group__text.select
  select.ng-touched.ng-invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  select.ng-touched.ng-invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  select.ng-touched.ng-invalid-required:hover,
.cui
  .form-group
  .form-group__text.select
  select.ng-touched.ng-invalid-required.hover,
.cui
  .form-group
  .form-group__text.select
  select.input--dirty.input--invalid:focus,
.cui
  .form-group
  .form-group__text.select
  select.input--dirty.input--invalid
  .focus,
.cui
  .form-group
  .form-group__text.select
  select.input--dirty.input--invalid:hover,
.cui
  .form-group
  .form-group__text.select
  select.input--dirty.input--invalid.hover,
.cui .form-group .form-group__text.select select.ng-dirty.ng-invalid:focus,
.cui .form-group .form-group__text.select select.ng-dirty.ng-invalid .focus,
.cui .form-group .form-group__text.select select.ng-dirty.ng-invalid:hover,
.cui .form-group .form-group__text.select select.ng-dirty.ng-invalid.hover,
.cui .form-group .form-group__text.select select.ng-touched.ng-invalid:focus,
.cui .form-group .form-group__text.select select.ng-touched.ng-invalid .focus,
.cui .form-group .form-group__text.select select.ng-touched.ng-invalid:hover,
.cui .form-group .form-group__text.select select.ng-touched.ng-invalid.hover,
.cui
  .form-group
  .form-group__text.select
  textarea.input--dirty.input--invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  textarea.input--dirty.input--invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  textarea.input--dirty.input--invalid-required:hover,
.cui
  .form-group
  .form-group__text.select
  textarea.input--dirty.input--invalid-required.hover,
.cui
  .form-group
  .form-group__text.select
  textarea.ng-dirty.ng-invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  textarea.ng-dirty.ng-invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  textarea.ng-dirty.ng-invalid-required:hover,
.cui
  .form-group
  .form-group__text.select
  textarea.ng-dirty.ng-invalid-required.hover,
.cui
  .form-group
  .form-group__text.select
  textarea.ng-touched.ng-invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  textarea.ng-touched.ng-invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  textarea.ng-touched.ng-invalid-required:hover,
.cui
  .form-group
  .form-group__text.select
  textarea.ng-touched.ng-invalid-required.hover,
.cui
  .form-group
  .form-group__text.select
  textarea.input--dirty.input--invalid:focus,
.cui
  .form-group
  .form-group__text.select
  textarea.input--dirty.input--invalid
  .focus,
.cui
  .form-group
  .form-group__text.select
  textarea.input--dirty.input--invalid:hover,
.cui
  .form-group
  .form-group__text.select
  textarea.input--dirty.input--invalid.hover,
.cui .form-group .form-group__text.select textarea.ng-dirty.ng-invalid:focus,
.cui .form-group .form-group__text.select textarea.ng-dirty.ng-invalid .focus,
.cui .form-group .form-group__text.select textarea.ng-dirty.ng-invalid:hover,
.cui .form-group .form-group__text.select textarea.ng-dirty.ng-invalid.hover,
.cui .form-group .form-group__text.select textarea.ng-touched.ng-invalid:focus,
.cui .form-group .form-group__text.select textarea.ng-touched.ng-invalid .focus,
.cui .form-group .form-group__text.select textarea.ng-touched.ng-invalid:hover,
.cui .form-group .form-group__text.select textarea.ng-touched.ng-invalid.hover {
  border-color: #e2231a;
}
.cui
  .form-group
  .form-group__text
  input.input--dirty.input--invalid-required:focus,
.cui
  .form-group
  .form-group__text
  input.input--dirty.input--invalid-required
  .focus,
.cui .form-group .form-group__text input.ng-dirty.ng-invalid-required:focus,
.cui .form-group .form-group__text input.ng-dirty.ng-invalid-required .focus,
.cui .form-group .form-group__text input.ng-touched.ng-invalid-required:focus,
.cui .form-group .form-group__text input.ng-touched.ng-invalid-required .focus,
.cui .form-group .form-group__text input.input--dirty.input--invalid:focus,
.cui .form-group .form-group__text input.input--dirty.input--invalid .focus,
.cui .form-group .form-group__text input.ng-dirty.ng-invalid:focus,
.cui .form-group .form-group__text input.ng-dirty.ng-invalid .focus,
.cui .form-group .form-group__text input.ng-touched.ng-invalid:focus,
.cui .form-group .form-group__text input.ng-touched.ng-invalid .focus,
.cui
  .form-group
  .form-group__text
  select.input--dirty.input--invalid-required:focus,
.cui
  .form-group
  .form-group__text
  select.input--dirty.input--invalid-required
  .focus,
.cui .form-group .form-group__text select.ng-dirty.ng-invalid-required:focus,
.cui .form-group .form-group__text select.ng-dirty.ng-invalid-required .focus,
.cui .form-group .form-group__text select.ng-touched.ng-invalid-required:focus,
.cui .form-group .form-group__text select.ng-touched.ng-invalid-required .focus,
.cui .form-group .form-group__text select.input--dirty.input--invalid:focus,
.cui .form-group .form-group__text select.input--dirty.input--invalid .focus,
.cui .form-group .form-group__text select.ng-dirty.ng-invalid:focus,
.cui .form-group .form-group__text select.ng-dirty.ng-invalid .focus,
.cui .form-group .form-group__text select.ng-touched.ng-invalid:focus,
.cui .form-group .form-group__text select.ng-touched.ng-invalid .focus,
.cui
  .form-group
  .form-group__text
  textarea.input--dirty.input--invalid-required:focus,
.cui
  .form-group
  .form-group__text
  textarea.input--dirty.input--invalid-required
  .focus,
.cui .form-group .form-group__text textarea.ng-dirty.ng-invalid-required:focus,
.cui .form-group .form-group__text textarea.ng-dirty.ng-invalid-required .focus,
.cui
  .form-group
  .form-group__text
  textarea.ng-touched.ng-invalid-required:focus,
.cui
  .form-group
  .form-group__text
  textarea.ng-touched.ng-invalid-required
  .focus,
.cui .form-group .form-group__text textarea.input--dirty.input--invalid:focus,
.cui .form-group .form-group__text textarea.input--dirty.input--invalid .focus,
.cui .form-group .form-group__text textarea.ng-dirty.ng-invalid:focus,
.cui .form-group .form-group__text textarea.ng-dirty.ng-invalid .focus,
.cui .form-group .form-group__text textarea.ng-touched.ng-invalid:focus,
.cui .form-group .form-group__text textarea.ng-touched.ng-invalid .focus,
.cui
  .form-group
  .form-group__text.select
  input.input--dirty.input--invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  input.input--dirty.input--invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  input.ng-dirty.ng-invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  input.ng-dirty.ng-invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  input.ng-touched.ng-invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  input.ng-touched.ng-invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  input.input--dirty.input--invalid:focus,
.cui
  .form-group
  .form-group__text.select
  input.input--dirty.input--invalid
  .focus,
.cui .form-group .form-group__text.select input.ng-dirty.ng-invalid:focus,
.cui .form-group .form-group__text.select input.ng-dirty.ng-invalid .focus,
.cui .form-group .form-group__text.select input.ng-touched.ng-invalid:focus,
.cui .form-group .form-group__text.select input.ng-touched.ng-invalid .focus,
.cui
  .form-group
  .form-group__text.select
  select.input--dirty.input--invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  select.input--dirty.input--invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  select.ng-dirty.ng-invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  select.ng-dirty.ng-invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  select.ng-touched.ng-invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  select.ng-touched.ng-invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  select.input--dirty.input--invalid:focus,
.cui
  .form-group
  .form-group__text.select
  select.input--dirty.input--invalid
  .focus,
.cui .form-group .form-group__text.select select.ng-dirty.ng-invalid:focus,
.cui .form-group .form-group__text.select select.ng-dirty.ng-invalid .focus,
.cui .form-group .form-group__text.select select.ng-touched.ng-invalid:focus,
.cui .form-group .form-group__text.select select.ng-touched.ng-invalid .focus,
.cui
  .form-group
  .form-group__text.select
  textarea.input--dirty.input--invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  textarea.input--dirty.input--invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  textarea.ng-dirty.ng-invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  textarea.ng-dirty.ng-invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  textarea.ng-touched.ng-invalid-required:focus,
.cui
  .form-group
  .form-group__text.select
  textarea.ng-touched.ng-invalid-required
  .focus,
.cui
  .form-group
  .form-group__text.select
  textarea.input--dirty.input--invalid:focus,
.cui
  .form-group
  .form-group__text.select
  textarea.input--dirty.input--invalid
  .focus,
.cui .form-group .form-group__text.select textarea.ng-dirty.ng-invalid:focus,
.cui .form-group .form-group__text.select textarea.ng-dirty.ng-invalid .focus,
.cui .form-group .form-group__text.select textarea.ng-touched.ng-invalid:focus,
.cui
  .form-group
  .form-group__text.select
  textarea.ng-touched.ng-invalid
  .focus {
  box-shadow: inset 0 0 0 2px rgba(226, 35, 26, 0.25);
}
.cui .form-group.input--compressed .form-group__text input,
.cui .form-group.input--compressed .form-group__text select,
.cui .form-group.input--compressed .form-group__text.select input,
.cui .form-group.input--compressed .form-group__text.select select {
  height: 30px;
  max-height: 30px;
  line-height: 2.4rem;
}
.cui .form-group.input--compressed .form-group__text input,
.cui .form-group.input--compressed .form-group__text select,
.cui .form-group.input--compressed .form-group__text textarea,
.cui .form-group.input--compressed .form-group__text.select input,
.cui .form-group.input--compressed .form-group__text.select select,
.cui .form-group.input--compressed .form-group__text.select textarea {
  min-height: 30px;
  padding: 5px;
}
.cui .form-group.input--compressed .form-group__text select,
.cui .form-group.input--compressed .form-group__text.select select {
  padding: 0 40px 0 5px;
}
.cui .form-group.input--compressed .form-group__text label,
.cui .form-group.input--compressed .form-group__text.select label {
  top: 5px;
}
.cui .form-group.input--compressed .form-group__text:after,
.cui .form-group.input--compressed .form-group__text.select:after {
  bottom: 5px;
}
.cui .form-group.label--floated {
  padding-top: 24px;
}
.cui .form-group.label--floated .form-group__text {
  position: relative;
}
.cui .form-group.label--floated .form-group__text textarea:focus + label,
.cui .form-group.label--floated .form-group__text textarea:valid + label,
.cui .form-group.label--floated .form-group__text input:focus + label,
.cui .form-group.label--floated .form-group__text input:valid + label {
  left: 0;
  font-style: normal;
  -webkit-transform: translate3d(0, -32px, 0);
  transform: translate3d(0, -32px, 0);
  -webkit-transform-origin: left, top;
  transform-origin: left, top;
}
.cui .form-group.label--floated .form-group__text label {
  position: absolute;
  left: 11px;
}
.cui .form-group.label--floated.input--compressed {
  padding-top: 20px;
}
.cui .form-group.label--floated.input--compressed textarea:focus + label,
.cui .form-group.label--floated.input--compressed textarea:valid + label,
.cui .form-group.label--floated.input--compressed input:focus + label,
.cui .form-group.label--floated.input--compressed input:valid + label {
  -webkit-transform: translate3d(0, -26px, 0);
  transform: translate3d(0, -26px, 0);
}
.cui .form-group.label--floated.input--compressed label {
  top: 3px;
  left: 6px;
}
.cui .form-group.label--inline .form-group__text {
  width: 100%;
  flex-direction: row;
  align-items: center;
}
.cui .form-group.label--inline .form-group__text label {
  margin: 0 10px 0 0;
}
.cui .form-group.label--inline .form-group__text.select:after {
  bottom: 10px;
}
.cui .form-group.label--inline .form-group__text textarea ~ label {
  align-self: flex-start;
  margin-top: 8px;
}
.cui .form-group.label--inline .form-group__text label[class^='col-'] {
  height: 40px;
}
.cui
  .form-group.label--inline.input--compressed
  .form-group__text
  label[class^='col-'] {
  height: 30px;
}
.cui .form-group--error .form-group__text,
.cui .form-group--error .form-group__text.select {
  position: relative;
}
.cui .form-group--error .form-group__text input,
.cui .form-group--error .form-group__text select,
.cui .form-group--error .form-group__text textarea,
.cui .form-group--error .form-group__text.select input,
.cui .form-group--error .form-group__text.select select,
.cui .form-group--error .form-group__text.select textarea {
  border-color: #e2231a;
  padding-right: 40px;
}
.cui .form-group--error .form-group__text input:focus,
.cui .form-group--error .form-group__text input .focus,
.cui .form-group--error .form-group__text input:hover,
.cui .form-group--error .form-group__text input.hover,
.cui .form-group--error .form-group__text select:focus,
.cui .form-group--error .form-group__text select .focus,
.cui .form-group--error .form-group__text select:hover,
.cui .form-group--error .form-group__text select.hover,
.cui .form-group--error .form-group__text textarea:focus,
.cui .form-group--error .form-group__text textarea .focus,
.cui .form-group--error .form-group__text textarea:hover,
.cui .form-group--error .form-group__text textarea.hover,
.cui .form-group--error .form-group__text.select input:focus,
.cui .form-group--error .form-group__text.select input .focus,
.cui .form-group--error .form-group__text.select input:hover,
.cui .form-group--error .form-group__text.select input.hover,
.cui .form-group--error .form-group__text.select select:focus,
.cui .form-group--error .form-group__text.select select .focus,
.cui .form-group--error .form-group__text.select select:hover,
.cui .form-group--error .form-group__text.select select.hover,
.cui .form-group--error .form-group__text.select textarea:focus,
.cui .form-group--error .form-group__text.select textarea .focus,
.cui .form-group--error .form-group__text.select textarea:hover,
.cui .form-group--error .form-group__text.select textarea.hover {
  border-color: #e2231a;
}
.cui .form-group--error .form-group__text input:focus,
.cui .form-group--error .form-group__text input .focus,
.cui .form-group--error .form-group__text select:focus,
.cui .form-group--error .form-group__text select .focus,
.cui .form-group--error .form-group__text textarea:focus,
.cui .form-group--error .form-group__text textarea .focus,
.cui .form-group--error .form-group__text.select input:focus,
.cui .form-group--error .form-group__text.select input .focus,
.cui .form-group--error .form-group__text.select select:focus,
.cui .form-group--error .form-group__text.select select .focus,
.cui .form-group--error .form-group__text.select textarea:focus,
.cui .form-group--error .form-group__text.select textarea .focus {
  box-shadow: inset 0 0 0 2px rgba(226, 35, 26, 0.25);
}
.cui .form-group--error .form-group__text:after,
.cui .form-group--error .form-group__text.select:after {
  content: '';
  color: red;
  font-size: 1.6rem;
  bottom: 10px;
  right: 10px;
  position: absolute;
}
.cui .form-group.label--floated .form-group__text {
  min-height: 40px;
}
.cui .form-group.label--floated.input--compressed .form-group__text {
  min-height: 30px;
}
.cui .help-block {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2px;
}
.cui .help-block span[class^='icon-'] + span {
  margin-left: 5px;
}
.cui .help-block--normal {
  text-transform: unset;
}
.cui .help-block--lower {
  text-transform: lowercase;
}
.cui .help-block--capital {
  text-transform: capitalize;
}
.cui .action-block,
.cui .required-block {
  display: none;
}
.cui.cui--compressed .form-group__text input,
.cui.cui--compressed .form-group__text select,
.cui.cui--compressed .form-group__text.select input,
.cui.cui--compressed .form-group__text.select select {
  height: 30px;
  max-height: 30px;
  line-height: 2.4rem;
}
.cui.cui--compressed .form-group__text input,
.cui.cui--compressed .form-group__text select,
.cui.cui--compressed .form-group__text textarea,
.cui.cui--compressed .form-group__text.select input,
.cui.cui--compressed .form-group__text.select select,
.cui.cui--compressed .form-group__text.select textarea {
  min-height: 30px;
  padding: 5px;
}
.cui.cui--compressed .form-group__text select,
.cui.cui--compressed .form-group__text.select select {
  padding: 0 40px 0 5px;
}
.cui.cui--compressed .form-group__text label,
.cui.cui--compressed .form-group__text.select label {
  top: 5px;
}
.cui.cui--compressed .form-group__text:after,
.cui.cui--compressed .form-group__text.select:after {
  bottom: 5px;
}
.cui.cui--compressed .label--inline .form-group__text label[class^='col-'] {
  height: 30px;
}
.cui.cui--animated .form-group__text input,
.cui.cui--animated .form-group__text textarea,
.cui.cui--animated .form-group__text.select input,
.cui.cui--animated .form-group__text.select textarea {
  transition: border-color, box-shadow 0.5s ease;
}
.cui.cui--animated .form-group__text input:focus,
.cui.cui--animated .form-group__text input.focus ~ label,
.cui.cui--animated .form-group__text label {
  transition: all 0.2s linear;
}
.cui.cui--animated .form-group.label--floated .form-group__text label {
  transition: -webkit-transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1),
    -webkit-transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.cui .thumb {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  flex-shrink: 0;
}
.cui .thumb--bordered {
  border: 2px solid #dfdfdf;
}
.cui .thumb--reflected {
  -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(70%, transparent), to(rgba(250, 250, 250, 0.2)));
}
.cui .thumb--raised {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05), 0 5px 6px rgba(0, 0, 0, 0.15);
}
.cui .thumb--square {
  border-radius: 3px;
}
.cui .thumb--small {
  width: 32px;
  height: 32px;
}
.cui .thumb--medium {
  width: 48px;
  height: 48px;
}
.cui .thumb--large {
  width: 64px;
  height: 64px;
}
.cui .thumb--xlarge {
  width: 96px;
  height: 96px;
}
.cui .thumb--huge {
  width: 128px;
  height: 128px;
}
.cui .thumb--goliath {
  width: 256px;
  height: 256px;
}
.cui .thumb--portrait img {
  top: 60%;
  width: 100%;
  height: auto;
}
.cui .thumb img {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .cui select::-ms-expand {
    display: none;
  }
  .cui textarea {
    overflow: auto;
  }
  .cui .label {
    display: inline-block;
    line-height: 2.4rem;
  }
  .cui .label.label--tiny {
    line-height: 1.5rem;
  }
  .cui .label.label--small {
    line-height: 2rem;
  }
  .cui .label.label--large {
    line-height: 3rem;
  }
  .cui ::-ms-clear {
    display: none;
  }
  .cui .form-group {
    min-width: 200px;
  }
  .cui .form-group.label--floated .form-group__text label {
    top: 12px;
  }
  .cui .smart-content__main {
    flex: 1 1 auto;
  }
  .cui .checkbox .checkbox__label,
  .cui .checkbox .radio__label,
  .cui .radio .checkbox__label,
  .cui .radio .radio__label {
    flex: 1 1 auto;
    padding-top: 5px;
  }
  .cui .switch .switch__input {
    display: inline-block;
    height: 100%;
  }
  .cui .figure figcaption {
    text-align: left;
  }
  .cui .header-heading .page-title {
    line-height: 4rem;
  }
  .cui .btn.btn--icon.btn--primary-ghost {
    box-shadow: none;
    border: 1px solid #00bceb;
  }
  .cui button.btn {
    padding-top: 2px;
  }
  .cui .ui-steps .ui-step .step__icon {
    display: inline-block;
    vertical-align: middle;
    line-height: 100%;
  }
  .cui .grid {
    display: flex !important;
    flex-wrap: wrap;
    margin: -5px !important;
  }
  .cui .grid .card {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
    padding: 0 !important;
    margin: 5px !important;
    flex: 0 0;
  }
}
@media screen and (-ms-high-contrast: active) and (min-width: 992px),
  screen and (-ms-high-contrast: none) and (min-width: 992px) {
  .cui .grid.grid--5up .card {
    flex: 0 0 20%;
    max-width: 20%;
    flex-basis: calc(20% - 12px);
  }
  .cui .grid.grid--4up .card {
    flex: 0 0 25%;
    max-width: 25%;
    flex-basis: calc(25% - 12px);
  }
  .cui .grid.grid--3up .card {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    flex-basis: calc(33% - 12px);
  }
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px) and (max-width: 1199.98px),
  screen and (-ms-high-contrast: none) and (min-width: 768px) and (max-width: 1199.98px) {
  .cui .grid.grid--5up .card {
    flex: 0 0 25%;
    max-width: 25%;
    flex-basis: calc(25% - 12px);
  }
  .cui .grid.grid--4up .card,
  .cui .grid.grid--3up .card {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    flex-basis: calc(33% - 12px);
  }
}
@media screen and (-ms-high-contrast: active) and (min-width: 576px) and (max-width: 991.98px),
  screen and (-ms-high-contrast: none) and (min-width: 576px) and (max-width: 991.98px) {
  .cui .grid.grid--5up .card,
  .cui .grid.grid--4up .card,
  .cui .grid.grid--3up .card {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    flex-basis: calc(33% - 12px);
  }
}
@media screen and (-ms-high-contrast: active) and (max-width: 767.98px),
  screen and (-ms-high-contrast: none) and (max-width: 767.98px) {
  .cui .grid.grid--5up .card,
  .cui .grid.grid--4up .card,
  .cui .grid.grid--3up .card {
    flex: 0 0 50%;
    max-width: 50%;
    flex-basis: calc(50% - 12px);
  }
}
@media screen and (-ms-high-contrast: active) and (max-width: 575.98px),
  screen and (-ms-high-contrast: none) and (max-width: 575.98px) {
  .cui .grid.grid--5up .card,
  .cui .grid.grid--4up .card,
  .cui .grid.grid--3up .card {
    flex: 0 0 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.cui .thumb + .activity__body {
  margin-left: 10px;
}

.cui .activity {
  display: flex;
  color: #58585b;
  position: relative;
  background: #ffffff;
  box-shadow: none;
  border: 1px solid transparent;
  padding: 10px;
  font-weight: unset;
}
.cui .activity__title + .cui .activity__description {
  margin: 5px 0;
}
.cui .activity__body {
  color: #58585b;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
}
.cui .activity__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  order: 1;
  flex: 2;
  margin-right: 10px;
}
.cui .activity__timestamp {
  order: 2;
  text-align: left;
  font-size: 1.2rem;
}
@media (min-width: 768px) {
  .cui .activity__timestamp {
    text-align: right;
  }
}
.cui .activity__timestamp--bottom {
  order: 4;
  text-align: left;
  margin-top: 10px;
}
.cui .activity__description {
  order: 3;
  width: 100%;
}
.cui .activity__timestamp,
.cui .activity__title {
  color: #9e9ea2;
}
.cui .activity--bordered {
  border: 1px solid #dfdfdf;
}

.cui .alert {
  color: #58585b;
  position: relative;
  background: transparent;
  box-shadow: none;
  border: 1px solid #00bceb;
  border-left-width: 48px;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  position: relative;
  border-radius: 3px;
  width: 100%;
  margin: 0 0 20px 0;
}
.cui .alert--info {
  color: #58585b;
  position: relative;
  background: transparent;
  box-shadow: none;
  border: 1px solid #00bceb;
  border-left-width: 48px;
}
.cui .alert--info .alert__icon {
  color: #ffffff;
}
.cui .alert--success {
  color: #58585b;
  position: relative;
  background: transparent;
  box-shadow: none;
  border: 1px solid #6ebe4a;
  border-left-width: 48px;
}
.cui .alert--success .alert__icon {
  color: #ffffff;
}
.cui .alert--warning {
  color: #58585b;
  position: relative;
  background: transparent;
  box-shadow: none;
  border: 1px solid #fbab18;
  border-left-width: 48px;
}
.cui .alert--warning .alert__icon {
  color: #ffffff;
}
.cui .alert--danger {
  color: #58585b;
  position: relative;
  background: transparent;
  box-shadow: none;
  border: 1px solid #e2231a;
  border-left-width: 48px;
}
.cui .alert--danger .alert__icon {
  color: #ffffff;
}
.cui .alert__close {
  font-size: 1.8rem;
}
.cui .alert__close:hover {
  text-decoration: none;
}
.cui .alert__icon {
  color: #ffffff;
  position: absolute;
  left: -36px;
  top: 10px;
  font-size: 2.4rem;
}
.cui .alert__icon,
.cui .alert__close {
  align-self: flex-start;
}
.cui .alert__message {
  flex: 1 !important;
  flex-shrink: 1 !important;
  flex-wrap: wrap !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.cui .alert__message a:hover,
.cui .alert__message a:active,
.cui .alert__message a:focus {
  color: #005073;
}

.cui .badge-wrapper {
  position: relative;
  display: inline-block;
}
.cui .badge-wrapper .badge {
  position: absolute;
  display: inline-flex;
  border-radius: 2rem;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 10;
  background: #dfdfdf;
  border: 'unset';
  color: #39393b;
  margin: 1px 0;
  padding: 0;
  font-size: 1.1rem;
  height: 20px;
  line-height: 1.1rem;
  vertical-align: middle;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
}
.cui .badge-wrapper .badge a,
.cui .badge-wrapper .badge a:hover {
  color: #39393b;
}
.cui .badge-wrapper .badge .icon-close {
  font-size: 1.1rem;
}
.cui .badge-wrapper .badge--info {
  background: #64bbe3;
  color: #39393b;
}
.cui .badge-wrapper .badge--info a,
.cui .badge-wrapper .badge--info a:hover {
  color: #39393b;
}
.cui .badge-wrapper .badge--success {
  background: #6ebe4a;
  color: #ffffff;
}
.cui .badge-wrapper .badge--success a,
.cui .badge-wrapper .badge--success a:hover {
  color: #ffffff;
}
.cui .badge-wrapper .badge--warning {
  background: #fbab18;
  color: #39393b;
}
.cui .badge-wrapper .badge--warning a,
.cui .badge-wrapper .badge--warning a:hover {
  color: #39393b;
}
.cui .badge-wrapper .badge--warning-alt {
  background: #ffcc00;
  color: #39393b;
}
.cui .badge-wrapper .badge--warning-alt a,
.cui .badge-wrapper .badge--warning-alt a:hover {
  color: #39393b;
}
.cui .badge-wrapper .badge--danger {
  background: #e2231a;
  color: #ffffff;
}
.cui .badge-wrapper .badge--danger a,
.cui .badge-wrapper .badge--danger a:hover {
  color: #ffffff;
}
.cui .badge-wrapper .badge--blue {
  background: #00bceb;
  color: #005073;
}
.cui .badge-wrapper .badge--blue a,
.cui .badge-wrapper .badge--blue a:hover {
  color: #005073;
}
.cui .badge-wrapper .badge--ltgray {
  background: #f2f2f2;
  color: #005073;
}
.cui .badge-wrapper .badge--ltgray a,
.cui .badge-wrapper .badge--ltgray a:hover {
  color: #005073;
}
.cui .badge-wrapper .badge--mdgray {
  background: #dfdfdf;
  color: #39393b;
}
.cui .badge-wrapper .badge--mdgray a,
.cui .badge-wrapper .badge--mdgray a:hover {
  color: #39393b;
}
.cui .badge-wrapper .badge--dkgray {
  background: #58585b;
  color: #ffffff;
}
.cui .badge-wrapper .badge--dkgray a,
.cui .badge-wrapper .badge--dkgray a:hover {
  color: #ffffff;
}
.cui .badge-wrapper .badge--vibblue {
  background: #017cad;
  color: #ffffff;
}
.cui .badge-wrapper .badge--vibblue a,
.cui .badge-wrapper .badge--vibblue a:hover {
  color: #ffffff;
}
.cui .badge-wrapper .badge--indigo {
  background: #005073;
  color: #ffffff;
}
.cui .badge-wrapper .badge--indigo a,
.cui .badge-wrapper .badge--indigo a:hover {
  color: #ffffff;
}
.cui .badge-wrapper .badge--white {
  background: #ffffff;
  color: #39393b;
}
.cui .badge-wrapper .badge--white a,
.cui .badge-wrapper .badge--white a:hover {
  color: #39393b;
}
.cui .badge-wrapper .badge--ghost {
  background: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.cui .badge-wrapper .badge--ghost a,
.cui .badge-wrapper .badge--ghost a:hover {
  color: #ffffff;
}
.cui .badge-wrapper .badge--tiny {
  margin: 1px 0;
  padding: 0;
  font-size: 0.9rem;
  height: 16px;
  line-height: 0.9rem;
  vertical-align: middle;
  width: 16px;
  min-width: 16px;
  max-width: 16px;
}
.cui .badge-wrapper .badge--tiny .icon-close {
  font-size: 0.9rem;
}
.cui .badge-wrapper .badge--small {
  margin: 1px 0;
  padding: 0;
  font-size: 1.1rem;
  height: 20px;
  line-height: 1.1rem;
  vertical-align: middle;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
}
.cui .badge-wrapper .badge--small .icon-close {
  font-size: 1.1rem;
}
.cui .badge-wrapper .badge--medium {
  margin: 1px 0;
  padding: 0;
  font-size: 1.3rem;
  height: 24px;
  line-height: 1.3rem;
  vertical-align: middle;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
}
.cui .badge-wrapper .badge--medium .icon-close {
  font-size: 1.3rem;
}
.cui .badge-wrapper .badge--large {
  margin: 1px 0;
  padding: 0;
  font-size: 1.4rem;
  height: 28px;
  line-height: 1.4rem;
  vertical-align: middle;
  width: 28px;
  min-width: 28px;
  max-width: 28px;
}
.cui .badge-wrapper .badge--large .icon-close {
  font-size: 1.4rem;
}
.cui .badge-wrapper .badge--bordered {
  border: 1px solid #dfdfdf;
  border-width: 2px;
}
.cui .badge-wrapper .badge--bordered-alt {
  border: 1px solid #ffffff;
  border-width: 2px;
}
.cui .badge-wrapper span[class^='icon-'] + .badge {
  top: -3px;
  right: -7px;
}
.cui .badge-wrapper span[class^='icon-'].icon-small + .badge.badge--tiny {
  top: 5.5px;
  right: -8px;
}
.cui .badge-wrapper span[class^='icon-'].icon-small + .badge.badge--small {
  top: 3.5px;
  right: -10px;
}
.cui .badge-wrapper span[class^='icon-'].icon-small + .badge.badge--medium {
  top: 1.5px;
  right: -12px;
}
.cui .badge-wrapper span[class^='icon-'].icon-small + .badge.badge--large {
  top: -0.5px;
  right: -14px;
}
.cui .badge-wrapper span[class^='icon-'].icon-medium + .badge.badge--tiny {
  top: 13px;
  right: -8px;
}
.cui .badge-wrapper span[class^='icon-'].icon-medium + .badge.badge--small {
  top: 11px;
  right: -10px;
}
.cui .badge-wrapper span[class^='icon-'].icon-medium + .badge.badge--medium {
  top: 9px;
  right: -12px;
}
.cui .badge-wrapper span[class^='icon-'].icon-medium + .badge.badge--large {
  top: 7px;
  right: -14px;
}
.cui .badge-wrapper span[class^='icon-'].icon-large + .badge.badge--tiny {
  top: 34px;
  right: -8px;
}
.cui .badge-wrapper span[class^='icon-'].icon-large + .badge.badge--small {
  top: 32px;
  right: -10px;
}
.cui .badge-wrapper span[class^='icon-'].icon-large + .badge.badge--medium {
  top: 30px;
  right: -12px;
}
.cui .badge-wrapper span[class^='icon-'].icon-large + .badge.badge--large {
  top: 28px;
  right: -14px;
}
.cui .badge-wrapper .thumb + .badge {
  top: 14px;
  right: -10px;
}
.cui .badge-wrapper .thumb.thumb--small + .badge.badge--tiny {
  top: 8px;
  right: -8px;
}
.cui .badge-wrapper .thumb.thumb--small + .badge.badge--small {
  top: 6px;
  right: -10px;
}
.cui .badge-wrapper .thumb.thumb--small + .badge.badge--medium {
  top: 4px;
  right: -12px;
}
.cui .badge-wrapper .thumb.thumb--small + .badge.badge--large {
  top: 2px;
  right: -14px;
}
.cui .badge-wrapper .thumb.thumb--medium + .badge.badge--tiny {
  top: 16px;
  right: -8px;
}
.cui .badge-wrapper .thumb.thumb--medium + .badge.badge--small {
  top: 14px;
  right: -10px;
}
.cui .badge-wrapper .thumb.thumb--medium + .badge.badge--medium {
  top: 12px;
  right: -12px;
}
.cui .badge-wrapper .thumb.thumb--medium + .badge.badge--large {
  top: 10px;
  right: -14px;
}
.cui .badge-wrapper .thumb.thumb--large + .badge.badge--tiny {
  top: 24px;
  right: -8px;
}
.cui .badge-wrapper .thumb.thumb--large + .badge.badge--small {
  top: 22px;
  right: -10px;
}
.cui .badge-wrapper .thumb.thumb--large + .badge.badge--medium {
  top: 20px;
  right: -12px;
}
.cui .badge-wrapper .thumb.thumb--large + .badge.badge--large {
  top: 18px;
  right: -14px;
}
.cui .badge-wrapper .thumb.thumb--xlarge + .badge.badge--tiny {
  top: 40px;
  right: -8px;
}
.cui .badge-wrapper .thumb.thumb--xlarge + .badge.badge--small {
  top: 38px;
  right: -10px;
}
.cui .badge-wrapper .thumb.thumb--xlarge + .badge.badge--medium {
  top: 36px;
  right: -12px;
}
.cui .badge-wrapper .thumb.thumb--xlarge + .badge.badge--large {
  top: 34px;
  right: -14px;
}
.cui .badge-wrapper .thumb.thumb--huge + .badge.badge--tiny {
  top: 56px;
  right: -8px;
}
.cui .badge-wrapper .thumb.thumb--huge + .badge.badge--small {
  top: 54px;
  right: -10px;
}
.cui .badge-wrapper .thumb.thumb--huge + .badge.badge--medium {
  top: 52px;
  right: -12px;
}
.cui .badge-wrapper .thumb.thumb--huge + .badge.badge--large {
  top: 50px;
  right: -14px;
}
.cui .badge-wrapper .thumb.thumb--goliath + .badge.badge--tiny {
  top: 120px;
  right: -8px;
}
.cui .badge-wrapper .thumb.thumb--goliath + .badge.badge--small {
  top: 118px;
  right: -10px;
}
.cui .badge-wrapper .thumb.thumb--goliath + .badge.badge--medium {
  top: 116px;
  right: -12px;
}
.cui .badge-wrapper .thumb.thumb--goliath + .badge.badge--large {
  top: 114px;
  right: -14px;
}

.cui .breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;
  background: 0 none;
  display: inline-flex;
}
.cui .breadcrumb:after {
  clear: both !important;
  content: '' !important;
  display: table !important;
}
.cui .breadcrumb ul:not(:first-child) {
  padding-left: 20px;
}
.cui .breadcrumb > li.active {
  color: #58585b;
}
.cui .breadcrumb > li + li:before {
  padding: 0 2px 0 6px;
  color: #58585b;
  content: '/ ';
}
.cui .breadcrumb > li span[class^='icon-'] {
  line-height: 1.4rem;
}

.cui .card,
.cui a.card {
  color: #58585b;
  position: relative;
  background: #ffffff;
  box-shadow: none;
  border: 1px solid #dfdfdf;
  display: block;
}
.cui .card__header,
.cui .card__body,
.cui a.card__header,
.cui a.card__body {
  color: #58585b;
  position: relative;
  background: #ffffff;
  box-shadow: none;
  border: 1px solid transparent;
  padding: 10px;
}
.cui .card__body,
.cui a.card__body {
  margin-top: 10px;
  margin-bottom: 10px;
}
.cui .card__header,
.cui a.card__header {
  border-bottom: 1px solid #dfdfdf;
}
.cui .card__header .card__header__title,
.cui a.card__header .card__header__title {
  font-size: 1.6rem;
}
.cui .card__header .card__header__subtitle,
.cui a.card__header .card__header__subtitle {
  font-size: 1.2rem;
}
.cui .card__footer,
.cui a.card__footer {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px;
  border-top: 1px solid #dfdfdf;
  background-color: #f2f2f2;
}
.cui .card__footer .card__footer__item,
.cui a.card__footer .card__footer__item {
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  min-height: 30px;
}
.cui .card__footer .card__footer__item a,
.cui a.card__footer .card__footer__item a {
  font-size: 2rem;
  margin: 0 10px;
}
.cui .card__footer .card__footer__item a .subtext,
.cui a.card__footer .card__footer__item a .subtext {
  color: #58585b;
  font-size: 1rem;
  white-space: nowrap;
  margin-top: 3px;
  text-transform: uppercase;
}
.cui .card__footer .card__footer__item a:hover,
.cui .card__footer .card__footer__item a:active,
.cui .card__footer .card__footer__item a:focus,
.cui a.card__footer .card__footer__item a:hover,
.cui a.card__footer .card__footer__item a:active,
.cui a.card__footer .card__footer__item a:focus {
  text-decoration: none;
}
.cui .card__footer .card__footer__item a:hover .subtext,
.cui .card__footer .card__footer__item a:active .subtext,
.cui .card__footer .card__footer__item a:focus .subtext,
.cui a.card__footer .card__footer__item a:hover .subtext,
.cui a.card__footer .card__footer__item a:active .subtext,
.cui a.card__footer .card__footer__item a:focus .subtext {
  color: #005073;
}
.cui .card.card--raised,
.cui a.card.card--raised {
  box-shadow: 0 10px 24px -6px rgba(0, 0, 0, 0.25);
}
.cui .card.hover,
.cui .card.selected,
.cui a.card.hover,
.cui a.card.selected {
  cursor: pointer;
  border-color: #00bceb;
}
.cui .card.selected:before,
.cui a.card.selected:before {
  background: rgba(0, 188, 235, 0.2);
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.cui .card__header + .card__footer {
  border-top: 0 none;
}

.cui.cui--animated a.card.selected:before,
.cui.cui--animated .card.selected:before {
  -webkit-animation: fade-in 0.15s 1 linear;
  animation: fade-in 0.15s 1 linear;
}

.cui .carousel__slide {
  display: none;
}
.cui .carousel__slide.active {
  display: block;
}

.cui .carousel__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
  margin-top: 20px;
}
.cui .carousel__controls .back,
.cui .carousel__controls .next {
  color: #00bceb;
}
.cui .carousel__controls .back:hover,
.cui .carousel__controls .next:hover {
  text-decoration: none;
}
.cui .carousel__controls .back {
  margin-right: 10px;
}
.cui .carousel__controls .next {
  margin-left: 10px;
}
.cui .carousel__controls .back.disabled,
.cui .carousel__controls .next.disabled {
  color: #9e9ea2;
}
.cui .carousel__controls .dot {
  margin: 0 2px;
  font-size: 1.2rem;
  color: #c6c7ca;
  transition: color 0.5s;
}
.cui .carousel__controls .dot:hover,
.cui .carousel__controls .dot.selected > span,
.cui .carousel__controls .dot.active > span {
  color: #00bceb;
  text-decoration: none;
}

.cui .carousel--indigo .carousel__controls .back,
.cui .carousel--indigo .carousel__controls .next,
.cui .carousel--indigo .carousel__controls .dot:hover,
.cui .carousel--indigo .carousel__controls .dot.selected > span,
.cui .carousel--indigo .carousel__controls .dot.active > span {
  color: #005073;
}

.cui dl,
.cui dt,
.cui dd {
  margin: 0;
}

.cui dt {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}

.cui dd {
  margin-bottom: 10px;
}

.cui dl.dl--skinny dt {
  width: 100px;
}

.cui dl.dl--compressed dd {
  margin-bottom: 0;
}

.cui .dl--inline,
.cui .dl--inline-centered,
.cui .dl--inline-wrap,
.cui .dl--horizontal {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cui .dl--inline:after,
.cui .dl--inline-centered:after,
.cui .dl--inline-wrap:after,
.cui .dl--horizontal:after {
  clear: both !important;
  content: '' !important;
  display: table !important;
}
.cui .dl--inline dt,
.cui .dl--inline-centered dt,
.cui .dl--inline-wrap dt,
.cui .dl--horizontal dt {
  width: auto;
  float: none;
  padding-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 240px;
  padding-right: 20px;
  float: left;
  clear: left;
}
.cui .dl--inline dd,
.cui .dl--inline-centered dd,
.cui .dl--inline-wrap dd,
.cui .dl--horizontal dd {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: none;
  margin-bottom: 10px;
  margin-bottom: 0;
  float: left;
}

.cui .dl--inline-centered dt {
  text-align: left;
  text-align: right;
}

.cui .dl--inline-wrap dd {
  white-space: normal;
  float: none;
}

.cui .dl--inline-xsmall dt {
  width: 80px;
}

.cui .dl--inline-small dt {
  width: 120px;
}

.cui .dl--inline-medium dt {
  width: 240px;
}

.cui .dl--inline-large dt {
  width: 360px;
}

.cui .dl--inline-xlarge dt {
  width: 480px;
}

.cui .figure {
  margin: 0 0 10px 0;
  padding: 20px;
}
.cui .figure img + figcaption,
.cui .figure figcaption + img {
  margin: 10px 0 0 0;
}
.cui .figure--horizontal img {
  margin: 0 0 0 10px;
}
.cui .figure--horizontal {
  display: flex;
  flex-direction: row;
}
.cui .figure--horizontal img {
  max-width: 50%;
  align-self: flex-start;
}
.cui .figure--compressed {
  margin: 0 0 5px 0;
  padding: 10px;
}
.cui .figure--compressed img + figcaption,
.cui .figure--compressed figcaption + img {
  margin: 5px 0 0 0;
}
.cui .figure--compressed--horizontal img {
  margin: 0 0 0 5px;
}
.cui .figure--regular {
  margin: 0 0 10px 0;
  padding: 20px;
}
.cui .figure--regular img + figcaption,
.cui .figure--regular figcaption + img {
  margin: 10px 0 0 0;
}
.cui .figure--regular--horizontal img {
  margin: 0 0 0 10px;
}
.cui .figure--loose {
  margin: 0 0 20px 0;
  padding: 40px;
}
.cui .figure--loose img + figcaption,
.cui .figure--loose figcaption + img {
  margin: 20px 0 0 0;
}
.cui .figure--loose--horizontal img {
  margin: 0 0 0 20px;
}
.cui .figure--bordered {
  border: 1px solid #dfdfdf;
}
.cui .figure--highlight figcaption {
  padding-left: 10px;
  border-left: 4px solid #00bceb;
}

.cui .gauge {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
  background-color: #dfdfdf;
  border-radius: 50%;
}
.cui .gauge .gauge__circle .mask .fill {
  background-color: #58585b;
}
.cui .gauge .gauge__inset .gauge__percentage {
  color: #58585b;
}
.cui .gauge .gauge__circle .mask,
.cui .gauge .gauge__circle .fill,
.cui .gauge .gauge__circle .shadow {
  width: 120px;
  height: 120px;
  position: absolute;
  border-radius: 50%;
}
.cui .gauge .gauge__circle .mask,
.cui .gauge .gauge__circle .fill {
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  border-radius: 50%;
}
.cui .gauge .gauge__circle .mask {
  clip: rect(0px, 120px, 120px, 60px);
}
.cui .gauge .gauge__circle .mask .fill {
  clip: rect(0px, 60px, 120px, 0px);
}
.cui .gauge .gauge__inset {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  position: absolute;
  margin-left: 5px;
  margin-top: 5px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: inset 0 0 0 2px #f5f5f6;
  background-color: #ffffff;
}
.cui .gauge .gauge__inset .gauge__percentage {
  font-size: 3.5rem;
  font-weight: 200;
}
.cui .gauge[data-percentage='1'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='1'] .gauge__circle .fill {
  -webkit-transform: rotate(1.8deg);
  transform: rotate(1.8deg);
}
.cui .gauge[data-percentage='1'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.cui .gauge[data-percentage='2'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='2'] .gauge__circle .fill {
  -webkit-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.cui .gauge[data-percentage='2'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.cui .gauge[data-percentage='3'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='3'] .gauge__circle .fill {
  -webkit-transform: rotate(5.4deg);
  transform: rotate(5.4deg);
}
.cui .gauge[data-percentage='3'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.cui .gauge[data-percentage='4'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='4'] .gauge__circle .fill {
  -webkit-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.cui .gauge[data-percentage='4'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.cui .gauge[data-percentage='5'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='5'] .gauge__circle .fill {
  -webkit-transform: rotate(9deg);
  transform: rotate(9deg);
}
.cui .gauge[data-percentage='5'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
}
.cui .gauge[data-percentage='6'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='6'] .gauge__circle .fill {
  -webkit-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.cui .gauge[data-percentage='6'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.cui .gauge[data-percentage='7'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='7'] .gauge__circle .fill {
  -webkit-transform: rotate(12.6deg);
  transform: rotate(12.6deg);
}
.cui .gauge[data-percentage='7'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.cui .gauge[data-percentage='8'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='8'] .gauge__circle .fill {
  -webkit-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.cui .gauge[data-percentage='8'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.cui .gauge[data-percentage='9'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='9'] .gauge__circle .fill {
  -webkit-transform: rotate(16.2deg);
  transform: rotate(16.2deg);
}
.cui .gauge[data-percentage='9'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.cui .gauge[data-percentage='10'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='10'] .gauge__circle .fill {
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
}
.cui .gauge[data-percentage='10'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
}
.cui .gauge[data-percentage='11'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='11'] .gauge__circle .fill {
  -webkit-transform: rotate(19.8deg);
  transform: rotate(19.8deg);
}
.cui .gauge[data-percentage='11'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.cui .gauge[data-percentage='12'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='12'] .gauge__circle .fill {
  -webkit-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.cui .gauge[data-percentage='12'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.cui .gauge[data-percentage='13'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='13'] .gauge__circle .fill {
  -webkit-transform: rotate(23.4deg);
  transform: rotate(23.4deg);
}
.cui .gauge[data-percentage='13'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.cui .gauge[data-percentage='14'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='14'] .gauge__circle .fill {
  -webkit-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.cui .gauge[data-percentage='14'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.cui .gauge[data-percentage='15'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='15'] .gauge__circle .fill {
  -webkit-transform: rotate(27deg);
  transform: rotate(27deg);
}
.cui .gauge[data-percentage='15'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(54deg);
  transform: rotate(54deg);
}
.cui .gauge[data-percentage='16'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='16'] .gauge__circle .fill {
  -webkit-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.cui .gauge[data-percentage='16'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.cui .gauge[data-percentage='17'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='17'] .gauge__circle .fill {
  -webkit-transform: rotate(30.6deg);
  transform: rotate(30.6deg);
}
.cui .gauge[data-percentage='17'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.cui .gauge[data-percentage='18'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='18'] .gauge__circle .fill {
  -webkit-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.cui .gauge[data-percentage='18'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.cui .gauge[data-percentage='19'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='19'] .gauge__circle .fill {
  -webkit-transform: rotate(34.2deg);
  transform: rotate(34.2deg);
}
.cui .gauge[data-percentage='19'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.cui .gauge[data-percentage='20'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='20'] .gauge__circle .fill {
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
}
.cui .gauge[data-percentage='20'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
}
.cui .gauge[data-percentage='21'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='21'] .gauge__circle .fill {
  -webkit-transform: rotate(37.8deg);
  transform: rotate(37.8deg);
}
.cui .gauge[data-percentage='21'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.cui .gauge[data-percentage='22'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='22'] .gauge__circle .fill {
  -webkit-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.cui .gauge[data-percentage='22'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.cui .gauge[data-percentage='23'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='23'] .gauge__circle .fill {
  -webkit-transform: rotate(41.4deg);
  transform: rotate(41.4deg);
}
.cui .gauge[data-percentage='23'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.cui .gauge[data-percentage='24'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='24'] .gauge__circle .fill {
  -webkit-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.cui .gauge[data-percentage='24'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.cui .gauge[data-percentage='25'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='25'] .gauge__circle .fill {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.cui .gauge[data-percentage='25'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.cui .gauge[data-percentage='26'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='26'] .gauge__circle .fill {
  -webkit-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.cui .gauge[data-percentage='26'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.cui .gauge[data-percentage='27'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='27'] .gauge__circle .fill {
  -webkit-transform: rotate(48.6deg);
  transform: rotate(48.6deg);
}
.cui .gauge[data-percentage='27'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.cui .gauge[data-percentage='28'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='28'] .gauge__circle .fill {
  -webkit-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.cui .gauge[data-percentage='28'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.cui .gauge[data-percentage='29'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='29'] .gauge__circle .fill {
  -webkit-transform: rotate(52.2deg);
  transform: rotate(52.2deg);
}
.cui .gauge[data-percentage='29'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.cui .gauge[data-percentage='30'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='30'] .gauge__circle .fill {
  -webkit-transform: rotate(54deg);
  transform: rotate(54deg);
}
.cui .gauge[data-percentage='30'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
}
.cui .gauge[data-percentage='31'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='31'] .gauge__circle .fill {
  -webkit-transform: rotate(55.8deg);
  transform: rotate(55.8deg);
}
.cui .gauge[data-percentage='31'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.cui .gauge[data-percentage='32'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='32'] .gauge__circle .fill {
  -webkit-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.cui .gauge[data-percentage='32'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.cui .gauge[data-percentage='33'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='33'] .gauge__circle .fill {
  -webkit-transform: rotate(59.4deg);
  transform: rotate(59.4deg);
}
.cui .gauge[data-percentage='33'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.cui .gauge[data-percentage='34'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='34'] .gauge__circle .fill {
  -webkit-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.cui .gauge[data-percentage='34'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.cui .gauge[data-percentage='35'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='35'] .gauge__circle .fill {
  -webkit-transform: rotate(63deg);
  transform: rotate(63deg);
}
.cui .gauge[data-percentage='35'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(126deg);
  transform: rotate(126deg);
}
.cui .gauge[data-percentage='36'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='36'] .gauge__circle .fill {
  -webkit-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.cui .gauge[data-percentage='36'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.cui .gauge[data-percentage='37'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='37'] .gauge__circle .fill {
  -webkit-transform: rotate(66.6deg);
  transform: rotate(66.6deg);
}
.cui .gauge[data-percentage='37'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.cui .gauge[data-percentage='38'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='38'] .gauge__circle .fill {
  -webkit-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.cui .gauge[data-percentage='38'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.cui .gauge[data-percentage='39'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='39'] .gauge__circle .fill {
  -webkit-transform: rotate(70.2deg);
  transform: rotate(70.2deg);
}
.cui .gauge[data-percentage='39'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.cui .gauge[data-percentage='40'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='40'] .gauge__circle .fill {
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
}
.cui .gauge[data-percentage='40'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
}
.cui .gauge[data-percentage='41'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='41'] .gauge__circle .fill {
  -webkit-transform: rotate(73.8deg);
  transform: rotate(73.8deg);
}
.cui .gauge[data-percentage='41'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.cui .gauge[data-percentage='42'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='42'] .gauge__circle .fill {
  -webkit-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.cui .gauge[data-percentage='42'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.cui .gauge[data-percentage='43'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='43'] .gauge__circle .fill {
  -webkit-transform: rotate(77.4deg);
  transform: rotate(77.4deg);
}
.cui .gauge[data-percentage='43'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.cui .gauge[data-percentage='44'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='44'] .gauge__circle .fill {
  -webkit-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.cui .gauge[data-percentage='44'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.cui .gauge[data-percentage='45'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='45'] .gauge__circle .fill {
  -webkit-transform: rotate(81deg);
  transform: rotate(81deg);
}
.cui .gauge[data-percentage='45'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(162deg);
  transform: rotate(162deg);
}
.cui .gauge[data-percentage='46'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='46'] .gauge__circle .fill {
  -webkit-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.cui .gauge[data-percentage='46'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.cui .gauge[data-percentage='47'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='47'] .gauge__circle .fill {
  -webkit-transform: rotate(84.6deg);
  transform: rotate(84.6deg);
}
.cui .gauge[data-percentage='47'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.cui .gauge[data-percentage='48'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='48'] .gauge__circle .fill {
  -webkit-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.cui .gauge[data-percentage='48'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.cui .gauge[data-percentage='49'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='49'] .gauge__circle .fill {
  -webkit-transform: rotate(88.2deg);
  transform: rotate(88.2deg);
}
.cui .gauge[data-percentage='49'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.cui .gauge[data-percentage='50'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='50'] .gauge__circle .fill {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.cui .gauge[data-percentage='50'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cui .gauge[data-percentage='51'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='51'] .gauge__circle .fill {
  -webkit-transform: rotate(91.8deg);
  transform: rotate(91.8deg);
}
.cui .gauge[data-percentage='51'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}
.cui .gauge[data-percentage='52'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='52'] .gauge__circle .fill {
  -webkit-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.cui .gauge[data-percentage='52'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
}
.cui .gauge[data-percentage='53'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='53'] .gauge__circle .fill {
  -webkit-transform: rotate(95.4deg);
  transform: rotate(95.4deg);
}
.cui .gauge[data-percentage='53'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}
.cui .gauge[data-percentage='54'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='54'] .gauge__circle .fill {
  -webkit-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.cui .gauge[data-percentage='54'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}
.cui .gauge[data-percentage='55'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='55'] .gauge__circle .fill {
  -webkit-transform: rotate(99deg);
  transform: rotate(99deg);
}
.cui .gauge[data-percentage='55'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(198deg);
  transform: rotate(198deg);
}
.cui .gauge[data-percentage='56'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='56'] .gauge__circle .fill {
  -webkit-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.cui .gauge[data-percentage='56'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}
.cui .gauge[data-percentage='57'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='57'] .gauge__circle .fill {
  -webkit-transform: rotate(102.6deg);
  transform: rotate(102.6deg);
}
.cui .gauge[data-percentage='57'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
}
.cui .gauge[data-percentage='58'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='58'] .gauge__circle .fill {
  -webkit-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.cui .gauge[data-percentage='58'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}
.cui .gauge[data-percentage='59'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='59'] .gauge__circle .fill {
  -webkit-transform: rotate(106.2deg);
  transform: rotate(106.2deg);
}
.cui .gauge[data-percentage='59'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}
.cui .gauge[data-percentage='60'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='60'] .gauge__circle .fill {
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
}
.cui .gauge[data-percentage='60'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(216deg);
  transform: rotate(216deg);
}
.cui .gauge[data-percentage='61'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='61'] .gauge__circle .fill {
  -webkit-transform: rotate(109.8deg);
  transform: rotate(109.8deg);
}
.cui .gauge[data-percentage='61'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}
.cui .gauge[data-percentage='62'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='62'] .gauge__circle .fill {
  -webkit-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.cui .gauge[data-percentage='62'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
}
.cui .gauge[data-percentage='63'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='63'] .gauge__circle .fill {
  -webkit-transform: rotate(113.4deg);
  transform: rotate(113.4deg);
}
.cui .gauge[data-percentage='63'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}
.cui .gauge[data-percentage='64'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='64'] .gauge__circle .fill {
  -webkit-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.cui .gauge[data-percentage='64'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}
.cui .gauge[data-percentage='65'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='65'] .gauge__circle .fill {
  -webkit-transform: rotate(117deg);
  transform: rotate(117deg);
}
.cui .gauge[data-percentage='65'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(234deg);
  transform: rotate(234deg);
}
.cui .gauge[data-percentage='66'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='66'] .gauge__circle .fill {
  -webkit-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.cui .gauge[data-percentage='66'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}
.cui .gauge[data-percentage='67'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='67'] .gauge__circle .fill {
  -webkit-transform: rotate(120.6deg);
  transform: rotate(120.6deg);
}
.cui .gauge[data-percentage='67'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
}
.cui .gauge[data-percentage='68'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='68'] .gauge__circle .fill {
  -webkit-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.cui .gauge[data-percentage='68'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}
.cui .gauge[data-percentage='69'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='69'] .gauge__circle .fill {
  -webkit-transform: rotate(124.2deg);
  transform: rotate(124.2deg);
}
.cui .gauge[data-percentage='69'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}
.cui .gauge[data-percentage='70'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='70'] .gauge__circle .fill {
  -webkit-transform: rotate(126deg);
  transform: rotate(126deg);
}
.cui .gauge[data-percentage='70'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(252deg);
  transform: rotate(252deg);
}
.cui .gauge[data-percentage='71'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='71'] .gauge__circle .fill {
  -webkit-transform: rotate(127.8deg);
  transform: rotate(127.8deg);
}
.cui .gauge[data-percentage='71'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}
.cui .gauge[data-percentage='72'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='72'] .gauge__circle .fill {
  -webkit-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.cui .gauge[data-percentage='72'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}
.cui .gauge[data-percentage='73'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='73'] .gauge__circle .fill {
  -webkit-transform: rotate(131.4deg);
  transform: rotate(131.4deg);
}
.cui .gauge[data-percentage='73'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}
.cui .gauge[data-percentage='74'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='74'] .gauge__circle .fill {
  -webkit-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.cui .gauge[data-percentage='74'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
}
.cui .gauge[data-percentage='75'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='75'] .gauge__circle .fill {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.cui .gauge[data-percentage='75'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.cui .gauge[data-percentage='76'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='76'] .gauge__circle .fill {
  -webkit-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.cui .gauge[data-percentage='76'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}
.cui .gauge[data-percentage='77'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='77'] .gauge__circle .fill {
  -webkit-transform: rotate(138.6deg);
  transform: rotate(138.6deg);
}
.cui .gauge[data-percentage='77'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}
.cui .gauge[data-percentage='78'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='78'] .gauge__circle .fill {
  -webkit-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.cui .gauge[data-percentage='78'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}
.cui .gauge[data-percentage='79'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='79'] .gauge__circle .fill {
  -webkit-transform: rotate(142.2deg);
  transform: rotate(142.2deg);
}
.cui .gauge[data-percentage='79'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
}
.cui .gauge[data-percentage='80'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='80'] .gauge__circle .fill {
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
}
.cui .gauge[data-percentage='80'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(288deg);
  transform: rotate(288deg);
}
.cui .gauge[data-percentage='81'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='81'] .gauge__circle .fill {
  -webkit-transform: rotate(145.8deg);
  transform: rotate(145.8deg);
}
.cui .gauge[data-percentage='81'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}
.cui .gauge[data-percentage='82'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='82'] .gauge__circle .fill {
  -webkit-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.cui .gauge[data-percentage='82'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}
.cui .gauge[data-percentage='83'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='83'] .gauge__circle .fill {
  -webkit-transform: rotate(149.4deg);
  transform: rotate(149.4deg);
}
.cui .gauge[data-percentage='83'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}
.cui .gauge[data-percentage='84'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='84'] .gauge__circle .fill {
  -webkit-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.cui .gauge[data-percentage='84'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
}
.cui .gauge[data-percentage='85'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='85'] .gauge__circle .fill {
  -webkit-transform: rotate(153deg);
  transform: rotate(153deg);
}
.cui .gauge[data-percentage='85'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(306deg);
  transform: rotate(306deg);
}
.cui .gauge[data-percentage='86'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='86'] .gauge__circle .fill {
  -webkit-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.cui .gauge[data-percentage='86'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}
.cui .gauge[data-percentage='87'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='87'] .gauge__circle .fill {
  -webkit-transform: rotate(156.6deg);
  transform: rotate(156.6deg);
}
.cui .gauge[data-percentage='87'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}
.cui .gauge[data-percentage='88'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='88'] .gauge__circle .fill {
  -webkit-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.cui .gauge[data-percentage='88'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}
.cui .gauge[data-percentage='89'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='89'] .gauge__circle .fill {
  -webkit-transform: rotate(160.2deg);
  transform: rotate(160.2deg);
}
.cui .gauge[data-percentage='89'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
}
.cui .gauge[data-percentage='90'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='90'] .gauge__circle .fill {
  -webkit-transform: rotate(162deg);
  transform: rotate(162deg);
}
.cui .gauge[data-percentage='90'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(324deg);
  transform: rotate(324deg);
}
.cui .gauge[data-percentage='91'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='91'] .gauge__circle .fill {
  -webkit-transform: rotate(163.8deg);
  transform: rotate(163.8deg);
}
.cui .gauge[data-percentage='91'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}
.cui .gauge[data-percentage='92'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='92'] .gauge__circle .fill {
  -webkit-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.cui .gauge[data-percentage='92'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}
.cui .gauge[data-percentage='93'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='93'] .gauge__circle .fill {
  -webkit-transform: rotate(167.4deg);
  transform: rotate(167.4deg);
}
.cui .gauge[data-percentage='93'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}
.cui .gauge[data-percentage='94'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='94'] .gauge__circle .fill {
  -webkit-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.cui .gauge[data-percentage='94'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
}
.cui .gauge[data-percentage='95'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='95'] .gauge__circle .fill {
  -webkit-transform: rotate(171deg);
  transform: rotate(171deg);
}
.cui .gauge[data-percentage='95'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(342deg);
  transform: rotate(342deg);
}
.cui .gauge[data-percentage='96'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='96'] .gauge__circle .fill {
  -webkit-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.cui .gauge[data-percentage='96'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}
.cui .gauge[data-percentage='97'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='97'] .gauge__circle .fill {
  -webkit-transform: rotate(174.6deg);
  transform: rotate(174.6deg);
}
.cui .gauge[data-percentage='97'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}
.cui .gauge[data-percentage='98'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='98'] .gauge__circle .fill {
  -webkit-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.cui .gauge[data-percentage='98'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}
.cui .gauge[data-percentage='99'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='99'] .gauge__circle .fill {
  -webkit-transform: rotate(178.2deg);
  transform: rotate(178.2deg);
}
.cui .gauge[data-percentage='99'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
}
.cui .gauge[data-percentage='100'] .gauge__circle .mask.full,
.cui .gauge[data-percentage='100'] .gauge__circle .fill {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cui .gauge[data-percentage='100'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.cui .gauge--primary .gauge__circle .mask .fill {
  background-color: #00bceb;
}
.cui .gauge--primary .gauge__inset .gauge__percentage {
  color: #00bceb;
}
.cui .gauge--default .gauge__circle .mask .fill {
  background-color: #888;
}
.cui .gauge--default .gauge__inset .gauge__percentage {
  color: #888;
}
.cui .gauge--blue .gauge__circle .mask .fill {
  background-color: #00bceb;
}
.cui .gauge--blue .gauge__inset .gauge__percentage {
  color: #00bceb;
}
.cui .gauge--success .gauge__circle .mask .fill {
  background-color: #6ebe4a;
}
.cui .gauge--success .gauge__inset .gauge__percentage {
  color: #6ebe4a;
}
.cui .gauge--danger .gauge__circle .mask .fill {
  background-color: #e2231a;
}
.cui .gauge--danger .gauge__inset .gauge__percentage {
  color: #e2231a;
}
.cui .gauge--warning .gauge__circle .mask .fill {
  background-color: #fbab18;
}
.cui .gauge--warning .gauge__inset .gauge__percentage {
  color: #fbab18;
}
.cui .gauge--warning-alt .gauge__circle .mask .fill {
  background-color: #ffcc00;
}
.cui .gauge--warning-alt .gauge__inset .gauge__percentage {
  color: #ffcc00;
}
.cui .gauge--info .gauge__circle .mask .fill {
  background-color: #64bbe3;
}
.cui .gauge--info .gauge__inset .gauge__percentage {
  color: #58585b;
}
.cui .gauge.gauge--small {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  background-color: #dfdfdf;
  border-radius: 50%;
}
.cui .gauge.gauge--small .gauge__circle .mask,
.cui .gauge.gauge--small .gauge__circle .fill,
.cui .gauge.gauge--small .gauge__circle .shadow {
  width: 60px;
  height: 60px;
  position: absolute;
  border-radius: 50%;
}
.cui .gauge.gauge--small .gauge__circle .mask,
.cui .gauge.gauge--small .gauge__circle .fill {
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  border-radius: 50%;
}
.cui .gauge.gauge--small .gauge__circle .mask {
  clip: rect(0px, 60px, 60px, 30px);
}
.cui .gauge.gauge--small .gauge__circle .mask .fill {
  clip: rect(0px, 30px, 60px, 0px);
}
.cui .gauge.gauge--small .gauge__inset {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  position: absolute;
  margin-left: 4px;
  margin-top: 4px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: inset 0 0 0 2px #f5f5f6;
  background-color: #ffffff;
}
.cui .gauge.gauge--small .gauge__inset .gauge__percentage {
  font-size: 2rem;
  font-weight: 300;
}
.cui .gauge.gauge--small[data-percentage='1'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='1'] .gauge__circle .fill {
  -webkit-transform: rotate(1.8deg);
  transform: rotate(1.8deg);
}
.cui .gauge.gauge--small[data-percentage='1'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.cui .gauge.gauge--small[data-percentage='2'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='2'] .gauge__circle .fill {
  -webkit-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.cui .gauge.gauge--small[data-percentage='2'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.cui .gauge.gauge--small[data-percentage='3'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='3'] .gauge__circle .fill {
  -webkit-transform: rotate(5.4deg);
  transform: rotate(5.4deg);
}
.cui .gauge.gauge--small[data-percentage='3'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.cui .gauge.gauge--small[data-percentage='4'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='4'] .gauge__circle .fill {
  -webkit-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.cui .gauge.gauge--small[data-percentage='4'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.cui .gauge.gauge--small[data-percentage='5'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='5'] .gauge__circle .fill {
  -webkit-transform: rotate(9deg);
  transform: rotate(9deg);
}
.cui .gauge.gauge--small[data-percentage='5'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
}
.cui .gauge.gauge--small[data-percentage='6'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='6'] .gauge__circle .fill {
  -webkit-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.cui .gauge.gauge--small[data-percentage='6'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.cui .gauge.gauge--small[data-percentage='7'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='7'] .gauge__circle .fill {
  -webkit-transform: rotate(12.6deg);
  transform: rotate(12.6deg);
}
.cui .gauge.gauge--small[data-percentage='7'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.cui .gauge.gauge--small[data-percentage='8'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='8'] .gauge__circle .fill {
  -webkit-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.cui .gauge.gauge--small[data-percentage='8'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.cui .gauge.gauge--small[data-percentage='9'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='9'] .gauge__circle .fill {
  -webkit-transform: rotate(16.2deg);
  transform: rotate(16.2deg);
}
.cui .gauge.gauge--small[data-percentage='9'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.cui .gauge.gauge--small[data-percentage='10'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='10'] .gauge__circle .fill {
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
}
.cui .gauge.gauge--small[data-percentage='10'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
}
.cui .gauge.gauge--small[data-percentage='11'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='11'] .gauge__circle .fill {
  -webkit-transform: rotate(19.8deg);
  transform: rotate(19.8deg);
}
.cui .gauge.gauge--small[data-percentage='11'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.cui .gauge.gauge--small[data-percentage='12'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='12'] .gauge__circle .fill {
  -webkit-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.cui .gauge.gauge--small[data-percentage='12'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.cui .gauge.gauge--small[data-percentage='13'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='13'] .gauge__circle .fill {
  -webkit-transform: rotate(23.4deg);
  transform: rotate(23.4deg);
}
.cui .gauge.gauge--small[data-percentage='13'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.cui .gauge.gauge--small[data-percentage='14'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='14'] .gauge__circle .fill {
  -webkit-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.cui .gauge.gauge--small[data-percentage='14'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.cui .gauge.gauge--small[data-percentage='15'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='15'] .gauge__circle .fill {
  -webkit-transform: rotate(27deg);
  transform: rotate(27deg);
}
.cui .gauge.gauge--small[data-percentage='15'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(54deg);
  transform: rotate(54deg);
}
.cui .gauge.gauge--small[data-percentage='16'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='16'] .gauge__circle .fill {
  -webkit-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.cui .gauge.gauge--small[data-percentage='16'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.cui .gauge.gauge--small[data-percentage='17'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='17'] .gauge__circle .fill {
  -webkit-transform: rotate(30.6deg);
  transform: rotate(30.6deg);
}
.cui .gauge.gauge--small[data-percentage='17'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.cui .gauge.gauge--small[data-percentage='18'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='18'] .gauge__circle .fill {
  -webkit-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.cui .gauge.gauge--small[data-percentage='18'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.cui .gauge.gauge--small[data-percentage='19'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='19'] .gauge__circle .fill {
  -webkit-transform: rotate(34.2deg);
  transform: rotate(34.2deg);
}
.cui .gauge.gauge--small[data-percentage='19'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.cui .gauge.gauge--small[data-percentage='20'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='20'] .gauge__circle .fill {
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
}
.cui .gauge.gauge--small[data-percentage='20'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
}
.cui .gauge.gauge--small[data-percentage='21'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='21'] .gauge__circle .fill {
  -webkit-transform: rotate(37.8deg);
  transform: rotate(37.8deg);
}
.cui .gauge.gauge--small[data-percentage='21'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.cui .gauge.gauge--small[data-percentage='22'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='22'] .gauge__circle .fill {
  -webkit-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.cui .gauge.gauge--small[data-percentage='22'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.cui .gauge.gauge--small[data-percentage='23'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='23'] .gauge__circle .fill {
  -webkit-transform: rotate(41.4deg);
  transform: rotate(41.4deg);
}
.cui .gauge.gauge--small[data-percentage='23'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.cui .gauge.gauge--small[data-percentage='24'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='24'] .gauge__circle .fill {
  -webkit-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.cui .gauge.gauge--small[data-percentage='24'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.cui .gauge.gauge--small[data-percentage='25'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='25'] .gauge__circle .fill {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.cui .gauge.gauge--small[data-percentage='25'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.cui .gauge.gauge--small[data-percentage='26'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='26'] .gauge__circle .fill {
  -webkit-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.cui .gauge.gauge--small[data-percentage='26'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.cui .gauge.gauge--small[data-percentage='27'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='27'] .gauge__circle .fill {
  -webkit-transform: rotate(48.6deg);
  transform: rotate(48.6deg);
}
.cui .gauge.gauge--small[data-percentage='27'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.cui .gauge.gauge--small[data-percentage='28'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='28'] .gauge__circle .fill {
  -webkit-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.cui .gauge.gauge--small[data-percentage='28'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.cui .gauge.gauge--small[data-percentage='29'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='29'] .gauge__circle .fill {
  -webkit-transform: rotate(52.2deg);
  transform: rotate(52.2deg);
}
.cui .gauge.gauge--small[data-percentage='29'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.cui .gauge.gauge--small[data-percentage='30'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='30'] .gauge__circle .fill {
  -webkit-transform: rotate(54deg);
  transform: rotate(54deg);
}
.cui .gauge.gauge--small[data-percentage='30'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
}
.cui .gauge.gauge--small[data-percentage='31'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='31'] .gauge__circle .fill {
  -webkit-transform: rotate(55.8deg);
  transform: rotate(55.8deg);
}
.cui .gauge.gauge--small[data-percentage='31'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.cui .gauge.gauge--small[data-percentage='32'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='32'] .gauge__circle .fill {
  -webkit-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.cui .gauge.gauge--small[data-percentage='32'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.cui .gauge.gauge--small[data-percentage='33'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='33'] .gauge__circle .fill {
  -webkit-transform: rotate(59.4deg);
  transform: rotate(59.4deg);
}
.cui .gauge.gauge--small[data-percentage='33'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.cui .gauge.gauge--small[data-percentage='34'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='34'] .gauge__circle .fill {
  -webkit-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.cui .gauge.gauge--small[data-percentage='34'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.cui .gauge.gauge--small[data-percentage='35'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='35'] .gauge__circle .fill {
  -webkit-transform: rotate(63deg);
  transform: rotate(63deg);
}
.cui .gauge.gauge--small[data-percentage='35'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(126deg);
  transform: rotate(126deg);
}
.cui .gauge.gauge--small[data-percentage='36'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='36'] .gauge__circle .fill {
  -webkit-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.cui .gauge.gauge--small[data-percentage='36'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.cui .gauge.gauge--small[data-percentage='37'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='37'] .gauge__circle .fill {
  -webkit-transform: rotate(66.6deg);
  transform: rotate(66.6deg);
}
.cui .gauge.gauge--small[data-percentage='37'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.cui .gauge.gauge--small[data-percentage='38'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='38'] .gauge__circle .fill {
  -webkit-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.cui .gauge.gauge--small[data-percentage='38'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.cui .gauge.gauge--small[data-percentage='39'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='39'] .gauge__circle .fill {
  -webkit-transform: rotate(70.2deg);
  transform: rotate(70.2deg);
}
.cui .gauge.gauge--small[data-percentage='39'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.cui .gauge.gauge--small[data-percentage='40'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='40'] .gauge__circle .fill {
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
}
.cui .gauge.gauge--small[data-percentage='40'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
}
.cui .gauge.gauge--small[data-percentage='41'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='41'] .gauge__circle .fill {
  -webkit-transform: rotate(73.8deg);
  transform: rotate(73.8deg);
}
.cui .gauge.gauge--small[data-percentage='41'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.cui .gauge.gauge--small[data-percentage='42'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='42'] .gauge__circle .fill {
  -webkit-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.cui .gauge.gauge--small[data-percentage='42'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.cui .gauge.gauge--small[data-percentage='43'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='43'] .gauge__circle .fill {
  -webkit-transform: rotate(77.4deg);
  transform: rotate(77.4deg);
}
.cui .gauge.gauge--small[data-percentage='43'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.cui .gauge.gauge--small[data-percentage='44'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='44'] .gauge__circle .fill {
  -webkit-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.cui .gauge.gauge--small[data-percentage='44'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.cui .gauge.gauge--small[data-percentage='45'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='45'] .gauge__circle .fill {
  -webkit-transform: rotate(81deg);
  transform: rotate(81deg);
}
.cui .gauge.gauge--small[data-percentage='45'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(162deg);
  transform: rotate(162deg);
}
.cui .gauge.gauge--small[data-percentage='46'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='46'] .gauge__circle .fill {
  -webkit-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.cui .gauge.gauge--small[data-percentage='46'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.cui .gauge.gauge--small[data-percentage='47'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='47'] .gauge__circle .fill {
  -webkit-transform: rotate(84.6deg);
  transform: rotate(84.6deg);
}
.cui .gauge.gauge--small[data-percentage='47'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.cui .gauge.gauge--small[data-percentage='48'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='48'] .gauge__circle .fill {
  -webkit-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.cui .gauge.gauge--small[data-percentage='48'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.cui .gauge.gauge--small[data-percentage='49'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='49'] .gauge__circle .fill {
  -webkit-transform: rotate(88.2deg);
  transform: rotate(88.2deg);
}
.cui .gauge.gauge--small[data-percentage='49'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.cui .gauge.gauge--small[data-percentage='50'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='50'] .gauge__circle .fill {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.cui .gauge.gauge--small[data-percentage='50'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cui .gauge.gauge--small[data-percentage='51'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='51'] .gauge__circle .fill {
  -webkit-transform: rotate(91.8deg);
  transform: rotate(91.8deg);
}
.cui .gauge.gauge--small[data-percentage='51'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}
.cui .gauge.gauge--small[data-percentage='52'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='52'] .gauge__circle .fill {
  -webkit-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.cui .gauge.gauge--small[data-percentage='52'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
}
.cui .gauge.gauge--small[data-percentage='53'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='53'] .gauge__circle .fill {
  -webkit-transform: rotate(95.4deg);
  transform: rotate(95.4deg);
}
.cui .gauge.gauge--small[data-percentage='53'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}
.cui .gauge.gauge--small[data-percentage='54'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='54'] .gauge__circle .fill {
  -webkit-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.cui .gauge.gauge--small[data-percentage='54'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}
.cui .gauge.gauge--small[data-percentage='55'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='55'] .gauge__circle .fill {
  -webkit-transform: rotate(99deg);
  transform: rotate(99deg);
}
.cui .gauge.gauge--small[data-percentage='55'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(198deg);
  transform: rotate(198deg);
}
.cui .gauge.gauge--small[data-percentage='56'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='56'] .gauge__circle .fill {
  -webkit-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.cui .gauge.gauge--small[data-percentage='56'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}
.cui .gauge.gauge--small[data-percentage='57'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='57'] .gauge__circle .fill {
  -webkit-transform: rotate(102.6deg);
  transform: rotate(102.6deg);
}
.cui .gauge.gauge--small[data-percentage='57'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
}
.cui .gauge.gauge--small[data-percentage='58'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='58'] .gauge__circle .fill {
  -webkit-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.cui .gauge.gauge--small[data-percentage='58'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}
.cui .gauge.gauge--small[data-percentage='59'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='59'] .gauge__circle .fill {
  -webkit-transform: rotate(106.2deg);
  transform: rotate(106.2deg);
}
.cui .gauge.gauge--small[data-percentage='59'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}
.cui .gauge.gauge--small[data-percentage='60'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='60'] .gauge__circle .fill {
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
}
.cui .gauge.gauge--small[data-percentage='60'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(216deg);
  transform: rotate(216deg);
}
.cui .gauge.gauge--small[data-percentage='61'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='61'] .gauge__circle .fill {
  -webkit-transform: rotate(109.8deg);
  transform: rotate(109.8deg);
}
.cui .gauge.gauge--small[data-percentage='61'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}
.cui .gauge.gauge--small[data-percentage='62'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='62'] .gauge__circle .fill {
  -webkit-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.cui .gauge.gauge--small[data-percentage='62'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
}
.cui .gauge.gauge--small[data-percentage='63'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='63'] .gauge__circle .fill {
  -webkit-transform: rotate(113.4deg);
  transform: rotate(113.4deg);
}
.cui .gauge.gauge--small[data-percentage='63'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}
.cui .gauge.gauge--small[data-percentage='64'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='64'] .gauge__circle .fill {
  -webkit-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.cui .gauge.gauge--small[data-percentage='64'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}
.cui .gauge.gauge--small[data-percentage='65'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='65'] .gauge__circle .fill {
  -webkit-transform: rotate(117deg);
  transform: rotate(117deg);
}
.cui .gauge.gauge--small[data-percentage='65'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(234deg);
  transform: rotate(234deg);
}
.cui .gauge.gauge--small[data-percentage='66'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='66'] .gauge__circle .fill {
  -webkit-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.cui .gauge.gauge--small[data-percentage='66'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}
.cui .gauge.gauge--small[data-percentage='67'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='67'] .gauge__circle .fill {
  -webkit-transform: rotate(120.6deg);
  transform: rotate(120.6deg);
}
.cui .gauge.gauge--small[data-percentage='67'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
}
.cui .gauge.gauge--small[data-percentage='68'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='68'] .gauge__circle .fill {
  -webkit-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.cui .gauge.gauge--small[data-percentage='68'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}
.cui .gauge.gauge--small[data-percentage='69'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='69'] .gauge__circle .fill {
  -webkit-transform: rotate(124.2deg);
  transform: rotate(124.2deg);
}
.cui .gauge.gauge--small[data-percentage='69'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}
.cui .gauge.gauge--small[data-percentage='70'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='70'] .gauge__circle .fill {
  -webkit-transform: rotate(126deg);
  transform: rotate(126deg);
}
.cui .gauge.gauge--small[data-percentage='70'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(252deg);
  transform: rotate(252deg);
}
.cui .gauge.gauge--small[data-percentage='71'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='71'] .gauge__circle .fill {
  -webkit-transform: rotate(127.8deg);
  transform: rotate(127.8deg);
}
.cui .gauge.gauge--small[data-percentage='71'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}
.cui .gauge.gauge--small[data-percentage='72'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='72'] .gauge__circle .fill {
  -webkit-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.cui .gauge.gauge--small[data-percentage='72'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}
.cui .gauge.gauge--small[data-percentage='73'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='73'] .gauge__circle .fill {
  -webkit-transform: rotate(131.4deg);
  transform: rotate(131.4deg);
}
.cui .gauge.gauge--small[data-percentage='73'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}
.cui .gauge.gauge--small[data-percentage='74'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='74'] .gauge__circle .fill {
  -webkit-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.cui .gauge.gauge--small[data-percentage='74'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
}
.cui .gauge.gauge--small[data-percentage='75'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='75'] .gauge__circle .fill {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.cui .gauge.gauge--small[data-percentage='75'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.cui .gauge.gauge--small[data-percentage='76'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='76'] .gauge__circle .fill {
  -webkit-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.cui .gauge.gauge--small[data-percentage='76'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}
.cui .gauge.gauge--small[data-percentage='77'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='77'] .gauge__circle .fill {
  -webkit-transform: rotate(138.6deg);
  transform: rotate(138.6deg);
}
.cui .gauge.gauge--small[data-percentage='77'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}
.cui .gauge.gauge--small[data-percentage='78'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='78'] .gauge__circle .fill {
  -webkit-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.cui .gauge.gauge--small[data-percentage='78'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}
.cui .gauge.gauge--small[data-percentage='79'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='79'] .gauge__circle .fill {
  -webkit-transform: rotate(142.2deg);
  transform: rotate(142.2deg);
}
.cui .gauge.gauge--small[data-percentage='79'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
}
.cui .gauge.gauge--small[data-percentage='80'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='80'] .gauge__circle .fill {
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
}
.cui .gauge.gauge--small[data-percentage='80'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(288deg);
  transform: rotate(288deg);
}
.cui .gauge.gauge--small[data-percentage='81'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='81'] .gauge__circle .fill {
  -webkit-transform: rotate(145.8deg);
  transform: rotate(145.8deg);
}
.cui .gauge.gauge--small[data-percentage='81'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}
.cui .gauge.gauge--small[data-percentage='82'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='82'] .gauge__circle .fill {
  -webkit-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.cui .gauge.gauge--small[data-percentage='82'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}
.cui .gauge.gauge--small[data-percentage='83'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='83'] .gauge__circle .fill {
  -webkit-transform: rotate(149.4deg);
  transform: rotate(149.4deg);
}
.cui .gauge.gauge--small[data-percentage='83'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}
.cui .gauge.gauge--small[data-percentage='84'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='84'] .gauge__circle .fill {
  -webkit-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.cui .gauge.gauge--small[data-percentage='84'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
}
.cui .gauge.gauge--small[data-percentage='85'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='85'] .gauge__circle .fill {
  -webkit-transform: rotate(153deg);
  transform: rotate(153deg);
}
.cui .gauge.gauge--small[data-percentage='85'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(306deg);
  transform: rotate(306deg);
}
.cui .gauge.gauge--small[data-percentage='86'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='86'] .gauge__circle .fill {
  -webkit-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.cui .gauge.gauge--small[data-percentage='86'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}
.cui .gauge.gauge--small[data-percentage='87'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='87'] .gauge__circle .fill {
  -webkit-transform: rotate(156.6deg);
  transform: rotate(156.6deg);
}
.cui .gauge.gauge--small[data-percentage='87'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}
.cui .gauge.gauge--small[data-percentage='88'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='88'] .gauge__circle .fill {
  -webkit-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.cui .gauge.gauge--small[data-percentage='88'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}
.cui .gauge.gauge--small[data-percentage='89'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='89'] .gauge__circle .fill {
  -webkit-transform: rotate(160.2deg);
  transform: rotate(160.2deg);
}
.cui .gauge.gauge--small[data-percentage='89'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
}
.cui .gauge.gauge--small[data-percentage='90'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='90'] .gauge__circle .fill {
  -webkit-transform: rotate(162deg);
  transform: rotate(162deg);
}
.cui .gauge.gauge--small[data-percentage='90'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(324deg);
  transform: rotate(324deg);
}
.cui .gauge.gauge--small[data-percentage='91'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='91'] .gauge__circle .fill {
  -webkit-transform: rotate(163.8deg);
  transform: rotate(163.8deg);
}
.cui .gauge.gauge--small[data-percentage='91'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}
.cui .gauge.gauge--small[data-percentage='92'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='92'] .gauge__circle .fill {
  -webkit-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.cui .gauge.gauge--small[data-percentage='92'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}
.cui .gauge.gauge--small[data-percentage='93'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='93'] .gauge__circle .fill {
  -webkit-transform: rotate(167.4deg);
  transform: rotate(167.4deg);
}
.cui .gauge.gauge--small[data-percentage='93'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}
.cui .gauge.gauge--small[data-percentage='94'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='94'] .gauge__circle .fill {
  -webkit-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.cui .gauge.gauge--small[data-percentage='94'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
}
.cui .gauge.gauge--small[data-percentage='95'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='95'] .gauge__circle .fill {
  -webkit-transform: rotate(171deg);
  transform: rotate(171deg);
}
.cui .gauge.gauge--small[data-percentage='95'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(342deg);
  transform: rotate(342deg);
}
.cui .gauge.gauge--small[data-percentage='96'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='96'] .gauge__circle .fill {
  -webkit-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.cui .gauge.gauge--small[data-percentage='96'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}
.cui .gauge.gauge--small[data-percentage='97'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='97'] .gauge__circle .fill {
  -webkit-transform: rotate(174.6deg);
  transform: rotate(174.6deg);
}
.cui .gauge.gauge--small[data-percentage='97'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}
.cui .gauge.gauge--small[data-percentage='98'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='98'] .gauge__circle .fill {
  -webkit-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.cui .gauge.gauge--small[data-percentage='98'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}
.cui .gauge.gauge--small[data-percentage='99'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='99'] .gauge__circle .fill {
  -webkit-transform: rotate(178.2deg);
  transform: rotate(178.2deg);
}
.cui .gauge.gauge--small[data-percentage='99'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
}
.cui .gauge.gauge--small[data-percentage='100'] .gauge__circle .mask.full,
.cui .gauge.gauge--small[data-percentage='100'] .gauge__circle .fill {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cui .gauge.gauge--small[data-percentage='100'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.cui .gauge.gauge--large {
  display: inline-block;
  position: relative;
  width: 160px;
  height: 160px;
  background-color: #dfdfdf;
  border-radius: 50%;
}
.cui .gauge.gauge--large .gauge__circle .mask,
.cui .gauge.gauge--large .gauge__circle .fill,
.cui .gauge.gauge--large .gauge__circle .shadow {
  width: 160px;
  height: 160px;
  position: absolute;
  border-radius: 50%;
}
.cui .gauge.gauge--large .gauge__circle .mask,
.cui .gauge.gauge--large .gauge__circle .fill {
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  border-radius: 50%;
}
.cui .gauge.gauge--large .gauge__circle .mask {
  clip: rect(0px, 160px, 160px, 80px);
}
.cui .gauge.gauge--large .gauge__circle .mask .fill {
  clip: rect(0px, 80px, 160px, 0px);
}
.cui .gauge.gauge--large .gauge__inset {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 148px;
  position: absolute;
  margin-left: 6px;
  margin-top: 6px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: inset 0 0 0 2px #f5f5f6;
  background-color: #ffffff;
}
.cui .gauge.gauge--large .gauge__inset .gauge__percentage {
  font-size: 6rem;
  font-weight: 100;
}
.cui .gauge.gauge--large[data-percentage='1'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='1'] .gauge__circle .fill {
  -webkit-transform: rotate(1.8deg);
  transform: rotate(1.8deg);
}
.cui .gauge.gauge--large[data-percentage='1'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.cui .gauge.gauge--large[data-percentage='2'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='2'] .gauge__circle .fill {
  -webkit-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.cui .gauge.gauge--large[data-percentage='2'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.cui .gauge.gauge--large[data-percentage='3'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='3'] .gauge__circle .fill {
  -webkit-transform: rotate(5.4deg);
  transform: rotate(5.4deg);
}
.cui .gauge.gauge--large[data-percentage='3'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.cui .gauge.gauge--large[data-percentage='4'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='4'] .gauge__circle .fill {
  -webkit-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.cui .gauge.gauge--large[data-percentage='4'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.cui .gauge.gauge--large[data-percentage='5'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='5'] .gauge__circle .fill {
  -webkit-transform: rotate(9deg);
  transform: rotate(9deg);
}
.cui .gauge.gauge--large[data-percentage='5'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
}
.cui .gauge.gauge--large[data-percentage='6'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='6'] .gauge__circle .fill {
  -webkit-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.cui .gauge.gauge--large[data-percentage='6'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.cui .gauge.gauge--large[data-percentage='7'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='7'] .gauge__circle .fill {
  -webkit-transform: rotate(12.6deg);
  transform: rotate(12.6deg);
}
.cui .gauge.gauge--large[data-percentage='7'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.cui .gauge.gauge--large[data-percentage='8'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='8'] .gauge__circle .fill {
  -webkit-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.cui .gauge.gauge--large[data-percentage='8'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.cui .gauge.gauge--large[data-percentage='9'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='9'] .gauge__circle .fill {
  -webkit-transform: rotate(16.2deg);
  transform: rotate(16.2deg);
}
.cui .gauge.gauge--large[data-percentage='9'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.cui .gauge.gauge--large[data-percentage='10'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='10'] .gauge__circle .fill {
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
}
.cui .gauge.gauge--large[data-percentage='10'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
}
.cui .gauge.gauge--large[data-percentage='11'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='11'] .gauge__circle .fill {
  -webkit-transform: rotate(19.8deg);
  transform: rotate(19.8deg);
}
.cui .gauge.gauge--large[data-percentage='11'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.cui .gauge.gauge--large[data-percentage='12'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='12'] .gauge__circle .fill {
  -webkit-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.cui .gauge.gauge--large[data-percentage='12'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.cui .gauge.gauge--large[data-percentage='13'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='13'] .gauge__circle .fill {
  -webkit-transform: rotate(23.4deg);
  transform: rotate(23.4deg);
}
.cui .gauge.gauge--large[data-percentage='13'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.cui .gauge.gauge--large[data-percentage='14'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='14'] .gauge__circle .fill {
  -webkit-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.cui .gauge.gauge--large[data-percentage='14'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.cui .gauge.gauge--large[data-percentage='15'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='15'] .gauge__circle .fill {
  -webkit-transform: rotate(27deg);
  transform: rotate(27deg);
}
.cui .gauge.gauge--large[data-percentage='15'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(54deg);
  transform: rotate(54deg);
}
.cui .gauge.gauge--large[data-percentage='16'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='16'] .gauge__circle .fill {
  -webkit-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.cui .gauge.gauge--large[data-percentage='16'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.cui .gauge.gauge--large[data-percentage='17'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='17'] .gauge__circle .fill {
  -webkit-transform: rotate(30.6deg);
  transform: rotate(30.6deg);
}
.cui .gauge.gauge--large[data-percentage='17'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.cui .gauge.gauge--large[data-percentage='18'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='18'] .gauge__circle .fill {
  -webkit-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.cui .gauge.gauge--large[data-percentage='18'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.cui .gauge.gauge--large[data-percentage='19'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='19'] .gauge__circle .fill {
  -webkit-transform: rotate(34.2deg);
  transform: rotate(34.2deg);
}
.cui .gauge.gauge--large[data-percentage='19'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.cui .gauge.gauge--large[data-percentage='20'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='20'] .gauge__circle .fill {
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
}
.cui .gauge.gauge--large[data-percentage='20'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
}
.cui .gauge.gauge--large[data-percentage='21'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='21'] .gauge__circle .fill {
  -webkit-transform: rotate(37.8deg);
  transform: rotate(37.8deg);
}
.cui .gauge.gauge--large[data-percentage='21'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.cui .gauge.gauge--large[data-percentage='22'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='22'] .gauge__circle .fill {
  -webkit-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.cui .gauge.gauge--large[data-percentage='22'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.cui .gauge.gauge--large[data-percentage='23'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='23'] .gauge__circle .fill {
  -webkit-transform: rotate(41.4deg);
  transform: rotate(41.4deg);
}
.cui .gauge.gauge--large[data-percentage='23'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.cui .gauge.gauge--large[data-percentage='24'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='24'] .gauge__circle .fill {
  -webkit-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.cui .gauge.gauge--large[data-percentage='24'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.cui .gauge.gauge--large[data-percentage='25'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='25'] .gauge__circle .fill {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.cui .gauge.gauge--large[data-percentage='25'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.cui .gauge.gauge--large[data-percentage='26'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='26'] .gauge__circle .fill {
  -webkit-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.cui .gauge.gauge--large[data-percentage='26'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.cui .gauge.gauge--large[data-percentage='27'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='27'] .gauge__circle .fill {
  -webkit-transform: rotate(48.6deg);
  transform: rotate(48.6deg);
}
.cui .gauge.gauge--large[data-percentage='27'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.cui .gauge.gauge--large[data-percentage='28'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='28'] .gauge__circle .fill {
  -webkit-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.cui .gauge.gauge--large[data-percentage='28'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.cui .gauge.gauge--large[data-percentage='29'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='29'] .gauge__circle .fill {
  -webkit-transform: rotate(52.2deg);
  transform: rotate(52.2deg);
}
.cui .gauge.gauge--large[data-percentage='29'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.cui .gauge.gauge--large[data-percentage='30'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='30'] .gauge__circle .fill {
  -webkit-transform: rotate(54deg);
  transform: rotate(54deg);
}
.cui .gauge.gauge--large[data-percentage='30'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
}
.cui .gauge.gauge--large[data-percentage='31'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='31'] .gauge__circle .fill {
  -webkit-transform: rotate(55.8deg);
  transform: rotate(55.8deg);
}
.cui .gauge.gauge--large[data-percentage='31'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.cui .gauge.gauge--large[data-percentage='32'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='32'] .gauge__circle .fill {
  -webkit-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.cui .gauge.gauge--large[data-percentage='32'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.cui .gauge.gauge--large[data-percentage='33'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='33'] .gauge__circle .fill {
  -webkit-transform: rotate(59.4deg);
  transform: rotate(59.4deg);
}
.cui .gauge.gauge--large[data-percentage='33'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.cui .gauge.gauge--large[data-percentage='34'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='34'] .gauge__circle .fill {
  -webkit-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.cui .gauge.gauge--large[data-percentage='34'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.cui .gauge.gauge--large[data-percentage='35'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='35'] .gauge__circle .fill {
  -webkit-transform: rotate(63deg);
  transform: rotate(63deg);
}
.cui .gauge.gauge--large[data-percentage='35'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(126deg);
  transform: rotate(126deg);
}
.cui .gauge.gauge--large[data-percentage='36'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='36'] .gauge__circle .fill {
  -webkit-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.cui .gauge.gauge--large[data-percentage='36'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.cui .gauge.gauge--large[data-percentage='37'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='37'] .gauge__circle .fill {
  -webkit-transform: rotate(66.6deg);
  transform: rotate(66.6deg);
}
.cui .gauge.gauge--large[data-percentage='37'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.cui .gauge.gauge--large[data-percentage='38'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='38'] .gauge__circle .fill {
  -webkit-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.cui .gauge.gauge--large[data-percentage='38'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.cui .gauge.gauge--large[data-percentage='39'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='39'] .gauge__circle .fill {
  -webkit-transform: rotate(70.2deg);
  transform: rotate(70.2deg);
}
.cui .gauge.gauge--large[data-percentage='39'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.cui .gauge.gauge--large[data-percentage='40'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='40'] .gauge__circle .fill {
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
}
.cui .gauge.gauge--large[data-percentage='40'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
}
.cui .gauge.gauge--large[data-percentage='41'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='41'] .gauge__circle .fill {
  -webkit-transform: rotate(73.8deg);
  transform: rotate(73.8deg);
}
.cui .gauge.gauge--large[data-percentage='41'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.cui .gauge.gauge--large[data-percentage='42'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='42'] .gauge__circle .fill {
  -webkit-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.cui .gauge.gauge--large[data-percentage='42'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.cui .gauge.gauge--large[data-percentage='43'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='43'] .gauge__circle .fill {
  -webkit-transform: rotate(77.4deg);
  transform: rotate(77.4deg);
}
.cui .gauge.gauge--large[data-percentage='43'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.cui .gauge.gauge--large[data-percentage='44'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='44'] .gauge__circle .fill {
  -webkit-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.cui .gauge.gauge--large[data-percentage='44'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.cui .gauge.gauge--large[data-percentage='45'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='45'] .gauge__circle .fill {
  -webkit-transform: rotate(81deg);
  transform: rotate(81deg);
}
.cui .gauge.gauge--large[data-percentage='45'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(162deg);
  transform: rotate(162deg);
}
.cui .gauge.gauge--large[data-percentage='46'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='46'] .gauge__circle .fill {
  -webkit-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.cui .gauge.gauge--large[data-percentage='46'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.cui .gauge.gauge--large[data-percentage='47'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='47'] .gauge__circle .fill {
  -webkit-transform: rotate(84.6deg);
  transform: rotate(84.6deg);
}
.cui .gauge.gauge--large[data-percentage='47'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.cui .gauge.gauge--large[data-percentage='48'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='48'] .gauge__circle .fill {
  -webkit-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.cui .gauge.gauge--large[data-percentage='48'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.cui .gauge.gauge--large[data-percentage='49'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='49'] .gauge__circle .fill {
  -webkit-transform: rotate(88.2deg);
  transform: rotate(88.2deg);
}
.cui .gauge.gauge--large[data-percentage='49'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.cui .gauge.gauge--large[data-percentage='50'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='50'] .gauge__circle .fill {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.cui .gauge.gauge--large[data-percentage='50'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cui .gauge.gauge--large[data-percentage='51'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='51'] .gauge__circle .fill {
  -webkit-transform: rotate(91.8deg);
  transform: rotate(91.8deg);
}
.cui .gauge.gauge--large[data-percentage='51'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}
.cui .gauge.gauge--large[data-percentage='52'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='52'] .gauge__circle .fill {
  -webkit-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.cui .gauge.gauge--large[data-percentage='52'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
}
.cui .gauge.gauge--large[data-percentage='53'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='53'] .gauge__circle .fill {
  -webkit-transform: rotate(95.4deg);
  transform: rotate(95.4deg);
}
.cui .gauge.gauge--large[data-percentage='53'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}
.cui .gauge.gauge--large[data-percentage='54'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='54'] .gauge__circle .fill {
  -webkit-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.cui .gauge.gauge--large[data-percentage='54'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}
.cui .gauge.gauge--large[data-percentage='55'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='55'] .gauge__circle .fill {
  -webkit-transform: rotate(99deg);
  transform: rotate(99deg);
}
.cui .gauge.gauge--large[data-percentage='55'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(198deg);
  transform: rotate(198deg);
}
.cui .gauge.gauge--large[data-percentage='56'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='56'] .gauge__circle .fill {
  -webkit-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.cui .gauge.gauge--large[data-percentage='56'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}
.cui .gauge.gauge--large[data-percentage='57'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='57'] .gauge__circle .fill {
  -webkit-transform: rotate(102.6deg);
  transform: rotate(102.6deg);
}
.cui .gauge.gauge--large[data-percentage='57'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
}
.cui .gauge.gauge--large[data-percentage='58'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='58'] .gauge__circle .fill {
  -webkit-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.cui .gauge.gauge--large[data-percentage='58'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}
.cui .gauge.gauge--large[data-percentage='59'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='59'] .gauge__circle .fill {
  -webkit-transform: rotate(106.2deg);
  transform: rotate(106.2deg);
}
.cui .gauge.gauge--large[data-percentage='59'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}
.cui .gauge.gauge--large[data-percentage='60'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='60'] .gauge__circle .fill {
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
}
.cui .gauge.gauge--large[data-percentage='60'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(216deg);
  transform: rotate(216deg);
}
.cui .gauge.gauge--large[data-percentage='61'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='61'] .gauge__circle .fill {
  -webkit-transform: rotate(109.8deg);
  transform: rotate(109.8deg);
}
.cui .gauge.gauge--large[data-percentage='61'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}
.cui .gauge.gauge--large[data-percentage='62'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='62'] .gauge__circle .fill {
  -webkit-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.cui .gauge.gauge--large[data-percentage='62'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
}
.cui .gauge.gauge--large[data-percentage='63'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='63'] .gauge__circle .fill {
  -webkit-transform: rotate(113.4deg);
  transform: rotate(113.4deg);
}
.cui .gauge.gauge--large[data-percentage='63'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}
.cui .gauge.gauge--large[data-percentage='64'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='64'] .gauge__circle .fill {
  -webkit-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.cui .gauge.gauge--large[data-percentage='64'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}
.cui .gauge.gauge--large[data-percentage='65'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='65'] .gauge__circle .fill {
  -webkit-transform: rotate(117deg);
  transform: rotate(117deg);
}
.cui .gauge.gauge--large[data-percentage='65'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(234deg);
  transform: rotate(234deg);
}
.cui .gauge.gauge--large[data-percentage='66'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='66'] .gauge__circle .fill {
  -webkit-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.cui .gauge.gauge--large[data-percentage='66'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}
.cui .gauge.gauge--large[data-percentage='67'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='67'] .gauge__circle .fill {
  -webkit-transform: rotate(120.6deg);
  transform: rotate(120.6deg);
}
.cui .gauge.gauge--large[data-percentage='67'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
}
.cui .gauge.gauge--large[data-percentage='68'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='68'] .gauge__circle .fill {
  -webkit-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.cui .gauge.gauge--large[data-percentage='68'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}
.cui .gauge.gauge--large[data-percentage='69'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='69'] .gauge__circle .fill {
  -webkit-transform: rotate(124.2deg);
  transform: rotate(124.2deg);
}
.cui .gauge.gauge--large[data-percentage='69'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}
.cui .gauge.gauge--large[data-percentage='70'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='70'] .gauge__circle .fill {
  -webkit-transform: rotate(126deg);
  transform: rotate(126deg);
}
.cui .gauge.gauge--large[data-percentage='70'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(252deg);
  transform: rotate(252deg);
}
.cui .gauge.gauge--large[data-percentage='71'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='71'] .gauge__circle .fill {
  -webkit-transform: rotate(127.8deg);
  transform: rotate(127.8deg);
}
.cui .gauge.gauge--large[data-percentage='71'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}
.cui .gauge.gauge--large[data-percentage='72'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='72'] .gauge__circle .fill {
  -webkit-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.cui .gauge.gauge--large[data-percentage='72'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}
.cui .gauge.gauge--large[data-percentage='73'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='73'] .gauge__circle .fill {
  -webkit-transform: rotate(131.4deg);
  transform: rotate(131.4deg);
}
.cui .gauge.gauge--large[data-percentage='73'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}
.cui .gauge.gauge--large[data-percentage='74'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='74'] .gauge__circle .fill {
  -webkit-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.cui .gauge.gauge--large[data-percentage='74'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
}
.cui .gauge.gauge--large[data-percentage='75'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='75'] .gauge__circle .fill {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.cui .gauge.gauge--large[data-percentage='75'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.cui .gauge.gauge--large[data-percentage='76'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='76'] .gauge__circle .fill {
  -webkit-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.cui .gauge.gauge--large[data-percentage='76'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}
.cui .gauge.gauge--large[data-percentage='77'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='77'] .gauge__circle .fill {
  -webkit-transform: rotate(138.6deg);
  transform: rotate(138.6deg);
}
.cui .gauge.gauge--large[data-percentage='77'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}
.cui .gauge.gauge--large[data-percentage='78'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='78'] .gauge__circle .fill {
  -webkit-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.cui .gauge.gauge--large[data-percentage='78'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}
.cui .gauge.gauge--large[data-percentage='79'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='79'] .gauge__circle .fill {
  -webkit-transform: rotate(142.2deg);
  transform: rotate(142.2deg);
}
.cui .gauge.gauge--large[data-percentage='79'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
}
.cui .gauge.gauge--large[data-percentage='80'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='80'] .gauge__circle .fill {
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
}
.cui .gauge.gauge--large[data-percentage='80'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(288deg);
  transform: rotate(288deg);
}
.cui .gauge.gauge--large[data-percentage='81'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='81'] .gauge__circle .fill {
  -webkit-transform: rotate(145.8deg);
  transform: rotate(145.8deg);
}
.cui .gauge.gauge--large[data-percentage='81'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}
.cui .gauge.gauge--large[data-percentage='82'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='82'] .gauge__circle .fill {
  -webkit-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.cui .gauge.gauge--large[data-percentage='82'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}
.cui .gauge.gauge--large[data-percentage='83'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='83'] .gauge__circle .fill {
  -webkit-transform: rotate(149.4deg);
  transform: rotate(149.4deg);
}
.cui .gauge.gauge--large[data-percentage='83'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}
.cui .gauge.gauge--large[data-percentage='84'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='84'] .gauge__circle .fill {
  -webkit-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.cui .gauge.gauge--large[data-percentage='84'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
}
.cui .gauge.gauge--large[data-percentage='85'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='85'] .gauge__circle .fill {
  -webkit-transform: rotate(153deg);
  transform: rotate(153deg);
}
.cui .gauge.gauge--large[data-percentage='85'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(306deg);
  transform: rotate(306deg);
}
.cui .gauge.gauge--large[data-percentage='86'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='86'] .gauge__circle .fill {
  -webkit-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.cui .gauge.gauge--large[data-percentage='86'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}
.cui .gauge.gauge--large[data-percentage='87'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='87'] .gauge__circle .fill {
  -webkit-transform: rotate(156.6deg);
  transform: rotate(156.6deg);
}
.cui .gauge.gauge--large[data-percentage='87'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}
.cui .gauge.gauge--large[data-percentage='88'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='88'] .gauge__circle .fill {
  -webkit-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.cui .gauge.gauge--large[data-percentage='88'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}
.cui .gauge.gauge--large[data-percentage='89'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='89'] .gauge__circle .fill {
  -webkit-transform: rotate(160.2deg);
  transform: rotate(160.2deg);
}
.cui .gauge.gauge--large[data-percentage='89'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
}
.cui .gauge.gauge--large[data-percentage='90'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='90'] .gauge__circle .fill {
  -webkit-transform: rotate(162deg);
  transform: rotate(162deg);
}
.cui .gauge.gauge--large[data-percentage='90'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(324deg);
  transform: rotate(324deg);
}
.cui .gauge.gauge--large[data-percentage='91'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='91'] .gauge__circle .fill {
  -webkit-transform: rotate(163.8deg);
  transform: rotate(163.8deg);
}
.cui .gauge.gauge--large[data-percentage='91'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}
.cui .gauge.gauge--large[data-percentage='92'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='92'] .gauge__circle .fill {
  -webkit-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.cui .gauge.gauge--large[data-percentage='92'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}
.cui .gauge.gauge--large[data-percentage='93'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='93'] .gauge__circle .fill {
  -webkit-transform: rotate(167.4deg);
  transform: rotate(167.4deg);
}
.cui .gauge.gauge--large[data-percentage='93'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}
.cui .gauge.gauge--large[data-percentage='94'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='94'] .gauge__circle .fill {
  -webkit-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.cui .gauge.gauge--large[data-percentage='94'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
}
.cui .gauge.gauge--large[data-percentage='95'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='95'] .gauge__circle .fill {
  -webkit-transform: rotate(171deg);
  transform: rotate(171deg);
}
.cui .gauge.gauge--large[data-percentage='95'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(342deg);
  transform: rotate(342deg);
}
.cui .gauge.gauge--large[data-percentage='96'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='96'] .gauge__circle .fill {
  -webkit-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.cui .gauge.gauge--large[data-percentage='96'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}
.cui .gauge.gauge--large[data-percentage='97'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='97'] .gauge__circle .fill {
  -webkit-transform: rotate(174.6deg);
  transform: rotate(174.6deg);
}
.cui .gauge.gauge--large[data-percentage='97'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}
.cui .gauge.gauge--large[data-percentage='98'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='98'] .gauge__circle .fill {
  -webkit-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.cui .gauge.gauge--large[data-percentage='98'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}
.cui .gauge.gauge--large[data-percentage='99'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='99'] .gauge__circle .fill {
  -webkit-transform: rotate(178.2deg);
  transform: rotate(178.2deg);
}
.cui .gauge.gauge--large[data-percentage='99'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
}
.cui .gauge.gauge--large[data-percentage='100'] .gauge__circle .mask.full,
.cui .gauge.gauge--large[data-percentage='100'] .gauge__circle .fill {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cui .gauge.gauge--large[data-percentage='100'] .gauge__circle .fill.fix {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.cui .gauge.gauge--alt .gauge__inset {
  background-color: #ffffff;
}

.cui .gauge-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin: unset;
}
.cui .gauge-container.disabled {
  opacity: 0.75;
}
.cui .gauge-container .gauge__label {
  font-weight: 500;
  margin-top: 10px;
}

.cui .hero {
  color: #ffffff;
  position: relative;
  overflow: hidden;
}
.cui .hero__title {
  font-size: 2.8rem;
}
@media (min-width: 768px) {
  .cui .hero__title {
    font-size: 3.2rem;
  }
}
@media (min-width: 992px) {
  .cui .hero__title {
    font-size: 3.8rem;
  }
}
@media (min-width: 1200px) {
  .cui .hero__title {
    font-size: 4.4rem;
  }
}
.cui .hero--ltgray {
  color: #58585b;
  position: relative;
  background: #f2f2f2;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .hero--ltgray:after {
  background: none;
}
.cui .hero--dkgray {
  color: #ffffff;
  position: relative;
  background: #58585b;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .hero--dkgray:after {
  background: none;
}
.cui .hero--green {
  color: #003c56;
  position: relative;
  background: #6ebe4a;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .hero--green:after {
  background: none;
}
.cui .hero--blue {
  color: #003c56;
  position: relative;
  background: #00bceb;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .hero--blue:after {
  background: none;
}
.cui .hero--vibblue {
  color: #ffffff;
  position: relative;
  background: #017cad;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .hero--vibblue:after {
  background: none;
}
.cui .hero--indigo {
  color: #ffffff;
  position: relative;
  background: #005073;
  box-shadow: none;
  border: 1px solid transparent;
}
.cui .hero--indigo:after {
  background: none;
}
.cui .hero--skinny .hero__content {
  padding: 10px 0 5px;
}
.cui .hero--compressed .hero__content {
  padding: 10px 0 5px;
}
.cui .hero--regular .hero__content {
  padding: 40px 0 30px;
}
.cui .hero--loose .hero__content {
  padding: 60px 0 100px;
}
.cui .hero--bordered {
  border: 1px solid #dfdfdf;
}
.cui .hero__content {
  position: relative;
  padding: 40px 0 30px;
  max-width: 100%;
}
.cui .hero__toolbar {
  position: relative;
  margin-bottom: 20px;
}
.cui .hero__toolbar:after {
  clear: both !important;
  content: '' !important;
  display: table !important;
}
@media (min-width: 992px) {
  .cui .hero__toolbar {
    margin-bottom: 40px;
  }
}
.cui .hero__toolbar .button {
  margin-right: 10px;
}
.cui .hero__toolbar .button:last-child {
  margin-right: 0;
}
.cui .hero__content,
.cui .hero__toolbar {
  z-index: 1;
}
.cui .hero--split {
  display: flex;
  background: none;
}
.cui .hero--split .hero__content,
.cui .hero--split .hero__marquee {
  width: 50%;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cui .hero--split:after {
  background: none;
}
.cui .hero--split-25 .hero__content {
  width: 25%;
}
.cui .hero--split-25 .hero__marquee {
  width: 75%;
}
.cui .hero--split-33 .hero__content {
  width: 33%;
}
.cui .hero--split-33 .hero__marquee {
  width: 66%;
}
.cui .hero--split-50 .hero__content {
  width: 50%;
}
.cui .hero--split-50 .hero__marquee {
  width: 50%;
}
.cui .hero--split-66 .hero__content {
  width: 66%;
}
.cui .hero--split-66 .hero__marquee {
  width: 33%;
}
.cui .hero--split-75 .hero__content {
  width: 75%;
}
.cui .hero--split-75 .hero__marquee {
  width: 25%;
}
@media (max-width: 767.98px) {
  .cui .hero {
    flex-wrap: wrap;
  }
  .cui .hero .hero__content,
  .cui .hero .hero__marquee {
    width: 100%;
  }
}

.cui .loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
  text-align: center;
}
.cui .loader--app-loading {
  background-color: #ffffff;
  background: radial-gradient(ellipse at center, #ffffff 15%, #dfdfdf 75%);
  z-index: 10000;
}
.cui .loader--app-loading span.icon-cisco {
  padding: 10px;
  color: #c6c7ca;
  font-size: 4.8rem;
  line-height: 1;
}
.cui .loader--page-loading {
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  z-index: 1060;
}

.cui .loading-spinner:focus {
  outline-color: #00bceb;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
}

.cui .loading-spinner .wrapper .wheel {
  position: relative;
  visibility: visible;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  border: 2px solid #dfdfdf;
  border-left-color: #00bceb;
  -webkit-animation: load 1.1s infinite linear;
  animation: load 1.1s infinite linear;
}

.cui .loading-spinner--indigo .wrapper .wheel {
  border-left-color: #005073;
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.cui .loading-dots {
  padding: 10px;
  text-align: center;
}
.cui .loading-dots:focus {
  outline-color: #00bceb;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
}
.cui .loading-dots.loading-dots--muted > span {
  background: #c6c7ca;
}
.cui .loading-dots.loading-dots--info > span {
  background: #00bceb;
}
.cui .loading-dots.loading-dots--success > span {
  background: #6ebe4a;
}
.cui .loading-dots > span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 2px;
  background: #ffffff;
  border-radius: 50%;
  -webkit-animation: pulse 1.4s infinite both ease-in-out;
  animation: pulse 1.4s infinite both ease-in-out;
}
.cui .loading-dots > span:nth-child(1) {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.cui .loading-dots > span:nth-child(2) {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.cui .modal-dialog .loader--page-loading {
  top: 20px;
  right: 10px;
  bottom: 20px;
  left: 10px;
}
@media (min-width: 768px) {
  .cui .modal-dialog .loader--page-loading {
    top: 30px;
    right: 20px;
    bottom: 30px;
    left: 20px;
  }
}

.cui .modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #c4c7cc;
  opacity: 0.65;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1040;
}

.cui .modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  pointer-events: none;
  z-index: 1050;
  pointer-events: auto;
}
.cui .modal.show .modal__dialog,
.cui .modal.show .modal-dialog {
  opacity: 1;
}
.cui .modal.fade .modal__dialog,
.cui .modal.fade .modal-dialog {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}
.cui .modal .modal__dialog,
.cui .modal .modal-dialog {
  width: 100%;
  max-width: 767px;
  margin: auto;
  padding: 20px;
}
@media (max-width: 767.98px) {
  .cui .modal .modal__dialog,
  .cui .modal .modal-dialog {
    padding: 0;
  }
}
.cui .modal .modal__content,
.cui .modal .modal-content {
  position: relative;
  display: block;
  text-align: center;
  padding: 60px 40px;
  background-color: #ffffff;
  color: #58585b;
  border-radius: 3px;
  border: 0 none;
  box-shadow: 0 10px 24px -6px rgba(0, 0, 0, 0.25);
}
@media (max-width: 767.98px) {
  .cui .modal .modal__content,
  .cui .modal .modal-content {
    padding: 20px;
  }
}
.cui .modal .modal__content .modal__close,
.cui .modal .modal-content .modal__close {
  font-size: 3.4rem;
  line-height: 3.4rem;
  position: absolute;
  right: 20px;
  top: 20px;
  color: #9e9ea2;
}
.cui .modal .modal__content .modal__close:hover,
.cui .modal .modal-content .modal__close:hover {
  color: #017cad;
  text-decoration: none;
}
.cui .modal .modal__content .modal__header,
.cui .modal .modal-content .modal__header {
  margin-bottom: 30px;
}
.cui .modal .modal__content .modal__header .modal__title,
.cui .modal .modal-content .modal__header .modal__title {
  font-size: 4.8rem;
  font-weight: 100;
}
@media (max-width: 767.98px) {
  .cui .modal .modal__content .modal__header .modal__title,
  .cui .modal .modal-content .modal__header .modal__title {
    font-size: 3.2rem;
  }
}
.cui .modal .modal__content .modal__footer,
.cui .modal .modal-content .modal__footer {
  margin-top: 40px;
}
.cui .modal--small .modal__dialog,
.cui .modal--small .modal-dialog {
  max-width: 575px;
}
.cui .modal--large .modal__dialog,
.cui .modal--large .modal-dialog {
  max-width: 991px;
}

.cui--animated .modal {
  -webkit-animation: blowup 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards,
    fade-in 0.25s 1 linear;
  animation: blowup 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards,
    fade-in 0.25s 1 linear;
}

.cui .progressbar {
  margin: 20px 0;
  background: #dfdfdf;
  border-radius: 2rem;
  height: 5px;
}
.cui .progressbar__fill {
  display: block;
  border-radius: 2rem;
}
.cui .progressbar__label {
  display: block;
  margin-top: 2px;
}
.cui .progressbar .progressbar__fill {
  height: 5px;
}
.cui .progressbar.progressbar--small {
  height: 2px;
}
.cui .progressbar.progressbar--small .progressbar__fill {
  height: 2px;
}
.cui .progressbar.progressbar--large {
  height: 10px;
}
.cui .progressbar.progressbar--large .progressbar__fill {
  height: 10px;
}
.cui .progressbar .progressbar__fill {
  background-color: #00bceb;
}
.cui .progressbar.progressbar--success .progressbar__fill {
  background-color: #6ebe4a;
}
.cui .progressbar.progressbar--warning .progressbar__fill {
  background-color: #fbab18;
}
.cui .progressbar.progressbar--indigo .progressbar__fill {
  background-color: #005073;
}
.cui .progressbar[data-percentage='1'] .progressbar__fill {
  width: 1%;
}
.cui .progressbar[data-percentage='2'] .progressbar__fill {
  width: 2%;
}
.cui .progressbar[data-percentage='3'] .progressbar__fill {
  width: 3%;
}
.cui .progressbar[data-percentage='4'] .progressbar__fill {
  width: 4%;
}
.cui .progressbar[data-percentage='5'] .progressbar__fill {
  width: 5%;
}
.cui .progressbar[data-percentage='6'] .progressbar__fill {
  width: 6%;
}
.cui .progressbar[data-percentage='7'] .progressbar__fill {
  width: 7%;
}
.cui .progressbar[data-percentage='8'] .progressbar__fill {
  width: 8%;
}
.cui .progressbar[data-percentage='9'] .progressbar__fill {
  width: 9%;
}
.cui .progressbar[data-percentage='10'] .progressbar__fill {
  width: 10%;
}
.cui .progressbar[data-percentage='11'] .progressbar__fill {
  width: 11%;
}
.cui .progressbar[data-percentage='12'] .progressbar__fill {
  width: 12%;
}
.cui .progressbar[data-percentage='13'] .progressbar__fill {
  width: 13%;
}
.cui .progressbar[data-percentage='14'] .progressbar__fill {
  width: 14%;
}
.cui .progressbar[data-percentage='15'] .progressbar__fill {
  width: 15%;
}
.cui .progressbar[data-percentage='16'] .progressbar__fill {
  width: 16%;
}
.cui .progressbar[data-percentage='17'] .progressbar__fill {
  width: 17%;
}
.cui .progressbar[data-percentage='18'] .progressbar__fill {
  width: 18%;
}
.cui .progressbar[data-percentage='19'] .progressbar__fill {
  width: 19%;
}
.cui .progressbar[data-percentage='20'] .progressbar__fill {
  width: 20%;
}
.cui .progressbar[data-percentage='21'] .progressbar__fill {
  width: 21%;
}
.cui .progressbar[data-percentage='22'] .progressbar__fill {
  width: 22%;
}
.cui .progressbar[data-percentage='23'] .progressbar__fill {
  width: 23%;
}
.cui .progressbar[data-percentage='24'] .progressbar__fill {
  width: 24%;
}
.cui .progressbar[data-percentage='25'] .progressbar__fill {
  width: 25%;
}
.cui .progressbar[data-percentage='26'] .progressbar__fill {
  width: 26%;
}
.cui .progressbar[data-percentage='27'] .progressbar__fill {
  width: 27%;
}
.cui .progressbar[data-percentage='28'] .progressbar__fill {
  width: 28%;
}
.cui .progressbar[data-percentage='29'] .progressbar__fill {
  width: 29%;
}
.cui .progressbar[data-percentage='30'] .progressbar__fill {
  width: 30%;
}
.cui .progressbar[data-percentage='31'] .progressbar__fill {
  width: 31%;
}
.cui .progressbar[data-percentage='32'] .progressbar__fill {
  width: 32%;
}
.cui .progressbar[data-percentage='33'] .progressbar__fill {
  width: 33%;
}
.cui .progressbar[data-percentage='34'] .progressbar__fill {
  width: 34%;
}
.cui .progressbar[data-percentage='35'] .progressbar__fill {
  width: 35%;
}
.cui .progressbar[data-percentage='36'] .progressbar__fill {
  width: 36%;
}
.cui .progressbar[data-percentage='37'] .progressbar__fill {
  width: 37%;
}
.cui .progressbar[data-percentage='38'] .progressbar__fill {
  width: 38%;
}
.cui .progressbar[data-percentage='39'] .progressbar__fill {
  width: 39%;
}
.cui .progressbar[data-percentage='40'] .progressbar__fill {
  width: 40%;
}
.cui .progressbar[data-percentage='41'] .progressbar__fill {
  width: 41%;
}
.cui .progressbar[data-percentage='42'] .progressbar__fill {
  width: 42%;
}
.cui .progressbar[data-percentage='43'] .progressbar__fill {
  width: 43%;
}
.cui .progressbar[data-percentage='44'] .progressbar__fill {
  width: 44%;
}
.cui .progressbar[data-percentage='45'] .progressbar__fill {
  width: 45%;
}
.cui .progressbar[data-percentage='46'] .progressbar__fill {
  width: 46%;
}
.cui .progressbar[data-percentage='47'] .progressbar__fill {
  width: 47%;
}
.cui .progressbar[data-percentage='48'] .progressbar__fill {
  width: 48%;
}
.cui .progressbar[data-percentage='49'] .progressbar__fill {
  width: 49%;
}
.cui .progressbar[data-percentage='50'] .progressbar__fill {
  width: 50%;
}
.cui .progressbar[data-percentage='51'] .progressbar__fill {
  width: 51%;
}
.cui .progressbar[data-percentage='52'] .progressbar__fill {
  width: 52%;
}
.cui .progressbar[data-percentage='53'] .progressbar__fill {
  width: 53%;
}
.cui .progressbar[data-percentage='54'] .progressbar__fill {
  width: 54%;
}
.cui .progressbar[data-percentage='55'] .progressbar__fill {
  width: 55%;
}
.cui .progressbar[data-percentage='56'] .progressbar__fill {
  width: 56%;
}
.cui .progressbar[data-percentage='57'] .progressbar__fill {
  width: 57%;
}
.cui .progressbar[data-percentage='58'] .progressbar__fill {
  width: 58%;
}
.cui .progressbar[data-percentage='59'] .progressbar__fill {
  width: 59%;
}
.cui .progressbar[data-percentage='60'] .progressbar__fill {
  width: 60%;
}
.cui .progressbar[data-percentage='61'] .progressbar__fill {
  width: 61%;
}
.cui .progressbar[data-percentage='62'] .progressbar__fill {
  width: 62%;
}
.cui .progressbar[data-percentage='63'] .progressbar__fill {
  width: 63%;
}
.cui .progressbar[data-percentage='64'] .progressbar__fill {
  width: 64%;
}
.cui .progressbar[data-percentage='65'] .progressbar__fill {
  width: 65%;
}
.cui .progressbar[data-percentage='66'] .progressbar__fill {
  width: 66%;
}
.cui .progressbar[data-percentage='67'] .progressbar__fill {
  width: 67%;
}
.cui .progressbar[data-percentage='68'] .progressbar__fill {
  width: 68%;
}
.cui .progressbar[data-percentage='69'] .progressbar__fill {
  width: 69%;
}
.cui .progressbar[data-percentage='70'] .progressbar__fill {
  width: 70%;
}
.cui .progressbar[data-percentage='71'] .progressbar__fill {
  width: 71%;
}
.cui .progressbar[data-percentage='72'] .progressbar__fill {
  width: 72%;
}
.cui .progressbar[data-percentage='73'] .progressbar__fill {
  width: 73%;
}
.cui .progressbar[data-percentage='74'] .progressbar__fill {
  width: 74%;
}
.cui .progressbar[data-percentage='75'] .progressbar__fill {
  width: 75%;
}
.cui .progressbar[data-percentage='76'] .progressbar__fill {
  width: 76%;
}
.cui .progressbar[data-percentage='77'] .progressbar__fill {
  width: 77%;
}
.cui .progressbar[data-percentage='78'] .progressbar__fill {
  width: 78%;
}
.cui .progressbar[data-percentage='79'] .progressbar__fill {
  width: 79%;
}
.cui .progressbar[data-percentage='80'] .progressbar__fill {
  width: 80%;
}
.cui .progressbar[data-percentage='81'] .progressbar__fill {
  width: 81%;
}
.cui .progressbar[data-percentage='82'] .progressbar__fill {
  width: 82%;
}
.cui .progressbar[data-percentage='83'] .progressbar__fill {
  width: 83%;
}
.cui .progressbar[data-percentage='84'] .progressbar__fill {
  width: 84%;
}
.cui .progressbar[data-percentage='85'] .progressbar__fill {
  width: 85%;
}
.cui .progressbar[data-percentage='86'] .progressbar__fill {
  width: 86%;
}
.cui .progressbar[data-percentage='87'] .progressbar__fill {
  width: 87%;
}
.cui .progressbar[data-percentage='88'] .progressbar__fill {
  width: 88%;
}
.cui .progressbar[data-percentage='89'] .progressbar__fill {
  width: 89%;
}
.cui .progressbar[data-percentage='90'] .progressbar__fill {
  width: 90%;
}
.cui .progressbar[data-percentage='91'] .progressbar__fill {
  width: 91%;
}
.cui .progressbar[data-percentage='92'] .progressbar__fill {
  width: 92%;
}
.cui .progressbar[data-percentage='93'] .progressbar__fill {
  width: 93%;
}
.cui .progressbar[data-percentage='94'] .progressbar__fill {
  width: 94%;
}
.cui .progressbar[data-percentage='95'] .progressbar__fill {
  width: 95%;
}
.cui .progressbar[data-percentage='96'] .progressbar__fill {
  width: 96%;
}
.cui .progressbar[data-percentage='97'] .progressbar__fill {
  width: 97%;
}
.cui .progressbar[data-percentage='98'] .progressbar__fill {
  width: 98%;
}
.cui .progressbar[data-percentage='99'] .progressbar__fill {
  width: 99%;
}
.cui .progressbar[data-percentage='100'] .progressbar__fill {
  width: 100%;
}

.cui.cui--animated .progressbar__fill {
  transition: width 1s;
}

.cui .rating {
  margin: 0;
  font-size: 0;
  text-align: center;
}
.cui .rating:after {
  clear: both !important;
  content: '' !important;
  display: table !important;
}
.cui .rating.rated {
  pointer-events: none;
}
.cui .rating--alt .rating__star.active,
.cui .rating--alt .rating__star:hover,
.cui .rating--alt .rating__star:hover ~ .rating__star {
  color: #efb73e;
}
.cui .rating--small .rating__star {
  font-size: 1.2rem;
  margin-right: 5px;
}
.cui .rating--large .rating__star {
  font-size: 3rem;
  margin-right: 20px;
}

.cui .rating__stars {
  display: inline-block;
}

.cui .rating__star {
  float: right;
  margin-right: 8px;
  color: #c6c7ca;
  cursor: pointer;
  font-size: 2rem;
  line-height: 1;
  transition: color 0.2s ease;
}
.cui .rating__star:first-child {
  margin-right: 0;
}
.cui .rating__star:focus {
  outline-color: #00bceb;
  outline-offset: 1px;
  outline-width: thin;
  outline-style: dashed;
}
.cui .rating__star.active,
.cui .rating__star:hover,
.cui .rating__star:hover ~ .rating__star {
  color: #00bceb;
  text-decoration: none;
}
.cui .rating__star.active {
  -webkit-animation: bounce 1s linear;
  animation: bounce 1s linear;
}

.cui .ui-steps {
  list-style: none;
  display: flex;
  align-items: top;
  justify-content: space-around;
  max-width: 900px;
  margin: 0 auto 20px auto;
  padding: 20px 0;
  text-align: center;
}
.cui .ui-steps ul:not(:first-child) {
  padding-left: 20px;
}
.cui .ui-steps .ui-step:before {
  top: 24px;
}
.cui .ui-steps .ui-step .step__icon {
  width: 48px;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  line-height: 4.8rem;
  font-size: 1.8rem;
}
.cui .ui-steps--small .ui-step:before {
  top: 16px;
}
.cui .ui-steps--small .ui-step .step__icon {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  line-height: 3.2rem;
  font-size: 1.2rem;
}
.cui .ui-steps--large .ui-step:before {
  top: 32px;
}
.cui .ui-steps--large .ui-step .step__icon {
  width: 64px;
  min-width: 64px;
  max-width: 64px;
  height: 64px;
  line-height: 6.4rem;
  font-size: 2.8rem;
}
.cui .ui-steps .ui-step .step__icon {
  background: #ffffff;
  color: #74747b;
  box-shadow: inset 0 0 0 1px #dfdfdf;
  border: 3px solid #ffffff;
}
.cui .ui-steps .ui-step .step__label {
  color: #74747b;
}
.cui .ui-steps .ui-step.active {
  color: #ffffff;
}
.cui .ui-steps .ui-step.active .step__icon {
  background: #00bceb;
  color: #ffffff;
  box-shadow: inset 0 0 0 1px #00bceb;
  border: 3px solid #ffffff;
  box-shadow: 0 0 0 1px #00bceb;
}
.cui .ui-steps .ui-step.active .step__label {
  color: #58585b;
  font-weight: bold;
}
.cui .ui-steps .ui-step.visited {
  color: #00bceb;
}
.cui .ui-steps .ui-step.visited .step__icon {
  background: #ffffff;
  color: #00bceb;
  box-shadow: inset 0 0 0 1px #00bceb;
  border: 3px solid #ffffff;
}
.cui .ui-steps .ui-step.visited .step__label {
  color: #58585b;
}
.cui .ui-steps .ui-step.visited.ui-step--alt .step__icon {
  background: #ffffff;
}
.cui .ui-steps .ui-step.active:before,
.cui .ui-steps .ui-step.visited:before {
  background: #00bceb;
}
.cui .ui-steps .ui-step:before {
  background: #dfdfdf;
}
.cui .ui-steps--indigo .ui-step .step__icon {
  background: #ffffff;
  color: #74747b;
  box-shadow: inset 0 0 0 1px #dfdfdf;
  border: 3px solid #ffffff;
}
.cui .ui-steps--indigo .ui-step .step__label {
  color: #74747b;
}
.cui .ui-steps--indigo .ui-step.active {
  color: #ffffff;
}
.cui .ui-steps--indigo .ui-step.active .step__icon {
  background: #005073;
  color: #ffffff;
  box-shadow: inset 0 0 0 1px #005073;
  border: 3px solid #ffffff;
  box-shadow: 0 0 0 1px #005073;
}
.cui .ui-steps--indigo .ui-step.active .step__label {
  color: #58585b;
  font-weight: bold;
}
.cui .ui-steps--indigo .ui-step.visited {
  color: #005073;
}
.cui .ui-steps--indigo .ui-step.visited .step__icon {
  background: #ffffff;
  color: #005073;
  box-shadow: inset 0 0 0 1px #005073;
  border: 3px solid #ffffff;
}
.cui .ui-steps--indigo .ui-step.visited .step__label {
  color: #58585b;
}
.cui .ui-steps--indigo .ui-step.visited.ui-step--alt .step__icon {
  background: #ffffff;
}
.cui .ui-steps--indigo .ui-step.active:before,
.cui .ui-steps--indigo .ui-step.visited:before {
  background: #005073;
}
.cui .ui-steps--indigo .ui-step:before {
  background: #dfdfdf;
}
.cui .ui-steps--success .ui-step .step__icon {
  background: #ffffff;
  color: #74747b;
  box-shadow: inset 0 0 0 1px #dfdfdf;
  border: 3px solid #ffffff;
}
.cui .ui-steps--success .ui-step .step__label {
  color: #74747b;
}
.cui .ui-steps--success .ui-step.active {
  color: #ffffff;
}
.cui .ui-steps--success .ui-step.active .step__icon {
  background: #6ebe4a;
  color: #ffffff;
  box-shadow: inset 0 0 0 1px #6ebe4a;
  border: 3px solid #ffffff;
  box-shadow: 0 0 0 1px #6ebe4a;
}
.cui .ui-steps--success .ui-step.active .step__label {
  color: #58585b;
  font-weight: bold;
}
.cui .ui-steps--success .ui-step.visited {
  color: #6ebe4a;
}
.cui .ui-steps--success .ui-step.visited .step__icon {
  background: #ffffff;
  color: #6ebe4a;
  box-shadow: inset 0 0 0 1px #6ebe4a;
  border: 3px solid #ffffff;
}
.cui .ui-steps--success .ui-step.visited .step__label {
  color: #58585b;
}
.cui .ui-steps--success .ui-step.visited.ui-step--alt .step__icon {
  background: #ffffff;
}
.cui .ui-steps--success .ui-step.active:before,
.cui .ui-steps--success .ui-step.visited:before {
  background: #6ebe4a;
}
.cui .ui-steps--success .ui-step:before {
  background: #dfdfdf;
}
.cui .ui-steps--vibblue .ui-step .step__icon {
  background: #ffffff;
  color: #74747b;
  box-shadow: inset 0 0 0 1px #dfdfdf;
  border: 3px solid #ffffff;
}
.cui .ui-steps--vibblue .ui-step .step__label {
  color: #74747b;
}
.cui .ui-steps--vibblue .ui-step.active {
  color: #ffffff;
}
.cui .ui-steps--vibblue .ui-step.active .step__icon {
  background: #017cad;
  color: #ffffff;
  box-shadow: inset 0 0 0 1px #017cad;
  border: 3px solid #ffffff;
  box-shadow: 0 0 0 1px #017cad;
}
.cui .ui-steps--vibblue .ui-step.active .step__label {
  color: #58585b;
  font-weight: bold;
}
.cui .ui-steps--vibblue .ui-step.visited {
  color: #017cad;
}
.cui .ui-steps--vibblue .ui-step.visited .step__icon {
  background: #ffffff;
  color: #017cad;
  box-shadow: inset 0 0 0 1px #017cad;
  border: 3px solid #ffffff;
}
.cui .ui-steps--vibblue .ui-step.visited .step__label {
  color: #58585b;
}
.cui .ui-steps--vibblue .ui-step.visited.ui-step--alt .step__icon {
  background: #ffffff;
}
.cui .ui-steps--vibblue .ui-step.active:before,
.cui .ui-steps--vibblue .ui-step.visited:before {
  background: #017cad;
}
.cui .ui-steps--vibblue .ui-step:before {
  background: #dfdfdf;
}
.cui .ui-steps .ui-step {
  position: relative;
  flex: 1;
}
.cui .ui-steps .ui-step .step__icon {
  margin: 0 auto 10px auto;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 1;
}
.cui .ui-steps .ui-step:first-child:before {
  display: none;
}
.cui .ui-steps .ui-step:before {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  left: -50%;
  background: #dfdfdf;
}
.cui .ui-steps .ui-step--alt .step__icon {
  background: #ffffff;
}
.cui .ui-steps--vertical {
  display: inline;
  text-align: left;
}
.cui .ui-steps--vertical .ui-step {
  display: flex;
  margin-bottom: 20px;
}
.cui .ui-steps--vertical .ui-step .step__icon {
  flex: 1;
  margin: 0;
}
.cui .ui-steps--vertical .ui-step .step__label {
  flex: 2;
  align-self: center;
  padding-left: 20px;
  color: #58585b;
}
.cui .ui-steps--vertical .ui-step:last-child:after {
  display: none;
}
.cui .ui-steps--vertical .ui-step:before,
.cui .ui-steps--vertical .ui-step:after {
  display: none;
}

.cui ul.tabs,
.cui .nav-tabs {
  list-style: none;
  display: block;
  position: relative;
  font-size: 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 0;
}
.cui ul.tabs ul:not(:first-child),
.cui .nav-tabs ul:not(:first-child) {
  padding-left: 20px;
}
.cui ul.tabs a:active,
.cui ul.tabs a:focus,
.cui .nav-tabs a:active,
.cui .nav-tabs a:focus {
  text-decoration: none;
}
.header .cui ul.tabs,
.header .cui .nav-tabs {
  display: block;
}
@media (min-width: 768px) {
  .header .cui ul.tabs,
  .header .cui .nav-tabs {
    margin-top: 0;
  }
}
.cui ul.tabs > li,
.cui .nav-tabs > li {
  display: inline-block;
  font-size: 1.4rem;
  vertical-align: bottom;
  margin: 0 20px 2px 0;
}
.cui ul.tabs > li.active > a,
.cui ul.tabs > li:hover > a,
.cui .nav-tabs > li.active > a,
.cui .nav-tabs > li:hover > a {
  font-weight: normal;
  box-shadow: 0 -5px 0 #00bceb inset;
  color: #017cad;
}
.cui ul.tabs > li > a,
.cui .nav-tabs > li > a {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0 10px 0;
  color: #58585b;
  line-height: 1;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cui ul.tabs > li > a:hover,
.cui .nav-tabs > li > a:hover {
  color: #017cad;
  text-decoration: none;
}
.cui ul.tabs > li > a:focus,
.cui .nav-tabs > li > a:focus {
  outline: none;
  box-shadow: 0 -5px 0 #00bceb inset;
  color: #017cad;
}
.cui ul.tabs .tab__heading,
.cui ul.tabs tab-heading,
.cui ul.tabs .tab-heading,
.cui .nav-tabs .tab__heading,
.cui .nav-tabs tab-heading,
.cui .nav-tabs .tab-heading {
  display: block;
  margin: 0;
  padding: 10px 0;
}
.cui ul.tabs .tab__heading:after,
.cui ul.tabs tab-heading:after,
.cui ul.tabs .tab-heading:after,
.cui .nav-tabs .tab__heading:after,
.cui .nav-tabs tab-heading:after,
.cui .nav-tabs .tab-heading:after {
  display: block;
  height: 0;
  content: attr(title);
  font-weight: bold;
  visibility: hidden;
}
.cui ul.tabs--tall .tab__heading,
.cui ul.tabs--tall tab-heading,
.cui ul.tabs--tall .tab-heading,
.cui .nav-tabs--tall .tab__heading,
.cui .nav-tabs--tall tab-heading,
.cui .nav-tabs--tall .tab-heading {
  padding: 20px 0;
}
.cui ul.tabs.tabs--vertical,
.cui .nav-tabs.tabs--vertical {
  box-shadow: none;
}
.cui ul.tabs.tabs--vertical > li,
.cui .nav-tabs.tabs--vertical > li {
  display: block;
  font-size: 1.4rem;
  vertical-align: unset;
  margin: 0;
}
.cui ul.tabs.tabs--vertical > li + li,
.cui .nav-tabs.tabs--vertical > li + li {
  margin: 5px 0;
}
.cui ul.tabs.tabs--vertical > li > a,
.cui .nav-tabs.tabs--vertical > li > a {
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  height: 40px;
}
.cui ul.tabs.tabs--vertical > li > a.tab__heading,
.cui .nav-tabs.tabs--vertical > li > a.tab__heading {
  margin: 0 5px;
}
.cui ul.tabs.tabs--vertical > li > a:focus,
.cui .nav-tabs.tabs--vertical > li > a:focus {
  box-shadow: 5px 0 #00bceb inset;
}
.cui ul.tabs.tabs--vertical > li.active > a,
.cui .nav-tabs.tabs--vertical > li.active > a {
  box-shadow: 0 none;
  background-color: #00bceb;
  color: #ffffff;
}
.cui ul.tabs.tabs--vertical > li.active:hover > a,
.cui .nav-tabs.tabs--vertical > li.active:hover > a {
  color: #ffffff;
}
.cui ul.tabs.tabs--vertical > li:hover > a,
.cui .nav-tabs.tabs--vertical > li:hover > a {
  box-shadow: 5px 0 #00bceb inset;
}
.cui ul.tabs.tabs--vertical.tabs--border-right,
.cui .nav-tabs.tabs--vertical.tabs--border-right {
  border: 0 none;
  padding-right: 0;
}
@media (min-width: 768px) {
  .cui ul.tabs.tabs--vertical.tabs--border-right,
  .cui .nav-tabs.tabs--vertical.tabs--border-right {
    border-right: 1px solid #dfdfdf;
    padding-right: 1px;
  }
}

.cui .tab-icon {
  margin: 0;
}

.cui .tab-icon--status {
  margin: 0 5px 0 -5px;
}

.cui .tab-icon--close {
  margin: 0 -5px 0 5px;
  color: #9e9ea2;
  padding: 0;
}
.cui .tab-icon--close:hover {
  color: #e2231a;
  opacity: 1;
}

.header .cui .tab-content {
  display: none;
}

.cui .tab-pane {
  display: none;
}
.cui .tab-pane:after {
  clear: both !important;
  content: '' !important;
  display: table !important;
}
.cui .tab-pane.active {
  display: block;
  -webkit-animation: fade-in 0.5s;
  animation: fade-in 0.5s;
}

.cui .secondary-tabs .tabs,
.cui .secondary-tabs .nav-tabs {
  display: block;
  box-shadow: 0 -1px 0 #dfdfdf inset;
}

.cui .secondary-tabs .tab-pane {
  padding: 20px 0;
}

.cui ul.tabs.list li + li {
  margin: 0;
}

.cui--animated .tabs > li:hover > a,
.cui--animated .nav-tabs > li:hover > a {
  transition: box-shadow 0.2s linear;
}

.cui .toast {
  color: #58585b;
  position: relative;
  background: #ffffff;
  box-shadow: none;
  border: 1px solid transparent;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  max-width: 300px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.15);
}
.cui .toast__icon {
  min-width: 20px;
  font-size: 2.1rem;
  line-height: 2.1rem;
}
.cui .toast__title {
  font-weight: bold;
}
.cui .toast--compressed {
  padding: 5px;
}
.cui .toast--regular {
  padding: 10px;
}
.cui .toast--loose {
  padding: 20px;
}

.cui .toast + .toast {
  margin-top: 10px;
}

.cui .toast__icon + .toast__body {
  margin-left: 10px;
}

.cui.cui--compressed .toast {
  padding: 5px;
}

.cui .timeline {
  position: relative;
}
.cui .timeline__item {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  margin: 20px auto;
  padding: 10px;
  width: 100%;
}
.cui .timeline__item:hover,
.cui .timeline__item:focus,
.cui .timeline__item:active {
  cursor: default;
  background-color: rgba(0, 0, 0, 0.05);
  transition: background-color 0.5s;
}
.cui .timeline--centered .timeline__item {
  align-items: center;
}
.cui .timeline--block .timeline__content {
  width: 100%;
}
.cui .timeline__icon {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 139px;
  z-index: 2;
  border: 5px solid #f5f5f6;
  background-color: #58585b;
}
.cui .timeline__time {
  float: left;
  width: 125px;
  min-width: 125px;
  text-align: right;
  font-style: italic;
  padding-right: 10px;
}
.cui .timeline__content {
  margin-left: 50px;
}
.cui .timeline:after {
  background-color: #f5f5f6;
  content: ' ';
  position: absolute;
  display: block;
  width: 2px;
  top: 0;
  left: 150px;
  bottom: 0;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .cui .timeline__time {
    font-size: 1.2rem;
  }
}

.cui .timeline__icon.timeline--success {
  background-color: #6ebe4a;
}

.cui .timeline__icon.timeline--info {
  background-color: #00bceb;
}

.cui .timeline__icon.timeline--warning {
  background-color: #fbab18;
}

.cui .timeline__icon.timeline--danger {
  background-color: #e2231a;
}

.cui--animated .timeline .timeline__item:hover,
.cui--animated .timeline .timeline__item:focus,
.cui--animated .timeline .timeline__item:active,
.cui .timeline.timeline--animation .timeline__item:hover,
.cui .timeline.timeline--animation .timeline__item:focus,
.cui .timeline.timeline--animation .timeline__item:active {
  cursor: default;
  transition: background-color 0.5s;
}
.cui--animated .timeline .timeline__item:hover .timeline__icon,
.cui--animated .timeline .timeline__item:focus .timeline__icon,
.cui--animated .timeline .timeline__item:active .timeline__icon,
.cui .timeline.timeline--animation .timeline__item:hover .timeline__icon,
.cui .timeline.timeline--animation .timeline__item:focus .timeline__icon,
.cui .timeline.timeline--animation .timeline__item:active .timeline__icon {
  -webkit-animation: pulseShadow 2s infinite;
  animation: pulseShadow 2s infinite;
}
.cui--animated .timeline .timeline__item:hover .timeline__icon.timeline--info,
.cui--animated .timeline .timeline__item:focus .timeline__icon.timeline--info,
.cui--animated .timeline .timeline__item:active .timeline__icon.timeline--info,
.cui
  .timeline.timeline--animation
  .timeline__item:hover
  .timeline__icon.timeline--info,
.cui
  .timeline.timeline--animation
  .timeline__item:focus
  .timeline__icon.timeline--info,
.cui
  .timeline.timeline--animation
  .timeline__item:active
  .timeline__icon.timeline--info {
  -webkit-animation: pulseShadowInfo 2s infinite;
  animation: pulseShadowInfo 2s infinite;
}
.cui--animated
  .timeline
  .timeline__item:hover
  .timeline__icon.timeline--success,
.cui--animated
  .timeline
  .timeline__item:focus
  .timeline__icon.timeline--success,
.cui--animated
  .timeline
  .timeline__item:active
  .timeline__icon.timeline--success,
.cui
  .timeline.timeline--animation
  .timeline__item:hover
  .timeline__icon.timeline--success,
.cui
  .timeline.timeline--animation
  .timeline__item:focus
  .timeline__icon.timeline--success,
.cui
  .timeline.timeline--animation
  .timeline__item:active
  .timeline__icon.timeline--success {
  -webkit-animation: pulseShadowSuccess 2s infinite;
  animation: pulseShadowSuccess 2s infinite;
}
.cui--animated
  .timeline
  .timeline__item:hover
  .timeline__icon.timeline--warning,
.cui--animated
  .timeline
  .timeline__item:focus
  .timeline__icon.timeline--warning,
.cui--animated
  .timeline
  .timeline__item:active
  .timeline__icon.timeline--warning,
.cui
  .timeline.timeline--animation
  .timeline__item:hover
  .timeline__icon.timeline--warning,
.cui
  .timeline.timeline--animation
  .timeline__item:focus
  .timeline__icon.timeline--warning,
.cui
  .timeline.timeline--animation
  .timeline__item:active
  .timeline__icon.timeline--warning {
  -webkit-animation: pulseShadowWarning 2s infinite;
  animation: pulseShadowWarning 2s infinite;
}
.cui--animated .timeline .timeline__item:hover .timeline__icon.timeline--danger,
.cui--animated .timeline .timeline__item:focus .timeline__icon.timeline--danger,
.cui--animated
  .timeline
  .timeline__item:active
  .timeline__icon.timeline--danger,
.cui
  .timeline.timeline--animation
  .timeline__item:hover
  .timeline__icon.timeline--danger,
.cui
  .timeline.timeline--animation
  .timeline__item:focus
  .timeline__icon.timeline--danger,
.cui
  .timeline.timeline--animation
  .timeline__item:active
  .timeline__icon.timeline--danger {
  -webkit-animation: pulseShadowDanger 2s infinite;
  animation: pulseShadowDanger 2s infinite;
}

.cui .scorechart__layer {
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 10px;
}

.cui .scorechart__tile {
  border-top: 1px solid #dfdfdf;
  flex-direction: column;
  text-align: center;
  padding: 10px 40px;
}

.cui .scorechart__tile + .cui .scorechart__tile {
  border-left: 1px solid #dfdfdf;
}

.cui .scoreboard-flex {
  display: flex;
  flex-wrap: wrap;
}
.cui .scoreboard-flex.scoreboard-flex--fixed .panel {
  min-width: 100%;
}
@media (min-width: 768px) {
  .cui .scoreboard-flex.scoreboard-flex--fixed .panel {
    min-width: 300px;
  }
}
.cui .scoreboard-flex .panel {
  margin-right: 10px;
  margin-top: 10px;
}
.cui .scoreboard-flex .panel h1 {
  font-size: 6.4rem;
}
.cui .scoreboard-flex .panel p {
  max-width: 75px;
  text-align: left;
}

.cui .feature-blocks {
  margin: 40px 0;
}
.cui .feature-blocks:after {
  clear: both !important;
  content: '' !important;
  display: table !important;
}

.cui .feature-block__image {
  margin-bottom: 20px;
}
.cui .feature-block__image img {
  display: block;
  margin: 0 auto;
  max-width: 150px;
  height: auto;
}

.cui .feature {
  display: block;
  color: #58585b;
  margin-top: 20px;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  transition: all 0.15s linear;
  text-align: center;
}
.cui .feature:hover {
  cursor: pointer;
  text-decoration: none;
  -webkit-transform: scale3d(1.05, 1.05, 1.05);
  transform: scale3d(1.05, 1.05, 1.05);
  transition: all 0.15s ease;
}
@media (min-width: 768px) {
  .cui .feature {
    margin-top: 0;
    text-align: left;
  }
}
.cui .feature > .thumb {
  margin-bottom: 20px;
}

.cui .callout {
  border: 5px solid #e9e9e9;
  border-radius: 5px;
  text-align: center;
  margin: 20px 0;
  cursor: pointer;
}
.cui .callout:hover {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
}

.cui .grid {
  display: grid;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-template-rows: auto;
  grid-gap: 10px;
}
.cui .grid.grid--2up {
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}
.cui .grid.grid--3up {
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}
.cui .grid.grid--4up {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.cui .grid.grid--5up {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
.cui .grid.grid--selectable > *:hover {
  border: 1px solid #00bceb;
  cursor: pointer;
}

.cui .header {
  flex-shrink: 0;
  background: #ffffff;
  border-bottom: 1px solid #dfdfdf;
  z-index: 1030;
  overflow: visible;
}
.cui .header > div:last-child:empty {
  display: none;
}
.cui .header-bar {
  display: flex !important;
  align-items: center !important;
  height: 54px;
  height: 110px;
}
.cui .header-bar .btn.toggle-menu {
  z-index: 1031;
}
.cui .header-bar .btn.toggle-menu > span {
  font-weight: bold;
}
.cui .header-bar__logo {
  display: none;
  display: block;
  margin: 0 30px 0 0;
}
.cui .header-bar__logo:hover {
  text-decoration: none !important;
}
.cui .header-bar__logo span.icon-cisco {
  display: block;
  color: #00bceb;
  font-size: 3.5rem;
  line-height: 1;
}
.cui .header-bar__logo img {
  display: block;
  width: auto;
  height: 35px;
}
.cui .header-bar__main {
  flex: 1;
  align-self: stretch;
  margin-right: 24px;
  position: relative;
  position: relative;
}
.cui .header--compressed .header-bar {
  height: 54px;
}
.cui .header--compressed .header-bar__logo {
  margin: 0 20px 0 0;
}
.cui .header--compressed .header-bar__logo span.icon-cisco {
  font-size: 3rem;
}
.cui .header--regular .header-bar {
  height: 90px;
}
.cui .header--tall .header-bar {
  height: 130px;
}

.cui .header-heading {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-left: 0;
}
.cui .header-heading .page-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
  font-size: 2.4rem;
  font-weight: 200;
  margin: 0;
  font-size: 24px;
  max-width: inherit;
}
.cui .header-heading .link {
  padding: 7.5px;
}
.cui .header-heading .link > span {
  font-size: 1.6rem;
}

.cui .header-breadcrumbs {
  display: none;
  margin: 20px 0 18px 0;
  font-size: 1.2rem;
  display: block;
  position: absolute;
  top: 0;
  line-height: 1;
  z-index: 1;
}

.cui .breadcrumb-bar {
  display: block;
  margin-top: 10px;
  display: none;
}

.cui .header-utilities {
  display: none;
  display: flex;
  flex: 1;
}
.cui .header-utilities > * {
  margin: 0 15px 0 0;
}
.cui .header-utilities > *:first-child {
  margin-left: 20px;
}
.cui .header-utilities > *:last-child {
  margin-right: 0;
}

.cui .header-menus {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.cui .header-menus .nav.nav-tabs {
  display: none;
  display: block;
  overflow-x: auto;
}

.cui .header-toolbar {
  display: none;
  align-items: center;
  justify-content: center;
  display: flex !important;
  align-items: center !important;
}
.cui .header-toolbar > .dropdown .dropdown-toggle:after {
  content: normal;
}
.cui .header-toolbar .avatar {
  margin-right: 10px;
}

.cui .secondary-nav span[class^='icon-'] {
  font-size: 2rem;
}

.cui footer,
.cui .footer {
  display: flex;
  padding: 20px 0;
  background-color: #005073;
  color: #ffffff;
}
.cui footer .footer__links,
.cui footer .footer__legal,
.cui .footer .footer__links,
.cui .footer .footer__legal {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
}
.cui footer .footer__links ul.list,
.cui .footer .footer__links ul.list {
  -webkit-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 40px;
  column-gap: 40px;
}
.cui footer .footer__links ul.list li a,
.cui .footer .footer__links ul.list li a {
  color: #ffffff;
}
.cui footer .footer__links ul.list li a:hover,
.cui .footer .footer__links ul.list li a:hover {
  color: #ffffff;
  text-decoration: underline;
}
.cui footer .footer__legal .footer__logo,
.cui .footer .footer__legal .footer__logo {
  margin-right: 10px;
}
.cui footer .footer__legal .footer__logo a,
.cui .footer .footer__legal .footer__logo a {
  color: #ffffff;
}
.cui footer .footer__legal .footer__logo a .icon-cisco,
.cui .footer .footer__legal .footer__logo a .icon-cisco {
  font-size: 3.5rem;
}
@media (min-width: 1200px) {
  .cui footer.footer--basic .footer__links ul.list,
  .cui .footer.footer--basic .footer__links ul.list {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .cui footer.footer--basic .footer__links ul.list li:last-child,
  .cui .footer.footer--basic .footer__links ul.list li:last-child {
    padding-right: 0;
  }
  .cui footer.footer--basic .footer__links ul.list li + li,
  .cui .footer.footer--basic .footer__links ul.list li + li {
    margin: 0 0 0 -1px;
  }
  .cui footer.footer--basic .footer__links ul.list li:after,
  .cui .footer.footer--basic .footer__links ul.list li:after {
    color: #9e9ea2;
    content: '|';
    margin: 0 5px;
    padding: 0;
  }
  .cui footer.footer--basic .footer__links ul.list li:last-child:after,
  .cui .footer.footer--basic .footer__links ul.list li:last-child:after {
    content: '';
    margin: 0;
  }
}
.cui footer.footer--compressed,
.cui .footer.footer--compressed {
  padding: 10px 0;
}
.cui footer.footer--loose,
.cui .footer.footer--loose {
  padding: 40px 0;
}
.cui footer.footer--sticky,
.cui .footer.footer--sticky {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 991.98px) {
  .cui .footer.footer--new {
    font-size: 1.2rem;
  }
  .cui .footer.footer--new.footer--basic .footer__links {
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 575.98px) {
  .cui .footer.footer--new:not(.footer--basic) {
    padding: 10px 0;
  }
  .cui .footer.footer--new:not(.footer--basic) .footer__links ul.list {
    -webkit-column-count: 1;
    column-count: 1;
  }
  .cui .footer.footer--new:not(.footer--basic) .footer__copyright {
    display: none;
  }
  .cui .footer.footer--new:not(.footer--basic) .footer__legal {
    align-items: flex-start;
    flex-direction: column;
  }
  .cui .footer.footer--new:not(.footer--basic) .footer__legal .footer__logo {
    margin-top: 10px;
    order: 2;
  }
  .cui
    .footer.footer--new:not(.footer--basic)
    .footer__legal
    .footer__logo
    a
    .icon-cisco {
    font-size: 1.8rem;
  }
}

.cui .masonry {
  display: block;
  box-sizing: border-box;
  -webkit-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 10px;
  column-gap: 10px;
}
@media (max-width: 1199.98px) {
  .cui .masonry {
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media (max-width: 991.98px) {
  .cui .masonry {
    -webkit-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 767.98px) {
  .cui .masonry {
    -webkit-column-count: 1;
    column-count: 1;
  }
}
@media (max-width: 575.98px) {
  .cui .masonry {
    -webkit-column-count: 1;
    column-count: 1;
  }
}
.cui .masonry > div {
  margin: 0 0 5px;
}
.cui .masonry > div {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  display: inline-block;
  width: 100%;
}
.cui .masonry--cols-2 {
  -webkit-column-count: 2;
  column-count: 2;
}
@media (max-width: 1199.98px) {
  .cui .masonry--cols-2 {
    -webkit-column-count: 1;
    column-count: 1;
  }
}
@media (max-width: 991.98px) {
  .cui .masonry--cols-2 {
    -webkit-column-count: 1;
    column-count: 1;
  }
}
@media (max-width: 767.98px) {
  .cui .masonry--cols-2 {
    -webkit-column-count: 1;
    column-count: 1;
  }
}
@media (max-width: 575.98px) {
  .cui .masonry--cols-2 {
    -webkit-column-count: 1;
    column-count: 1;
  }
}
.cui .masonry--cols-3 {
  -webkit-column-count: 3;
  column-count: 3;
}
@media (max-width: 1199.98px) {
  .cui .masonry--cols-3 {
    -webkit-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 991.98px) {
  .cui .masonry--cols-3 {
    -webkit-column-count: 1;
    column-count: 1;
  }
}
@media (max-width: 767.98px) {
  .cui .masonry--cols-3 {
    -webkit-column-count: 1;
    column-count: 1;
  }
}
@media (max-width: 575.98px) {
  .cui .masonry--cols-3 {
    -webkit-column-count: 1;
    column-count: 1;
  }
}
.cui .masonry--cols-4 {
  -webkit-column-count: 4;
  column-count: 4;
}
@media (max-width: 1199.98px) {
  .cui .masonry--cols-4 {
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media (max-width: 991.98px) {
  .cui .masonry--cols-4 {
    -webkit-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 767.98px) {
  .cui .masonry--cols-4 {
    -webkit-column-count: 1;
    column-count: 1;
  }
}
@media (max-width: 575.98px) {
  .cui .masonry--cols-4 {
    -webkit-column-count: 1;
    column-count: 1;
  }
}
.cui .masonry--cols-5 {
  -webkit-column-count: 5;
  column-count: 5;
}
@media (max-width: 1199.98px) {
  .cui .masonry--cols-5 {
    -webkit-column-count: 4;
    column-count: 4;
  }
}
@media (max-width: 991.98px) {
  .cui .masonry--cols-5 {
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media (max-width: 767.98px) {
  .cui .masonry--cols-5 {
    -webkit-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 575.98px) {
  .cui .masonry--cols-5 {
    -webkit-column-count: 1;
    column-count: 1;
  }
}
.cui .masonry--gap-none {
  -webkit-column-gap: 0;
  column-gap: 0;
}
.cui .masonry--gap-none > div {
  margin: 0 0 -5;
}
.cui .masonry--gap-qtr {
  -webkit-column-gap: 2.5px;
  column-gap: 2.5px;
}
.cui .masonry--gap-qtr > div {
  margin: 0 0 -2.5px;
}
.cui .masonry--gap-half {
  -webkit-column-gap: 5px;
  column-gap: 5px;
}
.cui .masonry--gap-half > div {
  margin: 0 0 0px;
}
.cui .masonry--gap-base {
  -webkit-column-gap: 10px;
  column-gap: 10px;
}
.cui .masonry--gap-base > div {
  margin: 0 0 5px;
}
.cui .masonry--gap-dbl {
  -webkit-column-gap: 20px;
  column-gap: 20px;
}
.cui .masonry--gap-dbl > div {
  margin: 0 0 15px;
}

@media screen and (max-width: 1199px) {
  .show-desktop-menu .cui .content-fluid,
  .show-desktop-menu .cui .content-body {
    transition: padding-left 0.5s, opacity 0.5s, -webkit-transform 0.5s;
    transition: padding-left 0.5s, transform 0.5s, opacity 0.5s;
    transition: padding-left 0.5s, transform 0.5s, opacity 0.5s,
      -webkit-transform 0.5s;
  }
}

@media screen and (min-width: 1200px) {
  .show-desktop-menu .cui .content-fluid,
  .show-desktop-menu .cui .content-body {
    padding-left: 280px;
  }
}

@media screen and (max-width: 1199px) {
  .show-desktop-menu.show-menu .cui .content-fluid,
  .show-desktop-menu.show-menu .cui .content-body {
    -webkit-transform: translateX(280px);
    transform: translateX(280px);
  }
}

@media screen and (max-width: 767px) {
  .hide-desktop-menu .cui .content-fluid,
  .hide-desktop-menu .cui .content-body {
    transition: padding-left 0.5s, opacity 0.5s, -webkit-transform 0.5s;
    transition: padding-left 0.5s, transform 0.5s, opacity 0.5s;
    transition: padding-left 0.5s, transform 0.5s, opacity 0.5s,
      -webkit-transform 0.5s;
  }
}

@media screen and (max-width: 767px) {
  .hide-desktop-menu.show-menu .cui .content-fluid,
  .hide-desktop-menu.show-menu .cui .content-body {
    -webkit-transform: translateX(280px);
    transform: translateX(280px);
  }
}

.cui .toggle-menu {
  margin-left: 0;
  margin-right: 20px;
}
@media screen and (min-width: 768px) {
  .cui .toggle-menu {
    margin-right: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .cui .toggle-menu {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .hide-desktop-menu .cui .toggle-menu {
    display: none;
  }
}

.cui .sidebar {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 280px;
  height: 100%;
  z-index: 1031;
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
  background: #017cad;
  color: #ffffff;
}
@media (min-width: 576px) {
  .cui .sidebar {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.cui .sidebar a {
  color: #ffffff;
}
.cui .sidebar li.sidebar__item--selected a,
.cui .sidebar li.selected a {
  color: #ffffff;
  box-shadow: 5px 0 0 #ffffff inset;
  background-color: rgba(255, 255, 255, 0.1);
}
.cui .sidebar li.sidebar__item--selected a > span[class^='icon-'],
.cui .sidebar li.selected a > span[class^='icon-'] {
  opacity: 1;
}
.cui .sidebar li a:hover,
.cui .sidebar li a:visited,
.cui .sidebar li a:active,
.cui .sidebar li a:focus {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
.cui .sidebar li a:hover > span[class^='icon-'],
.cui .sidebar li a:visited > span[class^='icon-'],
.cui .sidebar li a:active > span[class^='icon-'],
.cui .sidebar li a:focus > span[class^='icon-'] {
  opacity: 1;
}
.cui .sidebar > ul li.sidebar__drawer > a:after {
  color: #ffffff;
}
.cui .sidebar > ul li.sidebar__drawer:hover > a:after {
  color: #ffffff;
}
.cui .sidebar > ul > li {
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25) inset;
}
.cui .sidebar li.sidebar__drawer.sidebar__drawer--opened {
  background-color: #016a94;
}
.cui .sidebar li.sidebar__drawer.sidebar__drawer--opened a:hover {
  box-shadow: 5px 0 0 #ffffff inset;
}
.cui .sidebar--ltgray {
  background: #f2f2f2;
  color: #58585b;
}
.cui .sidebar--ltgray a {
  color: #58585b;
}
.cui .sidebar--ltgray li.sidebar__item--selected a,
.cui .sidebar--ltgray li.selected a {
  color: #58585b;
  box-shadow: 5px 0 0 #ffffff inset;
  background-color: rgba(0, 0, 0, 0.1);
}
.cui .sidebar--ltgray li.sidebar__item--selected a > span[class^='icon-'],
.cui .sidebar--ltgray li.selected a > span[class^='icon-'] {
  opacity: 1;
}
.cui .sidebar--ltgray li a:hover,
.cui .sidebar--ltgray li a:visited,
.cui .sidebar--ltgray li a:active,
.cui .sidebar--ltgray li a:focus {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.1);
  color: #58585b;
}
.cui .sidebar--ltgray li a:hover > span[class^='icon-'],
.cui .sidebar--ltgray li a:visited > span[class^='icon-'],
.cui .sidebar--ltgray li a:active > span[class^='icon-'],
.cui .sidebar--ltgray li a:focus > span[class^='icon-'] {
  opacity: 1;
}
.cui .sidebar--ltgray > ul li.sidebar__drawer > a:after {
  color: #58585b;
}
.cui .sidebar--ltgray > ul li.sidebar__drawer:hover > a:after {
  color: #58585b;
}
.cui .sidebar--ltgray > ul > li {
  box-shadow: 0 1px 0 #dfdfdf inset;
}
.cui .sidebar--ltgray li.sidebar__drawer.sidebar__drawer--opened {
  background-color: #d9d9d9;
}
.cui .sidebar--ltgray li.sidebar__drawer.sidebar__drawer--opened a:hover {
  box-shadow: 5px 0 0 #ffffff inset;
}
.cui .sidebar--dkgray {
  background: #58585b;
  color: #ffffff;
}
.cui .sidebar--dkgray a {
  color: #ffffff;
}
.cui .sidebar--dkgray li.sidebar__item--selected a,
.cui .sidebar--dkgray li.selected a {
  color: #ffffff;
  box-shadow: 5px 0 0 #ffffff inset;
  background-color: rgba(255, 255, 255, 0.1);
}
.cui .sidebar--dkgray li.sidebar__item--selected a > span[class^='icon-'],
.cui .sidebar--dkgray li.selected a > span[class^='icon-'] {
  opacity: 1;
}
.cui .sidebar--dkgray li a:hover,
.cui .sidebar--dkgray li a:visited,
.cui .sidebar--dkgray li a:active,
.cui .sidebar--dkgray li a:focus {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
.cui .sidebar--dkgray li a:hover > span[class^='icon-'],
.cui .sidebar--dkgray li a:visited > span[class^='icon-'],
.cui .sidebar--dkgray li a:active > span[class^='icon-'],
.cui .sidebar--dkgray li a:focus > span[class^='icon-'] {
  opacity: 1;
}
.cui .sidebar--dkgray > ul li.sidebar__drawer > a:after {
  color: #ffffff;
}
.cui .sidebar--dkgray > ul li.sidebar__drawer:hover > a:after {
  color: #ffffff;
}
.cui .sidebar--dkgray > ul > li {
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25) inset;
}
.cui .sidebar--dkgray li.sidebar__drawer.sidebar__drawer--opened {
  background-color: #3f3f41;
}
.cui .sidebar--dkgray li.sidebar__drawer.sidebar__drawer--opened a:hover {
  box-shadow: 5px 0 0 #ffffff inset;
}
.cui .sidebar--indigo {
  background: #005073;
  color: #ffffff;
}
.cui .sidebar--indigo a {
  color: #ffffff;
}
.cui .sidebar--indigo li.sidebar__item--selected a,
.cui .sidebar--indigo li.selected a {
  color: #ffffff;
  box-shadow: 5px 0 0 #ffffff inset;
  background-color: rgba(255, 255, 255, 0.1);
}
.cui .sidebar--indigo li.sidebar__item--selected a > span[class^='icon-'],
.cui .sidebar--indigo li.selected a > span[class^='icon-'] {
  opacity: 1;
}
.cui .sidebar--indigo li a:hover,
.cui .sidebar--indigo li a:visited,
.cui .sidebar--indigo li a:active,
.cui .sidebar--indigo li a:focus {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
.cui .sidebar--indigo li a:hover > span[class^='icon-'],
.cui .sidebar--indigo li a:visited > span[class^='icon-'],
.cui .sidebar--indigo li a:active > span[class^='icon-'],
.cui .sidebar--indigo li a:focus > span[class^='icon-'] {
  opacity: 1;
}
.cui .sidebar--indigo > ul li.sidebar__drawer > a:after {
  color: #ffffff;
}
.cui .sidebar--indigo > ul li.sidebar__drawer:hover > a:after {
  color: #ffffff;
}
.cui .sidebar--indigo > ul > li {
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25) inset;
}
.cui .sidebar--indigo li.sidebar__drawer.sidebar__drawer--opened {
  background-color: #003e5a;
}
.cui .sidebar--indigo li.sidebar__drawer.sidebar__drawer--opened a:hover {
  box-shadow: 5px 0 0 #ffffff inset;
}
.cui .sidebar__header {
  padding: 15px;
  text-align: center;
}
.cui .sidebar__header a:hover {
  box-shadow: none;
}
.cui .sidebar--hidden {
  visibility: hidden;
  transition: all 0.25s;
  -webkit-transform: translate3d(-280px, 0, 0);
  transform: translate3d(-280px, 0, 0);
}
.cui .sidebar ul {
  list-style: none;
  padding-left: 0;
}
.cui .sidebar li.sidebar__item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cui .sidebar li.sidebar__item--selected a,
.cui .sidebar li.selected a,
.cui .sidebar li a:hover {
  text-decoration: none;
}
.cui .sidebar a {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cui .sidebar a > span[class^='icon-'] {
  opacity: 0.75;
  margin-right: 10px;
}
.cui .sidebar--compressed .sidebar__header {
  padding: 10px;
}
.cui .sidebar--compressed a {
  height: 30px;
}
.cui .sidebar--compressed ul li ul > li > a {
  font-size: 1.2rem;
}
.cui .sidebar ul:after {
  clear: both !important;
  content: '' !important;
  display: table !important;
}
.cui .sidebar ul li.sidebar__drawer:hover > a:after,
.cui .sidebar ul li.sidebar-drawer:hover > a:after {
  opacity: 1;
}
.cui .sidebar ul li.sidebar__drawer > a:after,
.cui .sidebar ul li.sidebar-drawer > a:after {
  content: '';
  -webkit-transform: rotate(0);
  transform: rotate(0);
  opacity: 0.5;
  position: absolute;
  right: 14px;
}
.cui .sidebar ul li.sidebar__drawer > ul,
.cui .sidebar ul li.sidebar-drawer > ul {
  display: none;
}
.cui .sidebar ul li.sidebar__drawer--opened > a:after,
.cui .sidebar ul li.sidebar-drawer--opened > a:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  opacity: 1;
}
.cui .sidebar ul li.sidebar__drawer--opened > ul,
.cui .sidebar ul li.sidebar-drawer--opened > ul {
  display: block;
}
.cui .sidebar ul li ul > li > a {
  padding-left: 40px;
}
.cui .sidebar ul li ul > li > ul > li > a {
  padding-left: 60px;
}
.cui .sidebar ul.sidebar-section > li {
  box-shadow: none;
}
.cui .sidebar ul.sidebar-section > li a {
  display: flex;
  align-items: center;
}
.cui .sidebar ul.sidebar-section > li a > span[class^='icon-'] {
  margin-right: 7px;
}
.cui .sidebar .sidebar-header + .sidebar-section,
.cui .sidebar .sidebar-section + .sidebar-section {
  border-top: 1px solid #dfdfdf;
}
.cui .sidebar .sidebar__item-action,
.cui .sidebar .sidebar-action {
  position: absolute;
  font-size: 1.4rem;
  right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cui .sidebar__header-title,
.cui .sidebar-header__title {
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 1;
}

.cui .sidebar__header-toolbar,
.cui .sidebar-header__toolbar {
  margin-top: 15px;
}
.cui .sidebar__header-toolbar .btn,
.cui .sidebar-header__toolbar .btn {
  color: #ffffff;
  opacity: 0.5;
}
.cui .sidebar__header-toolbar .btn:hover,
.cui .sidebar-header__toolbar .btn:hover {
  opacity: 1;
}

.cui .content-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1029;
  display: block;
  margin: auto;
  background: rgba(0, 0, 0, 0.2);
  content: '';
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
}
@media screen and (max-width: 1199px) {
  .show-desktop-menu .cui .content-overlay {
    transition: padding-left 0.5s, opacity 0.5s, -webkit-transform 0.5s;
    transition: padding-left 0.5s, transform 0.5s, opacity 0.5s;
    transition: padding-left 0.5s, transform 0.5s, opacity 0.5s,
      -webkit-transform 0.5s;
  }
}
@media screen and (max-width: 1199px) {
  .show-desktop-menu.show-menu .cui .content-overlay {
    -webkit-transform: translateX(280px);
    transform: translateX(280px);
    opacity: 1;
    pointer-events: auto;
  }
}
@media screen and (max-width: 767px) {
  .hide-desktop-menu .cui .content-overlay {
    transition: padding-left 0.5s, opacity 0.5s, -webkit-transform 0.5s;
    transition: padding-left 0.5s, transform 0.5s, opacity 0.5s;
    transition: padding-left 0.5s, transform 0.5s, opacity 0.5s,
      -webkit-transform 0.5s;
  }
}
@media screen and (max-width: 767px) {
  .hide-desktop-menu.show-menu .cui .content-overlay {
    -webkit-transform: translateX(280px);
    transform: translateX(280px);
    opacity: 1;
    pointer-events: auto;
  }
}

@media screen and (max-height: 458px) {
  .cui .sidebar-logo,
  .cui .sidebar__logo {
    position: relative;
    bottom: auto;
    text-align: center;
    top: 90px;
  }
}

@media screen and (min-height: 458px) {
  .cui .sidebar-logo,
  .cui .sidebar__logo {
    text-align: center;
    position: absolute;
    bottom: 100px;
    left: calc(140px - 48px);
    top: auto;
  }
}

.cui .sidebar-logo span,
.cui .sidebar__logo span {
  font-weight: light;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  display: block;
}

.cui.cui--compressed .sidebar .sidebar__header {
  padding: 10px;
}

.cui.cui--compressed .sidebar ul > li a {
  height: 30px;
}

.cui.cui--compressed .sidebar ul li ul > li > a {
  font-size: 1.2rem;
}

.cui.cui--animated .sidebar ul > li.sidebar__drawer a {
  transition: all 0.2s linear;
}
.cui.cui--animated .sidebar ul > li.sidebar__drawer a:hover::after {
  transition: all 0.2s;
}

.cui button[data-balloon] {
  overflow: visible;
}

.cui [data-balloon] {
  position: relative;
}
.cui [data-balloon]:after {
  filter: alpha(opactiy=0);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.18s ease-out 0.18s;
  font-weight: normal;
  font-style: normal;
  text-shadow: none;
  font-size: 1.2rem !important;
  background: rgba(57, 57, 59, 0.95);
  border-radius: 4px;
  color: #fff;
  content: attr(data-balloon);
  padding: 0.5em 1em;
  position: absolute;
  white-space: nowrap;
  z-index: 10;
}
.cui [data-balloon]:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(57, 57, 59, 0.95)%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  filter: alpha(opactiy=0);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.18s ease-out 0.18s;
  content: '';
  position: absolute;
  z-index: 10;
}
.cui [data-balloon]:hover:before,
.cui [data-balloon]:hover:after,
.cui [data-balloon][data-balloon-visible]:before,
.cui [data-balloon][data-balloon-visible]:after {
  filter: alpha(opactiy=100);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  pointer-events: auto;
}
.cui [data-balloon].font-awesome:after {
  font-family: FontAwesome;
}
.cui [data-balloon][data-balloon-break]:after {
  white-space: pre;
}
.cui [data-balloon][data-balloon-blunt]:before,
.cui [data-balloon][data-balloon-blunt]:after {
  transition: none;
}
.cui [data-balloon][data-balloon-pos='up']:after {
  bottom: 100%;
  left: 50%;
  margin-bottom: 11px;
  -webkit-transform: translate(-50%, 10px);
  transform: translate(-50%, 10px);
  -webkit-transform-origin: top;
  transform-origin: top;
}
.cui [data-balloon][data-balloon-pos='up']:before {
  bottom: 100%;
  left: 50%;
  margin-bottom: 5px;
  -webkit-transform: translate(-50%, 10px);
  transform: translate(-50%, 10px);
  -webkit-transform-origin: top;
  transform-origin: top;
}
.cui [data-balloon][data-balloon-pos='up']:hover:after,
.cui [data-balloon][data-balloon-pos='up'][data-balloon-visible]:after {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.cui [data-balloon][data-balloon-pos='up']:hover:before,
.cui [data-balloon][data-balloon-pos='up'][data-balloon-visible]:before {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.cui [data-balloon][data-balloon-pos='up-left']:after {
  bottom: 100%;
  left: 0;
  margin-bottom: 11px;
  -webkit-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transform-origin: top;
  transform-origin: top;
}
.cui [data-balloon][data-balloon-pos='up-left']:before {
  bottom: 100%;
  left: 5px;
  margin-bottom: 5px;
  -webkit-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transform-origin: top;
  transform-origin: top;
}
.cui [data-balloon][data-balloon-pos='up-left']:hover:after,
.cui [data-balloon][data-balloon-pos='up-left'][data-balloon-visible]:after {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.cui [data-balloon][data-balloon-pos='up-left']:hover:before,
.cui [data-balloon][data-balloon-pos='up-left'][data-balloon-visible]:before {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.cui [data-balloon][data-balloon-pos='up-right']:after {
  bottom: 100%;
  right: 0;
  margin-bottom: 11px;
  -webkit-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transform-origin: top;
  transform-origin: top;
}
.cui [data-balloon][data-balloon-pos='up-right']:before {
  bottom: 100%;
  right: 5px;
  margin-bottom: 5px;
  -webkit-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transform-origin: top;
  transform-origin: top;
}
.cui [data-balloon][data-balloon-pos='up-right']:hover:after,
.cui [data-balloon][data-balloon-pos='up-right'][data-balloon-visible]:after {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.cui [data-balloon][data-balloon-pos='up-right']:hover:before,
.cui [data-balloon][data-balloon-pos='up-right'][data-balloon-visible]:before {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.cui [data-balloon][data-balloon-pos='down']:after {
  left: 50%;
  margin-top: 11px;
  top: 100%;
  -webkit-transform: translate(-50%, -10px);
  transform: translate(-50%, -10px);
}
.cui [data-balloon][data-balloon-pos='down']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(57, 57, 59, 0.95)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  left: 50%;
  margin-top: 5px;
  top: 100%;
  -webkit-transform: translate(-50%, -10px);
  transform: translate(-50%, -10px);
}
.cui [data-balloon][data-balloon-pos='down']:hover:after,
.cui [data-balloon][data-balloon-pos='down'][data-balloon-visible]:after {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.cui [data-balloon][data-balloon-pos='down']:hover:before,
.cui [data-balloon][data-balloon-pos='down'][data-balloon-visible]:before {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.cui [data-balloon][data-balloon-pos='down-left']:after {
  left: 0;
  margin-top: 11px;
  top: 100%;
  -webkit-transform: translate(0, -10px);
  transform: translate(0, -10px);
}
.cui [data-balloon][data-balloon-pos='down-left']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(57, 57, 59, 0.95)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  left: 5px;
  margin-top: 5px;
  top: 100%;
  -webkit-transform: translate(0, -10px);
  transform: translate(0, -10px);
}
.cui [data-balloon][data-balloon-pos='down-left']:hover:after,
.cui [data-balloon][data-balloon-pos='down-left'][data-balloon-visible]:after {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.cui [data-balloon][data-balloon-pos='down-left']:hover:before,
.cui [data-balloon][data-balloon-pos='down-left'][data-balloon-visible]:before {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.cui [data-balloon][data-balloon-pos='down-right']:after {
  right: 0;
  margin-top: 11px;
  top: 100%;
  -webkit-transform: translate(0, -10px);
  transform: translate(0, -10px);
}
.cui [data-balloon][data-balloon-pos='down-right']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(57, 57, 59, 0.95)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  right: 5px;
  margin-top: 5px;
  top: 100%;
  -webkit-transform: translate(0, -10px);
  transform: translate(0, -10px);
}
.cui [data-balloon][data-balloon-pos='down-right']:hover:after,
.cui [data-balloon][data-balloon-pos='down-right'][data-balloon-visible]:after {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.cui [data-balloon][data-balloon-pos='down-right']:hover:before,
.cui
  [data-balloon][data-balloon-pos='down-right'][data-balloon-visible]:before {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.cui [data-balloon][data-balloon-pos='left']:after {
  margin-right: 11px;
  right: 100%;
  top: 50%;
  -webkit-transform: translate(10px, -50%);
  transform: translate(10px, -50%);
}
.cui [data-balloon][data-balloon-pos='left']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(57, 57, 59, 0.95)%22%20transform%3D%22rotate(-90 18 18)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 6px;
  height: 18px;
  margin-right: 5px;
  right: 100%;
  top: 50%;
  -webkit-transform: translate(10px, -50%);
  transform: translate(10px, -50%);
}
.cui [data-balloon][data-balloon-pos='left']:hover:after,
.cui [data-balloon][data-balloon-pos='left'][data-balloon-visible]:after {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.cui [data-balloon][data-balloon-pos='left']:hover:before,
.cui [data-balloon][data-balloon-pos='left'][data-balloon-visible]:before {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.cui [data-balloon][data-balloon-pos='right']:after {
  left: 100%;
  margin-left: 11px;
  top: 50%;
  -webkit-transform: translate(-10px, -50%);
  transform: translate(-10px, -50%);
}
.cui [data-balloon][data-balloon-pos='right']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(57, 57, 59, 0.95)%22%20transform%3D%22rotate(90 6 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 6px;
  height: 18px;
  left: 100%;
  margin-left: 5px;
  top: 50%;
  -webkit-transform: translate(-10px, -50%);
  transform: translate(-10px, -50%);
}
.cui [data-balloon][data-balloon-pos='right']:hover:after,
.cui [data-balloon][data-balloon-pos='right'][data-balloon-visible]:after {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.cui [data-balloon][data-balloon-pos='right']:hover:before,
.cui [data-balloon][data-balloon-pos='right'][data-balloon-visible]:before {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.cui [data-balloon][data-balloon-length='small']:after {
  white-space: normal;
  width: 80px;
}
.cui [data-balloon][data-balloon-length='medium']:after {
  white-space: normal;
  width: 150px;
}
.cui [data-balloon][data-balloon-length='large']:after {
  white-space: normal;
  width: 260px;
}
.cui [data-balloon][data-balloon-length='xlarge']:after {
  white-space: normal;
  width: 380px;
}
@media screen and (max-width: 768px) {
  .cui [data-balloon][data-balloon-length='xlarge']:after {
    white-space: normal;
    width: 90vw;
  }
}
.cui [data-balloon][data-balloon-length='fit']:after {
  white-space: normal;
  width: 100%;
}

.cui [data-balloon][data-balloon-success][data-balloon]:after {
  background: rgba(110, 190, 74, 0.99);
}

.cui [data-balloon][data-balloon-success][data-balloon-pos='up']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(110, 190, 74, 0.99)%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 18px;
  height: 6px;
}

.cui [data-balloon][data-balloon-success][data-balloon-pos='down']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(110, 190, 74, 0.99)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 18px;
  height: 6px;
}

.cui [data-balloon][data-balloon-success][data-balloon-pos='left']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(110, 190, 74, 0.99)%22%20transform%3D%22rotate(-90 18 18)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 6px;
  height: 18px;
}

.cui [data-balloon][data-balloon-success][data-balloon-pos='right']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(110, 190, 74, 0.99)%22%20transform%3D%22rotate(90 6 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 6px;
  height: 18px;
}

.cui [data-balloon][data-balloon-indigo][data-balloon]:after {
  background: rgba(0, 80, 115, 0.99);
}

.cui
  [data-balloon][data-balloon-indigo][data-balloon][data-balloon-pos='up']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(0, 80, 115, 0.99)%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 18px;
  height: 6px;
}

.cui
  [data-balloon][data-balloon-indigo][data-balloon][data-balloon-pos='down']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(0, 80, 115, 0.99)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 18px;
  height: 6px;
}

.cui
  [data-balloon][data-balloon-indigo][data-balloon][data-balloon-pos='left']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(0, 80, 115, 0.99)%22%20transform%3D%22rotate(-90 18 18)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 6px;
  height: 18px;
}

.cui
  [data-balloon][data-balloon-indigo][data-balloon][data-balloon-pos='right']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(0, 80, 115, 0.99)%22%20transform%3D%22rotate(90 6 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 6px;
  height: 18px;
}

.cui [data-balloon][data-balloon-blue][data-balloon]:after {
  background: rgba(0, 188, 235, 0.99);
  color: #005073;
}

.cui
  [data-balloon][data-balloon-blue][data-balloon][data-balloon-pos='up']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(0, 188, 235, 0.99)%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 18px;
  height: 6px;
}

.cui
  [data-balloon][data-balloon-blue][data-balloon][data-balloon-pos='down']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(0, 188, 235, 0.99)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 18px;
  height: 6px;
}

.cui
  [data-balloon][data-balloon-blue][data-balloon][data-balloon-pos='left']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(0, 188, 235, 0.99)%22%20transform%3D%22rotate(-90 18 18)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 6px;
  height: 18px;
}

.cui
  [data-balloon][data-balloon-blue][data-balloon][data-balloon-pos='right']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(0, 188, 235, 0.99)%22%20transform%3D%22rotate(90 6 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 6px;
  height: 18px;
}

.cui.template--cdc {
  background-color: #f5f5f6;
  overflow-y: auto;
}
.cui.template--cdc .container {
  min-width: 0;
  max-width: 1440px;
  width: auto;
}
.cui.template--cdc header,
.cui.template--cdc main,
.cui.template--cdc footer {
  display: block;
  max-width: 1600px;
  margin: 0 auto;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.12);
}
.cui.template--cdc header,
.cui.template--cdc main {
  background-color: #ffffff;
}
.cui.template--cdc header {
  box-sizing: border-box;
  min-height: 50px;
  padding: 0;
  height: auto;
}
.cui.template--cdc header .header-bar {
  width: auto;
}
.cui.template--cdc header .toggle-menu {
  visibility: hidden;
  width: 0;
  min-width: 0;
  margin: 0;
}
@media (max-width: 991.98px) {
  .cui.template--cdc header {
    height: 50px;
  }
  .cui.template--cdc header .header-bar,
  .cui.template--cdc header.header--regular .header-bar {
    height: 50px;
  }
  .cui.template--cdc header .header-bar__main {
    order: 1;
    flex: 1;
    margin: 0;
  }
  .cui.template--cdc header .header-bar__main ul.list {
    display: none;
  }
  .cui.template--cdc header .header-bar__main .toggle-menu {
    min-width: 30px;
    width: 30px;
    visibility: visible;
  }
  .cui.template--cdc header .header-bar__logo {
    display: block;
    order: 2;
    flex: 1;
    text-align: center;
  }
  .cui.template--cdc header .header-bar__logo .icon-cisco {
    font-size: 2.4rem;
  }
  .cui.template--cdc header .header-toolbar {
    display: block;
    order: 3;
    flex: 1;
    justify-content: flex-end;
    text-align: right;
  }
  .cui.template--cdc header .header-toolbar .avatar {
    display: none;
  }
}
.cui.template--cdc main {
  min-height: 600px;
  position: relative;
}
.cui.template--cdc main:after {
  content: '';
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  position: absolute;
  top: -5px;
  height: 5px;
  left: 0;
  right: 0;
  bottom: auto;
}
.cui.template--cdc footer {
  background-color: #8e9299;
  width: auto;
  overflow: hidden;
  padding: 20px 0;
}
.cui.template--cdc footer a {
  color: #ffffff;
}
