// This file override styles from the ReactTable component
@import '../../../../styles/includes.scss';

$cisco-btn-blue: #017cad;
$grey: #c7c6c6;

.ReactTable.expandable-table {
  font-size: rem(13);
  border: none;

  .cell {
    white-space: normal;
    word-break: break-all;
  }

  .rt-thead {
    &-header {
      box-shadow: none;
    }

    .rt-th {
      border-bottom: none;
      font-weight: bold;
      border-right: none;
      border-left: none;
      background-color: $white;
      box-shadow: none;
      text-align: left;

      &.-sort-asc {
        box-shadow: none;
      }
    }
  }

  .rt-tr {
    border-bottom: none;
    cursor: pointer;

    &.-odd.rt-tr--expanded, &.-even.rt-tr--expanded {
      background-color: $cisco-blue;
      border-bottom-color: $status-blue;
      color: $white;

      .expander {
        color: $white;
      }
    }
  }

  .rt-tbody {
    .rt-tr-group {
      border-bottom: none;
    }
  }

  .rt-tr.-odd {
    background-color: $off-white;
  }

  .rt-tr.-even {
    background-color: $white;
  }

  .rt-noData {
    background: none;
    top: 72%;
  }
}

.ReactTable .-pagination {
  box-shadow: none;
  border-top: 0;
  padding: rem(20);

  .-next, .-previous {
    flex: 0;
  }

  .-btn {
    border-radius: rem(30);
    font-size: rem(16);
    padding: 0 rem(35);
    height: rem(40);
    min-width: rem(135);
    background: 0;
    border: 1px solid $cisco-btn-blue;
    color: $cisco-btn-blue;

    &:hover {
      color: $white;
      background-color: $cisco-btn-blue !important;
    }
  }

  .-pageJump input {
    border: 1px solid $grey;
    border-radius: 0;
    padding: rem(10);
    width: rem(50);
    min-width: rem(50);
  }
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-tbody .rt-td {
  border: 0;
}
